<template>
  <div>
    <mosaic-error-alert :override-error-message="error" class="mt-0" />
    <file-pond
      ref="filepond"
      name="file"
      :allow-multiple="allowMultiple"
      :max-files="maxFiles"
      :allow-revert="allowRevert"
      :server="server"
      :max-file-size="maxFileSize"
      :before-add-file="beforeAddFile"
      :accepted-file-types="contentTypes"
      :file-validate-type-detect-type="detectFileType"
      file-validate-type-label-expected-types="If you expect to be able to upload this type of file, then please contact support"
      @addfilestart="updateFileCount"
      @processfilestart="updateFileCount"
      @processfileabort="updateFileCount"
      @removefile="updateFileCount"
      @processfile="onProcessFile"
      @error="onError"
    />
  </div>
</template>

<script>
import vueFilePond from 'vue-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { allowedS3ContentTypes } from './s3-file-content-types';
import { invalidCharacters } from '@/utils/files';
import axios from 'axios';
import { fileMD5 } from '@/utils/md5';

const FilePond = vueFilePond(
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType
);

export default {
  name: 'MosaicFilePondExternalStorage1',
  components: { FilePond },
  props: {
    maxFiles: {
      type: Number,
      default: null,
    },
    getUploadUrlAndHeaders: {
      type: Function,
      required: true,
    },
    allowedContentTypes: {
      type: Array,
      required: false,
    },
  },
  emits: ['fileCountUpdated', 'fileUploaded'],
  data() {
    return {
      error: '',
      allowMultiple: !this.maxFiles || this.maxFiles > 1,
      allowRevert: false,
      maxFileSize: '5000MB', // Unsure if this is decimal or binary or MB, so gone for decimal to be safe, as S3 only accepts up to 5GB as a single part upload
      errorRequest: null,
      server: {
        maxFiles: this.maxFiles,
        process: (fieldName, file, metadata, load, error, progress, abort) => {
          const filepondRequest = new XMLHttpRequest();
          const cancelSource = axios.CancelToken.source();
          this.uploadFile(filepondRequest, file, load, progress, error, cancelSource.token);

          return {
            abort: () => {
              cancelSource.cancel();
              filepondRequest.abort();
              abort();
            },
          };
        },
      },
    };
  },
  computed: {
    contentTypes() {
      return this.allowedContentTypes || allowedS3ContentTypes;
    },
  },
  methods: {
    removeFile(fileId) {
      this.$refs.filepond.removeFile(fileId);
      this.updateFileCount();
    },
    async uploadFile(filepondRequest, file, load, progress, error, cancelToken) {
      try {
        const filepondFormData = new FormData();
        const fileType = await this.detectFileType(file, file.type);
        const { url, fields, fileId } = await this.getUploadUrlAndHeaders(cancelToken, fileType, file.name);
        for (const field in fields) {
          filepondFormData.append(field, fields[field]);
        }
        filepondFormData.append('Content-MD5', await fileMD5(file));
        filepondFormData.append('file', file);
        filepondRequest.upload.onprogress = function (e) {
          progress(e.lengthComputable, e.loaded, e.total);
        };
        filepondRequest.open('POST', url);
        filepondRequest.onload = function () {
          if (filepondRequest.status >= 200 && filepondRequest.status < 300) {
            load(fileId);
          } else {
            console.log('filepondRequest.onload');
            error();
          }
        };
        filepondRequest.onerror = function () {
          console.log('filepondRequest.onerror');
          error();
        };
        filepondRequest.send(filepondFormData);
      } catch (e) {
        console.log('uploadFile error', e);
        error(e);
      }
    },
    updateFileCount() {
      this.$emit('fileCountUpdated', this.$refs.filepond.getFiles().length);
    },
    onProcessFile(error, x) {
      if (!error) {
        this.updateFileCount();
        this.$emit('fileUploaded', {
          id: x.id,
          title: x.file.name,
          fileId: x.serverId,
        });
      }
    },
    async onError(error) {
      console.log('onError', error);
      if (
        error.response &&
        error.response.status === 422 &&
        error.response.data.error_code === 'disallowed_file_type'
      ) {
        this.error =
          'File is of invalid type. If you expect to be able to upload this type of file, then please contact support.';
      }
      this.updateFileCount();
    },
    onClose() {
      this.$refs.filepond.removeFiles();
      this.$emit('fileCountUpdated', 0);
    },
    detectFileType(source, type) {
      console.log('detectFileType', type, source);
      return new Promise(resolve => {
        if (source.name.endsWith('.notebook')) {
          resolve('application/x-notebook');
        } else if (source.name.endsWith('.flipchart')) {
          resolve('application/x-flipchart');
        } else if (source.name.endsWith('.pub')) {
          resolve('application/x-mspublisher');
        } else {
          resolve(type);
        }
      });
    },
    beforeAddFile(x) {
      this.error = '';
      const invalidChars = invalidCharacters.filter(c => x.filename.includes(c));
      if (invalidChars.length > 0) {
        this.error = `File name contains an invalid character: ${invalidChars.join(' ')}`;
        return false;
      }
      return true;
    },
  },
};
</script>

<style>
@import 'filepond/dist/filepond.min.css';
@import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
</style>
