<template>
  <div>
    <mosaic-loading-card v-if="busy" type="ect-progress"></mosaic-loading-card>
    <v-card v-else-if="error">
      <v-card-text>{{ error }}</v-card-text>
    </v-card>
    <v-card v-else>
      <v-card-text class="pa-5">
        <template v-if="progress">
          <div class="text-h6">
            <span class="mr-2">Progress</span>
            <v-tooltip v-if="ect.current_year" location="top">
              <template #activator="{ props }">
                <v-chip color="accent" v-bind="props" class="mr-2">{{
                  ect.current_year === 'year_1' ? 'Year 1' : 'Year 2'
                }}</v-chip>
              </template>
              <div v-if="ect.current_year === 'year_1'">
                An {{ traineeNounCapitalised() }} is in Year 1 of their induction until they've passed the projected
                date and had their End of Year 1 {{ reviewNounCapitalised }} approved
              </div>
              <div v-else>This {{ traineeNounCapitalised() }} is in Year 2 of their induction</div>
            </v-tooltip>
            <v-chip v-if="ect.current_fte_rate" class="mr-2">{{ ect.current_fte_rate }} FTE</v-chip>
            <v-chip v-if="ect.paused">Paused</v-chip>
          </div>
          <div v-if="!smallScreen" class="pb-2">
            <div class="d-flex mt-8">
              <div style="width: 50px"></div>
              <div style="width: calc(100% - 100px); position: relative">
                <v-progress-linear
                  striped
                  height="10"
                  :model-value="progress.current.percent"
                  color="primary"
                ></v-progress-linear>
                <div
                  v-for="e in timelineEvents"
                  :key="e.id"
                  :style="{ position: 'absolute', left: `calc(${e.percent}% - 15px)`, top: '-8px', 'z-index': 3 }"
                >
                  <v-tooltip location="top">
                    <template #activator="{ props }">
                      <div class="timeline-icon d-flex justify-center align-center" v-bind="props">
                        <v-icon color="accent" size="20">{{ e.icon }}</v-icon>
                      </div>
                    </template>
                    <span>{{ e.tooltip }}</span>
                  </v-tooltip>
                </div>
                <div
                  v-if="beforeJoinedMosaicPeriod"
                  :style="{
                    position: 'absolute',
                    left: `calc(${beforeJoinedMosaicPeriod.start}%)`,
                    top: '0px',
                    background: 'grey',
                    width: `calc(${beforeJoinedMosaicPeriod.end - beforeJoinedMosaicPeriod.start}%)`,
                    height: '10px',
                    'z-index': 2,
                  }"
                ></div>
                <div
                  v-for="period in pausedAndLeftPeriods"
                  :key="period.start"
                  :style="{
                    position: 'absolute',
                    left: `calc(${period.start}% + 10px)`,
                    top: '0px',
                    background: 'white',
                    width: `calc(${period.end - period.start}%)`,
                    height: '10px',
                    'z-index': 2,
                  }"
                ></div>
              </div>
            </div>
            <div class="d-flex mb-4">
              <div style="width: 50px"></div>
              <div class="flex-grow-1" style="position: relative">
                <div class="progress-tick" :style="{ position: 'absolute', left: '0%' }"></div>
                <div
                  v-if="progress.fte_term_1_end.percent"
                  class="progress-tick"
                  :style="{ position: 'absolute', left: `${progress.fte_term_1_end.percent}%` }"
                ></div>
                <div
                  v-if="progress.fte_term_2_end.percent"
                  class="progress-tick"
                  :style="{ position: 'absolute', left: `${progress.fte_term_2_end.percent}%` }"
                ></div>
                <div
                  v-if="progress.year_1_end.percent"
                  class="progress-tick"
                  :style="{ position: 'absolute', left: `${progress.year_1_end.percent}%` }"
                ></div>
                <div
                  v-if="progress.fte_term_4_end.percent"
                  class="progress-tick"
                  :style="{ position: 'absolute', left: `${progress.fte_term_4_end.percent}%` }"
                ></div>
                <div
                  v-if="progress.fte_term_5_end.percent"
                  class="progress-tick"
                  :style="{ position: 'absolute', left: `${progress.fte_term_5_end.percent}%` }"
                ></div>
                <div
                  v-if="progress.year_2_end.percent"
                  class="progress-tick"
                  :style="{ position: 'absolute', left: 'calc(100% - 2px)' }"
                ></div>
              </div>
              <div style="width: 50px"></div>
            </div>
            <div class="d-flex mb-10">
              <div style="width: 50px"></div>

              <div class="flex-grow-1" style="position: relative">
                <div :style="{ position: 'absolute', left: '0%', transform: 'translateX(-50%)' }">
                  <div class="text-center font-weight-bold">{{ formatDate(progress.start.date) }}</div>
                  <div class="text-center text-overline">Start</div>
                </div>
                <div
                  v-if="progress.fte_term_1_end.percent"
                  :style="{
                    position: 'absolute',
                    left: `${progress.fte_term_1_end.percent}%`,
                    transform: 'translateX(-50%)',
                  }"
                >
                  <div class="text-center font-weight-bold">{{ formatDate(progress.fte_term_1_end.date) }}</div>
                  <div class="text-center text-overline">FTE Term 1</div>
                </div>
                <div
                  v-if="progress.fte_term_2_end.percent"
                  :style="{
                    position: 'absolute',
                    left: `${progress.fte_term_2_end.percent}%`,
                    transform: 'translateX(-50%)',
                  }"
                >
                  <div class="text-center font-weight-bold">{{ formatDate(progress.fte_term_2_end.date) }}</div>
                  <div class="text-center text-overline">FTE Term 2</div>
                </div>
                <div
                  v-if="progress.year_1_end.percent"
                  :style="{
                    position: 'absolute',
                    left: `${progress.year_1_end.percent}%`,
                    transform: 'translateX(-50%)',
                  }"
                >
                  <div class="text-center font-weight-bold">{{ formatDate(progress.year_1_end.date) }}</div>
                  <div class="text-center text-overline">Year 1</div>
                </div>
                <div
                  v-if="progress.fte_term_4_end.percent"
                  :style="{
                    position: 'absolute',
                    left: `${progress.fte_term_4_end.percent}%`,
                    transform: 'translateX(-50%)',
                  }"
                >
                  <div class="text-center font-weight-bold">{{ formatDate(progress.fte_term_4_end.date) }}</div>
                  <div class="text-center text-overline">FTE Term 4</div>
                </div>
                <div
                  v-if="progress.fte_term_5_end.percent"
                  :style="{
                    position: 'absolute',
                    left: `${progress.fte_term_5_end.percent}%`,
                    transform: 'translateX(-50%)',
                  }"
                >
                  <div class="text-center font-weight-bold">{{ formatDate(progress.fte_term_5_end.date) }}</div>
                  <div class="text-center text-overline">FTE Term 5</div>
                </div>
                <div
                  v-if="progress.year_2_end.percent"
                  :style="{
                    position: 'absolute',
                    left: 'calc(100% - 2px)',
                    transform: 'translateX(-50%)',
                    width: '120px',
                  }"
                >
                  <div class="text-center font-weight-bold">{{ formatDate(progress.year_2_end.date) }}</div>
                  <div class="text-center text-overline">Year 2</div>
                </div>
              </div>
              <div style="width: 50px"></div>
            </div>
          </div>
          <div v-if="notEnoughTerms" class="mt-4">
            <v-alert type="warning" density="compact" class="mb-0">
              There are not enough terms configured to calculate the full progress for this
              {{ traineeNounCapitalised() }}. Please contact support to resolve this.
            </v-alert>
          </div>
          <div v-else-if="readOnly" class="pt-2">
            <em>
              N.B. this timeline relates to completion of <strong>statutory induction</strong> only - it does not relate
              to completion of the Early Career Framework (ECF) programme. Please contact your ECF provider for queries
              relating to the ECF programme.
            </em>
          </div>
        </template>
        <template v-else>
          <div class="text-h6">Progress</div>
          <div class="mt-2">
            Sorry, cannot load this {{ traineeNounCapitalised() }}'s progress timeline at the moment.<span
              v-if="progressEventErrors.length === 0"
            >
              Please contact support to resolve this.</span
            >
          </div>
        </template>
        <div v-if="progressEventErrors.length > 0" class="mt-4">
          <v-alert type="warning" variant="outlined" density="compact" class="mb-0">
            <div>
              This {{ traineeNounCapitalised() }}'s induction calculation may be incorrect due to the following:
            </div>
            <ul>
              <li v-for="e in progressEventErrors" :key="e">{{ e }}</li>
            </ul>
          </v-alert>
        </div>
      </v-card-text>
    </v-card>
    <v-card v-if="!busy && !error" class="mt-4">
      <v-card-text class="pa-5">
        <div class="d-flex">
          <div class="flex-grow-1 d-flex align-center">
            <div class="text-h6">Events</div>
            <mosaic-checkbox
              v-if="anyIgnoredProjectedReviews && !readOnly"
              v-model="hideIgnoredProjectedReviews"
              class="ml-4 mt-0 pt-0"
              density="compact"
              no-icon
              :label="`Hide ignored projected ${reviewNounCapitalisedAndPluralised}?`"
            />
          </div>
          <template v-if="!readOnly">
            <mosaic-btn-with-menu
              text="Add Event"
              :menu-items="
                [
                  { text: 'Change FTE Fraction', click: changeFteRate },
                  { text: 'Pause', click: pause },
                  canResume ? { text: 'Resume', click: resume } : null,
                  { text: 'Add Note', click: addNote },
                  { text: 'Add Absences', click: addAbsences },
                  { text: 'Add Extension', click: addExtension },
                  canChangeSchool ? { text: 'Change School', click: updateSchool } : null,
                  { text: 'Mark as Leaving', click: addLeaver },
                  canReturn ? { text: 'Mark as Returning', click: addReturn } : null,
                  { text: 'Finish Induction Early', click: finishInductionEarly },
                  manuallyManagedProjectedReviews
                    ? { text: `Add Scheduled ${reviewNounCapitalised}`, click: addProjectedReview }
                    : null,
                ].filter(x => x)
              "
            />
            <v-btn
              v-if="selectedInstitution.config.show_projected_reviews"
              class="ml-2"
              @click.prevent="openSettingsDialog"
              ><v-icon>mdi-cog</v-icon></v-btn
            ></template
          >
        </div>

        <mosaic-alert
          v-if="manuallyManagedProjectedReviews && !readOnly"
          type="info"
          variant="outlined"
          density="compact"
          class="my-2"
        >
          <div>
            This {{ traineeNounCapitalised() }}'s schedule of {{ reviewNounCapitalisedAndPluralised }} is manually
            controlled and will not be automatically updated when adding events. This can be changed using the cog icon.
          </div>
        </mosaic-alert>
        <v-timeline density="compact">
          <template v-for="(e, i) in history" :key="i">
            <v-timeline-item v-if="e.termEvent" hide-dot width="100%">
              <div
                v-if="e.type === 'end' && history[i - 1].termEvent"
                :key="e.date + '-no-events'"
                class="text-center mb-8"
              >
                No events this term
              </div>
              <div :key="e.date + '-term'" class="mb-4 d-flex align-center px-6 flex-grow-1">
                <v-divider />
                <div class="px-2 flex-grow-1 flex-shrink-0">{{ e.description }} ({{ formatDate(e.date) }})</div>
                <v-divider />
              </div>
            </v-timeline-item>
            <v-timeline-item v-else size="small" :dot-color="e.color" :icon="e.icon" fill-dot width="100%">
              <v-card class="elevation-2" :style="{ 'max-width': e.type === 'end_of_fte_term' ? '300px' : 'default' }">
                <v-card-text>
                  <div class="d-flex">
                    <div
                      class="font-weight-bold d-flex"
                      style="line-height: 1rem"
                      :style="{
                        'padding-top': e.events[0] && !eventHasSubtitle(e.events[0]) ? '15px' : '6px',
                      }"
                    >
                      <span>{{ formatDate(e.date) }}</span>
                    </div>
                    <div class="flex-grow-1 d-flex flex-column" style="row-gap: 8px">
                      <div v-for="(event, j) in e.events" :key="j" class="d-flex align-center" style="min-height: 48px">
                        <div>
                          <div class="d-flex" :class="{ 'align-center': !eventHasSubtitle(event) }">
                            <div class="text-overline px-4" style="line-height: 1rem">•</div>
                            <div class="text-overline" style="line-height: 1rem">
                              <span
                                :class="{ 'text-decoration-underline': event.clickRoute }"
                                :style="{ cursor: event.clickRoute ? 'pointer' : 'default' }"
                                v-on="event.clickRoute ? { click: () => $router.push(event.clickRoute) } : {}"
                                >{{ event.description }}</span
                              >
                            </div>
                          </div>

                          <div v-if="eventHasSubtitle(event)" style="padding-left: 38px">
                            <div v-for="(s, k) in event.subtitles" :key="k" style="line-height: 16px" class="pt-1">
                              <span
                                style="white-space: pre-wrap"
                                :style="{ color: s.color || 'default', cursor: s.clickRoute ? 'pointer' : 'default' }"
                                :class="{ 'text-decoration-underline': s.clickRoute }"
                                v-on="s.clickRoute ? { click: () => $router.push(s.clickRoute) } : {}"
                                >{{ s.text }}</span
                              >
                            </div>
                          </div>
                        </div>
                        <div class="flex-grow-1"></div>
                        <template v-for="infoIcon in event.infoIcons" :key="infoIcon.icon">
                          <v-tooltip location="top">
                            <template #activator="{ props }">
                              <span style="width: 36px" class="text-center">
                                <v-icon :color="infoIcon.color" v-bind="props">{{ infoIcon.icon }}</v-icon>
                              </span>
                            </template>
                            <span>{{ infoIcon.tooltip }}</span>
                          </v-tooltip>
                        </template>
                        <template v-if="!readOnly">
                          <template v-for="action in event.actions" :key="action.icon">
                            <ndt-icon-button
                              :icon="action.icon.slice(4)"
                              :tooltip="action.tooltip"
                              @click.prevent="action.click"
                            ></ndt-icon-button>
                          </template>
                          <div class="d-flex align-center">
                            <ndt-icon-button
                              v-if="event.editable"
                              icon="pencil"
                              tooltip="Edit"
                              @click.prevent="editEvent(event.event)"
                            ></ndt-icon-button>
                            <ndt-icon-button
                              v-if="event.deletable"
                              icon="delete"
                              tooltip="Delete"
                              @click.prevent="deleteEvent(event.event)"
                            ></ndt-icon-button></div
                        ></template>
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </template>
        </v-timeline>
      </v-card-text>
    </v-card>

    <ndt-dialog
      v-if="!readOnly"
      v-model:active="addEventDialog.active"
      :title="addEventDialog.title"
      :error-message="addEventDialog.error"
    >
      <mosaic-alert
        v-if="
          manuallyManagedProjectedReviews && !['note', 'absence', 'projected_review'].includes(addEventDialog.eventType)
        "
        type="info"
        variant="outlined"
        density="compact"
        class="mb-2"
      >
        <div>
          {{ addEventDialogProjectedReviewAlert }}
        </div>
      </mosaic-alert>
      <div class="d-flex justify-space-between" style="column-gap: 16px">
        <div :style="{ 'flex-basis': ['Custom Date', 'Today'].includes(addEventDialog.date.title) ? '48%' : '100%' }">
          <v-select
            v-model="addEventDialog.date"
            :items="selectedCohortFuturePlacementsDateItems"
            :label="addEventDialog.dateLabel || 'Date of change'"
            prepend-icon="mdi-calendar"
          ></v-select>
        </div>
        <div style="flex-basis: 48%">
          <mosaic-date-picker
            v-if="addEventDialog.date == 'other'"
            v-model:date="addEventDialog.otherDate"
            label="Custom date"
            :exact-width="false"
          ></mosaic-date-picker>
        </div>
      </div>

      <div class="d-flex flex-wrap justify-space-between" style="column-gap: 16px">
        <div v-if="addEventDialog.showTermsCompletedSinceLeaving" style="flex-basis: 48%">
          <v-text-field
            v-model="addEventDialog.termsCompletedSinceLeaving"
            label="Terms completed since leaving (full time equivalent - do not include any extension terms)"
            prepend-icon="mdi-clipboard-text-clock"
            type="number"
            :rules="termsCompletedRules"
          ></v-text-field>
        </div>
        <div v-if="addEventDialog.showFteRate" style="flex-basis: 48%">
          <v-text-field
            v-model="addEventDialog.fteRate"
            label="Full time equivalent (FTE) fraction"
            type="number"
            step="0.1"
            :rules="fteRateRules"
            prepend-icon="mdi-timetable"
          ></v-text-field>
        </div>
        <div v-if="addEventDialog.showAbsences" style="flex-basis: 48%">
          <v-text-field
            v-model="addEventDialog.absenceCount"
            label="Number of absences"
            type="number"
            :rules="absencesRules"
          ></v-text-field>
        </div>
        <div v-if="addEventDialog.showExtension" style="flex-basis: 48%">
          <v-select v-model="addEventDialog.extensionType" :items="extensionItems" label="Extension type"></v-select>
        </div>
        <div v-if="addEventDialog.showExtension && addEventDialog.extensionType === 'days'" style="flex-basis: 48%">
          <v-text-field v-model="addEventDialog.extensionDays" label="Number of FTE days" type="number"></v-text-field>
        </div>
        <div v-if="addEventDialog.showExtension && addEventDialog.extensionType === 'terms'" style="flex-basis: 48%">
          <v-text-field
            v-model="addEventDialog.extensionTerms"
            label="Number of FTE terms"
            type="number"
          ></v-text-field>
        </div>
        <div v-if="addEventDialog.showSchool" style="flex-basis: 100%">
          <div v-if="loadSchoolsProcessing">Loading Schools...</div>
          <div v-else-if="loadSchoolsError">Loading Schools failed. Please refresh the page.</div>
          <mosaic-autocomplete
            v-else
            v-model="addEventDialog.schoolId"
            name="ect-school"
            prepend-icon="mdi-domain"
            label="School"
            :items="schools"
            item-value="id"
            item-title="displayNameWithUrn"
          />
        </div>
      </div>
      <div>
        <v-textarea
          v-model="addEventDialog.note"
          name="note"
          variant="filled"
          label="Note"
          auto-grow
          type="text"
          rows="2"
        />
      </div>
      <p>
        This will add a new entry for this {{ traineeNounCapitalised() }}, if you want to correct a previous entry then
        use the Events area.
      </p>
      <template #buttons>
        <v-btn variant="text" ripple :disabled="!canAddEvent" @click.prevent="addEventDialog.submitFunction()"
          >Save</v-btn
        >
      </template>
    </ndt-dialog>

    <!-- There is a separate dialog for adding scheduled/projected reviews as it feels sufficiently different -->
    <ndt-dialog
      v-if="!readOnly"
      v-model:active="addProjectedReviewDialog.active"
      :title="`Add Scheduled ${reviewNounCapitalised}`"
      :error-message="addProjectedReviewDialog.error"
    >
      <div class="d-flex">
        <div style="flex-basis: 48%">
          <mosaic-select
            v-model="addProjectedReviewDialog.reviewType"
            :items="unusedReviewTypeItems"
            :label="`${reviewNounCapitalised} type`"
            name="review-type"
            item-value="value"
            item-title="title"
            prepend-icon="mdi-palette-swatch-variant"
            @update:model-value="addProjectedReviewChanged"
          ></mosaic-select>
        </div>
      </div>

      <div class="d-flex justify-space-between" style="column-gap: 16px">
        <div style="flex-basis: 48%">
          <mosaic-date-picker
            v-model:date="addProjectedReviewDialog.reviewDueDate"
            :label="`${reviewNounCapitalised} due date`"
            :exact-width="false"
            @update:date="addProjectedReviewChanged"
          ></mosaic-date-picker>
        </div>
        <div style="flex-basis: 48%">
          <mosaic-date-picker
            v-model:date="addProjectedReviewDialog.reviewCreationDate"
            :label="`${reviewNounCapitalised} creation date`"
            :exact-width="false"
          ></mosaic-date-picker>
        </div>
      </div>
      <div>
        <v-textarea
          v-model="addProjectedReviewDialog.note"
          name="note"
          variant="filled"
          label="Note"
          auto-grow
          type="text"
          rows="2"
        />
      </div>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          :disabled="addProjectedReviewDialog.processing"
          @click.prevent="submitAddProjectedReview"
          >Save</v-btn
        >
      </template>
    </ndt-dialog>

    <ndt-dialog
      v-if="!readOnly"
      v-model:active="deleteEventDialog.active"
      title="Delete Event"
      :error-message="deleteEventDialog.error"
    >
      <mosaic-alert
        v-if="
          manuallyManagedProjectedReviews &&
          !['note', 'absence', 'projected_review'].includes(deleteEventDialog.eventType)
        "
        type="info"
        variant="outlined"
        density="compact"
        class="mb-2"
      >
        <div>
          {{ addEventDialogProjectedReviewAlert }}
        </div>
      </mosaic-alert>

      <template v-if="deleteEventDialog.eventType === 'review'">
        <mosaic-alert
          type="warning"
          v-if="deleteEventDialog.reviewStarted || this.deleteEventDialog.completedOrApproved"
          class="mb-2"
        >
          <div v-if="deleteEventDialog.completedOrApproved">
            This {{ reviewNounCapitalised }} has already been approved or marked as complete by at least one
            Contributor.
          </div>

          <div v-else>This {{ reviewNounCapitalised }} has already been started.</div>
          <div>Deleting it will remove all data entered by contributors.</div>
          <div>This action cannot be undone.</div>
        </mosaic-alert>
        <div v-if="!deleteEventDialog.reviewLoading">
          Are you sure you want to delete this {{ reviewNounCapitalised }}?
        </div>
        <mosaic-text-field
          v-if="deleteEventDialog.reviewStarted || deleteEventDialog.completedOrApproved"
          v-model="deleteEventDialog.permanentlyDelete"
          name="perm-delete"
          :placeholder="`Type &quot;permanently delete&quot; to delete this ${reviewNounCapitalised}`"
          label=""
          no-icon
        />
      </template>
      <div v-else>Are you sure you want to delete this event?</div>
      <template #buttons>
        <v-btn variant="text" ripple color="error" :disabled="!canDeleteEvent" @click.prevent="submitDeleteEvent()"
          >Delete</v-btn
        >
      </template>
    </ndt-dialog>

    <ndt-dialog
      v-if="!readOnly"
      v-model:active="editEventDialog.active"
      title="Edit Event"
      :error-message="editEventDialog.error"
    >
      <mosaic-alert
        v-if="
          (manuallyManagedProjectedReviews && !['note', 'absence'].includes(editEventDialog.event.event_type)) ||
          editEventDialog.event.event_type === 'projected_review'
        "
        type="info"
        variant="outlined"
        density="compact"
        class="mb-2"
      >
        <div v-if="manuallyManagedProjectedReviews">
          {{ addEventDialogProjectedReviewAlert }}
        </div>
        <div v-else-if="editEventDialog.event.event_type === 'projected_review'">
          This {{ traineeNounCapitalised() }}'s schedule of {{ reviewNounCapitalisedAndPluralised }} is automatically
          calculated by Mosaic. If you wish to change the due date of this {{ reviewNounCapitalised }} then first try
          adding, updating or removing other events to ensure that it's calculated properly. If that doesn't work, then
          alter the {{ traineeNounCapitalised() }}'s settings (at the top of this page) to allow manual scheduling of
          {{ reviewNounCapitalisedAndPluralised }} or contact support.
        </div>
      </mosaic-alert>
      <div v-if="editEventDialog.showDate" class="d-flex justify-space-between" style="column-gap: 16px">
        <div :style="{ 'flex-basis': ['Custom Date', 'Today'].includes(addEventDialog.date.title) ? '48%' : '100%' }">
          <v-select
            v-model="editEventDialog.date"
            :items="selectedCohortAllPlacementsDateItems"
            :label="editEventDialog.dateLabel || 'Date of change'"
            prepend-icon="mdi-calendar"
          ></v-select>
        </div>
        <div style="flex-basis: 48%">
          <mosaic-date-picker
            v-if="editEventDialog.date == 'other'"
            v-model:date="editEventDialog.otherDate"
            label="Custom date"
            :exact-width="false"
          ></mosaic-date-picker>
        </div>
      </div>
      <div v-if="editEventDialog.showReviewCreationDate" class="d-flex" style="column-gap: 16px">
        <div style="flex-basis: 48%">
          <mosaic-date-picker
            v-model:date="editEventDialog.reviewCreationDate"
            :label="`${reviewNounCapitalised} creation date`"
            :exact-width="false"
          ></mosaic-date-picker>
        </div>
      </div>
      <div class="d-flex flex-wrap justify-space-between" style="column-gap: 16px">
        <div v-if="editEventDialog.showTermsAlreadyCompleted" style="flex-basis: 100%">
          <v-text-field
            v-model="editEventDialog.termsAlreadyCompleted"
            label="FTE terms of induction completed prior to joining this AB (do not include extension terms)"
            prepend-icon="mdi-clipboard-text-clock"
            type="number"
            :rules="termsCompletedRules"
          ></v-text-field>
        </div>
        <div v-if="editEventDialog.showTermsCompletedSinceStartDate" style="flex-basis: 100%">
          <v-text-field
            v-model="editEventDialog.termsCompletedSinceStartDate"
            label="Terms completed since start date (full time equivalent - do not include any extension terms)"
            prepend-icon="mdi-clipboard-text-clock"
            type="number"
            :rules="termsCompletedRules"
          ></v-text-field>
        </div>
        <div v-if="editEventDialog.showTermsCompletedSinceLeaving" style="flex-basis: 100%">
          <v-text-field
            v-model="editEventDialog.termsCompletedSinceLeaving"
            label="Terms completed since leaving (full time equivalent - do not include any extension terms)"
            prepend-icon="mdi-clipboard-text-clock"
            type="number"
            :rules="termsCompletedRules"
          ></v-text-field>
        </div>
        <div v-if="editEventDialog.showFteRate" style="flex-basis: 48%">
          <v-text-field
            v-model="editEventDialog.fteRate"
            label="Full time equivalent (FTE) fraction"
            type="number"
            step="0.1"
            :rules="fteRateRules"
            prepend-icon="mdi-timetable"
          ></v-text-field>
        </div>
        <div v-if="editEventDialog.showAbsences" style="flex-basis: 48%">
          <v-text-field
            v-model="editEventDialog.absenceCount"
            label="Number of absences"
            type="number"
            :rules="absencesRules"
          ></v-text-field>
        </div>
        <div v-if="editEventDialog.showExtension" style="flex-basis: 48%">
          <v-select v-model="editEventDialog.extensionType" :items="extensionItems" label="Extension type"></v-select>
        </div>
        <div v-if="editEventDialog.showExtension && editEventDialog.extensionType === 'days'" style="flex-basis: 48%">
          <v-text-field v-model="editEventDialog.extensionDays" label="Number of days" type="number"></v-text-field>
        </div>
        <div v-if="editEventDialog.showExtension && editEventDialog.extensionType === 'terms'" style="flex-basis: 48%">
          <v-text-field v-model="editEventDialog.extensionTerms" label="Number of terms" type="number"></v-text-field>
        </div>
        <div v-if="editEventDialog.showSchool" style="flex-basis: 100%">
          <div v-if="loadSchoolsProcessing">Loading Schools...</div>
          <div v-else-if="loadSchoolsError">Loading Schools failed. Please refresh the page.</div>
          <mosaic-autocomplete
            v-else
            v-model="editEventDialog.schoolId"
            name="edt-school"
            prepend-icon="mdi-domain"
            label="School"
            :items="schools"
            item-value="id"
            item-title="displayNameWithUrn"
          />
        </div>
      </div>
      <div v-if="editEventDialog.showIgnore">
        <mosaic-checkbox v-model="editEventDialog.ignored" name="ignored" no-icon filled label="Ignore this event?" />
      </div>
      <div>
        <v-textarea
          v-model="editEventDialog.note"
          name="note"
          variant="filled"
          label="Note"
          auto-grow
          type="text"
          rows="2"
        />
      </div>
      <template #buttons>
        <v-btn variant="text" ripple :disabled="!canEditEvent" @click.prevent="submitEditEvent()">Save</v-btn>
      </template>
    </ndt-dialog>

    <ndt-dialog
      v-if="!readOnly"
      v-model:active="unignoredEventsDialog.active"
      :title="`Projected ${reviewNounCapitalisedAndPluralised} No Longer Ignored`"
      :error-message="unignoredEventsDialog.error"
      close-button-text="Close"
    >
      <div class="pb-2">
        <div>
          Mosaic has recalculated projected {{ reviewNounCapitalisedAndPluralised }} for this
          {{ traineeNounCapitalised() }}. Some projected {{ reviewNounCapitalisedAndPluralised }} had been marked as
          "ignored" for the purposes of detecting errors and automatically creating
          {{ reviewNounCapitalisedAndPluralised }}.
        </div>
        <div class="pt-2">
          As a result of the recalculation, the following projected {{ reviewNounCapitalisedAndPluralised }} are no
          longer being ignored:
        </div>
        <ul>
          <li v-for="e in unignoredEventsDialog.events" :key="e.id">
            {{ formatDate(e.event_date) }} - {{ eventDescription(e) }}
          </li>
        </ul>
      </div>
      <div>Please ignore them again, if that still makes sense.</div>
    </ndt-dialog>

    <ndt-dialog v-model:active="settingsDialog.active" title="Settings" :error-message="settingsDialog.error">
      <v-alert type="info" variant="outlined" density="compact" class="mb-0">
        <div v-if="manuallyManagedProjectedReviews">
          Switching off manually scheduling {{ reviewNounCapitalisedAndPluralised }} will cause this
          {{ traineeNounCapitalised() }}'s {{ reviewNounCapitalisedAndPluralised }} to automatically be updated any time
          their events change and any manual alterations will be lost.
        </div>
        <div v-else>
          Manually scheduling {{ reviewNounCapitalisedAndPluralised }} will mean this {{ traineeNounCapitalised() }}'s
          {{ reviewNounCapitalisedAndPluralised }} are not automatically updated when their events change and may
          require manual alteration.
        </div>
      </v-alert>
      <mosaic-checkbox
        v-model="settingsDialog.manual"
        name="manual"
        :no-icon="true"
        :label="`Manually schedule this ${traineeNounCapitalised()}'s ${reviewNounCapitalisedAndPluralised} (they will still be created automatically by Mosaic)`"
      />
      <template #buttons>
        <v-btn variant="text" ripple :disabled="!canSaveSettings" @click.prevent="submitSettingsDialog()">Save</v-btn>
      </template>
    </ndt-dialog>

    <ndt-dialog
      v-if="!readOnly"
      v-model:active="createReviewDialog.active"
      :title="`Create ${reviewNounCapitalised}`"
      :error-message="createReviewDialog.error"
    >
      <div>
        Are you sure you want to create this {{ createReviewDialog.event.body.name }} {{ reviewNounCapitalised }} for
        this {{ traineeNounCapitalised() }}?
      </div>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          :disabled="createReviewDialog.processing"
          @click.prevent="submitCreateReviewFromProjectedReview()"
          >Create</v-btn
        >
      </template>
    </ndt-dialog>
    <change-school-dialog
      v-if="!readOnly"
      v-model:active="changeSchoolDialog.active"
      title="Change School"
      :pre-selected-ect="preSelectedEct"
      @update:changed-school="changedSchool()"
    ></change-school-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { capitaliseFirstLetters, enumerateItems } from '../../utils/text';
import NdtDialog from '../../components/NdtDialog.vue';
import moment from 'moment';
import NdtIconButton from '../../components/NdtIconButton.vue';
import { fteRateRules, termsCompletedRules, absencesRules } from '../../utils/validations';
import { createPlacementsDateItems, createFuturePlacementsDateItems } from '../../utils/placements';
import { hexToRGB } from '../../theme';
import { createErrorsMap } from '@/utils/errors';
import { ectProgressRules } from '@/utils/ect/ect';
import ChangeSchoolDialog from '@/components/ect/ChangeSchoolDialog.vue';
import { fromSnakeCaseToCamelCase } from '@/utils/transforms';
import { useTheme } from 'vuetify';
import { useCohortStore } from '@/stores/cohort';
import { mapStateProcessingAndError } from '@/store/map-store';

const reviewCreationDateInitialValue = moment().subtract(14, 'days').format('YYYY-MM-DD');
export default {
  name: 'TutorEctProgressPage',
  components: { NdtDialog, NdtIconButton, ChangeSchoolDialog },
  setup() {
    const theme = useTheme();
    const {
      actions: { loadCohortStudents },
    } = useCohortStore();
    return { theme, loadCohortStudents };
  },
  data: function () {
    return {
      error: null,
      busy: true,
      ect: null,
      hideIgnoredProjectedReviews: false,
      addEventDialog: {
        title: '',
        active: false,
        processing: false,
        error: '',
        note: '',
        fteRate: 0.5,
        date: null,
        otherDate: moment.utc().format('YYYY-MM-DD'),
        showFteRate: false,
        showAbsences: false,
        absenceCount: 0,
        showExtension: false,
        extensionType: 'days',
        extensionDays: null,
        extensionTerms: null,
        showSchool: false,
        schoolId: null,
        submitFunction: null,
        dateLabel: '',
        eventType: '',
        showTermsCompletedSinceLeaving: false,
        termsCompletedSinceLeaving: 0,
      },
      addProjectedReviewDialog: {
        active: false,
        processing: false,
        error: '',
        reviewDueDate: moment.utc().format('YYYY-MM-DD'),
        reviewCreationDate: reviewCreationDateInitialValue,
        reviewType: 'termly',
        note: '',
      },
      deleteEventDialog: {
        active: false,
        processing: false,
        id: null,
        error: '',
        completedOrApproved: false,
        reviewStarted: false,
        reviewLoading: false,
        permanentlyDelete: '',
      },
      editEventDialog: {
        active: false,
        processing: false,
        id: null,
        event: {},
        date: null,
        otherDate: moment.utc().format('YYYY-MM-DD'),
        showFteRate: true,
        fteRate: 0.5,
        showTermsAlreadyCompleted: false,
        termsAlreadyCompleted: 0,
        showTermsCompletedSinceStartDate: false,
        termsCompletedSinceStartDate: 0,
        ignored: false,
        error: '',
        note: '',
        showAbsences: false,
        absenceCount: 0,
        showExtension: false,
        extensionType: 'days',
        extensionDays: null,
        extensionTerms: null,
        showSchool: false,
        schoolId: null,
        showDate: true,
        showReviewCreationDate: false,
        reviewCreationDate: moment.utc().format('YYYY-MM-DD'),
        showIgnore: false,
      },
      changeSchoolDialog: {
        active: false,
        processing: false,
        error: null,
      },
      unignoredEventsDialog: {
        active: false,
        events: [],
      },
      settingsDialog: {
        active: false,
        processing: false,
        manual: false,
        error: '',
      },
      createReviewDialog: {
        active: false,
        processing: false,
        event: {
          body: {},
        },
        error: '',
      },
      extensionItems: [
        { value: 'days', title: 'Extend by a number of days' },
        { value: 'terms', title: 'Extend by a number of terms' },
      ],
      fteRateRules,
      termsCompletedRules,
      absencesRules,
      ectSchools: [],
    };
  },
  computed: {
    ...mapState(['selectedStudent', 'selectedCohort', 'reviewTypeItems', 'selectedInstitution', 'schools']),
    ...mapStateProcessingAndError(['loadSchools']),
    addEventDialogProjectedReviewAlert() {
      return `This ${this.traineeNounCapitalised()}'s schedule of ${this.reviewNounCapitalisedAndPluralised} is manually
          controlled (rather than automatically calculated by Mosaic). Remember to add, update or remove scheduled
          ${this.reviewNounCapitalisedAndPluralised} that are affected by this change.`;
    },
    preSelectedEct() {
      return fromSnakeCaseToCamelCase(JSON.parse(JSON.stringify(this.ect)));
    },
    finishInductionEarlyErrors() {
      return {
        multiple_events: `${this.traineeNounCapitalisedAndPluralised} can only have a single induction finished early event`,
        weekend: `${this.traineeNounCapitalisedAndPluralised} cannot finished their induction on a weekend`,
        bank_holiday: `${this.traineeNounCapitalisedAndPluralised} cannot finish their induction on a bank holiday`,
        out_of_term: `${this.traineeNounCapitalisedAndPluralised} cannot finish their induction out of term`,
      };
    },
    breadcrumbs() {
      return [{ text: 'Manage Induction' }];
    },
    selectedCohortAllPlacementsDateItems() {
      return createPlacementsDateItems(this.selectedCohort.placements);
    },
    selectedCohortFuturePlacementsDateItems() {
      return createFuturePlacementsDateItems(this.selectedCohort.placements);
    },
    canEditEvent() {
      return (
        !this.editEventDialog.processing &&
        (!this.editEventDialog.showFteRate || this.fteRateRules.every(r => r(this.editEventDialog.fteRate))) &&
        (!this.editEventDialog.showTermsAlreadyCompleted ||
          this.termsCompletedRules.every(r => r(this.editEventDialog.termsAlreadyCompleted) === true)) &&
        (!(this.editEventDialog.showExtension && this.editEventDialog.extensionType === 'days') ||
          this.editEventDialog.extensionDays) &&
        (!(this.editEventDialog.showExtension && this.editEventDialog.extensionType === 'terms') ||
          this.editEventDialog.extensionTerms) &&
        (!this.editEventDialog.showSchool || this.editEventDialog.schoolId)
      );
    },
    canAddEvent() {
      return (
        !this.addEventDialog.processing &&
        (!this.addEventDialog.showFteRate || this.fteRateRules.every(r => r(this.addEventDialog.fteRate))) &&
        (!(this.addEventDialog.showExtension && this.addEventDialog.extensionType === 'days') ||
          this.addEventDialog.extensionDays) &&
        (!(this.addEventDialog.showExtension && this.addEventDialog.extensionType === 'terms') ||
          this.addEventDialog.extensionTerms) &&
        (!this.addEventDialog.showSchool || this.addEventDialog.schoolId)
      );
    },
    canResume() {
      return this.ect.ect_progress_events.some(e => e.event_type === 'paused');
    },
    canReturn() {
      return this.ect.ect_progress_events.some(e => e.event_type === 'leaving');
    },
    canChangeSchool() {
      return !!this.ect.ect_progress_events.find(x => x.event_type === 'started').school_id;
    },
    canSaveSettings() {
      return !this.settingsDialog.processing && this.settingsDialog.manual !== this.manuallyManagedProjectedReviews;
    },
    timelineEvents() {
      return this.ect.ect_progress_events
        .filter(
          x => x.event_type !== 'started' && x.event_type !== 'note' && x.event_type !== 'absences' && !x.body.ignored
        )
        .map(e => {
          const { icon, tooltip } = this.timelineEventIconAndTooltip(e);
          return {
            ...e,
            icon,
            tooltip: `${this.formatDate(e.event_date)} - ${tooltip}`,
          };
        });
    },
    joinedMosaicEvent() {
      return this.ect.ect_progress_events.find(x => x.event_type === 'joined_mosaic');
    },
    beforeJoinedMosaicPeriod() {
      if (this.joinedMosaicEvent) {
        return { start: 0, end: this.joinedMosaicEvent.percent };
      }
      return null;
    },
    pausedAndLeftPeriods() {
      const pausedPeriods = [];

      let start = null;
      this.ect.ect_progress_events
        .filter(
          e =>
            e.event_type === 'paused' ||
            e.event_type === 'resumed' ||
            e.event_type === 'leaving' ||
            e.event_type === 'returned'
        )
        .forEach(e => {
          if (e.event_type === 'paused') {
            start = e.percent;
          }

          if (e.event_type === 'resumed') {
            pausedPeriods.push({ start, end: e.percent });
            start = null;
          }
          if (e.event_type === 'leaving') {
            start = e.percent;
          }
          if (e.event_type === 'returned') {
            pausedPeriods.push({ start, end: e.percent });
            start = null;
          }
        });

      if (start) {
        pausedPeriods.push({ start, end: 100 });
      }
      return pausedPeriods;
    },
    history() {
      const events = this.ect.ect_progress_events
        .filter(x => !this.hideIgnoredProjectedReviews || x.event_type !== 'projected_review' || !x.body.ignored)
        .map(x => ({
          event: x,
          date: x.event_date,
          description: this.eventDescription(x),
          subtitles: this.eventSubtitles(x),
          infoIcons: this.eventInfoIcons(x),
          actions: this.eventActions(x),
          clickRoute:
            x.event_type === 'review' && this.userStaffHasPermissionForSelectedStudent('ect.assessment.view')
              ? { name: 'TutorReviewPage', params: { id: x.body.student_review_id } }
              : null,
          editable: !['review'].includes(x.event_type),
          deletable: ![
            'joined_mosaic',
            'started',
            this.manuallyManagedProjectedReviews ? '' : 'projected_review',
          ].includes(x.event_type),
        }));

      events.sort((a, b) => {
        if (a.date === b.date) {
          if (a.event?.event_type === 'projected_review') return 1;
          if (b.event?.event_type === 'projected_review') return -1;
          if (a.event?.event_type === 'leaving') return -1;
          if (b.event?.event_type === 'leaving') return 1;
        }
        return a.date > b.date ? 1 : -1;
      });

      let groupedEvents = [];
      let date = null;
      for (const event of events) {
        if (groupedEvents.length > 0 && event.date == date) {
          const lastGroupedEvent = groupedEvents[groupedEvents.length - 1];
          lastGroupedEvent.events.push(event);
        } else {
          groupedEvents.push({
            date: event.date,
            color: this.eventColor(event.date),
            events: [event],
          });
        }

        date = event.date;
      }

      if (this.progress) {
        ['fte_term_1_end', 'fte_term_2_end', 'year_1_end', 'fte_term_4_end', 'fte_term_5_end', 'year_2_end'].forEach(
          x => {
            const date = this.progress[x].date;
            if (date) {
              groupedEvents.push({
                date,
                type: 'end_of_fte_term',
                color: this.eventColor(date),
                icon: 'mdi-calendar-star',
                events: [
                  {
                    description: `End of ${x
                      .split('_end')[0]
                      .split('_')
                      .map(x => (x === 'fte' ? 'FTE' : x[0].toLocaleUpperCase() + x.slice(1)))
                      .join(' ')}`,
                    infoIcons: [],
                    editable: false,
                    deletable: false,
                  },
                ],
              });
            }
          }
        );
      }

      groupedEvents = groupedEvents.concat(
        this.selectedCohort.placements
          .filter(
            p => p.end_date >= groupedEvents[0].date && p.start_date <= groupedEvents[groupedEvents.length - 1].date
          )
          .map(p => [
            {
              date: p.start_date,
              description: `Start of ${p.name}`,
              termEvent: true,
              type: 'start',
            },
            {
              date: p.end_date,
              description: `End of ${p.name}`,
              termEvent: true,
              type: 'end',
            },
          ])
          .flat()
      );

      groupedEvents.sort((a, b) => {
        if (a.date === b.date) {
          if (a.termEvent && a.type === 'start') return -1;
          if (b.termEvent && b.type === 'start') return 1;
          if (a.termEvent && a.type === 'end') return 1;
          if (b.termEvent && b.type === 'end') return -1;
          if (a.type === 'end_of_fte_term') return 1;
          if (b.type === 'end_of_fte_term') return -1;
        }
        return a.date > b.date ? 1 : -1;
      });

      return groupedEvents;
    },
    progress() {
      return this.ect?.progress;
    },
    notEnoughTerms() {
      return (
        !this.progress.year_2_end.date &&
        !['paused', 'leaving', 'returned_with_too_many_terms'].includes(this.progress.no_end_date_reason)
      );
    },
    anyIgnoredProjectedReviews() {
      return this.ect.ect_progress_events.some(e => e.event_type === 'projected_review' && e.body.ignored);
    },
    progressEventErrors() {
      return this.ect.ect_progress_events
        .map(e => ectProgressRules.map(f => f(e, this.ect)))
        .flat()
        .filter(e => e)
        .unique()
        .concat(
          this.progress?.no_end_date_reason === 'returned_with_too_many_terms'
            ? ['Returned event has too many terms since leaving']
            : []
        );
    },
    manuallyManagedProjectedReviews() {
      return this.ect?.manually_manage_projected_reviews;
    },
    unusedReviewTypeItems() {
      if (!this.ect) return this.reviewTypeItems;
      const usedReviewTypes = this.ect.ect_progress_events
        .filter(e => e.event_type === 'projected_review' || e.event_type === 'review')
        .map(e => e.body.type);

      const hasEndOfYear1 = usedReviewTypes.includes('end_of_year_1');
      const hasEndOfYear2 = usedReviewTypes.includes('end_of_year_2');
      return this.reviewTypeItems.filter(
        x => (x.value !== 'end_of_year_1' || !hasEndOfYear1) && (x.value !== 'end_of_year_2' || !hasEndOfYear2)
      );
    },
    startedSchoolId() {
      return this.ect.ect_progress_events.find(x => x.event_type === 'started').body.school_id;
    },
    canDeleteEvent() {
      return (
        !this.deleteEventDialog.processing &&
        (this.deleteEventDialog.eventType !== 'review' ||
          (!this.deleteEventDialog.reviewStarted && !this.deleteEventDialog.completedOrApproved) ||
          this.deleteEventDialog.permanentlyDelete === 'permanently delete')
      );
    },
    readOnly() {
      return !this.userStaffHasPermissionForSelectedStudent('Admin');
    },
  },
  async created() {
    // full schools list is needed for adding/editing schools, which is not available for read-only users
    if (this.userStaffHasPermissionForSelectedStudent('Admin')) await this.$store.dispatch('loadSchools');
    await this.load();
    this.addEventDialog.date = moment().format('YYYY-MM-DD');
  },
  methods: {
    async load() {
      this.busy = true;
      try {
        const r = await this.$api.get(`/ects/${this.selectedStudent.ect.id}`);
        this.ect = r.data;
        this.ectSchools = this.ect.schools;
      } catch (e) {
        console.log(e);
        this.error = `Sorry, cannot load this ${this.traineeNounCapitalised()}'s progress at the moment. Please try again later.`;
      }
      this.busy = false;
    },
    eventDescription(event) {
      if (event.event_type == 'started') {
        let text = this.isInPast(event.event_date) ? 'Started' : 'Starting';
        if (event.body.fte_rate) {
          text += ` - ${event.body.fte_rate} FTE`;
        }
        if (event.body.terms_already_completed !== 0) {
          text += ` - Terms already completed: ${event.body.terms_already_completed}`;
        }
        return text;
      }
      if (event.event_type == 'joined_mosaic') {
        return `Joined Mosaic - ${event.body.fte_rate} FTE - Terms completed since started: ${event.body.terms_completed_since_start_date}`;
      }
      if (event.event_type == 'fte_rate_changed') {
        return `FTE Fraction Changed - ${event.body.fte_rate} FTE`;
      }
      if (event.event_type == 'absences') {
        return `Absences Recorded - ${event.body.absence_count}`;
      }
      if (event.event_type == 'extension') {
        if (event.body.type === 'days')
          return `Extension - ${event.body.days} ${event.body.days === 1 ? 'day' : 'days'}`;
        if (event.body.type === 'terms')
          return `Extension - ${event.body.terms} ${event.body.terms === 1 ? 'term' : 'terms'}`;
      }
      if (event.event_type == 'review') {
        return `${this.reviewNounCapitalised} - ${event.body.name}${
          event.body.type ? ` - ${this.reviewTypeItems.find(x => x.value == event.body.type).title}` : ''
        }`;
      }
      if (event.event_type == 'projected_review') {
        return `${this.manuallyManagedProjectedReviews ? 'Scheduled' : 'Projected'} ${
          this.reviewNounCapitalised
        } Due - ${event.body.name}${event.body.ignored ? ' (Ignored)' : ''}`;
      }
      if (event.event_type == 'leaving') {
        return this.isInPast(event.event_date) ? 'Left' : 'Leaving';
      }
      if (event.event_type == 'returned') {
        let text = `${this.isInPast(event.event_date) ? 'Returned' : 'Returning'} - ${event.body.fte_rate} FTE`;

        if (event.body.terms_completed_since_leaving !== 0) {
          text += ` - Terms completed since leaving: ${event.body.terms_completed_since_leaving}`;
        }

        return text;
      }
      return capitaliseFirstLetters(event.event_type.split('_').join(' '));
    },
    eventSubtitles(event) {
      const subtitles = [];

      if (event.school_id) {
        const school = this.ectSchools.find(s => s.id === event.school_id);
        subtitles.push({
          text: `School: ${school.display_name}`,
          clickRoute: this.readOnly
            ? undefined
            : { name: 'TutorSchoolEditPage', params: { schoolId: event.school_id } },
        });
      }

      if (event.body.note && !this.readOnly) {
        subtitles.push({
          text: event.body.note.trim(),
        });
      }

      if (event.event_type === 'review') {
        if (event.body.approved_date) {
          subtitles.push({
            text: `Approved on ${this.formatDate(event.body.approved_date)}`,
          });
        } else if (this.isInPast(event.event_date)) {
          subtitles.push({
            text: 'Overdue - Not Approved',
            color: 'red',
          });
        }
      } else if (event.event_type === 'projected_review') {
        if (!event.body.ignored) {
          if (this.isInPast(event.body.review_creation_date)) {
            subtitles.push({
              text: `Missing - Should have been created on ${this.formatDate(event.body.review_creation_date)}`,
              color: 'red',
            });
          } else {
            if (this.selectedInstitution.config.run_reviews_scheduler) {
              subtitles.push({
                text: `Scheduled to be automatically created on ${this.formatDate(event.body.review_creation_date)}`,
              });
            } else {
              subtitles.push({
                text: `Should be created on ${this.formatDate(event.body.review_creation_date)} (automatic ${
                  this.reviewNounCapitalised
                } creation is currently turned off)`,
              });
            }
          }
        }
      }
      return subtitles;
    },
    isInPast(dueDate) {
      return moment().isAfter(dueDate);
    },
    eventColor(eventDate) {
      const joinedMosaicEventDate = this.joinedMosaicEvent?.event_date;
      if (joinedMosaicEventDate && eventDate < joinedMosaicEventDate) return 'grey';
      if (eventDate <= moment().format('YYYY-MM-DD')) return 'primary';
      return hexToRGB(this.theme.global.current.value.colors.primary, 0.3);
    },
    eventActions(event) {
      const actions = [];

      if (event.event_type == 'projected_review') {
        const tomorrow = moment();
        tomorrow.add(1, 'day');

        if (tomorrow.isAfter(event.body.review_creation_date)) {
          actions.push({
            icon: 'mdi-plus',
            tooltip: `Create ${this.reviewNounCapitalised}`,
            click: () => this.createReviewFromProjectedReview(event),
          });
        }
      }

      return actions;
    },
    eventInfoIcons(event) {
      const infoIcons = ectProgressRules
        .map(e => e(event, this.ect))
        .filter(e => e)
        .map(e => ({
          icon: 'mdi-alert-circle-outline',
          color: 'warning',
          tooltip: e,
        }));

      if (event.event_type === 'review') {
        const term = this.selectedCohort.placements.find(
          p => event.event_date >= p.start_date && event.event_date <= p.end_date
        );
        let anotherReviewDueInTheSameTerm = false;
        if (term) {
          anotherReviewDueInTheSameTerm = this.ect.ect_progress_events.some(
            e =>
              e.event_type === 'review' &&
              e.id !== event.id &&
              e.event_date >= term.start_date &&
              e.event_date <= term.end_date
          );
        }
        return infoIcons.concat(
          [
            !term && !event.body.approved_date
              ? {
                  icon: 'mdi-alert-circle-outline',
                  color: 'warning',
                  tooltip: `This ${this.reviewNounCapitalised} is due outside of a term`,
                }
              : null,
            anotherReviewDueInTheSameTerm && !event.body.approved_date
              ? {
                  icon: 'mdi-alert-circle-outline',
                  color: 'warning',
                  tooltip: `Mutiple ${this.reviewNounCapitalisedAndPluralised} are due this term`,
                }
              : null,
            {
              icon: 'mdi-check-circle-outline',
              tooltip: `Linked to ${this.reviewNounCapitalised}`,
            },
          ].filter(x => x)
        );
      }

      if (event.event_type == 'projected_review') {
        const tomorrow = moment();
        tomorrow.add(1, 'day');

        return infoIcons.concat(
          [
            this.isInPast(event.event_date) && !event.body.ignored
              ? {
                  icon: 'mdi-alert-circle-outline',
                  color: 'warning',
                  tooltip: `This ${this.traineeNounCapitalised()} is probably missing this ${
                    this.reviewNounCapitalised
                  }`,
                }
              : null,
            !this.manuallyManagedProjectedReviews
              ? {
                  icon: 'mdi-calculator',
                  tooltip: 'Calculated by Mosaic',
                }
              : null,
          ].filter(x => x)
        );
      }
      return infoIcons;
    },
    timelineEventIconAndTooltip(event) {
      if (event.event_type === 'fte_rate_changed') {
        return { icon: 'mdi-timetable', tooltip: `FTE rate changed to ${event.body.fte_rate} FTE` };
      }
      if (event.event_type === 'paused') {
        return { icon: 'mdi-pause', tooltip: `ECT paused` };
      }
      if (event.event_type === 'resumed') {
        return { icon: 'mdi-play', tooltip: `ECT resumed` };
      }
      if (event.event_type === 'leaving') {
        return { icon: 'mdi-exit-run', tooltip: this.isInPast(event.event_date) ? `ECT left` : `ECT is leaving` };
      }
      if (event.event_type === 'returned') {
        let tooltip = this.isInPast(event.event_date) ? `ECT returned` : `ECT is returning`;
        if (event.body.terms_completed_since_leaving) {
          tooltip += ` - ${enumerateItems(event.body.terms_completed_since_leaving, 'term')} completed since leaving`;
        }
        return { icon: 'mdi-restart', tooltip };
      }
      if (event.event_type === 'review') {
        return {
          icon: 'mdi-check-circle-outline',
          tooltip: `${this.reviewNounCapitalised} - ${event.body.name}${
            event.body.type ? ` - ${this.reviewTypeItems.find(x => x.value == event.body.type).title}` : ''
          }`,
        };
      }
      if (event.event_type === 'projected_review') {
        return {
          icon: 'mdi-checkbox-marked-circle-plus-outline',
          tooltip: `${this.manuallyManagedProjectedReviews ? 'Scheduled' : 'Projected'} ${
            this.reviewNounCapitalised
          } Due - ${event.body.name}`,
        };
      }
      if (event.event_type === 'extension') {
        return {
          icon: 'mdi-clock-plus-outline',
          tooltip: `ECT extended by ${
            event.body.type === 'days'
              ? `${event.body.days} ${event.body.days === 1 ? 'day' : 'days'}`
              : `${event.body.terms} ${event.body.terms === 1 ? 'term' : 'terms'}`
          }`,
        };
      }
      if (event.event_type === 'induction_finished_early') {
        return { icon: 'mdi-flag-checkered', tooltip: `ECT's induction finished early` };
      }
      return { icon: 'mdi-star', tooltip: capitaliseFirstLetters(event.event_type.split('_').join(' ')) };
    },
    changeFteRate() {
      this.resetAddEventDialog('fte_rate_changed');
      this.addEventDialog.showFteRate = true;
      this.addEventDialog.fteRate = this.ect.current_fte_rate === 1 ? 0.5 : 1;
      this.addEventDialog.title = 'Change FTE Fraction';
      this.addEventDialog.submitFunction = this.submitChangeFteRate;
    },
    async submitChangeFteRate() {
      this.submitDialog(
        this.addEventDialog,
        () =>
          this.$api.post(`/ects/${this.ect.id}/change-fte-rate`, {
            fte_rate: parseFloat(this.addEventDialog.fteRate),
            date: this.getDialogDate(this.addEventDialog),
            note: this.addEventDialog.note,
          }),
        createErrorsMap(`update this ${this.traineeNounCapitalised()}`)
      );
    },
    pause() {
      this.resetAddEventDialog('paused');
      this.addEventDialog.title = `Pause ${this.traineeNounCapitalised()}`;
      this.addEventDialog.submitFunction = this.submitPause;
    },
    async submitPause() {
      this.submitDialog(
        this.addEventDialog,
        () =>
          this.$api.post(`/ects/${this.ect.id}/pause`, {
            date: this.getDialogDate(this.addEventDialog),
            note: this.addEventDialog.note,
          }),
        createErrorsMap(`pause this ${this.traineeNounCapitalised()}`)
      );
    },
    resume() {
      this.resetAddEventDialog('resumed');
      this.addEventDialog.title = `Resume ${this.traineeNounCapitalised()}`;
      this.addEventDialog.submitFunction = this.submitResume;
    },
    async submitResume() {
      this.submitDialog(
        this.addEventDialog,
        () =>
          this.$api.post(`/ects/${this.ect.id}/resume`, {
            date: this.getDialogDate(this.addEventDialog),
            note: this.addEventDialog.note,
          }),
        createErrorsMap(`resume this ${this.traineeNounCapitalised()}`)
      );
    },
    addNote() {
      this.resetAddEventDialog('note');
      this.addEventDialog.title = `Add Note for ${this.traineeNounCapitalised()}`;
      this.addEventDialog.submitFunction = this.submitAddNote;
    },
    async submitAddNote() {
      this.submitDialog(
        this.addEventDialog,
        () =>
          this.$api.post(`/ects/${this.ect.id}/add-note`, {
            date: this.getDialogDate(this.addEventDialog),
            note: this.addEventDialog.note,
          }),
        createErrorsMap(`add a note to this ${this.traineeNounCapitalised()}`)
      );
    },
    addAbsences() {
      this.resetAddEventDialog('absence');
      this.addEventDialog.showAbsences = true;
      this.addEventDialog.absenceCount = 1;
      this.addEventDialog.title = `Add Absences for ${this.traineeNounCapitalised()}`;
      this.addEventDialog.submitFunction = this.submitAddAbsences;
    },
    async submitAddAbsences() {
      this.submitDialog(
        this.addEventDialog,
        () =>
          this.$api.post(`/ects/${this.ect.id}/add-absences`, {
            date: this.getDialogDate(this.addEventDialog),
            note: this.addEventDialog.note,
            absence_count: parseFloat(this.addEventDialog.absenceCount),
          }),
        createErrorsMap(`add absences to this ${this.traineeNounCapitalised()}`)
      );
    },
    addLeaver() {
      this.resetAddEventDialog('leaving');
      this.addEventDialog.title = `Mark ${this.traineeNounCapitalised()} as Leaving`;
      this.addEventDialog.submitFunction = this.submitAddLeaver;
    },
    async submitAddLeaver() {
      this.submitDialog(
        this.addEventDialog,
        () =>
          this.$api.post(`/ects/${this.ect.id}/mark-as-leaving`, {
            date: this.getDialogDate(this.addEventDialog),
            note: this.addEventDialog.note,
          }),
        createErrorsMap(`mark this ${this.traineeNounCapitalised()} as leaving`)
      );
    },
    addReturn() {
      this.resetAddEventDialog('returned');
      this.addEventDialog.title = `Mark ${this.traineeNounCapitalised()} as Returning`;
      this.addEventDialog.showFteRate = true;
      this.addEventDialog.fteRate = this.ect.current_fte_rate;
      this.addEventDialog.showSchool = true;
      this.addEventDialog.showTermsCompletedSinceLeaving = true;
      this.addEventDialog.termsCompletedSinceLeaving = 0;
      this.addEventDialog.submitFunction = this.submitAddReturn;
    },
    updateSchool() {
      this.changeSchoolDialog = {
        active: true,
        processing: false,
        error: null,
      };
    },
    changedSchool() {
      this.changeSchoolDialog.active = false;
      this.load();
      this.$store.dispatch('loadStudent', this.selectedStudent.id);
    },
    async submitAddReturn() {
      this.submitDialog(
        this.addEventDialog,
        () =>
          this.$api.post(`/ects/${this.ect.id}/mark-as-returning`, {
            date: this.getDialogDate(this.addEventDialog),
            fte_rate: parseFloat(this.addEventDialog.fteRate),
            note: this.addEventDialog.note,
            school_id: this.addEventDialog.schoolId,
            terms_completed_since_leaving: this.addEventDialog.termsCompletedSinceLeaving,
          }),
        createErrorsMap(`mark this ${this.traineeNounCapitalised()} as returning`)
      );
    },
    addExtension() {
      this.resetAddEventDialog('extension');
      this.addEventDialog.showExtension = true;
      this.addEventDialog.title = `Add Extension for ${this.traineeNounCapitalised()}`;
      this.addEventDialog.submitFunction = this.submitAddExtension;
    },
    async submitAddExtension() {
      this.submitDialog(
        this.addEventDialog,
        () =>
          this.$api.post(`/ects/${this.ect.id}/add-extension`, {
            date: this.getDialogDate(this.addEventDialog),
            note: this.addEventDialog.note,
            days:
              this.addEventDialog.extensionType === 'days' ? parseInt(this.addEventDialog.extensionDays) : undefined,
            terms:
              this.addEventDialog.extensionType === 'terms' ? parseInt(this.addEventDialog.extensionTerms) : undefined,
          }),
        createErrorsMap(`add an extension to this ${this.traineeNounCapitalised()}`)
      );
    },
    finishInductionEarly() {
      this.resetAddEventDialog('finish_induction_early');
      this.addEventDialog.title = `Finish ${this.traineeNounCapitalised()}'s Induction Early`;
      this.addEventDialog.submitFunction = this.submitFinishInductionEarly;
      this.addEventDialog.dateLabel = 'End of induction date';
    },
    async submitFinishInductionEarly() {
      this.submitDialog(
        this.addEventDialog,
        () =>
          this.$api.post(`/ects/${this.ect.id}/finish-induction-early`, {
            date: this.getDialogDate(this.addEventDialog),
            note: this.addEventDialog.note,
          }),
        createErrorsMap(
          `finish this ${this.traineeNounCapitalised()}'s induction early`,
          this.finishInductionEarlyErrors
        )
      );
    },
    addProjectedReview() {
      this.addProjectedReviewDialog.active = true;
      this.addProjectedReviewDialog.error = '';
      this.addProjectedReviewDialog.reviewDueDate = moment().format('YYYY-MM-DD');
      this.addProjectedReviewDialog.reviewCreationDate = reviewCreationDateInitialValue;
      this.addProjectedReviewDialog.reviewType = 'termly';
    },
    addProjectedReviewChanged() {
      if (moment(reviewCreationDateInitialValue).isSame(this.addProjectedReviewDialog.reviewCreationDate)) {
        const dueDate = moment(this.addProjectedReviewDialog.reviewDueDate);
        const type = this.addProjectedReviewDialog.reviewType;
        if (type == 'end_of_year_1' || type == 'end_of_year_2') {
          dueDate.subtract(12, 'weeks');
          this.addProjectedReviewDialog.reviewCreationDate = dueDate.format('YYYY-MM-DD');
        } else {
          dueDate.subtract(14, 'days');
          this.addProjectedReviewDialog.reviewCreationDate = dueDate.format('YYYY-MM-DD');
        }
      }
    },
    async submitAddProjectedReview() {
      this.submitDialog(
        this.addProjectedReviewDialog,
        () =>
          this.$api.post(`/ects/${this.ect.id}/ect-projected-reviews`, {
            due_date: this.addProjectedReviewDialog.reviewDueDate,
            review_creation_date: this.addProjectedReviewDialog.reviewCreationDate,
            review_type: this.addProjectedReviewDialog.reviewType,
            note: this.addProjectedReviewDialog.note,
          }),
        createErrorsMap(`add this scheduled ${this.reviewNounCapitalised}`)
      );
    },
    async deleteEvent(x) {
      this.deleteEventDialog.id = x.id;
      this.deleteEventDialog.eventType = x.event_type;
      this.deleteEventDialog.error = '';
      this.deleteEventDialog.completedOrApproved = false;
      this.deleteEventDialog.reviewStarted = false;
      this.deleteEventDialog.permanentlyDelete = '';

      if (x.event_type == 'review') {
        this.deleteEventDialog.reviewLoading = true;
        this.deleteEventDialog.processing = true;
        try {
          const r = await this.$api.get(`/student-reviews/${x.id}/delete-status-check`);
          this.deleteEventDialog.completedOrApproved = r.data.completed_or_reviewed;
          this.deleteEventDialog.reviewStarted = r.data.started;
          this.deleteEventDialog.active = true;
        } catch (e) {
          console.log(e);
          this.deleteEventDialog.error = `Sorry, cannot delete this ${this.reviewNounCapitalised} at the moment`;
        }
        this.deleteEventDialog.processing = false;
      } else {
        this.deleteEventDialog.active = true;
      }

      this.deleteEventDialog.reviewLoading = false;
    },
    async submitDeleteEvent() {
      this.submitDialog(
        this.deleteEventDialog,
        () =>
          this.$api.delete(
            this.deleteEventDialog.eventType === 'projected_review'
              ? `/ect-projected-reviews/${this.deleteEventDialog.id}`
              : this.deleteEventDialog.eventType === 'review'
              ? `/student-reviews/${this.deleteEventDialog.id}`
              : `/ect-progress-events/${this.deleteEventDialog.id}`
          ),
        createErrorsMap('delete this event')
      );
    },
    editEvent(x) {
      this.editEventDialog.id = x.id;
      this.editEventDialog.event = x;
      this.editEventDialog.active = true;
      const date = this.selectedCohortAllPlacementsDateItems.find(y => y.value === x.event_date) || { value: 'other' };
      this.editEventDialog.date = date.value;
      this.editEventDialog.otherDate = x.event_date;
      this.editEventDialog.showFteRate =
        // Only allow editing of FTE rate for the start events that have FTE rate
        (x.event_type === 'started' && x.body.fte_rate) ||
        x.event_type === 'fte_rate_changed' ||
        x.event_type === 'joined_mosaic' ||
        x.event_type === 'returned';
      this.editEventDialog.fteRate = x.body.fte_rate;
      this.editEventDialog.showTermsAlreadyCompleted = x.event_type === 'started';
      this.editEventDialog.termsAlreadyCompleted = x.body.terms_already_completed;
      this.editEventDialog.showTermsCompletedSinceStartDate = x.event_type === 'joined_mosaic';
      this.editEventDialog.termsCompletedSinceStartDate = x.body.terms_completed_since_start_date;
      this.editEventDialog.showTermsCompletedSinceLeaving = x.event_type === 'returned';
      this.editEventDialog.termsCompletedSinceLeaving = x.body.terms_completed_since_leaving;
      this.editEventDialog.note = x.body.note;
      this.editEventDialog.showAbsences = x.event_type === 'absences';
      this.editEventDialog.absenceCount = x.body.absence_count;
      this.editEventDialog.showExtension = x.event_type === 'extension';
      this.editEventDialog.extensionType = x.body.type;
      this.editEventDialog.extensionDays = x.body.days;
      this.editEventDialog.extensionTerms = x.body.terms;
      this.editEventDialog.showDate = x.event_type !== 'projected_review' || this.manuallyManagedProjectedReviews;
      this.editEventDialog.showIgnore = x.event_type === 'projected_review' && !this.manuallyManagedProjectedReviews;
      this.editEventDialog.ignored = x.body.ignored;
      this.editEventDialog.showSchool = x.event_type === 'started' || x.event_type === 'returned';
      this.editEventDialog.schoolId = x.school_id;

      if (x.event_type === 'induction_finished_early') {
        this.editEventDialog.dateLabel = 'End of induction date';
      }
      if (x.event_type === 'projected_review') {
        this.editEventDialog.dateLabel = `${this.reviewNounCapitalised} due date`;
      }
      if (x.event_type === 'projected_review' && this.manuallyManagedProjectedReviews) {
        this.editEventDialog.showReviewCreationDate = true;
        this.editEventDialog.reviewCreationDate = x.body.review_creation_date;
      }
    },
    submitEditEvent() {
      this.submitDialog(
        this.editEventDialog,
        () => {
          let body = {};
          const e = this.editEventDialog.event;
          if (e.event_type === 'started') {
            body = {
              fte_rate: this.editEventDialog.showFteRate ? parseFloat(this.editEventDialog.fteRate) : null,
              terms_already_completed: parseFloat(this.editEventDialog.termsAlreadyCompleted),
            };
          } else if (e.event_type === 'joined_mosaic') {
            body = {
              fte_rate: parseFloat(this.editEventDialog.fteRate),
              terms_completed_since_start_date: parseFloat(this.editEventDialog.termsCompletedSinceStartDate),
            };
          } else if (e.event_type === 'fte_rate_changed') {
            body = {
              fte_rate: parseFloat(this.editEventDialog.fteRate),
            };
          } else if (e.event_type === 'absences') {
            body = {
              absence_count: parseFloat(this.editEventDialog.absenceCount),
            };
          } else if (e.event_type === 'extension') {
            body = {
              type: this.editEventDialog.extensionType,
              days:
                this.editEventDialog.extensionType === 'days'
                  ? parseInt(this.editEventDialog.extensionDays)
                  : undefined,
              terms:
                this.editEventDialog.extensionType === 'terms'
                  ? parseInt(this.editEventDialog.extensionTerms)
                  : undefined,
            };
          } else if (e.event_type === 'returned') {
            body = {
              fte_rate: parseFloat(this.editEventDialog.fteRate),
              terms_completed_since_leaving: parseFloat(this.editEventDialog.termsCompletedSinceLeaving),
            };
          }

          body.note = this.editEventDialog.note;

          if (e.event_type === 'projected_review') {
            return this.$api.put(`/ect-projected-reviews/${this.editEventDialog.event.id}`, {
              due_date: this.getDialogDate(this.editEventDialog),
              ignored: this.editEventDialog.ignored,
              review_creation_date: this.editEventDialog.reviewCreationDate,
              ...body,
            });
          } else {
            return this.$api.put(`/ect-progress-events/${this.editEventDialog.event.id}`, {
              event_date: this.getDialogDate(this.editEventDialog),
              school_id: this.editEventDialog.schoolId,
              body,
            });
          }
        },
        createErrorsMap('edit this event', this.finishInductionEarlyErrors),
        this.editEventDialog.event.event_type !== 'projected_review'
      );
    },
    resetAddEventDialog(newEventType) {
      this.addEventDialog.active = true;
      this.addEventDialog.error = '';
      this.addEventDialog.showFteRate = false;
      this.addEventDialog.showAbsences = false;
      this.addEventDialog.showExtension = false;
      this.addEventDialog.showSchool = false;
      this.addEventDialog.schoolId = null;
      this.addEventDialog.dateLabel = '';
      this.addEventDialog.eventType = newEventType;
    },
    openSettingsDialog() {
      this.settingsDialog.active = true;
      this.settingsDialog.manual = this.manuallyManagedProjectedReviews;
    },
    async submitSettingsDialog() {
      this.submitDialog(
        this.settingsDialog,
        () =>
          this.$api.put(`/ects/${this.ect.id}/settings`, {
            manually_manage_projected_reviews: this.settingsDialog.manual,
          }),
        createErrorsMap(`update ${this.traineeNounCapitalised()} settings`),
        false
      );
    },
    createReviewFromProjectedReview(event) {
      this.createReviewDialog.active = true;
      this.createReviewDialog.error = '';
      this.createReviewDialog.event = event;
    },
    async submitCreateReviewFromProjectedReview() {
      this.submitDialog(
        this.createReviewDialog,
        () => this.$api.post(`/ect-projected-reviews/${this.createReviewDialog.event.id}/create-review`, {}),
        createErrorsMap(`create this ${this.reviewNounCapitalised}`, {
          no_active_review_template: `There is no active ${this.reviewNounCapitalised} Template for this type of ${this.reviewNounCapitalised}. Please go to the ${this.reviewNounCapitalised} Templates area to resolve this.`,
        })
      );
    },
    async submitDialog(dialog, request, errors, showIgnoredEventsDialog = true) {
      dialog.processing = true;
      dialog.error = '';
      const beforeIgnoredEvents = this.ect.ect_progress_events.filter(x => x.body.ignored);
      try {
        await request();
        await this.load();
        const afterIgnoredEvents = this.ect.ect_progress_events.filter(x => x.body.ignored);
        dialog.active = false;
        dialog.note = '';
        this.$store.dispatch('loadStudent', this.selectedStudent.id);
        // ECT state is cached on the cohort and this may modify it (probably a better data setup than this)
        // This is slow for large numbers of ECTs, but is happening in the background
        this.loadCohortStudents(true, true);

        if (showIgnoredEventsDialog && afterIgnoredEvents.length < beforeIgnoredEvents.length) {
          this.unignoredEventsDialog = {
            active: true,
            events: beforeIgnoredEvents
              .filter(e => !afterIgnoredEvents.some(e2 => e2.id === e.id))
              .map(e => ({
                ...e,
                body: {
                  ...e.body,
                  ignored: false,
                },
              })),
          };
        }
      } catch (e) {
        if (e.response?.data.error_code) {
          dialog.error = errors[e.response?.data.error_code] || errors['default'];
        } else {
          console.log(e);
          dialog.error = errors['default'];
        }
      }
      dialog.processing = false;
    },
    getDialogDate(dialog) {
      return dialog.date === 'other' ? dialog.otherDate : dialog.date;
    },
    eventHasSubtitle(e) {
      return e.subtitles && e.subtitles.length > 0;
    },
  },
};
</script>

<style>
.v-slide-group__prev {
  display: none !important;
}
</style>

<style scoped>
.progress-tick {
  color: rgb(var(--v-theme-primary));
  border-left: 2px solid;
  height: 10px;
}
.timeline-icon {
  background: white;
  border: 2px solid rgb(var(--v-theme-primary));
  border-radius: 50%;
  height: 30px;
  width: 30px;
}
</style>
