<template>
  <mosaic-save-dialog
    :active="active"
    @update:active="emit('update:active', $event)"
    title="Edit Reflection Template"
    object-type="Reflection Template"
    :save="save"
    :can-save="canSave"
    @save="emit('save', { name, studentFacingName })"
  >
    <mosaic-text-field label="Name" v-model="name" prepend-icon="pencil" />
    <div class="d-flex align-center">
      <mosaic-text-field
        :label="`${traineeNoun} facing name`"
        v-model="studentFacingName"
        :prepend-icon="icons.student"
        class="flex-grow-1"
      />
      <mosaic-help class="ml-2 mb-2">
        This will show for new Reflections and update for all existing Reflections created from this Reflection Template
      </mosaic-help>
    </div>
  </mosaic-save-dialog>
</template>

<script setup lang="ts">
import { useApi } from '@/composables/api';
import type { ReflectionTemplateSlimResponse } from './reflection-templates';
import { computed, ref, watchEffect } from 'vue';
import { icons } from '@/utils/icons';
import { useStudentStore } from '@/stores/student';

const props = defineProps<{
  active: boolean;
  template: ReflectionTemplateSlimResponse;
}>();

const emit = defineEmits<{
  'update:active': [active: boolean];
  save: [template: { name: string; studentFacingName: string }];
}>();

const api = useApi();
const { traineeNoun } = useStudentStore();

const name = ref('');
const studentFacingName = ref();

watchEffect(() => {
  name.value = props.template.name;
  studentFacingName.value = props.template.studentFacingName;
});

const canSave = computed(() => !!name.value && !!studentFacingName.value);

async function save() {
  await api.put(`/reflection-templates/${props.template.id}/details`, {
    name: name.value,
    studentFacingName: studentFacingName.value,
  });
}
</script>
