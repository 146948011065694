<template>
  <v-card class="mb-4">
    <v-card-text class="pb-0">
      <div class="d-flex align-bottom flex-wrap flex-container" style="row-gap: 8px">
        <div class="text-h6">{{ title }}</div>
        <div class="flex-grow-1" />
        <div class="d-flex flex-wrap" style="row-gap: 12px">
          <template v-if="items.length > 5">
            <mosaic-text-field
              name="search"
              :style="{ 'min-width': smallScreen ? '300px' : '400px' }"
              v-model="searchTerm"
              hide-details
              :class="{ 'pl-6 pr-4': !smallScreen }"
              density="compact"
              :label="filterLabel"
              prepend-icon="mdi-filter"
            />
          </template>
          <template v-if="items.length > 5 || initialFilterStatus !== 'all'">
            <v-select
              v-if="statusFilterStatuses"
              v-model="selectedStatus"
              style="max-width: 300px; min-width: 200px"
              hide-details
              density="compact"
              class="pr-4"
              :label="statusFilterLabel"
              :items="statusFilterStatuses"
            />
          </template>
        </div>
      </div>
      <template v-if="errorMessage">{{ errorMessage }}</template>
      <template v-else>
        <div v-if="sortedAndFilteredItems.length === 0" class="py-2">
          There are no {{ itemNamePluralised }} for these filters. {{ contactProviderMessage }}
        </div>
        <mosaic-list two-line :items="paginatedItems">
          <template #item="{ item }">
            <slot name="item" v-bind="{ item }"></slot>
          </template>
        </mosaic-list>
      </template>
      <mosaic-pagination
        v-model="currentPage"
        v-model:page-size="pageSize"
        class="pb-4"
        :mosaic-key="mosaicKey"
        :total="sortedAndFilteredItems.length"
        :show-page-size-options="false"
        :include-top-margin="false"
      />
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts" generic="T extends { status?: string }">
import { ref, computed, watch } from 'vue';
import type { Many } from 'lodash';
import { mapGetters } from '@/store/map-store';

const props = defineProps<{
  mosaicKey?: string;
  title: string;
  filterLabel: string;
  errorMessage?: string;
  itemNamePluralised: string;
  items: T[];
  statusFilterStatuses?: {
    title: string;
    value: string;
  }[];
  statusFilterLabel?: string;
  initialFilterStatus?: string;
  institutionName?: string;
  institutionContactEmail?: string;
  search: (searchTerm: string, item: T) => boolean;
  sort:
    | string
    | string[]
    | {
        iteratees: string | string[];
        orders: Many<boolean | 'asc' | 'desc'>;
      };
}>();

// contact message
const { institutionNoun } = mapGetters();
const contactProviderMessage = computed(() => {
  const name = props.institutionName || `your ${institutionNoun.value}`;
  return (
    `Please contact ${name}` +
    (props.institutionContactEmail ? ` on ${props.institutionContactEmail}` : '') +
    ', if you think this is incorrect.'
  );
});

// search
const searchTerm = ref('');
const selectedStatus = ref(props.initialFilterStatus || props.statusFilterStatuses?.[0].value);
const sortedAndFilteredItems = computed(() => {
  if (Array.isArray(props.sort) || typeof props.sort == 'string') {
    return props.items
      .sortBy(props.sort)
      .filter(
        i =>
          props.search(searchTerm.value, i) &&
          (selectedStatus.value === 'all' ||
            i.status === selectedStatus.value ||
            props.statusFilterStatuses === undefined)
      );
  } else {
    return props.items
      .sortBy(props.sort.iteratees, props.sort.orders)
      .filter(
        i =>
          props.search(searchTerm.value, i) &&
          (selectedStatus.value === 'all' ||
            i.status === selectedStatus.value ||
            props.statusFilterStatuses === undefined)
      );
  }
});

// pagination
const currentPage = ref(1);
const pageSize = ref(5);
const paginatedItems = computed(() => {
  const index = (currentPage.value - 1) * pageSize.value;
  return sortedAndFilteredItems.value.slice(index, index + pageSize.value);
});
watch(sortedAndFilteredItems, () => {
  currentPage.value = 1;
});
</script>
