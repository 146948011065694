<template>
  <div>
    <mosaic-loading-card v-if="busy" type="two-cards" />
    <mosaic-load-error-card v-else-if="loadError" object-type="Reflection" @retry="loadReflectionFromApi" />
    <template v-else>
      <reflection-card
        v-if="!busy"
        ref="reflectionCard"
        v-model:reflection="reflection"
        :student-id="userStudent.id"
        :curriculum-statement-id="
          $route.query.curriculumStatementId ? $route.query.curriculumStatementId.toString() : undefined
        "
        :templates="fromSnakeCaseToCamelCase(userStudent.cohort.reflection_templates)"
        :return-to="{ name: 'ReflectionsListPage' }"
        :editable="true"
        @update:dirty="reflectionDirty = $event"
      />
      <comments-card
        v-if="reflectionComments"
        class="mt-6"
        :comments="reflectionComments"
        @update:dirty="commentsDirty = $event"
      />
    </template>
  </div>
</template>

<script>
import ReflectionCard from './ReflectionCard.vue';
import CommentsCard from '@/components/CommentsCard.vue';
import axios from 'axios';
import { mapState } from 'vuex';
import { fromSnakeCaseToCamelCase } from '@/utils/transforms';

export default {
  name: 'StudentReflectionEditPage',
  components: { CommentsCard, ReflectionCard },
  emits: ['update:dirty'],
  expose: ['save'],
  data() {
    return {
      reflectionId: null,
      busy: true,
      reflection: null,
      reflectionComments: null,
      reflectionDirty: false,
      commentsDirty: false,
      loadError: false,
    };
  },
  computed: {
    ...mapState(['userStudent']),
    dirtyComputed() {
      return this.reflectionDirty || this.commentsDirty;
    },
    isEditing() {
      return this.$route.name === 'ReflectionPage';
    },
    breadcrumbs() {
      return [
        {
          text: 'Reflections',
          to: {
            name: 'ReflectionsListPage',
          },
        },
        {
          text: this.isEditing ? this.reflection?.title : 'New Reflection',
        },
      ];
    },
  },
  watch: {
    dirtyComputed(x) {
      this.$emit('update:dirty', x);
    },
  },
  async created() {
    if (this.isEditing) {
      this.reflectionId = this.$route.params.reflectionId.toString();
      await this.loadReflectionFromApi();
    }
    this.busy = false;
  },
  methods: {
    async loadReflectionFromApi() {
      this.loadError = false;
      this.busy = true;
      try {
        const response = await this.$api.get(`/reflections/${this.reflectionId}`);
        this.reflection = fromSnakeCaseToCamelCase(response.data);
        this.reflectionComments = response.data.journal_entry_comments;
      } catch (e) {
        if (axios.isAxiosError(e) && (e.response?.status === 404 || e.response?.status === 403)) {
          this.$router.replace({
            name: 'DoNotHaveAccessErrorPage',
            query: { to: this.$route.fullPath, objectType: 'Reflection' },
          });
        } else {
          console.log(e);
          this.loadError = true;
        }
      }
      this.busy = false;
    },
    save() {
      return this.$refs.reflectionCard.save();
    },
    fromSnakeCaseToCamelCase,
  },
};
</script>
