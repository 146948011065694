<template>
  <div>
    <v-card v-if="!userStudent.storage_set_up" class="mb-4">
      <v-card-text>
        <div class="text-h6">My Files</div>
        <div class="py-2" v-if="!userStudent.storage_email">
          <p>To view and upload files, you need to link your {{ selectedInstitution.storage_type }} account.</p>
          <p>
            All files you see within the Files area on Mosaic will actually be stored on your
            {{ selectedInstitution.storage_type }}.
          </p>
          <p />
        </div>
        <div v-else class="py-2">
          <p>
            The link between your Mosaic account and {{ selectedInstitution.storage_type }} account has expired. To fix
            this, you need to relink your <b>{{ userStudent.storage_email }}</b>
            {{ selectedInstitution.storage_type }} account. If you would like to link a different One Drive account
            please contact support.
          </p>
          <p />
        </div>

        <v-alert v-if="storageConstraint" class="mb-4" type="info"
          ><div>{{ storageConstraintMessage }}</div>
        </v-alert>

        <v-alert v-if="storageEmailError || wrongAccountWhenRelinkingError" class="mb-4" type="error"
          ><div>{{ storageEmailError || wrongAccountWhenRelinkingError }}</div>
          <div class="mt-2" v-if="storageEmailError">
            Please try again with an allowable One Drive account. You may need to
            <a href="https://login.microsoftonline.com/logout.srf" target="_blank">logout</a> of your One Drive account
            first.
          </div>
        </v-alert>

        <v-btn @click.prevent="navigateToStorageLogin()">
          <v-icon v-if="selectedInstitution.storage_type === 'Google Drive'" class="mr-2">mdi-google-drive</v-icon>
          <v-icon v-if="selectedInstitution.storage_type === 'One Drive'" class="mr-2">mdi-microsoft-onedrive</v-icon>
          <span>{{ !userStudent.storage_email ? 'Link' : 'Relink' }} {{ selectedInstitution.storage_type }}</span>
        </v-btn>
      </v-card-text>
    </v-card>
    <files-list
      v-else
      :load-items="loadItems"
      :files-storage-type="selectedInstitution.storage_type"
      :get-file-url="getFileUrl"
      :get-evidence="getEvidence"
      :move-item="moveItem"
      :can-add-as-evidence="showEvidence"
      :page-breadcrumbs="breadcrumbs"
      :storage-set-up="userStudent.storage_set_up"
      :refresh-student="() => $store.dispatch('refreshUser')"
    />
  </div>
</template>

<script>
import FilesList from '@/components/files-list/FilesList.vue';
import { mapGetters, mapState } from 'vuex';
import { startStudentLinkOneDriveFlow, startStudentLinkGoogleDriveFlow } from '@/utils/external-auth';
import { storageConstraintMessage, storageEmailError } from '@/utils/storage-constraints';
import { executeMosaicJob } from '@/utils/mosaic-job';
import { MosaicError } from '@/utils/errors';

export default {
  name: 'StudentFilesListPage',
  components: { FilesList },
  data: () => ({
    storageConstraint: null,
  }),
  computed: {
    ...mapState(['userStudent', 'selectedInstitution', 'user']),
    ...mapGetters(['showEvidence']),
    breadcrumbs() {
      return [
        {
          text: 'Files',
          to: {
            name: 'FilesListPage',
            params: { studentId: this.userStudent.id },
          },
        },
      ];
    },
    wrongAccountWhenRelinkingError() {
      if (this.$route.query.storage_email_error !== 'storage_email_does_not_match') return;

      return `When re-linking your ${this.userStudent.storage_email} ${this.selectedInstitution.storage_type} account you have logged in as ${this.$route.query.storage_email} instead. Please try again with the correct account.`;
    },
    storageEmailError() {
      return storageEmailError(this.$route.query, this.storageConstraint);
    },
    storageConstraintMessage() {
      if (!this.storageConstraint) return false;
      return storageConstraintMessage(this.storageConstraint);
    },
  },
  async created() {
    if (this.selectedInstitution.storage_type === 'One Drive' && !this.userStudent.storage_email) {
      try {
        const r = await this.$api.get(`/storage-constraint`);
        this.storageConstraint = r.data;
      } catch (e) {
        console.log(e);
      }
    }
  },
  methods: {
    async loadItems(folder) {
      const urlRoot = `students/${this.userStudent.id}/files`;

      let responseData = null;
      if (this.selectedInstitution.storage_type === 'One Drive') {
        await executeMosaicJob(
          this.$api,
          this.$ws,
          this.user.id,
          `load your files`,
          `/${urlRoot}-async/${encodeURIComponent(folder.id)}`,
          {},
          d => {
            responseData = d.response;
          },
          ({ e, errorMessage, errorCode }) => {
            if (e) {
              throw e;
            } else if (errorCode) {
              throw new MosaicError(errorCode, errorMessage);
            } else {
              throw errorMessage;
            }
          },
          { waitInMs: 100, maxAttempts: 300 }
        );
      } else {
        responseData = (await this.$api.get(`/${urlRoot}/${encodeURIComponent(folder.id)}`)).data;
      }

      // Add editable at the API instead
      const items = responseData.items.map(x => ({
        ...x,
        sortName: x.shared ? `000000000${x.name}` : x.name,
        isMoveTarget: x.type === 'folder',
        icon: {
          name: this.getIconName(x),
          color: null,
          tooltip: this.getTooltip(x),
        },
        editable: true,
        canLinkEvidence: x.type === 'file' && this.showEvidence,
        filesApiPath: `/${urlRoot}`,
      }));

      let presignFilesApiPath = null;
      if (this.selectedInstitution.storage_type === 'Mosaic') {
        presignFilesApiPath = `/presign/students/${this.userStudent.id}/files`;
      }

      return {
        items,
        folder: {
          id: folder.id || responseData.folder.id,
          name: folder.name || responseData.folder.name,
          webUrl: folder.webUrl || responseData.folder.webUrl,
          isRootFolder: folder.isRootFolder || false,
          editable: true,
          isMoveTarget: false,
          uploadDirectToExternalStorage: this.selectedInstitution.storage_type === 'Mosaic',
          filesApiPath: `/${urlRoot}`,
          presignFilesApiPath,
          folderApiPath: `students/${this.userStudent.id}/folders`,
        },
      };
    },
    async getFileUrl(item, download) {
      const urlRoot = `students/${this.userStudent.id}/file`;
      const r = await this.$api.get(`/${urlRoot}/${encodeURIComponent(item.id)}?download=${download}`);
      return r.data.webUrl;
    },
    async getEvidence(evidenceId) {
      const r = await this.$api.get(`/evidence/${evidenceId}`);
      return r.data;
    },
    getIconName(item) {
      if (item.type === 'file') {
        if (item.shared) return 'file-account';
        if (item.isShortcut) return 'file-move';
        return 'file';
      } else {
        if (item.shared) return 'folder-account';
        if (item.isShortcut) return 'folder-arrow-right';
      }
      return 'folder';
    },
    getTooltip(item) {
      if (item.shared)
        return 'This item has been shared with you by another Google Drive user. It may need to be shared separately from Google Drive to provide access.';
      if (item.isShortcut)
        return 'This item is a shortcut and may need to be shared separately from Google Drive to provide access.';
      return '';
    },
    async moveItem(item, destinationFolder, currentFolder) {
      await this.$api.post(`/files/${encodeURIComponent(item.id)}/move-item`, {
        destinationId: destinationFolder.id,
        currentParentId: currentFolder.id,
        evidenceId: item.evidence?.id,
      });
    },
    navigateToStorageLogin() {
      this.selectedInstitution.storage_type === 'One Drive'
        ? startStudentLinkOneDriveFlow(this.$route.path)
        : startStudentLinkGoogleDriveFlow(this.$route.path, this.$api);
    },
  },
};
</script>
