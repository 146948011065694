import { createRouter, createWebHashHistory } from 'vue-router';
import StudentEvidenceListPage from '../pages/student/StudentEvidenceListPage.vue';
import LoginPage from '../pages/LoginPage.vue';
import LogoutPage from '../pages/LogoutPage.vue';
import PrivacyPolicy from '../pages/PrivacyPolicyPage.vue';
import PasswordSecurity from '../pages/PasswordSecurityPage.vue';
import ForgotPasswordPage from '../pages/ForgotPasswordPage.vue';
import ResetPasswordPage from '../pages/ResetPasswordPage.vue';
import TutorStudentListPage from '../pages/student-accounts/TutorStudentListPage.vue';
import TutorCohortListPage from '../pages/tutor/TutorCohortListPage.vue';
import TutorAdminCohortCreatePage from '../pages/tutor/TutorAdminCohortCreatePage.vue';
import TutorAdminCohortEditPage from '../pages/settings/TutorAdminCohortEditPage.vue';
import TutorStudentWrapper from '../pages/tutor/TutorStudentWrapper.vue';
import TutorStudentDetailsPage from '../pages/tutor/TutorStudentDetailsPage.vue';
import TutorStudentProficienciesPage from '../pages/tutor/TutorStudentProficienciesPage.vue';
import TutorCurriculumPage from '../pages/curriculum/TutorCurriculumPage.vue';
import TutorCurriculumStatementPage from '../pages/curriculum/TutorCurriculumStatementPage.vue';
import TutorCurriculumTeachingStrategyPage from '../pages/curriculum/TutorCurriculumTeachingStrategyPage.vue';
import TutorAdminCalendarPage from '../pages/tutor/TutorAdminCalendarPage.vue';
import CalendarPage from '../pages/calendar/CalendarPage.vue';
import TutorAdminGlobalSearchPage from '../pages/tutor/TutorAdminGlobalSearchPage.vue';
import TutorCohortStaffTrainingPage from '../pages/tutor/TutorCohortStaffTrainingPage/TutorCohortStaffTrainingPage.vue';
import CohortStaffListPage from '../pages/tutor/TutorStaffListPages/CohortStaffListPage.vue';
import TutorAdminCurriculumPage from '../pages/curriculum/TutorAdminCurriculumPage.vue';
import TutorAdminCurriculumThemePage from '../pages/curriculum/TutorAdminCurriculumThemePage.vue';
import AdminCurriculumStatementPage from '../pages/curriculum/AdminCurriculumStatementPage.vue';
import AdminCurriculumTeachingStrategyPage from '../pages/curriculum/AdminCurriculumTeachingStrategyPage.vue';
import TutorAdminFeatureConfigPage from '../pages/settings/TutorAdminFeatureConfigPage.vue';
import TutorAdminPermissionsPage from '../pages/settings/TutorAdminPermissionsPage.vue';
import TutorPortfolioOverviewPage from '../pages/tutor/TutorPortfolioOverviewPage.vue';
import TutorPortfolioStandardPage from '../pages/tutor/TutorPortfolioStandardPage.vue';
import TutorEvidenceListPage from '../pages/tutor/TutorEvidenceListPage.vue';
import FilesListPage from '../pages/files/FilesListPage.vue';
import CourseFilesListPage from '../pages/files/CourseFilesListPage.vue';
import ReflectionsListPage from '../pages/reflections/ReflectionsListPage.vue';
import TargetsListPage from '../pages/targets/TargetsListPage.vue';
import TargetPage from '../pages/targets/TargetPage.vue';
import TutorGroupsListPage from '../pages/tutor/TutorGroupsListPage.vue';
import TutorGroupPage from '../pages/tutor/TutorGroupPage.vue';
import CohortDevelopmentLessonObservationsPage from '../pages/tutor/TutorCohortDevelopmentPages/CohortDevelopmentLessonObservationsPage.vue';
import CohortDevelopmentTargetsPage from '../pages/tutor/TutorCohortDevelopmentPages/CohortDevelopmentTargetsPage.vue';
import CohortDevelopmentReflectionsPage from '../pages/tutor/TutorCohortDevelopmentPages/CohortDevelopmentReflectionsPage.vue';
import TutorAdminReviewEditPage from '../pages/reviews/TutorAdminReviewEditPage/TutorAdminReviewEditPage.vue';
import CohortCourseReflectionsPage from '../pages/reflections/CohortCourseReflectionsPage.vue';
import CohortReviewsPage from '../pages/reviews/CohortReviewsPage.vue';
import TutorAdminJudgementSetsPage from '../pages/settings/TutorAdminJudgementSetsPage.vue';
import TutorAdminReviewViewPage from '../pages/reviews/TutorAdminReviewViewPage.vue';
import TutorReviewsListPage from '../pages/reviews/TutorReviewsListPage.vue';
import TutorReviewPage from '../pages/reviews/TutorReviewPage.vue';
import TutorReviewOverallPage from '../pages/reviews/TutorReviewOverallPage.vue';
import TutorReviewStandardPage from '../pages/reviews/TutorReviewStandardPage.vue';
import TutorReviewPart2Page from '../pages/reviews/TutorReviewPart2Page.vue';
import StudentReviewPage from '../pages/reviews/StudentReviewPage.vue';
import StudentReviewOverallPage from '../pages/reviews/StudentReviewOverallPage.vue';
import StudentReviewStandardPage from '../pages/reviews/StudentReviewStandardPage.vue';
import StudentReviewPart2Page from '../pages/reviews/StudentReviewPart2Page.vue';
import TutorCohortWrapper from '../pages/tutor/TutorCohortWrapper.vue';
import TutorInstitutionPage from '../pages/tutor/TutorInstitutionPage.vue';
import TutorSchoolsListPage from '../pages/tutor/TutorSchoolsListPage.vue';
import TutorSchoolEditPage from '../pages/tutor/TutorSchoolEditPage.vue';
import TutorSchoolStudentsPage from '../pages/tutor/TutorSchoolStudentsPage.vue';
import TutorSchoolStaffPage from '../pages/tutor/TutorSchoolStaffPage.vue';
import TutorSchoolWrapper from '../pages/tutor/TutorSchoolWrapper.vue';
import InstitutionStaffListPage from '../pages/tutor/TutorStaffListPages/InstitutionStaffListPage.vue';
import InstitutionTutorStaffPage from '@/pages/tutor/TutorStaffPages/InstitutionTutorStaffPage.vue';
import CohortTutorStaffPage from '@/pages/tutor/TutorStaffPages/CohortTutorStaffPage.vue';
import TutorStaffTrainingPage from '../pages/staff-training/TutorStaffTrainingPage.vue';
import TutorStaffTrainingModulePage from '../pages/staff-training/TutorStaffTrainingModulePage.vue';
import TutorStaffTrainingCompetencyThemePage from '../pages/staff-training/TutorStaffTrainingCompetencyThemePage.vue';
import StaffTrainingRecordEditPage from '../pages/staff-training/StaffTrainingRecordEditPage.vue';
import StaffTrainingEventPage from '../pages/staff-training/StaffTrainingEventPage.vue';
import StaffTrainingCertificateEditPage from '../pages/staff-training/StaffTrainingCertificateEditPage.vue';
import InstitutionStaffTrainingPage from '../pages/tutor/TutorAdminStaffTrainingPages/InstitutionStaffTrainingPage.vue';
import InstitutionStaffTrainingModulePage from '../pages/tutor/TutorAdminStaffTrainingPages/InstitutionStaffTrainingModulePage.vue';
import InstitutionStaffTrainingAcceptedCertificateEditPage from '../pages/tutor/TutorAdminStaffTrainingPages/InstitutionStaffTrainingAcceptedCertificateEditPage.vue';
import InstitutionStaffTrainingEventEditPage from '../pages/tutor/TutorAdminStaffTrainingPages/InstitutionStaffTrainingEventEditPage.vue';
import InstitutionStaffTrainingModuleSectionPage from '../pages/tutor/TutorAdminStaffTrainingPages/InstitutionStaffTrainingModuleSectionPage.vue';
import InstitutionStaffTrainingFrameworkPage from '../pages/tutor/TutorAdminStaffTrainingPages/InstitutionStaffTrainingFrameworkPage.vue';
import InstitutionStaffTrainingCompetencyThemePage from '../pages/tutor/TutorAdminStaffTrainingPages/InstitutionStaffTrainingCompetencyThemePage.vue';
import InstitutionStaffTrainingCompetencyPage from '../pages/tutor/TutorAdminStaffTrainingPages/InstitutionStaffTrainingCompetencyPage.vue';
import StudentPortfolioOverviewPage from '../pages/student/StudentPortfolioOverviewPage.vue';
import StudentPortfolioStandardPage from '../pages/student/StudentPortfolioStandardPage.vue';
import StudentCurriculumPage from '../pages/curriculum/StudentCurriculumPage.vue';
import StudentCurriculumStatementPage from '../pages/curriculum/StudentCurriculumStatementPage.vue';
import StudentCurriculumTeachingStrategyPage from '../pages/curriculum/StudentCurriculumTeachingStrategyPage.vue';
import StudentReviewsListPage from '../pages/reviews/StudentReviewsListPage.vue';
import ReflectionPage from '../pages/reflections/ReflectionPage.vue';
import LessonObservationPage from '../pages/lesson-observations/LessonObservationPage.vue';
import MentorMeetingPage from '../pages/mentor-meetings/MentorMeetingPage.vue';
import StudentAnnouncementPage from '../pages/announcements/StudentAnnouncementPage.vue';
import StudentAnnouncementsPage from '../pages/announcements/StudentAnnouncementsPage.vue';
import CohortAdminStaffAnnouncementsPage from '../pages/announcements/CohortAdminStaffAnnouncementsPage.vue';
import CohortAdminStaffAnnouncementEditPage from '../pages/announcements/CohortAdminStaffAnnouncementEditPage.vue';
import CohortAdminAnnouncementsPage from '../pages/announcements/CohortAdminAnnouncementsPage.vue';
import CohortAdminAnnouncementEditPage from '../pages/announcements/CohortAdminAnnouncementEditPage.vue';
import CohortBulkUploadPage from '@/pages/student-accounts/CohortBulkUploadPage.vue';
import StudentProficienciesPage from '../pages/student/StudentProficienciesPage.vue';
import StudentHomePage from '../pages/student/StudentHomePage.vue';
import TutorHomePage from '../pages/tutor/TutorHomePage.vue';
import UserHomePage from '../pages/UserHomePage.vue';
import AdminPage from '../pages/admin/AdminPage.vue';
import AdminUsersPage from '../pages/admin/AdminUsersPage.vue';
import AdminUserPage from '../pages/admin/AdminUserPage.vue';
import AdminFeatureSwitchesPage from '../pages/admin/AdminFeatureSwitchesPage.vue';
import AdminPermissionsPage from '../pages/admin/AdminPermissionsPage.vue';
import AdminInstitutionPage from '../pages/admin/AdminInstitutionPage.vue';
import AdminInstitutionStaffPage from '../pages/admin/AdminInstitutionStaffPage.vue';
import AdminInstitutionConfigPage from '../pages/admin/AdminInstitutionConfigPage.vue';
import AdminCurriculumTemplatesPage from '../pages/admin/AdminCurriculumTemplatesPage.vue';
import AdminCurriculumThemeTemplatesPage from '../pages/admin/AdminCurriculumThemeTemplatesPage.vue';
import AdminCurriculumStatementTemplatesPage from '../pages/admin/AdminCurriculumStatementTemplatesPage.vue';
import AdminStandardSetsPage from '../pages/admin/standards/AdminStandardSetsPage.vue';
import AdminStandardSetStandardsPage from '../pages/admin/standards/AdminStandardSetStandardsPage.vue';
import AdminSubjectsPage from '../pages/admin/AdminSubjectsPage.vue';
import AdminStandardSetSubstandardsPage from '../pages/admin/standards/AdminStandardSetSubstandardsPage.vue';
import AdminInstitutionCohortPage from '../pages/admin/AdminInstitutionCohortPage.vue';
import AdminInstitutionCreditsPage from '../pages/admin/AdminInstitutionCreditsPage.vue';
import AdminJudgementSetsPage from '../pages/admin/AdminJudgementSetsPage.vue';
import AdminProfessionalResourcesPage from '../pages/professional-resources/AdminProfessionalResourcesPage.vue';
import AdminCertificateTypesPage from '../pages/admin/AdminCertificateTypesPage.vue';
import UserSetupPage from '../pages/UserSetupPage.vue';
import ForceChangePasswordPage from '../pages/ForceChangePasswordPage.vue';
import VerifyEmailPage from '../pages/VerifyEmailPage.vue';
import VerifyEmailClickedPage from '../pages/VerifyEmailClickedPage.vue';
import UserProfilePage from '../pages/UserProfilePage.vue';
import TutorAdminStudentCourseFilesPage from '../pages/tutor/TutorAdminStudentCourseFilesPage.vue';
import TutorAdminStaffFilesPage from '../pages/tutor/TutorAdminStaffFilesPage.vue';
import TutorStaffFilesListPage from '../pages/tutor/TutorStaffFilesListPage.vue';
import TutorAdminFolderTemplatePage from '../pages/tutor/TutorAdminFolderTemplatePage.vue';
import TutorAdminFolderTemplatesListPage from '../pages/tutor/TutorAdminFolderTemplatesListPage.vue';
import TutorAdminAssignmentTemplatesListPage from '../pages/assignments/TutorAdminAssignmentTemplatesListPage.vue';
import TutorAdminAssignmentTemplateCreatePage from '../pages/assignments/TutorAdminAssignmentTemplateCreatePage.vue';
import TutorAdminAssignmentTemplateEditPage from '../pages/assignments/TutorAdminAssignmentTemplateEditPage.vue';
import TutorAdminAssignmentTemplateSectionPage from '../pages/assignments/TutorAdminAssignmentTemplateSectionPage.vue';
import TutorAdminReviewTemplatePage from '../pages/reviews/TutorAdminReviewTemplatePage.vue';
import TutorAdminReviewTemplateCreatePage from '../pages/reviews/TutorAdminReviewTemplateCreatePage.vue';
import TutorAdminReviewTemplateCopyPage from '../pages/reviews/TutorAdminReviewTemplateCopyPage.vue';
import TutorAdminReviewTemplatesListPage from '../pages/reviews/TutorAdminReviewTemplatesListPage.vue';
import TutorAdminReviewTemplateOverallPage from '../pages/reviews/TutorAdminReviewTemplateOverallPage.vue';
import TutorAdminReviewTemplatePerStandardPage from '../pages/reviews/TutorAdminReviewTemplatePerStandardPage.vue';
import TutorAdminReviewTemplateStandardPage from '../pages/reviews/TutorAdminReviewTemplateStandardPage.vue';
import TutorAdminReviewTemplatePart2Page from '../pages/reviews/TutorAdminReviewTemplatePart2Page.vue';
import TutorAdminTargetTemplatePage from '../pages/targets/TutorAdminTargetTemplatePage.vue';
import TutorAdminCourseReflectionTemplatePage from '../pages/reflections/TutorAdminCourseReflectionTemplatePage.vue';
import LessonObservationsListPage from '../pages/lesson-observations/LessonObservationsListPage.vue';
import MentorMeetingsListPage from '../pages/mentor-meetings/MentorMeetingsListPage.vue';
import TutorEctProgressPage from '../pages/tutor/TutorEctProgressPage.vue';
import TutorEctRegistrationPage from '../pages/tutor/TutorEctRegistrationPage.vue';
import TutorEctsOverviewPage from '../pages/tutor/TutorEctsOverviewPage.vue';
import TutorPendingEctsPage from '../pages/tutor/TutorPendingEctsPage.vue';
import TutorCompletedEctsPage from '../pages/tutor/TutorCompletedEctsPage.vue';
import TutorAdminCohortCoursePage from '../pages/cohort-course/TutorAdminCohortCoursePage.vue';
import TutorAdminCohortCourseTermPage from '../pages/cohort-course/TutorAdminCohortCourseTermPage.vue';
import TutorAdminCohortCourseWeekPage from '../pages/cohort-course/TutorAdminCohortCourseWeekPage.vue';
import TutorAdminCohortCourseWeekSectionPage from '../pages/cohort-course/TutorAdminCohortCourseWeekSectionPage.vue';
import TutorAdminCohortCourseWeekTemplatePage from '../pages/cohort-course/TutorAdminCohortCourseWeekTemplatePage.vue';
import TutorAdminCohortCourseWeekTemplateSectionPage from '../pages/cohort-course/TutorAdminCohortCourseWeekTemplateSectionPage.vue';
import StudentCohortCoursePage from '../pages/cohort-course/StudentCohortCoursePage.vue';
import StudentCohortCourseWeekPage from '../pages/cohort-course/StudentCohortCourseWeekPage.vue';
import TutorCohortCoursePage from '../pages/cohort-course/TutorCohortCoursePage.vue';
import TutorCohortCourseWeekPage from '../pages/cohort-course/TutorCohortCourseWeekPage.vue';
import CohortAssignmentsListPage from '../pages/assignments/CohortAssignmentsListPage.vue';
import CohortAssignmentEditPage from '@/pages/assignments/CohortAssignmentEditPage.vue';
import CohortAssignmentViewPage from '@/pages/assignments/CohortAssignmentViewPage.vue';
import StudentAssignmentsListPage from '@/pages/assignments/StudentAssignmentsListPage.vue';
import StudentAssignmentPage from '@/pages/assignments/StudentAssignmentPage.vue';
import TutorAssignmentsListPage from '@/pages/assignments/TutorAssignmentsListPage.vue';
import TutorAssignmentPage from '@/pages/assignments/TutorAssignmentPage.vue';
import CohortCourseTargetsPage from '@/pages/targets/CohortCourseTargetsPage.vue';
import CohortMentorMeetingsPage from '@/pages/mentor-meetings/CohortMentorMeetingsPage.vue';
import LoadScopeErrorPage from '../pages/error/LoadScopeErrorPage.vue';
import DoNotHaveAccessErrorPage from '../pages/error/DoNotHaveAccessErrorPage.vue';
import InstitutionHomePagesSettingsPage from '../pages/settings/InstitutionHomePagesSettingsPage.vue';
import InstitutionProfessionalResourcesConfigurationPage from '../pages/professional-resources/InstitutionProfessionalResourcesConfigurationPage.vue';
import AdminForumsListPage from '@/pages/forums/AdminForumsListPage.vue';
import AdminForumEditPage from '@/pages/forums/AdminForumEditPage.vue';
import ForumPage from '@/pages/forums/ForumPage.vue';
import ForumEditMembersPage from '@/pages/forums/ForumEditMembersPage.vue';
import ForumsListPage from '@/pages/forums/ForumsListPage.vue';
import ForumDiscussionEditPage from '@/pages/forums/ForumDiscussionEditPage.vue';
import ForumDiscussionViewPage from '@/pages/forums/ForumDiscussionViewPage.vue';
import InstitutionGoReactTemplatesListPage from '@/pages/go-react-templates/InstitutionGoReactTemplatesListPage.vue';
import InstitutionGoReactTemplateEditPage from '@/pages/go-react-templates/InstitutionGoReactTemplateEditPage.vue';
import {
  loadStudentData,
  loadAdminData,
  authorise,
  findHomePage,
  loadCohort,
  loadSchool,
  selectUserStaffFromInstitutionId,
  selectUserStaffFromStaffId,
  activateAccount,
  ssoMicrosoft,
  ssoGoogle,
  clearSelectedStudent,
  clearSelectedSchool,
  clearSelectedCohort,
  clearSelectedInstitutionIfInMultiple,
  checkIfStudentOrTutorOnlyPage,
  startNavigating,
  stopNavigating,
  setMobileFriendly,
  findStudentPage,
  mustBeAdmin,
  mustBeStaff,
} from './guard-methods';
import StaffAnnouncementsPage from '@/pages/announcements/StaffAnnouncementsPage.vue';
import StaffAnnouncementPage from '@/pages/announcements/StaffAnnouncementPage.vue';
import StaffWrapper from '@/pages/tutor/StaffWrapper.vue';
import CohortCourseTargetEditPage from '@/pages/targets/CohortCourseTargetEditPage.vue';
import CohortCourseReflectionEditPage from '@/pages/reflections/CohortCourseReflectionEditPage.vue';
import SSOMicrosoftErrorPage from '@/pages/SSOMicrosoftErrorPage.vue';
import ScormPlayerPage from '@/pages/scorm/ScormPlayerPage.vue';
import InstitutionReflectionTemplatesListPage from '@/pages/reflections/InstitutionReflectionTemplatesListPage.vue';
import InstitutionReflectionTemplateEditPage from '@/pages/reflections/InstitutionReflectionTemplateEditPage.vue';
import InstitutionReflectionTemplateCreatePage from '@/pages/reflections/InstitutionReflectionTemplateCreatePage.vue';

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      name: 'Home',
      beforeEnter: findHomePage,
      meta: { noAuth: true },
    },
    {
      path: '/activate-account',
      name: 'ActivateAccount',
      beforeEnter: activateAccount,
      meta: { noAuth: true },
    },
    {
      path: '/sso/microsoft',
      name: 'SSOMicrosoft',
      beforeEnter: ssoMicrosoft,
      meta: { noAuth: true },
    },
    {
      path: '/sso/microsoft/error',
      name: 'SSOMicrosoftErrorPage',
      component: SSOMicrosoftErrorPage,
      meta: { noAuth: true, noLayoutContainerNavigation: true },
    },
    {
      path: '/sso/google',
      name: 'SSOGoogle',
      beforeEnter: ssoGoogle,
      meta: { noAuth: true },
    },
    {
      path: '/login',
      name: 'LoginPage',
      component: LoginPage,
      meta: { noAuth: true, noLayoutContainerNavigation: true, isLogin: true },
    },
    {
      path: '/logout',
      name: 'LogoutPage',
      component: LogoutPage,
      meta: { noAuth: true, noLayoutContainerNavigation: true, isLogin: true },
    },
    {
      path: '/forgot-password',
      name: 'ForgotPassword',
      component: ForgotPasswordPage,
      meta: { noAuth: true, noLayoutContainerNavigation: true, isLogin: true },
    },
    {
      path: '/reset-password',
      name: 'ResetPassword',
      component: ResetPasswordPage,
      meta: { noAuth: true, noLayoutContainerNavigation: true },
    },
    {
      path: '/profile',
      name: 'UserProfilePage',
      component: UserProfilePage,
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicy',
      component: PrivacyPolicy,
      meta: { noAuth: true, noLayoutContainerNavigation: true, layoutContainerFullWidthWhenNoNavigation: true },
    },
    {
      path: '/password-security',
      name: 'PasswordSecurity',
      component: PasswordSecurity,
      meta: { noAuth: true, noLayoutContainerNavigation: true, layoutContainerFullWidthWhenNoNavigation: true },
    },
    {
      path: '/scorm/:id',
      name: 'ScormPlayerPage',
      component: ScormPlayerPage,
      meta: { noNav: true },
    },
    {
      path: '/evidence',
      name: 'StudentEvidenceListPage',
      component: StudentEvidenceListPage,
    },
    {
      path: '/student-home',
      name: 'StudentHomePageRedirect',
      beforeEnter: findStudentPage('StudentHomePage'),
      alias: '/home',
    },
    {
      path: '/user-home',
      name: 'UserHomePage',
      component: UserHomePage,
      alias: '/staff-home',
      meta: {
        clearSelectedStudent: true,
        clearSelectedSchool: true,
        clearSelectedCohort: true,
        clearSelectedInstitutionIfInMultiple: true,
      },
    },
    {
      path: '/calendar',
      name: 'StudentCalendarPage',
      beforeEnter: findStudentPage(),
    },
    {
      path: '/course',
      name: 'StudentCohortCoursePage',
      component: StudentCohortCoursePage,
    },
    {
      path: '/course/weeks/:studentWeekId',
      name: 'StudentCohortCourseWeekPage',
      component: StudentCohortCourseWeekPage,
    },
    {
      path: '/proficiencies',
      name: 'StudentProficienciesPage',
      component: StudentProficienciesPage,
    },
    {
      path: '/reflections',
      name: 'StudentReflectionsListPage',
      beforeEnter: findStudentPage(),
    },
    {
      path: '/reflections/create',
      name: 'StudentReflectionCreatePage',
      beforeEnter: findStudentPage(),
    },
    {
      path: '/reflections/:reflectionId',
      name: 'StudentReflectionPage',
      beforeEnter: findStudentPage(),
    },
    {
      path: '/announcements',
      name: 'StudentAnnouncementsPage',
      component: StudentAnnouncementsPage,
    },
    {
      path: '/announcements/:id',
      name: 'StudentAnnouncementPage',
      component: StudentAnnouncementPage,
    },
    {
      path: '/reviews',
      name: 'StudentReviewsListPage',
      component: StudentReviewsListPage,
    },
    {
      path: '/reviews/:id',
      name: 'StudentReviewPage',
      component: StudentReviewPage,
    },
    {
      path: '/reviews/:id/standards/:standardId',
      name: 'StudentReviewStandardPage',
      component: StudentReviewStandardPage,
    },
    {
      path: '/reviews/:id/overall',
      name: 'StudentReviewOverallPage',
      component: StudentReviewOverallPage,
    },
    {
      path: '/reviews/:id/part2',
      name: 'StudentReviewPart2Page',
      component: StudentReviewPart2Page,
    },
    {
      path: '/files',
      name: 'StudentFilesListPage',
      beforeEnter: findStudentPage(),
    },
    {
      path: '/course-files',
      name: 'StudentCourseFilesListPage',
      beforeEnter: findStudentPage(),
    },
    {
      path: '/targets',
      name: 'StudentTargetsListPage',
      beforeEnter: findStudentPage(),
    },
    {
      path: '/targets/create',
      name: 'StudentTargetCreatePage',
      beforeEnter: findStudentPage(),
    },
    {
      path: '/targets/:targetId',
      name: 'StudentTargetPage',
      beforeEnter: findStudentPage(),
    },
    {
      path: '/mentor-meetings/:mentorMeetingId/targets/create',
      name: 'StudentMentorMeetingTargetCreatePage',
      beforeEnter: findStudentPage(),
    },
    {
      path: '/mentor-meetings/:mentorMeetingId/targets/:id',
      name: 'StudentMentorMeetingTargetEditPage',
      beforeEnter: findStudentPage(),
    },
    {
      path: '/lesson-observations',
      name: 'StudentLessonObservationsListPage',
      beforeEnter: findStudentPage(),
    },
    {
      path: '/lesson-observations/create',
      name: 'StudentLessonObservationCreatePage',
      beforeEnter: findStudentPage(),
    },
    {
      path: '/lesson-observations/:id',
      name: 'StudentLessonObservationPage',
      beforeEnter: findStudentPage(),
    },
    {
      path: '/mentor-meetings',
      name: 'StudentMentorMeetingsListPage',
      beforeEnter: findStudentPage(),
    },
    {
      path: '/mentor-meetings/create',
      name: 'StudentMentorMeetingCreatePage',
      beforeEnter: findStudentPage(),
    },
    {
      path: '/mentor-meetings/:id',
      name: 'StudentMentorMeetingPage',
      beforeEnter: findStudentPage(),
    },
    {
      path: '/assignments',
      name: 'StudentAssignmentsListPage',
      component: StudentAssignmentsListPage,
    },
    {
      path: '/assignments/:id',
      name: 'StudentAssignmentLatestVersionPage',
      component: StudentAssignmentPage,
    },
    {
      path: '/assignments/:id/versions/:versionNumber',
      name: 'StudentAssignmentPage',
      component: StudentAssignmentPage,
    },
    {
      path: '/staff/:staffId',
      component: StaffWrapper,
      beforeEnter: mustBeStaff,
      meta: {
        setUserStaffFromStaffId: true,
        clearSelectedStudent: true,
        clearSelectedSchool: true,
        clearSelectedCohort: true,
      },
      children: [
        {
          path: 'home',
          name: 'TutorHomePage',
          component: TutorHomePage,
        },
        {
          path: 'announcements',
          name: 'StaffAnnouncementsPage',
          component: StaffAnnouncementsPage,
        },
        {
          path: 'announcements/:id',
          name: 'StaffAnnouncementPage',
          component: StaffAnnouncementPage,
        },
        {
          path: 'forums',
          name: 'StaffForumsListPage',
          component: ForumsListPage,
        },
        {
          path: 'forums/:id',
          name: 'StaffForumPage',
          component: ForumPage,
        },
        {
          path: 'forums/:id/members',
          name: 'StaffForumEditMembersPage',
          component: ForumEditMembersPage,
        },
        {
          path: 'forums/:forumId/discussions/create',
          name: 'StaffForumDiscussionCreatePage',
          component: ForumDiscussionEditPage,
        },
        {
          path: 'forums/:forumId/discussions/:id/edit',
          name: 'StaffForumDiscussionEditPage',
          component: ForumDiscussionEditPage,
        },
        {
          path: 'forums/:forumId/discussions/:id',
          name: 'StaffForumDiscussionViewPage',
          component: ForumDiscussionViewPage,
        },
      ],
    },
    {
      path: '/institutions/:institutionId',
      component: TutorInstitutionPage,
      meta: {
        notMobileFriendly: true,
        setUserStaffFromInstitutionId: true,
        clearSelectedCohort: true,
        clearSelectedStudent: true,
        clearSelectedSchool: true,
      },
      children: [
        {
          path: 'instructor-training',
          name: 'TutorStaffTrainingPage',
          component: TutorStaffTrainingPage,
          meta: { mobileFriendly: true },
        },
        {
          path: 'instructor-training/competency-themes/:themeId',
          name: 'MyStaffTrainingCompetencyThemePage',
          component: TutorStaffTrainingCompetencyThemePage,
          meta: { mobileFriendly: true },
        },
        {
          path: 'instructors/:staffId/competency-themes/:themeId',
          name: 'InstitutionTutorStaffTrainingCompetencyThemePage',
          component: TutorStaffTrainingCompetencyThemePage,
          meta: { mobileFriendly: true },
        },
        {
          path: 'instructors/:staffId/training-modules/:moduleId',
          name: 'InstitutionTutorStaffTrainingModulePage',
          component: TutorStaffTrainingModulePage,
          meta: { mobileFriendly: true },
        },
        {
          path: 'instructor-training/training-modules/:moduleId',
          name: 'MyStaffTrainingModulePage',
          component: TutorStaffTrainingModulePage,
          meta: { mobileFriendly: true },
        },
        {
          path: 'instructors/:staffId/training-record/create',
          name: 'InstitutionStaffTrainingRecordCreatePage',
          component: StaffTrainingRecordEditPage,
          meta: { mobileFriendly: true },
        },
        {
          path: 'instructors/:staffId/training-record/:recordId',
          name: 'InstitutionStaffTrainingRecordEditPage',
          component: StaffTrainingRecordEditPage,
          meta: { mobileFriendly: true },
        },
        {
          path: 'instructor-training/training-record/create',
          name: 'MyStaffTrainingRecordCreatePage',
          component: StaffTrainingRecordEditPage,
          meta: { mobileFriendly: true },
        },
        {
          path: 'instructor-training/training-record/:recordId',
          name: 'MyStaffTrainingRecordEditPage',
          component: StaffTrainingRecordEditPage,
          meta: { mobileFriendly: true },
        },
        {
          path: 'instructors/:staffId/events/:eventId',
          name: 'InstitutionStaffTrainingEventPage',
          component: StaffTrainingEventPage,
          meta: { mobileFriendly: true },
        },
        {
          path: 'instructor-training/events/:eventId',
          name: 'MyStaffTrainingEventPage',
          component: StaffTrainingEventPage,
          meta: { mobileFriendly: true },
        },
        {
          path: 'instructors/:staffId/certificate/create',
          name: 'InstitutionStaffTrainingCertificateCreatePage',
          component: StaffTrainingCertificateEditPage,
          meta: { mobileFriendly: true },
        },
        {
          path: 'instructors/:staffId/certificate/:certificateId',
          name: 'InstitutionStaffTrainingCertificateEditPage',
          component: StaffTrainingCertificateEditPage,
          meta: { mobileFriendly: true },
        },
        {
          path: 'instructor-training/certificate/create',
          name: 'MyStaffTrainingCertificateCreatePage',
          component: StaffTrainingCertificateEditPage,
          meta: { mobileFriendly: true },
        },
        {
          path: 'instructor-training/certificate/:certificateId',
          name: 'MyStaffTrainingCertificateEditPage',
          component: StaffTrainingCertificateEditPage,
          meta: { mobileFriendly: true },
        },
        {
          path: 'staff-files',
          name: 'TutorStaffFilesListPage',
          component: TutorStaffFilesListPage,
          meta: { mobileFriendly: true },
        },
        {
          path: 'course-files',
          name: 'TutorAdminStudentCourseFilesPage',
          component: TutorAdminStudentCourseFilesPage,
          meta: { mobileFriendly: true },
        },
        {
          path: 'admin-staff-files',
          name: 'TutorAdminStaffFilesPage',
          component: TutorAdminStaffFilesPage,
          meta: { mobileFriendly: true },
        },
        {
          path: 'target-template',
          name: 'TutorAdminTargetTemplatePage',
          component: TutorAdminTargetTemplatePage,
        },
        {
          path: 'reflection-templates',
          name: 'InstitutionReflectionTemplatesListPage',
          component: InstitutionReflectionTemplatesListPage,
        },
        {
          path: 'reflection-templates/create',
          name: 'InstitutionReflectionTemplateCreatePage',
          component: InstitutionReflectionTemplateCreatePage,
        },
        {
          path: 'reflection-templates/:templateId',
          name: 'InstitutionReflectionTemplateEditPage',
          component: InstitutionReflectionTemplateEditPage,
        },
        {
          path: 'course-reflection-template',
          name: 'TutorAdminCourseReflectionTemplatePage',
          component: TutorAdminCourseReflectionTemplatePage,
        },
        {
          path: 'folder-templates',
          name: 'TutorAdminFolderTemplatesListPage',
          component: TutorAdminFolderTemplatesListPage,
        },
        {
          path: 'folder-templates/:templateId',
          name: 'TutorAdminFolderTemplatePage',
          component: TutorAdminFolderTemplatePage,
        },
        {
          path: 'cohorts',
          name: 'TutorCohortListPage',
          component: TutorCohortListPage,
        },
        {
          path: 'cohorts/create',
          name: 'TutorAdminCohortCreatePage',
          component: TutorAdminCohortCreatePage,
        },
        {
          path: 'cohorts/:cohortId/copy',
          name: 'TutorAdminCohortCopyPage',
          component: TutorAdminCohortCreatePage,
        },
        {
          path: 'schools',
          name: 'TutorSchoolsListPage',
          component: TutorSchoolsListPage,
        },
        {
          path: 'instructors',
          name: 'InstitutionStaffListPage',
          component: InstitutionStaffListPage,
        },
        {
          path: 'instructors/:id',
          name: 'InstitutionTutorStaffPage',
          component: InstitutionTutorStaffPage,
        },
        {
          path: 'students',
          name: 'TutorAdminGlobalSearchPage',
          component: TutorAdminGlobalSearchPage,
        },
        {
          path: 'admin-instructors-training',
          name: 'InstitutionStaffTrainingPage',
          component: InstitutionStaffTrainingPage,
        },
        {
          path: 'admin-instructors-training/modules/:id',
          name: 'InstitutionStaffTrainingModulePage',
          component: InstitutionStaffTrainingModulePage,
        },
        {
          path: 'admin-instructors-training/modules/:moduleId/sections/:id',
          name: 'InstitutionStaffTrainingModuleSectionPage',
          component: InstitutionStaffTrainingModuleSectionPage,
        },
        {
          path: 'admin-instructors-training/frameworks/:id',
          name: 'InstitutionStaffTrainingFrameworkPage',
          component: InstitutionStaffTrainingFrameworkPage,
        },
        {
          path: 'admin-instructors-training/frameworks/:frameworkId/competency-themes/:id',
          name: 'InstitutionStaffTrainingCompetencyThemePage',
          component: InstitutionStaffTrainingCompetencyThemePage,
        },
        {
          path: 'admin-instructors-training/frameworks/:frameworkId/competency-themes/:themeId/competencies/:id',
          name: 'InstitutionStaffTrainingCompetencyPage',
          component: InstitutionStaffTrainingCompetencyPage,
        },
        {
          path: 'admin-instructors-training/certificates/create',
          name: 'InstitutionStaffTrainingAcceptedCertificateCreatePage',
          component: InstitutionStaffTrainingAcceptedCertificateEditPage,
        },
        {
          path: 'admin-instructors-training/certificates/:id',
          name: 'InstitutionStaffTrainingAcceptedCertificateEditPage',
          component: InstitutionStaffTrainingAcceptedCertificateEditPage,
        },
        {
          path: 'admin-instructors-training/events/create',
          name: 'InstitutionStaffTrainingEventCreatePage',
          component: InstitutionStaffTrainingEventEditPage,
        },
        {
          path: 'admin-instructors-training/events/:id',
          name: 'InstitutionStaffTrainingEventEditPage',
          component: InstitutionStaffTrainingEventEditPage,
        },
        {
          path: 'calendar',
          name: 'TutorAdminCalendarPage',
          component: TutorAdminCalendarPage,
        },
        {
          path: 'curriculum-setup',
          name: 'TutorAdminCurriculumPage',
          component: TutorAdminCurriculumPage,
        },
        {
          path: 'curriculum-setup/themes/:themeId',
          name: 'TutorAdminCurriculumThemePage',
          component: TutorAdminCurriculumThemePage,
        },
        {
          path: 'curriculum-setup/themes/:themeId/statements/:statementId',
          name: 'InstAdminCurriculumStatementPage',
          component: AdminCurriculumStatementPage,
        },
        {
          path: 'curriculum-setup/themes/:themeId/statements/:statementId/teaching-strategies/:strategyId',
          name: 'InstAdminCurriculumTeachingStrategyPage',
          component: AdminCurriculumTeachingStrategyPage,
        },
        {
          path: 'tutor/feature-config',
          name: 'TutorAdminFeatureConfigPage',
          component: TutorAdminFeatureConfigPage,
        },
        {
          path: 'tutor/permissions',
          name: 'TutorAdminPermissionsPage',
          component: TutorAdminPermissionsPage,
        },
        {
          path: 'professional-resources',
          name: 'InstitutionProfessionalResourcesConfigurationPage',
          component: InstitutionProfessionalResourcesConfigurationPage,
        },
        {
          path: 'judgement-sets',
          name: 'TutorAdminJudgementSetsPage',
          component: TutorAdminJudgementSetsPage,
        },
        {
          path: 'home-pages-settings',
          name: 'InstitutionHomePagesSettingsPage',
          component: InstitutionHomePagesSettingsPage,
        },
        {
          path: 'assignment-templates',
          name: 'TutorAdminAssignmentTemplatesListPage',
          component: TutorAdminAssignmentTemplatesListPage,
        },
        {
          path: 'assignment-templates/create',
          name: 'TutorAdminAssignmentTemplateCreatePage',
          component: TutorAdminAssignmentTemplateCreatePage,
        },
        {
          path: 'assignment-templates/:id',
          name: 'TutorAdminAssignmentTemplateEditPage',
          component: TutorAdminAssignmentTemplateEditPage,
        },
        {
          path: 'assignment-templates/:assignmentTemplateId/sections/:id',
          name: 'TutorAdminAssignmentTemplateSectionPage',
          component: TutorAdminAssignmentTemplateSectionPage,
        },
        {
          path: 'review-templates',
          name: 'TutorAdminReviewTemplatesListPage',
          component: TutorAdminReviewTemplatesListPage,
        },
        {
          path: 'review-templates/create',
          name: 'TutorAdminReviewTemplateCreatePage',
          component: TutorAdminReviewTemplateCreatePage,
        },
        {
          path: 'review-templates/copy',
          name: 'TutorAdminReviewTemplateCopyPage',
          component: TutorAdminReviewTemplateCopyPage,
        },
        {
          path: 'review-templates/:templateId',
          name: 'TutorAdminReviewTemplatePage',
          component: TutorAdminReviewTemplatePage,
        },
        {
          path: 'review-templates/:templateId/overall',
          name: 'TutorAdminReviewTemplateOverallPage',
          component: TutorAdminReviewTemplateOverallPage,
        },
        {
          path: 'review-templates/:templateId/per-standard',
          name: 'TutorAdminReviewTemplatePerStandardPage',
          component: TutorAdminReviewTemplatePerStandardPage,
        },
        {
          path: 'review-templates/:templateId/standards/:id',
          name: 'TutorAdminReviewTemplateStandardPage',
          component: TutorAdminReviewTemplateStandardPage,
        },
        {
          path: 'review-templates/:templateId/part-2',
          name: 'TutorAdminReviewTemplatePart2Page',
          component: TutorAdminReviewTemplatePart2Page,
        },
        {
          path: 'review-templates/:templateId/edit',
          name: 'TutorAdminReviewTemplateEditPage',
          component: TutorAdminReviewTemplateCreatePage,
        },
        {
          path: 'instructor-forums',
          name: 'InstitutionForumsListPage',
          component: AdminForumsListPage,
        },
        {
          path: 'instructor-forums/create',
          name: 'InstitutionForumCreatePage',
          component: AdminForumEditPage,
        },
        {
          path: 'instructor-forums/:id',
          name: 'InstitutionForumEditPage',
          component: AdminForumEditPage,
        },
        {
          path: 'go-react-templates',
          name: 'InstitutionGoReactTemplatesListPage',
          component: InstitutionGoReactTemplatesListPage,
        },
        {
          path: 'go-react-templates/create',
          name: 'InstitutionGoReactTemplateCreatePage',
          component: InstitutionGoReactTemplateEditPage,
        },
        {
          path: 'go-react-templates/:id',
          name: 'InstitutionGoReactTemplateEditPage',
          component: InstitutionGoReactTemplateEditPage,
        },
        {
          path: 'ects',
          name: 'TutorEctsOverviewPage',
          component: TutorEctsOverviewPage,
        },
        {
          path: 'pending-ects',
          name: 'TutorPendingEctsPage',
          component: TutorPendingEctsPage,
        },
        { path: 'completed-ects', name: 'TutorCompletedEctsPage', component: TutorCompletedEctsPage },
        {
          path: 'pending-ects/:ectId/approve-registration',
          name: 'TutorEctApproveRegistrationPage',
          component: TutorEctRegistrationPage,
          meta: { dontClearSelectedSchool: true },
        },
      ],
    },
    {
      path: '/cohorts/:cohortId',
      component: TutorCohortWrapper,
      meta: { notMobileFriendly: true, loadCohort: true, clearSelectedStudent: true, clearSelectedSchool: true },
      children: [
        {
          path: 'instructor-training',
          name: 'TutorCohortStaffTrainingPage',
          component: TutorCohortStaffTrainingPage,
        },
        {
          path: 'accounts',
          name: 'CohortStaffListPage',
          component: CohortStaffListPage,
        },
        {
          path: 'accounts/:id',
          name: 'CohortTutorStaffPage',
          component: CohortTutorStaffPage,
        },
        {
          path: 'cohort-instructors/:staffId/competency-themes/:themeId',
          name: 'CohortTutorStaffTrainingCompetencyThemePage',
          component: TutorStaffTrainingCompetencyThemePage,
          meta: { mobileFriendly: true },
        },
        {
          path: 'cohort-instructors/:staffId/events/:eventId',
          name: 'CohortStaffTrainingEventPage',
          component: StaffTrainingEventPage,
          meta: { mobileFriendly: true },
        },
        {
          path: 'cohort-instructors/:staffId/training-modules/:moduleId',
          name: 'CohortTutorCohortStaffTrainingModulePage',
          component: TutorStaffTrainingModulePage,
          meta: { mobileFriendly: true },
        },
        {
          path: 'cohort-instructors/:staffId/training-record/:recordId',
          name: 'CohortStaffTrainingRecordEditPage',
          component: StaffTrainingRecordEditPage,
          meta: { mobileFriendly: true },
        },
        {
          path: 'cohort-instructors/:staffId/training-record/create',
          name: 'CohortStaffTrainingRecordCreatePage',
          component: StaffTrainingRecordEditPage,
          meta: { mobileFriendly: true },
        },
        {
          path: 'cohort-instructors/:staffId/training-record/:recordId',
          name: 'CohortStaffTrainingRecordEditPage',
          component: StaffTrainingRecordEditPage,
          meta: { mobileFriendly: true },
        },
        {
          path: 'cohort-instructors/:staffId/certificate/:certificateId',
          name: 'CohortStaffTrainingCertificateEditPage',
          component: StaffTrainingCertificateEditPage,
          meta: { mobileFriendly: true },
        },
        {
          path: 'cohort-instructors/:staffId/training-modules/:moduleId',
          name: 'CohortTutorStaffTrainingModulePage',
          component: TutorStaffTrainingModulePage,
          meta: { mobileFriendly: true },
        },
        {
          path: 'instructors/:staffId/training-modules/:moduleId',
          name: 'TutorCohortStaffTrainingModulePage',
          component: TutorStaffTrainingModulePage,
          meta: { mobileFriendly: true },
        },
        {
          path: 'instructor-forums',
          name: 'CohortForumsListPage',
          component: AdminForumsListPage,
        },
        {
          path: 'instructor-forums/create',
          name: 'CohortForumCreatePage',
          component: AdminForumEditPage,
        },
        {
          path: 'instructor-forums/:id',
          name: 'CohortForumEditPage',
          component: AdminForumEditPage,
        },
        {
          path: 'course',
          name: 'TutorAdminCohortCoursePage',
          component: TutorAdminCohortCoursePage,
        },
        {
          path: 'course/terms/:termId',
          name: 'TutorAdminCohortCourseTermPage',
          component: TutorAdminCohortCourseTermPage,
        },
        {
          path: 'course/terms/:termId/weeks/:weekId',
          name: 'TutorAdminCohortCourseWeekPage',
          component: TutorAdminCohortCourseWeekPage,
        },
        {
          path: 'course/terms/:termId/weeks/:weekId/sections/:sectionId',
          name: 'TutorAdminCohortCourseWeekSectionPage',
          component: TutorAdminCohortCourseWeekSectionPage,
        },
        {
          path: 'course/week-template',
          name: 'TutorAdminCohortCourseWeekTemplatePage',
          component: TutorAdminCohortCourseWeekTemplatePage,
        },
        {
          path: 'course/week-template/sections/:sectionId',
          name: 'TutorAdminCohortCourseWeekTemplateSectionPage',
          component: TutorAdminCohortCourseWeekTemplateSectionPage,
        },
        {
          path: 'assignments',
          name: 'CohortAssignmentsListPage',
          component: CohortAssignmentsListPage,
        },
        {
          path: 'assignments/create',
          name: 'CohortAssignmentCreatePage',
          component: CohortAssignmentEditPage,
        },
        {
          path: 'assignments/edit/:id',
          name: 'CohortAssignmentEditPage',
          component: CohortAssignmentEditPage,
        },
        {
          path: 'assignments/:id',
          name: 'CohortAssignmentViewPage',
          component: CohortAssignmentViewPage,
        },
        {
          path: 'curriculum-setup',
          name: 'TutorAdminCohortCurriculumPage',
          component: TutorAdminCurriculumPage,
        },
        {
          path: 'curriculum-setup/themes/:themeId',
          name: 'TutorAdminCohortCurriculumThemePage',
          component: TutorAdminCurriculumThemePage,
        },
        {
          path: 'curriculum-setup/themes/:themeId/statements/:statementId',
          name: 'CohortAdminCurriculumStatementPage',
          component: AdminCurriculumStatementPage,
        },
        {
          path: 'curriculum-setup/themes/:themeId/statements/:statementId/teaching-strategies/:strategyId',
          name: 'CohortAdminCurriculumTeachingStrategyPage',
          component: AdminCurriculumTeachingStrategyPage,
        },
        {
          path: 'development-target-monitoring',
          name: 'CohortDevelopmentTargetsPage',
          component: CohortDevelopmentTargetsPage,
        },
        {
          path: 'development-reflection-monitoring',
          name: 'CohortDevelopmentReflectionsPage',
          component: CohortDevelopmentReflectionsPage,
        },
        {
          path: 'lesson-observation-monitoring',
          name: 'CohortDevelopmentLessonObservationsPage',
          component: CohortDevelopmentLessonObservationsPage,
        },
        {
          path: 'mentor-meetings',
          name: 'CohortMentorMeetingsPage',
          component: CohortMentorMeetingsPage,
        },
        {
          path: 'announcements',
          name: 'CohortAdminAnnouncementsPage',
          component: CohortAdminAnnouncementsPage,
        },
        {
          path: 'announcements/create',
          name: 'CohortAdminAnnouncementCreatePage',
          component: CohortAdminAnnouncementEditPage,
        },
        {
          path: 'announcements/:id',
          name: 'CohortAdminAnnouncementEditPage',
          component: CohortAdminAnnouncementEditPage,
        },
        {
          path: 'instructor-announcements',
          name: 'CohortAdminStaffAnnouncementsPage',
          component: CohortAdminStaffAnnouncementsPage,
        },
        {
          path: 'instructor-announcements/create',
          name: 'CohortAdminStaffAnnouncementCreatePage',
          component: CohortAdminStaffAnnouncementEditPage,
        },
        {
          path: 'instructor-announcements/:id',
          name: 'CohortAdminStaffAnnouncementEditPage',
          component: CohortAdminStaffAnnouncementEditPage,
        },
        {
          path: 'targets',
          name: 'CohortCourseTargetsPage',
          component: CohortCourseTargetsPage,
        },
        {
          path: 'targets/create',
          name: 'CohortCourseTargetCreatePage',
          component: CohortCourseTargetEditPage,
        },
        {
          path: 'targets/:id',
          name: 'CohortCourseTargetEditPage',
          component: CohortCourseTargetEditPage,
        },
        {
          path: 'reflections',
          name: 'CohortCourseReflectionsPage',
          component: CohortCourseReflectionsPage,
        },
        {
          path: 'reflections/create',
          name: 'CohortCourseReflectionCreatePage',
          component: CohortCourseReflectionEditPage,
        },
        {
          path: 'reflections/:id',
          name: 'CohortCourseReflectionEditPage',
          component: CohortCourseReflectionEditPage,
        },
        {
          path: 'reviews',
          name: 'CohortReviewsPage',
          component: CohortReviewsPage,
        },
        {
          path: 'reviews/create',
          name: 'TutorAdminReviewCreatePage',
          component: TutorAdminReviewEditPage,
        },
        {
          path: 'reviews/:reviewId',
          name: 'TutorAdminReviewViewPage',
          component: TutorAdminReviewViewPage,
        },
        {
          path: 'reviews/edit/:id',
          name: 'TutorAdminReviewEditPage',
          component: TutorAdminReviewEditPage,
        },
        {
          path: 'groups',
          name: 'TutorGroupsListPage',
          component: TutorGroupsListPage,
        },
        {
          path: 'groups/:id',
          name: 'TutorGroupPage',
          component: TutorGroupPage,
        },
        {
          path: 'students',
          name: 'TutorStudentListPage',
          component: TutorStudentListPage,
        },
        {
          path: 'students/bulk-create',
          name: 'CohortBulkUploadPage',
          component: CohortBulkUploadPage,
        },
        {
          path: 'settings',
          name: 'TutorAdminCohortEditPage',
          component: TutorAdminCohortEditPage,
        },
      ],
    },
    {
      path: '/schools/:schoolId',
      component: TutorSchoolWrapper,
      meta: { notMobileFriendly: true, loadSchool: true, clearSelectedStudent: true, clearSelectedCohort: true },
      children: [
        {
          path: '',
          name: 'TutorSchoolEditPage',
          component: TutorSchoolEditPage,
        },
        {
          path: 'students',
          name: 'TutorSchoolStudentsPage',
          component: TutorSchoolStudentsPage,
        },
        {
          path: 'staff',
          name: 'TutorSchoolStaffPage',
          component: TutorSchoolStaffPage,
        },
        {
          path: 'pending-ects/register-ect',
          name: 'TutorEctRegistrationPage',
          component: TutorEctRegistrationPage,
        },
        {
          path: 'pending-ects/:ectId/review-ect-registration',
          name: 'TutorEctReviewRegistrationPage',
          component: TutorEctRegistrationPage,
        },
      ],
    },
    {
      path: '/students/:studentId',
      component: TutorStudentWrapper,
      meta: { loadStudentData: true },
      children: [
        {
          path: '',
          name: 'TutorStudentDetailsPage',
          component: TutorStudentDetailsPage,
          alias: 'details',
        },
        {
          path: 'home',
          name: 'StudentHomePage',
          component: StudentHomePage,
        },
        {
          path: 'ect-progress',
          name: 'TutorEctProgressPage',
          component: TutorEctProgressPage,
        },
        {
          path: 'calendar',
          name: 'CalendarPage',
          component: CalendarPage,
        },
        // TODO: remove this when sidebar is updated (after all pages are updated)
        {
          path: 'calendar',
          name: 'TutorCalendarPage',
          component: CalendarPage,
        },
        {
          path: 'course',
          name: 'TutorCohortCoursePage',
          component: TutorCohortCoursePage,
        },
        {
          path: 'course/weeks/:studentWeekId',
          name: 'TutorCohortCourseWeekPage',
          component: TutorCohortCourseWeekPage,
          meta: {
            switchStudentPath: newStudentId => ({
              name: 'TutorCohortCoursePage',
              params: { studentId: newStudentId },
            }),
          },
        },
        {
          path: 'proficiencies',
          name: 'TutorProficienciesPage',
          component: TutorStudentProficienciesPage,
        },
        {
          path: 'curriculum',
          name: 'TutorCurriculumPage',
          component: TutorCurriculumPage,
        },
        {
          path: 'curriculum/:id',
          name: 'TutorCurriculumStatementPage',
          component: TutorCurriculumStatementPage,
        },
        {
          path: 'curriculum/statements/teaching-strategies/:id',
          name: 'TutorCurriculumTeachingStrategyPage',
          component: TutorCurriculumTeachingStrategyPage,
        },
        {
          path: 'portfolio',
          name: 'TutorPortfolioOverviewPage',
          component: TutorPortfolioOverviewPage,
        },
        {
          path: 'standards/:standardId',
          name: 'TutorPortfolioStandardPage',
          component: TutorPortfolioStandardPage,
        },
        {
          path: 'evidence',
          name: 'TutorEvidenceListPage',
          component: TutorEvidenceListPage,
        },
        {
          path: 'files',
          name: 'FilesListPage',
          component: FilesListPage,
        },
        // TODO: remove this when sidebar is updated (after all pages are updated)
        {
          path: 'files',
          name: 'TutorStudentFilesListPage',
          component: FilesListPage,
        },
        {
          path: 'course-files',
          name: 'CourseFilesListPage',
          component: CourseFilesListPage,
        },
        // TODO: remove this when sidebar is updated (after all pages are updated)
        {
          path: 'course-files',
          name: 'TutorCourseFilesListPage',
          component: CourseFilesListPage,
        },
        {
          path: 'reflections',
          name: 'ReflectionsListPage',
          component: ReflectionsListPage,
        },
        // TODO: remove this when sidebar is updated (after all pages are updated)
        {
          path: 'reflections',
          name: 'TutorReflectionsListPage',
          component: ReflectionsListPage,
        },
        {
          path: 'reflections/create',
          name: 'ReflectionCreatePage',
          component: ReflectionPage,
          meta: {
            switchStudentPath: newStudentId => ({
              name: 'ReflectionsListPage',
              params: { studentId: newStudentId },
            }),
          },
        },
        {
          path: 'reflections/:reflectionId',
          name: 'ReflectionPage',
          component: ReflectionPage,
          meta: {
            switchStudentPath: newStudentId => ({
              name: 'ReflectionsListPage',
              params: { studentId: newStudentId },
            }),
          },
        },
        {
          path: 'targets',
          name: 'TargetsListPage',
          component: TargetsListPage,
        },
        // TODO: remove this when sidebar is updated (after all pages are updated)
        {
          path: 'targets',
          name: 'TutorTargetsListPage',
          component: TargetsListPage,
        },
        {
          path: 'targets/create',
          name: 'TargetCreatePage',
          component: TargetPage,
          meta: {
            switchStudentPath: newStudentId => ({
              name: 'TargetsListPage',
              params: { studentId: newStudentId },
            }),
          },
        },
        {
          path: 'targets/:targetId',
          name: 'TargetPage',
          component: TargetPage,
          meta: {
            switchStudentPath: newStudentId => ({
              name: 'TargetsListPage',
              params: { studentId: newStudentId },
            }),
          },
        },
        {
          path: 'mentor-meetings/:mentorMeetingId/targets/create',
          name: 'MentorMeetingTargetCreatePage',
          component: TargetPage,
          meta: {
            switchStudentPath: newStudentId => ({
              name: 'MentorMeetingsListPage',
              params: { studentId: newStudentId },
            }),
          },
        },
        {
          path: 'mentor-meetings/:mentorMeetingId/targets/:targetId',
          name: 'MentorMeetingTargetPage',
          component: TargetPage,
          meta: {
            switchStudentPath: newStudentId => ({
              name: 'MentorMeetingsListPage',
              params: { studentId: newStudentId },
            }),
          },
        },
        {
          path: 'lesson-observations',
          name: 'LessonObservationsListPage',
          component: LessonObservationsListPage,
        },
        // TODO: remove this when sidebar is updated (after all pages are updated)
        {
          path: 'lesson-observations',
          name: 'TutorLessonObservationsListPage',
          component: LessonObservationsListPage,
        },
        {
          path: 'lesson-observations/create',
          name: 'LessonObservationCreatePage',
          component: LessonObservationPage,
          meta: {
            switchStudentPath: newStudentId => ({
              name: 'LessonObservationsListPage',
              params: { studentId: newStudentId },
            }),
          },
        },
        {
          path: 'lesson-observations/:id',
          name: 'LessonObservationPage',
          component: LessonObservationPage,
          meta: {
            switchStudentPath: newStudentId => ({
              name: 'LessonObservationsListPage',
              params: { studentId: newStudentId },
            }),
          },
        },
        {
          path: 'mentor-meetings',
          name: 'MentorMeetingsListPage',
          component: MentorMeetingsListPage,
        },
        // TODO: remove this when sidebar is updated (after all pages are updated)
        {
          path: 'mentor-meetings',
          name: 'TutorMentorMeetingsListPage',
          component: MentorMeetingsListPage,
        },
        {
          path: 'mentor-meetings/create',
          name: 'MentorMeetingCreatePage',
          component: MentorMeetingPage,
          meta: {
            switchStudentPath: newStudentId => ({
              name: 'MentorMeetingsListPage',
              params: { studentId: newStudentId },
            }),
          },
        },
        {
          path: 'mentor-meetings/:id',
          name: 'MentorMeetingPage',
          component: MentorMeetingPage,
          meta: {
            switchStudentPath: newStudentId => ({
              name: 'MentorMeetingsListPage',
              params: { studentId: newStudentId },
            }),
          },
        },
        {
          path: 'reviews',
          name: 'TutorReviewsListPage',
          component: TutorReviewsListPage,
        },
        {
          path: 'reviews/:id',
          name: 'TutorReviewPage',
          component: TutorReviewPage,
          meta: {
            switchStudentPath: newStudentId => ({ name: 'TutorReviewsListPage', params: { studentId: newStudentId } }),
          },
        },
        {
          path: 'reviews/:id/overall',
          name: 'TutorReviewOverallPage',
          component: TutorReviewOverallPage,
          meta: {
            switchStudentPath: newStudentId => ({ name: 'TutorReviewsListPage', params: { studentId: newStudentId } }),
          },
        },
        {
          path: 'reviews/:id/standards/:standardId',
          name: 'TutorReviewStandardPage',
          component: TutorReviewStandardPage,
          meta: {
            switchStudentPath: newStudentId => ({ name: 'TutorReviewsListPage', params: { studentId: newStudentId } }),
          },
        },
        {
          path: 'reviews/:id/part2',
          name: 'TutorReviewPart2Page',
          component: TutorReviewPart2Page,
          meta: {
            switchStudentPath: newStudentId => ({ name: 'TutorReviewsListPage', params: { studentId: newStudentId } }),
          },
        },
        {
          path: 'assignments',
          name: 'TutorAssignmentsListPage',
          component: TutorAssignmentsListPage,
        },
        {
          path: 'assignments/:id',
          name: 'TutorAssignmentLatestVersionPage',
          component: TutorAssignmentPage,
        },
        {
          path: 'assignments/:id/versions/:versionNumber',
          name: 'TutorAssignmentPage',
          component: TutorAssignmentPage,
          meta: {
            switchStudentPath: (newStudentId, from) => {
              return {
                name: 'TutorAssignmentLatestVersionPage',
                params: { studentId: newStudentId, id: from.params.id },
              };
            },
          },
        },
      ],
    },
    {
      path: '/curriculum',
      name: 'StudentCurriculumPage',
      component: StudentCurriculumPage,
    },
    {
      path: '/curriculum/:id',
      name: 'StudentCurriculumStatementPage',
      component: StudentCurriculumStatementPage,
    },
    {
      path: '/curriculum/statements/teaching-strategies/:id',
      name: 'StudentCurriculumTeachingStrategyPage',
      component: StudentCurriculumTeachingStrategyPage,
    },
    {
      path: '/portfolio',
      name: 'StudentPortfolioOverviewPage',
      component: StudentPortfolioOverviewPage,
    },
    {
      path: '/portfolio/:standardId',
      name: 'StudentPortfolioStandardPage',
      component: StudentPortfolioStandardPage,
    },
    {
      path: '/admin',
      beforeEnter: mustBeAdmin,
      children: [
        {
          path: '',
          name: 'AdminPage',
          component: AdminPage,
          meta: { notMobileFriendly: true, loadAdminData: true },
        },
        {
          path: 'users',
          name: 'AdminUsersPage',
          component: AdminUsersPage,
          meta: { notMobileFriendly: true },
        },
        {
          path: 'config',
          name: 'AdminFeatureSwitchesPage',
          component: AdminFeatureSwitchesPage,
          meta: { notMobileFriendly: true },
        },
        {
          path: 'permissions',
          name: 'AdminPermissionsPage',
          component: AdminPermissionsPage,
          meta: { notMobileFriendly: true },
        },
        {
          path: 'users/:userId',
          name: 'AdminUserPage',
          component: AdminUserPage,
          meta: { notMobileFriendly: true },
        },
        {
          path: 'curriculum-templates',
          name: 'AdminCurriculumTemplatesPage',
          component: AdminCurriculumTemplatesPage,
          meta: { notMobileFriendly: true },
        },
        {
          path: 'curriculum-templates/:templateId',
          name: 'AdminCurriculumThemeTemplatesPage',
          component: AdminCurriculumThemeTemplatesPage,
          meta: { notMobileFriendly: true },
        },
        {
          path: 'curriculum-theme-templates/:themeId',
          name: 'AdminCurriculumStatementTemplatesPage',
          component: AdminCurriculumStatementTemplatesPage,
          meta: { notMobileFriendly: true },
        },
        {
          path: 'standard-sets',
          name: 'AdminStandardSetsPage',
          component: AdminStandardSetsPage,
          meta: { notMobileFriendly: true },
        },
        {
          path: 'standard-sets/:standardSetId',
          name: 'AdminStandardSetStandardsPage',
          component: AdminStandardSetStandardsPage,
          meta: { notMobileFriendly: true },
        },
        {
          path: 'standards/:standardId',
          name: 'AdminStandardSetSubstandardsPage',
          component: AdminStandardSetSubstandardsPage,
          meta: { notMobileFriendly: true },
        },
        {
          path: 'subjects',
          name: 'AdminSubjectsPage',
          component: AdminSubjectsPage,
          meta: { notMobileFriendly: true },
        },
        {
          path: 'judgement-sets',
          name: 'AdminJudgementSetsPage',
          component: AdminJudgementSetsPage,
          meta: { notMobileFriendly: true },
        },
        {
          path: 'professional-resources',
          name: 'AdminProfessionalResourcesPage',
          component: AdminProfessionalResourcesPage,
          meta: { notMobileFriendly: true },
        },
        {
          path: 'certificate-types',
          name: 'AdminCertificateTypesPage',
          component: AdminCertificateTypesPage,
          meta: { notMobileFriendly: true },
        },
        {
          path: 'institutions/:institutionId',
          name: 'AdminInstitutionPage',
          component: AdminInstitutionPage,
          meta: { notMobileFriendly: true, loadAdminData: true },
        },
        {
          path: 'institutions/:institutionId/config',
          name: 'AdminInstitutionConfigPage',
          component: AdminInstitutionConfigPage,
          meta: { notMobileFriendly: true, loadAdminData: true },
        },
        {
          path: 'institutions/:institutionId/credits',
          name: 'AdminInstitutionCreditsPage',
          component: AdminInstitutionCreditsPage,
          meta: { notMobileFriendly: true, loadAdminData: true },
        },
        {
          path: 'institutions/:institutionId/staff',
          name: 'AdminInstitutionStaffPage',
          component: AdminInstitutionStaffPage,
          meta: { notMobileFriendly: true, loadAdminData: true },
        },
        {
          path: 'institutions/:institutionId/cohorts/:cohortId',
          name: 'AdminInstitutionCohortPage',
          component: AdminInstitutionCohortPage,
          meta: { notMobileFriendly: true, loadAdminData: true },
        },
      ],
    },
    {
      path: '/user-setup',
      name: 'UserSetupPage',
      component: UserSetupPage,
      meta: { noLayoutContainerNavigation: true },
    },
    {
      path: '/change-password',
      name: 'ForceChangePasswordPage',
      component: ForceChangePasswordPage,
      meta: { noLayoutContainerNavigation: true },
    },
    {
      path: '/change-password',
      name: 'UserInsecurePasswordPage',
      component: ForceChangePasswordPage,
      meta: { noLayoutContainerNavigation: true },
    },
    {
      path: '/verify-email',
      name: 'VerifyEmailPage',
      component: VerifyEmailPage,
      meta: { noLayoutContainerNavigation: true },
    },
    {
      path: '/verify-email-clicked',
      name: 'VerifyEmailClickedPage',
      component: VerifyEmailClickedPage,
      meta: { noAuth: true, noLayoutContainerNavigation: true },
    },
    {
      path: '/student-error',
      name: 'LoadStudentErrorPage',
      component: LoadScopeErrorPage,
    },
    {
      path: '/cohort-error',
      name: 'LoadCohortErrorPage',
      component: LoadScopeErrorPage,
    },
    {
      path: '/wrong-user-type-error',
      name: 'WrongUserTypeErrorPage',
      component: DoNotHaveAccessErrorPage,
    },
    {
      path: '/do-not-have-access-error',
      name: 'DoNotHaveAccessErrorPage',
      component: DoNotHaveAccessErrorPage,
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
      beforeEnter: findHomePage,
    },
    {
      path: '/:pathMatch(.*)',
      name: 'BadNotFound',
      beforeEnter: findHomePage,
    },
  ],
});

router.beforeEach(async (to, from) => {
  if (to.path === from.path) return;
  setMobileFriendly(to, from);

  const next = await authorise(to);
  if (next) return next;

  const next2 = checkIfStudentOrTutorOnlyPage(to);
  if (next2) return next2;
});

router.beforeResolve(async (to, from) => {
  // There may be a better way to solve this, but in vue router 4 navigating between the same page causes a navigation,
  // which I don't think it did in vue router 3
  // Therefore, don't do anything if the path stays the same (but the query changes)
  if (to.path === from.path) return;

  // startNavigating is called in beforeResolve (as opposed to beforeEach) as then beforeRouteUpate will have already been called
  // This allows unsaved changes to fire when navigating to the same page with different params.
  // This means all data loading should be done using meta properties (as below) and not via the individual router beforeEnter
  startNavigating();

  if (to.matched.some(x => x.meta.loadStudentData) && to.params.studentId != from.params.studentId) {
    return await loadStudentData(to, from);
  } else if (
    to.matched.some(x => x.meta.setUserStaffFromInstitutionId) &&
    to.params.institutionId != from.params.institutionId
  ) {
    return await selectUserStaffFromInstitutionId(to.params.institutionId, to);
  } else if (to.matched.some(x => x.meta.setUserStaffFromStaffId) && to.params.staffId != from.params.staffId) {
    return await selectUserStaffFromStaffId(to.params.staffId, to);
  } else if (to.matched.some(x => x.meta.loadCohort) && to.params.cohortId != from.params.cohortId) {
    return await loadCohort(to.params.cohortId, to);
  } else if (to.matched.some(x => x.meta.loadSchool) && to.params.schoolId != from.params.schoolId) {
    return await loadSchool(to.params.schoolId);
  } else if (to.matched.some(x => x.meta.loadAdminData)) {
    return await loadAdminData(to);
  }
});

router.afterEach((to, from, failure) => {
  if (failure || to.path === from.path) return;

  // These can only be cleared after the navigation has been cleared otherwise it can causes issues with
  // the page that's being left trying to use something that's been cleared (or it has to have defensive code)
  if (to.matched.some(x => x.meta.clearSelectedCohort)) {
    clearSelectedCohort();
  }
  if (to.matched.some(x => x.meta.clearSelectedStudent)) {
    clearSelectedStudent();
  }
  if (to.matched.some(x => x.meta.clearSelectedSchool) && !to.matched.some(x => x.meta.dontClearSelectedSchool)) {
    clearSelectedSchool();
  }
  if (to.matched.some(x => x.meta.clearSelectedInstitutionIfInMultiple)) {
    clearSelectedInstitutionIfInMultiple();
  }

  stopNavigating();
});

export default router;
