<template>
  <mosaic-loading-and-error-cards
    :object-type="objectTypePluralised"
    :load="load"
    :trigger-background-load="triggerBackgroundLoad"
    @update:trigger-background-load="emit('update:triggerBackgroundLoad', $event)"
  >
    <mosaic-list-filters-card :title="titleInternal">
      <template #switches>
        <slot name="switches"></slot>
      </template>

      <template #actions v-if="!hideAdd">
        <v-btn ripple @click="emit('add')">
          <span class="d-flex align-center">
            <v-icon class="mr-2">mdi-plus</v-icon>
            <span>{{ props.shortObjectType || props.objectType }}</span>
          </span>
        </v-btn>
      </template>

      <template #alert v-if="!isSlotEmpty($slots.alert)">
        <slot name="alert"></slot>
      </template>
      <slot name="filters"></slot>
    </mosaic-list-filters-card>

    <mosaic-card>
      <slot name="list-snackbar"></slot>
      <mosaic-list
        :items="paginatedList"
        :empty-text="`There are no ${objectTypePluralised}${
          items.length !== (filteredItems || items).length ? ' for these filters' : ''
        }.${!props.hideAdd ? ' Add one by clicking the button above.' : ''}`"
      >
        <template #item="{ item, first, last, index }">
          <slot name="list-item" v-bind="{ item, first, last, index }"></slot>
        </template>
      </mosaic-list>
    </mosaic-card>
    <mosaic-pagination v-model="currentPage" v-model:page-size="pageSize" :total="paginationTotal" />
  </mosaic-loading-and-error-cards>
</template>

<script setup lang="ts" generic="T">
import { computed, toRef } from 'vue';
import { paginateList } from '@/components/library/pagination/pagination';
import { pluralise } from '@/utils/text';
import { isSlotEmpty } from '@/utils/mosaic-slots';

/*
The MosaicFilterListPage is for lists that are potentially quite long and require filtering and pagination.
Because the list can be long, the creation button should be at the top of the page alongside the filters.
This also makes sense for objects that have a separation creation page because they're not simple (i.e. more than name and one or two more fields) to create.
Shorter lists, with explicit ordering should use the LegacyMosaicInlineCreationListPage or the MosaicDialogCreationListPage (for more complicated creation).
*/

const props = defineProps<{
  objectType: string;
  shortObjectType?: string;
  load: () => Promise<void>;
  items: T[];
  filteredItems?: T[];
  hideAdd?: boolean;
  title?: string;
  triggerBackgroundLoad?: boolean;
}>();

const emit = defineEmits(['add', 'update:triggerBackgroundLoad']);

const objectTypePluralised = computed(() => pluralise(props.objectType));
const titleInternal = computed(() => props.title || objectTypePluralised.value);

// Can be written as toRef(() => props.items) in Vue 3.3+
const itemsRef = toRef(() => props.filteredItems || props.items);
const { paginatedList, currentPage, pageSize, paginationTotal } = paginateList(itemsRef);
</script>
