import { getCurrentInstance } from 'vue';

export function useWebSockets() {
  const instance = getCurrentInstance();
  if (!instance) {
    throw 'You must use this function within the "setup()" method';
  }

  return instance.proxy!.$ws;
}
