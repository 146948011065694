<template>
  <div class="overlay"></div>
  <div ref="card" :class="['card', { draggable: isPointerEventSupported }]" @pointerdown="startDrag">
    <div class="flex-row">
      <div class="flex-row">
        <mosaic-icon v-if="isPointerEventSupported" class="mr-2" icon="mdi-drag" />
        <mosaic-card-title v-if="title"> {{ title }}</mosaic-card-title>
      </div>
      <mosaic-close-icon-btn @click="closeCard" />
    </div>
    <div>
      <slot> </slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, onUnmounted, onBeforeUnmount } from 'vue';

const props = defineProps<{
  x: number;
  y: number;
  title: string | null;
  closeCard: () => void;
}>();

//distance from the clicked element
const marginY = 55;
const marginX = 150;
const card = ref<HTMLElement | null>(null);
const position = ref({ top: marginY, left: marginX });
const isDragging = ref(false);
const offset = ref({ x: 0, y: 0 });
const referenceWindowWidth = 992;

const isPointerEventSupported = window.PointerEvent !== undefined;

// Helpers
const cardSizes = () => {
  const cardRect = card.value?.getBoundingClientRect() || {
    bottom: 0,
    right: 0,
  };
  return {
    cardRight: cardRect.right,
    cardBottom: cardRect.bottom,
    cardWidth: card.value?.offsetWidth || 0,
    cardHeight: card.value?.offsetHeight || 0,
  };
};

const updateCardLocation = (left: number, top: number) => {
  if (card.value) {
    card.value.style.left = `${left}px`;
    card.value.style.top = `${top}px`;
  }
};

// Drag
const startDrag = (event: PointerEvent) => {
  // If pointer events are not supported by the client's browser, this component won't be draggable
  if (!isPointerEventSupported || isNaN(event?.clientX) || isNaN(event?.clientY)) {
    console.warn('Pointer events are not supported on this browser, or invalid event data was detected.');
    return;
  }

  if (window.innerWidth <= referenceWindowWidth) return;
  isDragging.value = true;

  //coordinates in the viewport
  const clientX = event.clientX;
  const clientY = event.clientY;

  //calculate the initial offset between the mouse/touch point and the top-left corner
  //of the draggable card
  offset.value.x = clientX - position.value.left;
  offset.value.y = clientY - position.value.top;

  document.addEventListener('pointermove', onDrag);
  document.addEventListener('pointerup', stopDrag);
};

const onDrag = (event: PointerEvent) => {
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  if (!isDragging.value || windowWidth <= referenceWindowWidth) return;

  const clientX = event.clientX;
  const clientY = event.clientY;

  // Ensure the card does not go out of window bounds
  const { cardWidth, cardHeight } = cardSizes();

  position.value.left = Math.max(0, Math.min(clientX - offset.value.x, windowWidth - cardWidth));
  position.value.top = Math.max(0, Math.min(clientY - offset.value.y, windowHeight - cardHeight));
  updateCardLocation(position.value.left, position.value.top);
};

const stopDrag = () => {
  if (window.innerWidth <= referenceWindowWidth) return;
  isDragging.value = false;

  document.removeEventListener('pointermove', onDrag);
  document.removeEventListener('pointerup', stopDrag);
};

const onResize = () => {
  if (card.value && window.innerWidth > referenceWindowWidth - 2) {
    //if after resizing from a smaller to a bigger screen -> last known position
    const { cardWidth } = cardSizes();
    const hasSpaceToRight = window.innerWidth > cardWidth + position.value.left;
    const left = hasSpaceToRight ? position.value.left : window.innerWidth - cardWidth;
    updateCardLocation(left, position.value.top);
  }
};

// Function to handle the resize event
const handleCardSizeChange = (entries: ResizeObserverEntry[]) => {
  entries.forEach(() => {
    if (window.innerWidth <= referenceWindowWidth || !card.value) return;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const { cardRight, cardBottom, cardWidth, cardHeight } = cardSizes();

    if (cardRight > windowWidth) {
      position.value.left = Math.max(0, windowWidth - cardWidth);
      card.value.style.left = `${position.value.left}px`;
    }

    if (cardBottom > windowHeight) {
      position.value.top = Math.max(0, windowHeight - cardHeight);
      card.value.style.top = `${position.value.top}px`;
    }
    return;
  });
};

// Create a ResizeObserver instance to monitor changes in the card's size
let cardObserver: ResizeObserver;

onMounted(() => {
  if (card.value) {
    cardObserver = new ResizeObserver(handleCardSizeChange);
    cardObserver.observe(card.value);
  }

  const windowWidth = window.innerWidth;

  if (card.value && windowWidth >= referenceWindowWidth) {
    const hasSpaceToRight = windowWidth > card.value?.offsetWidth + props.x + marginX;
    const left = hasSpaceToRight ? props?.x + marginX : windowWidth - marginX - card.value?.offsetWidth;
    position.value.left = left;
    updateCardLocation(position.value.left, position.value.top);
  }

  window.addEventListener('resize', onResize);
});

onBeforeUnmount(() => {
  if (cardObserver) cardObserver.disconnect();
});

onUnmounted(() => {
  document.removeEventListener('pointermove', onDrag);
  document.removeEventListener('pointerup', stopDrag);
  window.removeEventListener('resize', onResize);
});
</script>

<style scoped>
/* overlay - avoiding click events outside (only for small devices) */
.overlay {
  position: fixed;
  top: 64px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: all;
  display: none;
}

.card {
  z-index: 2;
  padding: 10px;
  position: fixed;
  user-select: none;
  touch-action: none;
  box-sizing: border-box;
  width: 800px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  background-color: white;
  color: black;
  padding: 20px;
  overflow: auto;
}

.draggable {
  cursor: grab;
}

.flex-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 992px) {
  .card {
    max-width: 90vw;
    max-height: 90vh;
    width: 90vw !important;
    min-width: initial;
    position: fixed;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
  }

  .overlay {
    display: block;
  }
}
</style>
