<template>
  <div>
    <div>You are deleting {{ pluralise(actionNoun) }} for {{ finalStatement }}.</div>
    <mosaic-expandable-user-list
      :lists="lists"
      :title-for-exported-list="`${actionNoun} status per ${studentOrStaffNoun}`"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { pluralise } from '@/utils/text';

const props = defineProps<{
  actionNoun: string;
  actionTargetTitle: string;
  statusItems: { title: string; value: string; showDetails: boolean }[];
  studentOrStaffIdsToBeRemoved: number[];
  getActionStatus: (studentOrStaffId: number) => string | undefined;
  getStudentOrStaffDetails: (studentOrStaffId: number) =>
    | {
        id: number;
        userId: number;
        email: string;
        displayName: string;
        profilePictureUpdatedAt: string;
        hideProfilePicture: boolean;
      }
    | undefined;
  studentOrStaffNoun: string;
  studentOrStaffNounPluralised: string;
}>();

const lists = computed(() =>
  props.statusItems.map(status => {
    const items = props.studentOrStaffIdsToBeRemoved.flatMap(id => {
      if (props.getActionStatus(id) === status.value) {
        const details = props.getStudentOrStaffDetails(id);
        return details
          ? [
              {
                avatar: {
                  user: {
                    id: details.userId,
                    displayName: details.displayName,
                    email: details.email,
                    profilePictureUpdatedAt: details.profilePictureUpdatedAt,
                  },
                  hideProfilePicture: details.hideProfilePicture,
                },
              },
            ]
          : [];
      }
      return [];
    });
    const countInStatus = items.length;
    return {
      id: status.value,
      showExpand: status.showDetails,
      title: {
        text: `${status.title}: ${countInStatus} ${
          countInStatus === 1 ? props.studentOrStaffNoun : props.studentOrStaffNounPluralised
        }`,
      },
      exportConfig: {
        title: `${props.actionNoun} - ${status.title}`,
        subtitle: `${pluralise(props.studentOrStaffNoun)} with ${props.actionNoun} "${
          props.actionTargetTitle
        }" marked as ${status.title.toLowerCase()}`,
      },
      items,
    };
  })
);

const finalStatement = computed(() => {
  const count = props.studentOrStaffIdsToBeRemoved.length;
  return `${count} ${count > 1 ? props.studentOrStaffNounPluralised : props.studentOrStaffNoun}`;
});
</script>
