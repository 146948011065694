<template>
  <mosaic-tab-card-page
    object-type="Forum Members"
    :object-type-is-plural="true"
    :load="load"
    :headers="tabs"
    :title="`Edit Members of ${forum?.name}`"
  >
    <template #participants-tab-item>
      <div v-if="forum">
        <institution-staff-assignment
          v-if="!forum.cohort"
          v-model:selected-staff-ids="selectedParticipantIds"
          :initially-selected-staff-ids="forumParticipantIds"
          :disabled-staff="disabledParticipants"
          :is-editing="true"
          object-type="Forum"
          pending-assignment-label="Pending Membership"
          pending-assignment-tooltip="The Instructor will be added to the Forum"
          hide-title
        >
          <template #status-badge="{ staff: s }">
            <forum-member-chip v-if="selectedParticipantIds.includes(s.id)" />
          </template>
        </institution-staff-assignment>

        <cohort-staff-assignment
          v-else
          :cohort-id="forum.cohort.id"
          v-model:selected-staff-ids="selectedParticipantIds"
          :initially-selected-staff-ids="forumParticipantIds"
          :disabled-staff="disabledParticipants"
          :extra-staff="extraStaff"
          :filter="s => !onlyNotInCohort || s.staffRoles.length === 0"
          :is-editing="true"
          object-type="Forum"
          pending-assignment-label="Pending Membership"
          pending-assignment-tooltip="The Instructor will be added to the Forum"
          hide-title
        >
          <template #filters>
            <mosaic-checkbox
              label="Only show Instructors not linked to the Cohort?"
              v-model="onlyNotInCohort"
              no-icon
              hide-details
            />
          </template>

          <!-- extraStaff are supplied with no staffRoles, but all staff in the cohort will have staffRoles -->
          <template #details="{ staff: s }">
            <forum-member-not-in-cohort-warning-icon v-if="s.staffRoles.length === 0" />
          </template>

          <template #status-badge="{ staff: s }">
            <forum-member-chip v-if="forumParticipantIds.includes(s.id)" />
          </template>
        </cohort-staff-assignment>

        <mosaic-save-buttons
          class="mt-2"
          object-type="Forum Members"
          :object-type-is-plural="true"
          :return-to="breadcrumbs[1].to!"
          :can-save="participantsDirty"
          :save="saveParticipants"
        />
      </div>
    </template>

    <template #facilitators-moderators-tab-item>
      <mosaic-info-alert class="mb-3">
        Facilitators are able to create new Discussions for Participants to discuss in. Moderators are able to create
        new Discussions, edit and delete all Discussions and delete any Comments within a Discussion.
      </mosaic-info-alert>
      <div v-if="hasInstitutionLevelStaffForumsEdit && forum">
        <institution-staff-assignment
          v-model:selected-staff-ids="selectedFacilitatorAndModeratorIds"
          :disabled-staff="disabledFacilitatorsAndModerators"
          :initially-selected-staff-ids="forumFacilitatorAndModeratorIds"
          :is-editing="true"
          object-type="Forum"
          pending-assignment-label="Pending Membership"
          pending-assignment-tooltip="The Instructor will be added to the Forum"
          hide-title
        >
          <template #details="{ staff: s }">
            <div class="mr-8" @click.stop>
              <mosaic-select
                v-if="selectedFacilitatorAndModeratorIds.includes(s.id)"
                name="member-type"
                dense
                hide-details
                :items="memberTypeItems.filter(i => i.value != 'participant')"
                :model-value="memberTypes[s.id] || 'facilitator'"
                @update:model-value="updateMemberType(s, $event)"
                :prepend-icon="icons.role"
              />
            </div>
          </template>

          <template #status-badge="{ staff: s }">
            <forum-member-chip v-if="forumFacilitatorAndModeratorIds.includes(s.id)" />
          </template>
        </institution-staff-assignment>

        <mosaic-save-buttons
          class="mt-2"
          object-type="Forum Members"
          :object-type-is-plural="true"
          :return-to="breadcrumbs[1].to!"
          :can-save="facilitatorsAndModeratorsDirty"
          :save="saveFacilitatorsAndModerators"
        />
      </div>
      <div v-else class="pt-2">
        Only Instructors who are {{ listItems(rolesWithInstitutionStaffForumsEdit.map(r => pluralise(r.name))) }} can
        update Facilitators and Moderators.
      </div>
    </template>
  </mosaic-tab-card-page>

  <unsaved-changes-dialog
    v-model:unsaved-changes-dialog="dialog"
    object-type="Forum Members"
    :object-type-is-plural="true"
  />
</template>

<script setup lang="ts">
import { parseRouteId } from '@/composables/vue-router';
import { useStaffStore } from '@/stores/staff';
import { computed, ref } from 'vue';
import { type ForumResponse, memberTypeItems } from './forums';
import { useApi } from '@/composables/api';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { icons } from '@/utils/icons';
import InstitutionStaffAssignment from '@/components/assignment/InstitutionStaffAssignment.vue';
import CohortStaffAssignment from '@/components/assignment/CohortStaffAssignment.vue';
import { useUnsavedChanges } from '@/composables/unsaved-changes';
import UnsavedChangesDialog from '@/components/UnsavedChangesDialog.vue';
import ForumMemberChip from './ForumMemberChip.vue';
import { hasPermissionForSelectedInstitution } from '@/composables/permission';
import { useRoleStore } from '@/stores/role';
import { listItems } from '@/utils/text';
import ForumMemberNotInCohortWarningIcon from './ForumMemberNotInCohortWarningIcon.vue';
import { syncQueryParam } from '@/composables/query';

// #region setup
const { userStaff } = useStaffStore();
const id = parseRouteId('id');
const api = useApi();
const {
  roles,
  actions: { loadRoles },
} = useRoleStore();

const tabs = computed(() => [
  { key: 'participants', text: 'Participants', dirty: participantsDirty.value },
  { key: 'facilitators-moderators', text: 'Facilitators and Moderators', dirty: facilitatorsAndModeratorsDirty.value },
]);
// #endregion

// #region permissions
const hasInstitutionLevelStaffForumsEdit = hasPermissionForSelectedInstitution('staff.forums.edit');
loadRoles();
const rolesWithInstitutionStaffForumsEdit = computed(() =>
  roles.value.filter(r => r.institution_scope && r.permissions.some(p => p.name == 'staff.forums.edit'))
);
// #endregion

// #region data
const forum = ref<ForumResponse>();
const forumParticipants = computed(() => forum.value?.forumMembers.filter(m => m.memberType == 'participant') || []);
const forumParticipantIds = computed(() => forumParticipants.value.map(m => m.staffId));

// Extra staff are for participants who may have been removed from the Cohort and therefore not loaded by the CohortStaffAssignment
const extraStaff = computed(() =>
  forumParticipants.value.map(p => ({
    id: p.staffId,
    name: p.name,
    email: p.email,
    hideProfilePicture: p.hideProfilePicture,
    profilePictureUpdatedAt: p.profilePictureUpdatedAt,
    displayName: p.displayName,
    staffRoles: [],
  }))
);

const onlyNotInCohort = ref(false);
syncQueryParam(onlyNotInCohort, 'notInCohort', 'boolean');

const forumFacilitatorAndModeratorIds = computed(
  () => forum.value?.forumMembers.filter(m => m.memberType != 'participant').map(m => m.staffId) || []
);

const selectedParticipantIds = ref<number[]>([]);
const selectedFacilitatorAndModeratorIds = ref<number[]>([]);

const disabledParticipants = computed(() =>
  forumFacilitatorAndModeratorIds.value.map(id => ({
    id,
    tooltip: `This Instructor is already a Facilitator or Moderator in this Forum.`,
  }))
);
const disabledFacilitatorsAndModerators = computed(() =>
  forumParticipantIds.value.map(id => ({
    id,
    tooltip: `This Instructor is already a Participant in this Forum.`,
  }))
);

type MemberType = 'participant' | 'facilitator' | 'moderator';

const memberTypes = ref<{ [staffId: number]: MemberType }>({});
function updateMemberType(staff: { id: number }, type: MemberType) {
  memberTypes.value[staff.id] = type;
}

const breadcrumbs = computed(() => [
  { text: 'Forums', to: { name: 'StaffForumsListPage' } },
  { text: forum.value?.name || '', to: { name: 'StaffForumPage', query: { tab: 'members' } } },
  { text: 'Edit Members' },
]);
setBreadcrumbs(breadcrumbs);
// #endegion

// #region load
async function load() {
  const r = await api.get<ForumResponse>(`/staff/${userStaff.value.id}/forums/${id.value}`);
  forum.value = r.data;
  for (const member of r.data.forumMembers.filter(m => m.memberType != 'participant')) {
    memberTypes.value[member.staffId] = member.memberType;
  }
  selectedParticipantIds.value = r.data.forumMembers.filter(m => m.memberType == 'participant').map(m => m.staffId);
  selectedFacilitatorAndModeratorIds.value = r.data.forumMembers
    .filter(m => m.memberType != 'participant')
    .map(m => m.staffId);
}
// #endregion

// #region save
async function saveParticipants() {
  await api.put(`/staff/${userStaff.value.id}/forums/${id.value}/forum-members/participants`, {
    members: selectedParticipantIds.value.map(id => ({
      staffId: id,
    })),
  });
  await load();
}
async function saveFacilitatorsAndModerators() {
  await api.put(`/staff/${userStaff.value.id}/forums/${id.value}/forum-members/facilitators-and-moderators`, {
    members: selectedFacilitatorAndModeratorIds.value.map(id => ({
      staffId: id,
      memberType: memberTypes.value[id] || 'facilitator',
    })),
  });
  await load();
}
// #endregion

const participantsDirty = computed(() => {
  return (
    selectedParticipantIds.value.length != forumParticipantIds.value.length ||
    (!!forum.value &&
      forum.value?.forumMembers
        .filter(m => m.memberType == 'participant')
        .some(m => !selectedParticipantIds.value.includes(m.staffId)))
  );
});
const facilitatorsAndModeratorsDirty = computed(() => {
  return (
    selectedFacilitatorAndModeratorIds.value.length != forumFacilitatorAndModeratorIds.value.length ||
    (!!forum.value &&
      forum.value?.forumMembers
        .filter(m => m.memberType != 'participant')
        .some(
          m =>
            !selectedFacilitatorAndModeratorIds.value.includes(m.staffId) ||
            (memberTypes.value[m.staffId] && memberTypes.value[m.staffId] != m.memberType)
        ))
  );
});
const { dialog } = useUnsavedChanges(computed(() => participantsDirty.value || facilitatorsAndModeratorsDirty.value));
</script>
