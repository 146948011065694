export function rateLimitErrorMessage(waitTimeInSeconds: number, action: string) {
  let errorMessage = `You have made too many attempts to ${action}.`;
  const waitTime = Math.ceil(waitTimeInSeconds);
  if (waitTime === 0) {
    errorMessage += ' You may now try again.';
  } else if (waitTime) {
    errorMessage += ` Please wait ${waitTime} second${waitTime === 1 ? '' : 's'} before trying again.`;
  }
  return errorMessage;
}

// Could do something fancier here?
type ErrorCode = string;
export function createErrorsMap(
  defaultErrorContents: string,
  errors: { [errorCode: ErrorCode]: string } = {}
): { [errorCode: string]: string; default: string } {
  return {
    ...errors,
    default: `Sorry, cannot ${defaultErrorContents} at the moment`,
  };
}

export class MosaicError extends Error {
  public isMosaicError = true;

  constructor(public errorCode: string, public errorMessage: string) {
    super(`${errorCode}: ${errorMessage || ''}`);
    this.name = MosaicError.name;
  }
}
