<template>
  <mosaic-tab-card-page
    object-type="Reflection Template"
    :load="load"
    :headers="tabs"
    :title="template?.name"
    :edit="() => (editDialog.active = true)"
  >
    <template #subtitle> {{ traineeNoun }} facing name: {{ template?.studentFacingName }} </template>

    <template #content-tab-item v-if="template">
      <mosaic-configure-preview>
        <template #configure>
          <div>
            Changes to the template will only apply to future Reflections. No existing Reflections will be modified.
          </div>
          <mosaic-content-layout-builder
            v-model:content-layout="contentLayout"
            :supported-item-types="[
              'columns',
              'comment',
              'fileUpload',
              'image',
              'link',
              'resource',
              'taskList',
              'text',
              'video',
              'youtube',
            ]"
            :presign-url="`/presign/institutions/${selectedInstitution.id}/reflection-template-resources`"
            get-resource-url-prefix="/reflection-templates/resources"
            :save-resource-url="`/institutions/${selectedInstitution.id}/reflection-templates/resources`"
            :save-resource-params="{ reflectionTemplateId: template.id }"
            @valid="contentLayoutValid = $event"
          />
        </template>

        <template #preview>
          <reflection-card
            class="mb-4"
            :templates="[{ id: -1, studentFacingName: template.studentFacingName, contentLayout: contentLayout }]"
            :preview="true"
            :return-to="{}"
            :editable="true"
          />
        </template>
      </mosaic-configure-preview>
      <mosaic-save-buttons
        class="mt-2"
        object-type="Reflection Template"
        :return-to="getReturnTo(breadcrumbs)"
        :can-save="canSaveContent"
        :save="saveContent"
      />
    </template>

    <template #cohorts-tab-item v-if="template">
      <mosaic-tab-item-list
        :items="sortedCohorts"
        empty-text="This Reflection Template is not assigned to any Cohorts"
        mosaic-key="cohorts"
        :action="{
          icon: 'plus',
          text: 'Cohort',
          click: () => (addCohortDialog.active = true),
        }"
      >
        <template #item="{ item }">
          <mosaic-list-item
            :icon="icons.cohort"
            :title="item.name"
            :chip="item.status == 'closed' ? { color: 'secondary', text: 'Closed' } : undefined"
            :actions="[
              {
                icon: 'delete',
                tooltip: 'Unassign this Reflection Template from this Cohort',
                click: () => removeCohort(item),
                disabled: item.reflectionTemplateCount == 1,
                disabledTooltip: `Cannot remove the last Reflection Template for this Cohort. All Cohorts must have at least one Reflection Template.`,
              },
            ]"
          />
        </template>
      </mosaic-tab-item-list>
    </template>
  </mosaic-tab-card-page>

  <mosaic-save-dialog
    v-model:active="addCohortDialog.active"
    title="Assign Reflection Template to Cohort"
    object-type="Reflection Template"
    :save="saveCohorts"
    :can-save="canSaveCohorts"
    @save="addCohortDialog.cohortIds = []"
  >
    <mosaic-select
      label="Cohort"
      :prepend-icon="icons.cohort"
      :items="unassignedActiveCohorts"
      item-value="id"
      item-title="name"
      multiple
      v-model="addCohortDialog.cohortIds"
    />
  </mosaic-save-dialog>

  <mosaic-delete-dialog
    v-model:active="removeCohortDialog.active"
    object-type="Cohort"
    :object-name="removeCohortDialog.name"
    action="unassign"
    :url="`reflection-templates/${templateId}/cohorts/${removeCohortDialog.cohortId}`"
    @delete="cohortRemoved()"
    :error-code-map="{
      cannot_remove_last_template:
        'Cannot remove the last Reflection Template for this Cohort. All Cohorts must have at least one Reflection Template.',
    }"
  />

  <unsaved-changes-dialog v-model:unsaved-changes-dialog="dialog" object-type="Reflection Template" :save="save" />

  <reflection-template-edit-dialog
    v-if="template"
    v-model:active="editDialog.active"
    :template="template"
    @save="
      t => {
        if (!template) return;
        template.name = t.name;
        template.studentFacingName = t.studentFacingName;
      }
    "
  />
</template>

<script setup lang="ts">
import { setBreadcrumbs, getReturnTo } from '@/utils/breadcrumbs';
import { computed, ref } from 'vue';
import { useUnsavedChanges } from '@/composables/unsaved-changes';
import UnsavedChangesDialog from '@/components/UnsavedChangesDialog.vue';
import { useApi } from '@/composables/api';
import { parseRouteId } from '@/composables/vue-router';
import type { ContentLayout } from '@/utils/content-layout';
import { useInstitutionStore } from '@/stores/institution';
import MosaicContentLayoutBuilder from '@/components/mosaic-content-layout/MosaicContentLayoutBuilder.vue';
import { useStudentStore } from '@/stores/student';
import ReflectionCard from './ReflectionCard.vue';
import { icons } from '@/utils/icons';
import { mapActions, mapGetters } from '@/store/map-store';
import ReflectionTemplateEditDialog from './ReflectionTemplateEditDialog.vue';

const api = useApi();
const templateId = parseRouteId('templateId');
const { selectedInstitution } = useInstitutionStore();
const { traineeNoun } = useStudentStore();
const { activeCohorts } = mapGetters();
const { loadCohorts } = mapActions();
loadCohorts();

interface ReflectionTemplateResponse {
  id: number;
  name: string;
  studentFacingName: string;
  contentLayout: ContentLayout;
  cohorts: { id: number; name: string; status: 'active' | 'closed'; reflectionTemplateCount: number }[];
}

const tabs = computed(() => [
  { key: 'content', text: 'Content', dirty: contentDirty.value },
  { key: 'cohorts', text: 'Cohorts', dirty: false },
]);

const template = ref<ReflectionTemplateResponse>();
const contentLayout = ref<ContentLayout>({ sections: [] });
const contentLayoutValid = ref(true);
const cohorts = ref<ReflectionTemplateResponse['cohorts']>([]);
const sortedCohorts = computed(() => template.value?.cohorts.sortBy([c => c.status, c => c.name]) || []);

const contentDirty = computed(
  () => JSON.stringify(contentLayout.value) != JSON.stringify(template.value?.contentLayout)
);
const dirty = computed(() => contentDirty.value);
const { dialog } = useUnsavedChanges(dirty);

const breadcrumbs = computed(() => [
  { text: 'Reflection Templates', to: { name: 'InstitutionReflectionTemplatesListPage' } },
  { text: template.value?.name || '' },
]);
setBreadcrumbs(breadcrumbs);

async function load() {
  const r = await api.get<ReflectionTemplateResponse>(`/reflection-templates/${templateId.value}`);
  template.value = r.data;
  contentLayout.value = r.data.contentLayout;
  cohorts.value = r.data.cohorts;
}

const canSaveContent = computed(() => contentLayoutValid.value && contentDirty.value);

async function saveContent() {
  const r = await api.put<unknown, ReflectionTemplateResponse>(
    `/reflection-templates/${templateId.value}/template-content`,
    {
      templateContent: contentLayout.value,
    }
  );
  template.value = r.data;
}

const addCohortDialog = ref({
  active: false,
  cohortIds: [] as number[],
});

const unassignedActiveCohorts = computed(() => {
  const assignedCohortIds = template?.value?.cohorts.map(c => c.id) || [];
  return activeCohorts.value.filter(c => !assignedCohortIds.includes(c.id));
});

const removeCohortDialog = ref({
  active: false,
  cohortId: -1,
  name: '',
});
function removeCohort(cohort: { id: number; name: string }) {
  removeCohortDialog.value = {
    active: true,
    cohortId: cohort.id,
    name: cohort.name,
  };
}
function cohortRemoved() {
  template.value = {
    ...template.value!,
    cohorts: template.value!.cohorts.filter(c => c.id !== removeCohortDialog.value.cohortId),
  };
}

const canSaveCohorts = computed(() => addCohortDialog.value.cohortIds.length > 0);

async function saveCohorts() {
  const r = await api.post<unknown, ReflectionTemplateResponse>(`/reflection-templates/${templateId.value}/cohorts`, {
    cohortIds: addCohortDialog.value.cohortIds,
  });
  template.value = r.data;
}

async function save() {
  await Promise.all([saveContent(), saveCohorts()]);
}

const editDialog = ref({
  active: false,
});
</script>
