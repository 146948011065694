<template>
  <div>
    <div v-if="loadError" class="pt-2">
      <span v-if="isString(loadError)">{{ loadError }}</span>
      <span v-else>
        Sorry, cannot load {{ isMe ? 'your' : "this Instructor's" }} Training Requirements right now. If this problem
        persists, please contact support.
      </span>
    </div>
    <div v-else>
      <mosaic-tab-item-list
        :items="frameworksWithThemes"
        :empty-text="renderNoRequirementsMessage"
        mosaic-key="frameworksWithThemes"
      >
        <template #filters v-if="props.showCohortsFilter && cohortItems.length > 1">
          <mosaic-select
            name="Filter by Cohorts where the Frameworks are published"
            no-icon
            v-model="filteredCohorts"
            item-value="value"
            item-title="name"
            multiple
            class="multiple-v-select mr-3 cohort-filter"
            label="Filter by Cohorts where the Frameworks are published"
            :items="cohortItems"
            hide-details
          >
            <template #item="{ item, props: slotProps }">
              <v-list-item v-bind="slotProps" :key="item.value">
                <template #title>
                  <div class="d-flex justify-space-between">
                    <div>
                      <v-icon class="mx-2" :color="filteredCohorts.includes(item.value) ? 'primary' : 'secondary'">{{
                        filteredCohorts.includes(item.value) ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'
                      }}</v-icon>

                      <span class="mr-2">{{ item.title }}</span>
                    </div>
                    <mosaic-tooltip-chip v-if="item.raw.chipText && item.raw.tooltip" color="primary"
                      ><template #text>
                        {{ item.raw.chipText }}
                      </template>
                      <template #tooltip>{{ item.raw.tooltip }}</template></mosaic-tooltip-chip
                    >
                  </div>
                </template>
              </v-list-item>
            </template>
          </mosaic-select>
        </template>
        <template #item="{ item }">
          <mosaic-title level="h6">{{ item.name }} </mosaic-title>
          <mosaic-list :items="item.staffTrainingCompetencyThemes.filter(t => t.staffTrainingCompetencies.length > 0)">
            <template #item="{ item: theme }">
              <mosaic-list-item
                :key="theme.id"
                :icon="icons.instructorTrainingCompetencyTheme"
                :title="theme.name"
                :subtitle="themeSubtitle(theme)"
                :to="clickThemeTo(theme)"
                :chip="chip(theme)"
              >
              </mosaic-list-item>
            </template>
          </mosaic-list>
        </template>
      </mosaic-tab-item-list>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { StaffTraining, StaffTrainingCompetencyTheme } from '@/stores/staff-training';
import type { RouteLocationNamedRaw } from 'vue-router';
import { icons } from '@/utils/icons';
import { computed, ref } from 'vue';
import MosaicTitle from '@/components/library/headers/MosaicTitle.vue';
import { mapState } from '@/store/map-store';
import { syncQueryParam } from '@/composables/query';
import { isString } from 'lodash';

// Display requirements
const props = withDefaults(
  defineProps<{
    frameworks: StaffTraining['staffTrainingFrameworks'];
    loadError?: boolean | string;
    clickThemeTo: (theme: StaffTrainingCompetencyTheme) => RouteLocationNamedRaw;
    isMe?: boolean;
    showCohortsFilter?: boolean;
  }>(),
  {
    loadError: false,
    isMe: false,
  }
);

const { selectedCohort } = mapState();

const cohortItems = computed(() => {
  const uniqueIds = new Set();
  return props.frameworks.flatMap(framework => {
    return framework.requiredFor
      .map(r => ({
        value: r.cohort.id,
        name:
          selectedCohort.value && selectedCohort.value.id === r.cohort.id
            ? `${r.cohort.name} (current)`
            : r.cohort.name,
        tooltip: r.cohort.status === 'closed' ? 'This Cohort has been closed.' : null,
        chipText: r.cohort.status === 'closed' ? 'closed' : null,
      }))
      .filter(c => {
        if (!uniqueIds.has(c.value)) {
          uniqueIds.add(c.value);
          return true;
        }
        return false;
      });
  });
});

const filteredCohorts = ref<number[]>(cohortItems.value.length ? cohortItems.value?.map(c => c.value) : []);

syncQueryParam(filteredCohorts, 'cohorts', 'integer[]');

const frameworksWithThemes = computed(() => {
  return props.frameworks.filter(framework => {
    const hasCompetencyThemes = framework.staffTrainingCompetencyThemes.length > 0;
    const meetsCohortFilter = props.showCohortsFilter
      ? filteredCohorts.value.some(cohortId => framework.requiredFor.some(r => r.cohort.id === cohortId))
      : true;

    return hasCompetencyThemes && meetsCohortFilter;
  });
});

function themeSubtitle(theme: StaffTrainingCompetencyTheme) {
  if (theme.status === 'met') return 'All Competencies met';
  const requirementsCount = theme.requiredModuleCount;
  const incompleteRequirementsCount = theme.incompleteRequiredModuleCount;
  const completedRequirementsCount = requirementsCount - incompleteRequirementsCount;
  if (requirementsCount === 0) return 'No Requirements';
  else if (theme.startedRequiredModuleCount === 0)
    return `${completedRequirementsCount}/${requirementsCount} Requirements completed`;
  else
    return `${theme.startedRequiredModuleCount}/${requirementsCount} Requirements started, ${completedRequirementsCount} completed`;
}

function chip(theme: StaffTrainingCompetencyTheme) {
  if (theme.status === 'not_met') return { text: 'Not Met', color: 'accent' };
  return { text: theme.status === 'up_to_date' ? 'Up to date' : 'Met', color: 'primary' };
}

const renderNoRequirementsMessage = computed(() => {
  const allSelected = cohortItems.value.length === filteredCohorts.value.length;
  const allDeselected = cohortItems.value.length && !filteredCohorts.value.length;
  const messageToAdmin = 'There are no Training Requirements for this Instructor';

  return props.isMe
    ? "You don't have any Training Requirements."
    : props.showCohortsFilter && allDeselected
    ? 'Select at least one Cohort.'
    : props.showCohortsFilter && !allSelected
    ? `${messageToAdmin} in the Frameworks published to the filtered Cohorts.`
    : `There are no Training Requirements for this Instructor.`;
});
</script>
<style scoped>
.cohort-filter {
  width: 340px;
}
</style>
