<template>
  <span v-if="!readonly">
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      :disabled="disabled"
      max-width="360px"
      min-width="360px"
    >
      <template #activator="{ props }">
        <div :style="{ width: exactWidth ? exactWidth : null }">
          <v-text-field
            v-model="formatDateComputed"
            :class="{ 'mr-2': endMargin }"
            :label="label"
            :placeholder="placeholder"
            readonly
            :density="dense ? 'compact' : 'default'"
            :hide-details="hideDetails"
            :name="name"
            :rules="allRules"
            :disabled="disabled"
            v-bind="props"
          >
            <template #prepend>
              <mosaic-icon v-if="!hideIcon" icon="calendar" :color="iconColour" />
            </template>
          </v-text-field>
        </div>
      </template>
      <v-sheet class="fit-content">
        <v-date-picker
          :model-value="vDatePickerDate"
          :max="max"
          :min="min"
          :view-mode="viewMode"
          :name="name"
          @update:model-value="dateChanged"
          color="primary"
        >
        </v-date-picker>
        <div class="d-flex justify-end pb-1 pr-1">
          <v-btn v-if="!hideClear" variant="text" color="error" @click.prevent="clear()">Clear</v-btn>
          <v-btn variant="text" color="primary" @click.prevent="menu = false">OK</v-btn>
        </div>
      </v-sheet>
    </v-menu>
  </span>
  <!-- mb-4 will need to change if hide-details etc. are supplied -->
  <div v-else class="d-flex align-center mb-4" :data-test-name="name">
    <mosaic-icon class="mt-5" icon="calendar" :color="iconColour" />
    <div class="pl-4">
      <div class="text-caption">{{ label }}</div>
      <div class="text-body-1">{{ formatDateComputed || 'Not recorded' }}</div>
    </div>
  </div>
</template>

<script>
import { requiredText } from '../../../utils/validations';
import { DateTime } from 'luxon';

export default {
  name: 'MosaicDatePicker',
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    // YYYY-MM-DD
    date: {
      type: [String, null],
    },
    max: {
      type: String,
    },
    min: {
      type: String,
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    name: {
      type: String,
    },
    exactWidth: {
      // Should be a width or false
      type: [String, Boolean],
      default: '150px',
    },
    // Probably should look to get rid of this and apply margins in outer components?
    endMargin: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    activeIconColour: {
      type: Boolean,
      default: false,
    },
    viewMode: {
      type: String, // 'month' | 'months' | 'year'
      default: 'month',
    },
    required: {
      type: Boolean,
      default: false,
    },
    hideClear: {
      type: Boolean,
      default: false,
    },
    hideIcon: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:date'],
  data: () => ({
    menu: false,
    requiredText,
  }),
  computed: {
    vDatePickerDate: {
      get() {
        return this.date ? DateTime.fromISO(this.date).toJSDate() : null;
      },
    },
    formatDateComputed: {
      get() {
        return this.date ? DateTime.fromISO(this.date).toFormat('dd/MM/y') : '';
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      set() {},
    },
    iconColour() {
      if (!this.activeIconColour) return '';
      if (!this.date) return '';
      return 'primary';
    },
    allRules() {
      return this.required ? [this.requiredText, ...this.rules] : this.rules;
    },
  },
  methods: {
    dateChanged(x) {
      this.$emit('update:date', x ? DateTime.fromJSDate(x).toFormat('y-MM-dd') : null);
    },
    clear() {
      this.$emit('update:date', null);
      this.menu = false;
    },
  },
};
</script>
<style scoped>
.fit-content {
  width: fit-content;
}
</style>
