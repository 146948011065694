<template>
  <mosaic-dialog v-model:active="activeDialog" :title="title" :error-message="errorMessage">
    <span :class="{ 'pb-2': !isSlotEmpty($slots.default) }"
      >Are you sure you want to {{ action }} <span class="font-weight-bold">"{{ objectName }}"</span>?</span
    >
    <slot></slot>
    <div v-if="deletingData" class="mt-2">
      <div class="mb-2">
        This will permanently delete {{ actionNoun || traineeNounCapitalised() }} data. Double check that you are happy
        for this data to be removed (and if you're not sure then contact support).
      </div>
      <div>If you're happy to proceed then type "permanently delete" below:</div>
      <mosaic-text-field
        v-model="permanentlyDelete"
        name="perm-delete"
        :placeholder="`Type &quot;permanently delete&quot; to delete ${actionNoun || traineeNounCapitalised()} data`"
        label=""
        no-icon
      />
    </div>
    <template #buttons>
      <v-btn
        variant="text"
        ripple
        color="error"
        :disabled="processing || !canDelete"
        :loading="processing"
        @click.prevent="submitDeleteItem()"
        >{{ action }}</v-btn
      >
    </template>
  </mosaic-dialog>
</template>

<script setup lang="ts">
import { useApi } from '@/composables/api';
import { capitaliseFirstLetters } from '@/utils/text';
import { handleErrorCodes } from '@/composables/processing-and-errors';
import { isString } from 'lodash';
import { isSlotEmpty } from '@/utils/mosaic-slots';
import { computed, ref, watch } from 'vue';

const api = useApi();

const props = withDefaults(
  defineProps<{
    active: boolean;
    objectType: string;
    objectName: string;
    actionNoun?: string;
    url: string;
    deletingData?: boolean;
    errorCodeMap?: { [errorCode: string]: string };
    action?: string;
    method?: 'delete' | 'put';
  }>(),
  {
    deletingData: false,
    errorCodeMap: () => ({}),
    action: 'delete',
    method: 'delete',
  }
);
const emit = defineEmits<{
  (e: 'update:active', active: boolean): void;
  (e: 'delete'): void;
}>();

const activeDialog = ref(props.active);
const processing = ref(false);
const errorMessage = ref('');
const permanentlyDelete = ref('');

const title = computed(() => `${capitaliseFirstLetters(props.action)} ${capitaliseFirstLetters(props.objectType)}`);
const canDelete = computed(() => !props.deletingData || permanentlyDelete.value === 'permanently delete');

watch(activeDialog, x => {
  emit('update:active', x);
});

watch(
  () => props.active,
  (x: boolean) => {
    activeDialog.value = x;
  }
);

watch(
  () => props.url,
  () => {
    errorMessage.value = '';
  }
);

async function submitDeleteItem() {
  processing.value = true;
  errorMessage.value = '';
  try {
    const error = await handleErrorCodes(
      () => (props.method === 'delete' ? api.delete(props.url) : api.put(props.url, {})),
      props.errorCodeMap
    );

    if (isString(error)) {
      errorMessage.value = error;
    } else {
      activeDialog.value = false;
      permanentlyDelete.value = '';
      emit('delete');
    }
  } catch (e) {
    console.log(e);
    errorMessage.value = `Sorry, cannot ${props.action} your ${props.objectType} at the moment`;
  }
  processing.value = false;
}
</script>
