<template>
  <mosaic-filter-list-page
    object-type="Reflection Template"
    short-object-type="Template"
    :load="load"
    :items="templates"
    :filtered-items="filteredTemplates"
    @add="router.push({ name: 'InstitutionReflectionTemplateCreatePage' })"
  >
    <template #filters>
      <mosaic-text-field
        v-model="nameFilter"
        name="name-filter"
        label="Filter by name"
        style="width: 250px"
        prepend-icon="magnify"
        hide-details
      />
      <mosaic-select
        v-model="assignmentFilter"
        :items="assignmentOptions"
        label="Filter by assigned?"
        hide-details
        density="compact"
        style="min-width: 150px"
        no-icon
      />
    </template>
    <template #list-item="{ item }">
      <mosaic-list-item
        :icon="icons.reflection"
        :title="item.name"
        :subtitle="`${item.studentFacingName} - Assigned to ${lowerCaseEnumerateItems(
          item.cohorts.length,
          'active Cohort'
        )}`"
        :to="{ name: 'InstitutionReflectionTemplateEditPage', params: { templateId: item.id } }"
        :actions="[
          {
            icon: 'pencil',
            tooltip: 'Edit Reflection Template details',
            click: () => (editDialog = { active: true, template: item }),
          },
        ]"
      />
    </template>
  </mosaic-filter-list-page>

  <reflection-template-edit-dialog
    v-if="editDialog.template"
    v-model:active="editDialog.active"
    :template="editDialog.template"
    @save="load"
  />
</template>

<script setup lang="ts">
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { icons } from '@/utils/icons';
import { lowerCaseEnumerateItems } from '@/utils/text';
import { useRouter } from 'vue-router';
import { useLoadReflectionTemplates, type ReflectionTemplateSlimResponse } from './reflection-templates';
import { querySyncedRef } from '@/composables/query';
import { computed, ref } from 'vue';
import { filterByString } from '@/components/library/filters/filters';
import ReflectionTemplateEditDialog from './ReflectionTemplateEditDialog.vue';

const router = useRouter();

setBreadcrumbs([{ text: 'Reflection Templates' }]);

const { templates, load } = useLoadReflectionTemplates();

const nameFilter = querySyncedRef('', 'name', 'string');

const assignmentOptions = [
  { text: 'All', value: 'all' },
  { text: 'Only assigned', value: 'assigned' },
  { text: 'Only unassigned', value: 'unassigned' },
];
const assignmentFilter = querySyncedRef('all', 'assigned', 'string');
function filterbyAssigned(template: ReflectionTemplateSlimResponse) {
  if (assignmentFilter.value == 'all') return true;
  if (assignmentFilter.value == 'assigned') return template.cohorts.length > 0;
  // 'unassgined'
  return template.cohorts.length == 0;
}

const filteredTemplates = computed(() =>
  templates.value.filter(t => filterByString(t.name, nameFilter.value) && filterbyAssigned(t))
);

const editDialog = ref({
  active: false,
  template: undefined as ReflectionTemplateSlimResponse | undefined,
});
</script>
