import config from '@/utils/config';
import { Realtime, type ConnectionState, type InboundMessage, type RealtimeChannel } from 'ably';
import type { App } from 'vue';

// Channels are automatically detached from by a beforeunload browser event handler. Still we want to detach from user channel on logout

// When doing notification, to consider:
// - disconnecting during periods of inactivity?
// - check for notifications on reconnect manually or when network has been down? Can Ably tell us when the network is down?
// - how to fall back to using API polling?

export class WebSockets {
  private ably?: Realtime;
  private userChannel?: RealtimeChannel;
  private connectionCount = 0;
  private connectionState: ConnectionState = 'initialized';

  async connect(userId: number) {
    this.ably = new Realtime({ authUrl: '/websockets/auth' });
    this.ably.connection.on(x => {
      console.debug(`Ably is ${x.current}`);
      this.connectionState = x.current;
    });
    await this.ably.connection.once('connected');
    this.userChannel = this.ably?.channels.get(`${config.environment}:users:${userId}`);
  }

  needToConnect() {
    // https://ably.com/docs/connect/states#connection-states
    // disconnected will be automatically recovered from by the client if we don't do an explicit connection
    return ['suspended', 'closing', 'closed', 'disconnected'].includes(this.connectionState);
  }

  disconnect() {
    console.debug(`Disconnecting Ably`);
    // Deliberately unawaited so that we never have a detached channel with a non-undefined userChannel
    this.userChannel?.detach();
    this.userChannel = undefined;
    this.ably?.connection.off();
    this.ably?.close();
    this.ably = undefined;
  }

  async subscribeForOneMessage(userId: number, name: string, handler: (m: InboundMessage) => Promise<void>) {
    if (!this.ably || this.needToConnect()) await this.connect(userId);
    this.connectionCount += 1;
    await this.userChannel!.subscribe(name, async m => {
      await handler(m);
      if (this.connectionCount == 1) {
        this.disconnect();
      }
      this.connectionCount -= 1;
    });
  }
}

const webSockets = new WebSockets();

export function installWebSocketsPlugin(app: App) {
  app.config.globalProperties.$ws = webSockets;
}

export default webSockets;
