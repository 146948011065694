<template>
  <div>
    <mosaic-card-heading class="pb-2">{{ traineeNounCapitalisedAndPluralised }}</mosaic-card-heading>
    <div v-if="!isSlotEmpty($slots.filters)">
      <slot name="filters"></slot>
    </div>

    <div class="d-flex align-center mb-2">
      <mosaic-card-subheading icon="mdi-account-multiple-check"
        >Selected {{ traineeNounCapitalisedAndPluralised }}</mosaic-card-subheading
      >
      <div class="px-4 text-right flex-shrink-0 font-italic">
        <span
          >({{ selectedStudentIdsInternal.length }} selected<span
            v-if="selectedStudentIdsInternal.length !== filterSelectedStudentCount"
          >
            - {{ filterSelectedStudentCount }}
            {{ filterSelectedStudentCount !== 1 ? traineeNounPluralised() : traineeNoun() }} for the current
            filters</span
          >)</span
        >
      </div>
    </div>
    <v-list class="py-0">
      <v-list-item ripple @click.prevent="() => {}" title="Select all" @click="selectAllChanged(!selectAll)">
        <template #prepend>
          <mosaic-checkbox
            name="select-all"
            no-icon
            :model-value="selectAll"
            color="primary"
            @update:model-value="selectAllChanged"
            density="comfortable"
            class="mr-2"
          />
        </template>

        <template #append>
          <div class="d-flex">
            <slot name="details-header"></slot>
            <div class="flex-grow-1"></div>
            <div class="d-flex justify-center mr-4" style="width: 160px">Status</div>
          </div>
        </template>
      </v-list-item>
    </v-list>
    <v-divider />
    <v-list density="compact">
      <template v-for="student in paginatedStudents" :key="student.id">
        <v-list-item
          ripple
          :title="student.details_filled_in ? student.name : student.email"
          @click="toggleSelect(student)"
        >
          <template #prepend>
            <mosaic-checkbox
              name="student"
              no-icon
              :model-value="isSelected(student)"
              color="primary"
              @update:model-value="selectChanged(student, $event)"
              density="comfortable"
              class="mr-2"
            />
          </template>

          <template #append>
            <div class="mr-4 d-flex align-center">
              <slot name="details" :student="student"></slot>
            </div>
            <div class="mr-4 text-center" style="width: 160px">
              <div v-if="pendingStudentBadgeStatus(student)">
                <v-tooltip location="top">
                  <template #activator="{ props }">
                    <v-chip :color="pendingStudentBadgeStatus(student).color" v-bind="props">{{
                      pendingStudentBadgeStatus(student).label
                    }}</v-chip>
                  </template>
                  <span>{{ pendingStudentBadgeStatus(student).tooltip }}</span>
                </v-tooltip>
              </div>
              <div v-else>
                <slot name="status-badge" :student="student"></slot>
              </div>
            </div>
          </template>
        </v-list-item>
      </template>
    </v-list>
    <mosaic-pagination v-model="currentPage" v-model:page-size="pageSize" :total="filteredStudentsInternal.length" />
    <div v-if="filteredStudents && filteredStudents.length === 0" class="pa-4 pl-14">
      There are no {{ traineeNounPluralised() }} for these filters
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { isSlotEmpty } from '@/utils/mosaic-slots';

export default {
  name: 'MosaicStudentSelector',
  props: {
    students: {
      required: true,
      type: Array,
    },
    filteredStudents: {
      default: null,
      type: Array,
    },
    selectedStudentIds: {
      required: true,
      type: Array,
    },
    studentsToAdd: {
      required: true,
      type: Array,
    },
    studentsToRemove: {
      required: true,
      type: Array,
    },
    setInTheFuture: {
      type: Boolean,
      default: false,
    },
    originallySetInTheFuture: {
      type: Boolean,
      default: false,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    actionName: {
      required: true,
      type: String,
    },
  },
  emits: ['update:selectedStudentIds', 'createOrUpdateClicked'],
  data() {
    return {
      selectedStudentIdsInternal: this.selectedStudentIds || [],
      currentPage: 1,
      pageSize: 10,
    };
  },
  computed: {
    filteredStudentsInternal() {
      return this.filteredStudents || this.students;
    },
    paginatedStudents() {
      const index = (this.currentPage - 1) * this.pageSize;
      return this.filteredStudentsInternal.slice(index, index + this.pageSize);
    },
    selectAll() {
      return (
        this.selectedStudentIdsInternal.length > 0 &&
        this.filteredStudentsInternal.length > 0 &&
        this.filteredStudentsInternal.every(s => this.selectedStudentIdsInternal.includes(s.id))
      );
    },
    filterSelectedStudentCount() {
      const filteredStudentIds = this.filteredStudentsInternal.map(x => x.id);
      return this.selectedStudentIdsInternal.filter(s => filteredStudentIds.includes(s)).length;
    },
    capitalisedActionName() {
      return this.actionName.charAt(0).toUpperCase() + this.actionName.slice(1);
    },
  },
  watch: {
    selectedStudentIdsInternal: {
      handler(x) {
        this.$emit('update:selectedStudentIds', x);
      },
      deep: true,
    },
    selectedStudentIds(x) {
      this.selectedStudentIdsInternal = x;
    },
    filteredStudentsInternal() {
      this.currentPage = 1;
    },
  },
  methods: {
    isSelected(student) {
      return this.selectedStudentIdsInternal.includes(student.id);
    },
    isSlotEmpty,
    selectChanged(student, selected) {
      if (selected) {
        this.selectedStudentIdsInternal.push(student.id);
      } else {
        this.selectedStudentIdsInternal = this.selectedStudentIdsInternal.filter(x => x !== student.id);
      }
    },
    toggleSelect(student) {
      if (this.selectedStudentIdsInternal.includes(student.id)) {
        this.selectChanged(student, false);
      } else {
        this.selectChanged(student, true);
      }
    },
    selectAllChanged(x) {
      if (x) {
        this.selectedStudentIdsInternal = [
          ...this.selectedStudentIdsInternal,
          ...this.filteredStudentsInternal.map(x => x.id),
        ].unique();
      } else {
        const filteredStudentIds = this.filteredStudentsInternal.map(x => x.id);
        this.selectedStudentIdsInternal = this.selectedStudentIdsInternal.filter(s => !filteredStudentIds.includes(s));
      }
    },
    createOrUpdateClick() {
      this.$emit('createOrUpdateClicked');
    },
    pendingStudentBadgeStatus(student) {
      if (
        this.studentsToAdd.includes(student.id) ||
        (this.selectedStudentIds.includes(student.id) && !this.setInTheFuture && this.originallySetInTheFuture)
      ) {
        return {
          label: 'Pending Assignment',
          tooltip: `The ${this.capitalisedActionName} will be assigned to the ${this.traineeNoun()} on ${
            this.isEditing ? 'update' : 'creation'
          }.`,
          color: 'green',
        };
      } else if (this.studentsToRemove.includes(student.id)) {
        return {
          label: 'Pending Removal',
          tooltip: `The ${
            this.capitalisedActionName
          } will be removed from the ${this.traineeNoun()}'s account on update.`,
          color: 'red',
        };
      } else if (this.setInTheFuture && this.selectedStudentIds.includes(student.id)) {
        return {
          label: 'Scheduled',
          tooltip: `The ${this.capitalisedActionName} will be assigned to the ${this.traineeNoun()} on ${moment(
            this.scheduledDate
          ).format('DD-MM-YYYY')}.`,
          color: 'purple',
        };
      } else return false;
    },
  },
};
</script>
