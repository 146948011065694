<template>
  <div>
    <div v-if="showPublish(module)">
      <mosaic-icon-btn
        v-if="showPublish(module)"
        icon="mdi-publish"
        :tooltip="publishTooltip(module)"
        :disabled="disablePublish(module)"
        @click.prevent="publishModule(module)"
      />
    </div>
    <div v-else>
      <mosaic-icon-btn
        v-if="!unpublishModuleDialog.processing"
        icon="mdi-publish-off"
        tooltip="Unpublish Training Module"
        @click.prevent="unpublishModule(module)"
      />
      <mosaic-loading-icon class="ml-4 mr-3" :is-loading="unpublishModuleDialog.processing" />
    </div>
  </div>

  <mosaic-dialog
    v-model:active="publishModuleDialog.active"
    :error-message="publishModuleDialog.errorMessage"
    title="Publish Module"
  >
    <div>
      Are you sure you want to publish
      <span class="font-weight-medium">"{{ publishModuleDialog?.selectedModule?.name }}</span
      >"?
      <span v-if="publishModuleDialog.frameworks.length > 0">
        This Module is a requirement to meet Competencies of the following Frameworks and it will become visible to
        Instructors:</span
      >
    </div>

    <v-list class="py-0" v-if="publishModuleDialog.frameworks.length > 0" density="compact">
      <v-list-item :key="fName" class="py-0" v-for="fName in publishModuleDialog.frameworks">
        <v-icon size="18" class="text-secondary" :icon="`mdi-${icons.instructorTrainingFramework}`"></v-icon>
        <span class="ml-2">{{ fName }}</span>
      </v-list-item>
    </v-list>

    <template #buttons>
      <v-btn
        variant="text"
        ripple
        color="primary"
        :disabled="publishModuleDialog.processing"
        @click.prevent="savePublishModule(module)"
      >
        Confirm
      </v-btn>
    </template>
  </mosaic-dialog>

  <mosaic-delete-dialog
    v-model:active="unpublishModuleDialog.active"
    object-type="Module"
    action="unpublish"
    action-noun="Instructor"
    :object-name="module.name"
    :url="`/staff-training/modules/${module.id}/unpublish`"
    :deleting-data="
      !!(
        unpublishModuleDialog.frameworks.length &&
        unpublishModuleDialog.warningLists.some(l => l.items.length && l.showExpand)
      )
    "
    @delete="emit('handleSaveUnpublishModule', module)"
    method="put"
  >
    <div>
      <span v-if="unpublishModuleDialog.frameworks.length > 1">
        This Module is currently used in Compentencies of the following Frameworks:
      </span>
      <span v-else-if="unpublishModuleDialog.frameworks.length === 1">
        This Module is currently used in Compentencies of the Framework "{{
          unpublishModuleDialog.frameworks[0]
        }}".</span
      >
    </div>

    <div v-if="unpublishModuleDialog.frameworks.length > 1">
      <div class="d-flex mt-2" v-for="framework in unpublishModuleDialog.frameworks" :key="framework">
        <mosaic-icon color="secondary" :icon="icons.instructorTrainingFramework" size="small" class="mr-2 list-icon" />
        <span>{{ framework }}</span>
      </div>
    </div>

    <div
      class="mt-3"
      v-if="unpublishModuleDialog.frameworks.length && unpublishModuleDialog.warningLists.some(l => l.items.length)"
    >
      <span class="font-weight-medium">
        Once unpublished, it will no longer be visible to Instructors, and their progress will be lost.
      </span>
      Please review the Instructors' completion status for this Module:

      <mosaic-expandable-user-list
        :lists="unpublishModuleDialog.warningLists"
        title-for-exported-list="Module Completion Status per Instructor"
      />
    </div>
  </mosaic-delete-dialog>

  <mosaic-snackbar v-model="snackbar.active" color="error" :message="snackbar.message" />
</template>
<script setup lang="ts">
import type { Item } from './library/mosaic-list/MosaicExpandableUserList.vue';
import type { ExpandCollapseList } from './library/mosaic-list/MosaicExpandableUserList.vue';
import type { CompletionsResponse } from '@/types/responses';
import { useInstitutionStaffTrainingStore } from '@/stores/institution-staff-training';
import type { StaffTrainingModule } from '@/store/map-store';
import { icons } from '@/utils/icons';
import { useApi } from '@/composables/api';
import { ref } from 'vue';
import { enumerateItems } from '@/utils/text';

interface Props {
  module: StaffTrainingModule;
}
defineProps<Props>();

const api = useApi();

const {
  institutionStaffTrainingFrameworks,
  actions: { loadInstitutionStaffTrainingFrameworks },
} = useInstitutionStaffTrainingStore();

loadInstitutionStaffTrainingFrameworks();

type ModuleDialog = {
  active: boolean;
  selectedModule: StaffTrainingModule | null;
  processing: boolean;
  errorMessage: string;
  frameworks: string[];
};

const publishModuleDialog = ref<ModuleDialog>({
  active: false,
  selectedModule: null,
  processing: false,
  errorMessage: '',
  frameworks: [],
});

const unpublishModuleDialog = ref<ModuleDialog & { warningLists: ExpandCollapseList[] }>({
  active: false,
  selectedModule: null,
  processing: false,
  errorMessage: '',
  warningLists: [],
  frameworks: [],
});

const snackbar = ref({
  active: false,
  message: '',
});

const emit = defineEmits<{
  (e: 'handleSavePublishModule', payload: StaffTrainingModule): void;
  (e: 'handleSaveUnpublishModule', payload: StaffTrainingModule): void;
}>();

const showPublish = (module: StaffTrainingModule) => module.status !== 'published';

function publishTooltip(module: StaffTrainingModule) {
  if (!module.hasSections) {
    return 'Cannot publish Module without any Sections';
  }
  if (!module.allSectionsHaveContent) {
    return 'Cannot publish Module as some Sections do not have any content';
  }
  return 'Publish Training Module. Published Modules cannot be edited.';
}

const disablePublish = (module: StaffTrainingModule) => !(module.hasSections && module.allSectionsHaveContent);

async function publishModule(m: StaffTrainingModule) {
  const frameworkIds = m.staffTrainingCompetencies.map(c => c.frameworkId);
  publishModuleDialog.value.active = true;
  publishModuleDialog.value.errorMessage = '';
  publishModuleDialog.value.selectedModule = m;
  if (institutionStaffTrainingFrameworks.value)
    publishModuleDialog.value.frameworks = institutionStaffTrainingFrameworks.value
      .filter(f => frameworkIds.includes(f.id))
      .map(f => f.name);
}

const getList = (
  items: Item[],
  count: number,
  showExpand: boolean,
  completionStatus: 'notStarted' | 'started' | 'completed',
  moduleName: string
) => {
  const color = !!items.length ? 'red' : undefined;
  const friendlyCompletionStatus = completionStatus === 'notStarted' ? 'not started' : completionStatus;

  return {
    id: `s${completionStatus}`,
    items,
    showExpand,
    title: {
      color,
      text: ` ${friendlyCompletionStatus.charAt(0).toUpperCase() + friendlyCompletionStatus.slice(1)}: ${enumerateItems(
        count,
        'Instructor'
      )}`,
    },
    exportConfig: {
      title: `Instructors - Module ${friendlyCompletionStatus}`,
      subtitle: `Instructors who have ${friendlyCompletionStatus} the Module "${moduleName}"`,
    },
  };
};

const staffByCompletionStatus = (completions: CompletionsResponse['completions']) => {
  return completions.reduce<Record<'completed' | 'started', Item[]>>(
    (acc, c) => {
      const details = {
        avatar: {
          ...c.staff,
        },
        text: c.staff.user.displayName,
      };
      if (c.completedAt) {
        acc.completed.push({ ...details });
      } else {
        acc.started.push({ ...details });
      }
      return acc;
    },
    { started: [], completed: [] }
  );
};

async function unpublishModule(m: StaffTrainingModule) {
  snackbar.value = {
    active: false,
    message: '',
  };
  unpublishModuleDialog.value.errorMessage = '';
  unpublishModuleDialog.value.selectedModule = m;
  unpublishModuleDialog.value.processing = true;
  try {
    const r = await api.get<CompletionsResponse>(`/staff-training/modules/${m.id}/completions`);
    const { completions, staffRequiredToCompleteCount: totalStaffCount } = r.data;
    const { started, completed } = staffByCompletionStatus(completions);

    const notStartedCount = totalStaffCount - started.length - completed.length;

    const frameworkIds = m.staffTrainingCompetencies.map(c => c.frameworkId);

    if (institutionStaffTrainingFrameworks.value)
      unpublishModuleDialog.value.frameworks = institutionStaffTrainingFrameworks.value
        .filter(f => frameworkIds.includes(f.id))
        .map(f => f.name);

    const notStartedList = getList([], notStartedCount, false, 'notStarted', m.name);
    const startedList = getList(started, started.length, true, 'started', m.name);
    const completedList = getList(completed, completed.length, true, 'completed', m.name);

    unpublishModuleDialog.value.warningLists = [notStartedList, startedList, completedList];
    unpublishModuleDialog.value.active = true;
  } catch (e) {
    console.log(e);
    snackbar.value = {
      active: true,
      message: 'Sorry, could not load the Module data at the moment',
    };
  } finally {
    unpublishModuleDialog.value.processing = false;
  }
}

async function savePublishModule(m: StaffTrainingModule) {
  publishModuleDialog.value.processing = true;
  try {
    await api.put(`/staff-training/modules/${m.id}/publish`, {});
    emit('handleSavePublishModule', m);
    publishModuleDialog.value.active = false;
    publishModuleDialog.value.processing = false;
  } catch (e) {
    console.log(e);
    publishModuleDialog.value.errorMessage = `Sorry, cannot publish the Module "${publishModuleDialog.value?.selectedModule?.name}" at the moment`;
  }
}
</script>
