import type { Ref } from 'vue';
import { computed, ref, watch } from 'vue';
import type { CurriculumStatement, CurriculumStatements } from '@/store/map-store';

export type ContentLayoutItemType =
  | 'text'
  | 'resource'
  | 'curriculumLink'
  | 'comment'
  | 'taskList'
  | 'video'
  | 'youtube'
  | 'fileUpload'
  | 'link'
  | 'image'
  | 'nasbttMentorModule'
  | 'nasbttTraineeModule'
  | 'scormPackage'
  | 'goReactAssignment'
  | 'columns';

export interface ContentLayout {
  sections: ContentLayoutSection[];
}

export interface ContentLayoutSection {
  id: string;
  heading: string;
  items: ContentLayoutItem[];
}

export type ContentLayoutItem =
  | ContentLayoutComment
  | ContentLayoutTaskList
  | ContentLayoutCurriculumLink
  | ContentLayoutFileUpload
  | { id: string; itemType: 'text'; value: string }
  | ContentLayoutResource
  | { id: string; itemType: 'youtube'; url: string }
  | ContentLayoutVideo
  | ContentLayoutLink
  | ContentLayoutImage
  | ContentLayoutNasbttModule
  | ContentLayoutScormPackage
  | ContentLayoutGoReactAssignment
  | ContentLayoutColumns;

export interface ContentLayoutComment {
  id: string;
  itemType: 'comment';
  title: string;
  placeholder: string | null;
}
export interface ContentLayoutTaskList {
  id: string;
  itemType: 'taskList';
  title: string;
  tasks: ContentLayoutTask[];
}
export interface ContentLayoutTask {
  id: string;
  title: string;
}
export interface ContentLayoutCurriculumLink {
  id: string;
  itemType: 'curriculumLink';
  curriculumStatementId: number;
}
export interface ContentLayoutResource {
  id: string;
  itemType: 'resource';
  title: string;
  description: string;
  resourceId: number;
}
export interface ContentLayoutFileUpload {
  id: string;
  itemType: 'fileUpload';
  title: string;
  minFiles: number;
  maxFiles: number;
}
export interface ContentLayoutVideo {
  id: string;
  itemType: 'video';
  title: string;
  resourceId: number;
}
export interface ContentLayoutLink {
  id: string;
  itemType: 'link';
  title: string;
  description: string | null;
  url: string;
  openType: 'inline' | 'newTab';
}
export interface ContentLayoutImage {
  id: string;
  itemType: 'image';
  description: string;
  resourceId: number;
  displayType: 'fullWidth' | 'scaled';
  widthPercentage: number;
  alignment: 'left' | 'center' | 'right';
  backgroundColor: string;
}
export interface ContentLayoutNasbttModule {
  id: string;
  itemType: 'nasbttMentorModule' | 'nasbttTraineeModule';
  publicationCode: string;
}
export interface ContentLayoutScormPackage {
  id: string;
  itemType: 'scormPackage';
  scormPackageId: number;
}
export interface ContentLayoutGoReactAssignment {
  id: string;
  itemType: 'goReactAssignment';
  title: string;
  goReactTemplateId: number;
}
export interface ContentLayoutColumns {
  id: string;
  itemType: 'columns';
  alignment: 'top' | 'center' | 'bottom';
  left?: ContentLayoutColumnsItem;
  right?: ContentLayoutColumnsItem;
}
export type ContentLayoutColumnsItem = Exclude<ContentLayoutItem, ContentLayoutColumns>;

export interface ContentLayoutCompletionComment {
  comment: string | null;
  templateId: string;
}

export interface ContentLayoutCompletionTask {
  completed: boolean;
  templateId: string;
}

export interface ContentLayoutCompletionFile {
  fileId: string;
  title: string;
  templateId: string;
  unsaved?: boolean;
}

export function itemIsComment(item: ContentLayoutItem): item is ContentLayoutComment {
  return item.itemType === 'comment';
}

export function itemIsTaskList(item: ContentLayoutItem): item is ContentLayoutTaskList {
  return item.itemType === 'taskList';
}

export function itemIsColumns(item: ContentLayoutItem): item is ContentLayoutColumns {
  return item.itemType === 'columns';
}

export function itemIsCurriculumLink(item: ContentLayoutItem): item is ContentLayoutCurriculumLink {
  return item.itemType === 'curriculumLink';
}

export function itemIsGoReactAssignment(item: ContentLayoutItem): item is ContentLayoutGoReactAssignment {
  return item.itemType === 'goReactAssignment';
}

// For helping set up previews of a content layout or for options API (where you don't want refs)
export function mapContentLayoutToCompletionComments<T>(contentLayout: ContentLayout, addId: (id: string) => T) {
  return getContentLayoutItemsOfType(contentLayout, itemIsComment).map(c => ({
    ...addId(c.id),
    comment: '',
    templateId: c.id,
  }));
}

export function mapContentLayoutToCompletionTasks<T>(contentLayout: ContentLayout, addId: (id: string) => T) {
  return getContentLayoutItemsOfType(contentLayout, itemIsTaskList)
    .flatMap(i => i.tasks)
    .map(t => ({ ...addId(t.id), completed: false, templateId: t.id }));
}

// For helping set up completion og a content layout when they entity doesn't exist e.g. creating a reflection
export function createContentLayoutCompletionCommentsFromTemplate<T>(
  contentLayout: ContentLayout,
  addId: (id: string) => T
) {
  const comments = ref<ContentLayoutCompletionComment[]>(mapContentLayoutToCompletionComments(contentLayout, addId));
  const commentsDirty = computed(() => {
    const contentLayoutComments = getContentLayoutItemsOfType(contentLayout, itemIsComment);
    return comments.value.some(c => {
      const clc = contentLayoutComments.find(x => x.id === c.templateId);
      return !!c.comment && clc!.placeholder !== c.comment;
    });
  });

  return { comments, commentsDirty };
}

export function createContentLayoutCompletionTasksFromTemplate<T>(
  contentLayout: ContentLayout,
  addId: (id: string) => T
) {
  const tasks = ref<ContentLayoutCompletionTask[]>(mapContentLayoutToCompletionTasks(contentLayout, addId));
  const tasksDirty = computed(() => tasks.value.some(t => t.completed));

  return { tasks, tasksDirty };
}

export function createContentLayoutCompletionFilesFromTemplate() {
  const files = ref<ContentLayoutCompletionFile[]>([]);
  const filesDirty = computed(() => files.value.length > 0);
  return { files, filesDirty };
}

// For helping set up completion of a content layout when the entity always already exists e.g. staff training modules
export function createContentLayoutCompletionComments<T extends { comment: string }>(
  completionComments: Ref<T[]>,
  getTemplateId: (completionComment: T) => string
) {
  const comments = ref<ContentLayoutCompletionComment[]>([]);
  watch(completionComments, () => {
    comments.value = completionComments.value.map(c => ({
      ...c,
      templateId: getTemplateId(c),
    }));
  });
  comments.value = completionComments.value.map(c => ({
    ...c,
    templateId: getTemplateId(c),
  }));

  const commentsDirty = computed(() => {
    return completionComments.value.some(
      c => comments.value.find(x => x.templateId === getTemplateId(c))?.comment !== c.comment
    );
  });
  return { comments, commentsDirty };
}

export function createContentLayoutCompletionTasks<T extends { completed: boolean }>(
  completionTasks: Ref<T[]>,
  getTemplateId: (completionTask: T) => string
) {
  const tasks = ref<ContentLayoutCompletionTask[]>([]);
  watch(completionTasks, () => {
    tasks.value = completionTasks.value.map(t => ({
      ...t,
      templateId: getTemplateId(t),
    }));
  });
  tasks.value = completionTasks.value.map(t => ({
    ...t,
    templateId: getTemplateId(t),
  }));

  const tasksDirty = computed(() =>
    completionTasks.value.some(t => tasks.value.find(x => x.templateId === getTemplateId(t))?.completed !== t.completed)
  );
  return { tasks, tasksDirty };
}

export function createContentLayoutCompletionFiles<T extends { fileId: string; title: string }>(
  completionFiles: Ref<T[]>,
  getTemplateId: (completionFile: T) => string
) {
  const files = ref<ContentLayoutCompletionFile[]>([]);
  watch(completionFiles, () => {
    files.value = completionFiles.value.map(f => ({
      ...f,
      templateId: getTemplateId(f),
    }));
  });
  files.value = completionFiles.value.map(f => ({
    ...f,
    templateId: getTemplateId(f),
  }));

  const filesDirty = computed(() => {
    const fileIds = files.value.map(f => f.fileId);
    return (
      completionFiles.value.length !== files.value.length ||
      completionFiles.value.some(f => !fileIds.includes(f.fileId))
    );
  });
  return { files, filesDirty };
}

export function safelyGetCurriculumStatement(
  item: ContentLayoutCurriculumLink,
  curriculumStatements: CurriculumStatements
): CurriculumStatement {
  const emptyCurriculumStatement = {
    id: -1,
    code: '',
    statement: '',
    student_visible: true,
    hidden: false,
    subject_id: null,
    theme_id: -1,
    theme_order: 0,
    practice_content_layout: { sections: [] },
    theory_content_layout: { sections: [] },
    student_cohort_course_weeks: [],
  };
  if (!curriculumStatements) return emptyCurriculumStatement;
  // Some sentry errors suggest that `curriculumStatements[item.curriculumStatementId]` can be null, but don't know why
  return curriculumStatements[item.curriculumStatementId] || emptyCurriculumStatement;
}

export function hasHiddenCurriculumStatements(
  contentLayout: ContentLayout,
  curriculumStatements: CurriculumStatements
) {
  return getContentLayoutItemsOfType(contentLayout, itemIsCurriculumLink)
    .map(cs => curriculumStatements[cs.curriculumStatementId])
    .some(cs => cs.hidden);
}

export function contentLayoutDirty(x: ContentLayout | null | undefined, y: ContentLayout | null | undefined) {
  return JSON.stringify(x) !== JSON.stringify(y);
}

export function hasComment(
  commentItem: ContentLayoutItem,
  contentLayout: ContentLayout,
  comments: ContentLayoutCompletionComment[]
) {
  const comment = comments.find(c => c.templateId === commentItem.id);
  const layoutComment = getContentLayoutItemsOfType(contentLayout, itemIsComment).find(i => i.id === commentItem.id);

  return !!comment?.comment && comment.comment !== layoutComment!.placeholder && comment.comment !== '';
}

export function getContentLayoutItemsOfType<T extends ContentLayoutItem>(
  contentLayout: ContentLayout,
  itemIsType: (item: ContentLayoutItem) => item is T
) {
  return contentLayout.sections.flatMap(s => {
    const items = s.items.filter(itemIsType);
    s.items.filter(itemIsColumns).forEach(c => {
      if (c.left && itemIsType(c.left)) items.push(c.left);
      if (c.right && itemIsType(c.right)) items.push(c.right);
    });
    return items;
  });
}
