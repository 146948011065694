<template>
  <div v-if="props.staff" class="pt-6">
    <div class="d-flex align-center">
      <mosaic-text-field
        v-model="email"
        prepend-icon="mdi-at"
        label="Email"
        required
        :rules="[isValidEmail]"
        persistent-placeholder
        class="w-100"
        :readonly="props.staff.user.activated || !props.canEditDetails"
      />
      <account-locked-chip v-if="props.staff.user.account_locked" class="pl-2" />
      <email-status-chip
        v-if="isEmailVerificationOnForSelectedInstitution && !props.staff.user.is_demo"
        class="ml-2"
        :email-verified="props.staff.user.email_verified"
        :email-bounced="props.staff.user.email_bounced"
        :opted-out-of-emails="props.staff.user.opted_out_of_emails"
      />
      <div v-if="props.staff.user.activated && props.canEditDetails && props.showEditDetailsActions" class="ml-2">
        <mosaic-icon-button icon="pencil" @click.prevent="editEmail" :disabled="!props.canEditDetails" />
      </div>
    </div>
    <mosaic-text-field
      label="Name"
      v-model="name"
      prepend-icon="mdi-account"
      required
      type="text"
      :readonly="!props.canEditDetails || !props.showEditDetailsActions"
    />
    <div class="d-flex align-center" v-if="props.canEditDetails && props.showEditDetailsActions">
      <div>
        <mosaic-disabled-tooltip
          :disabled="disableDemoAccountCheckbox"
          tooltip="This Instructor shares their account with another Institution and demo Instructors cannot be in multiple Institutions"
        >
          <template #default>
            <mosaic-checkbox
              :disabled="disableDemoAccountCheckbox"
              v-model="isDemo"
              no-icon
              class="pr-2"
              label="Demo account"
              name="is-demo"
            />
          </template>
        </mosaic-disabled-tooltip>
      </div>
      <mosaic-help>
        <div>Demo accounts are intended for testing or demonstrating functionality</div>
        <div>and will not trigger any automated emails from Mosaic.</div>
      </mosaic-help>
    </div>
    <force-microsoft-sso-checkbox
      v-model="forceMicrosoftSso"
      :disabled="
        props.staff.user.force_microsoft_sso &&
        props.staff.user.in_multiple_institutions &&
        props.canEditDetails &&
        props.showEditDetailsActions
      "
      :readonly="!props.canEditDetails || !props.showEditDetailsActions"
      type="staff"
    />
    <mosaic-checkbox
      v-if="staff.user.profile_picture_updated_at && selectedInstitution.config.show_profile_pictures"
      v-model="hideProfilePicture"
      no-icon
      label="Hide profile picture"
      name="hide-profile-picture"
      hide-details
      :readonly="!props.canEditDetails"
      help-text="Hide this Instructor's profile picture for everyone in your Institution"
      positive-help-text="This Instructor's profile picture is hidden for everyone in this Institution"
      negative-help-text="This Instructor's profile picture is showing for everyone in this Institution"
    />
    <mosaic-error-alert :override-error-message="updateError" />
    <div class="text-right" v-if="props.canEditDetails && props.showEditDetailsActions">
      <mosaic-btn
        :disabled="processing || !props.canEditDetails || !props.showEditDetailsActions || !canSave"
        :loading="processing"
        color="primary"
        @click.prevent="updateStaff()"
        >Save</mosaic-btn
      >
    </div>
  </div>

  <mosaic-snackbar v-model="updateSuccessSnackbar" color="success" :message="updateSuccess" />

  <mosaic-save-dialog
    :save="submitEditEmail"
    :save-errors="{ email_conflict: 'An instructor with that email already exists' }"
    action="save"
    object-type="email"
    :can-save="canSaveEmail"
    v-model:active="editEmailDialog.active"
    title="Edit Email"
  >
    <mosaic-alert class="mb-4" type="warning"
      >The Instructor's email is used to login. Please let them know if you change it. They will need to verify this
      email when they next login.</mosaic-alert
    >
    <mosaic-text-field :rules="[isValidEmail]" class="mt-2" v-model="editEmailDialog.email" label="Email" />
  </mosaic-save-dialog>
</template>

<script setup lang="ts">
import { ref, computed, watchEffect } from 'vue';
import { mapState, mapActions, mapGetters } from '@/store/map-store';
import EmailStatusChip from '@/components/user/EmailStatusChip.vue';
import MosaicIconButton from '@/components/library/buttons/MosaicIconButton.vue';
import { isValidEmail } from '@/utils/validations';
import AccountLockedChip from '@/components/user/AccountLockedChip.vue';
import { useRoute } from 'vue-router';
import axios from 'axios';
import { validateEmail } from '@/utils/email';
import { useApi } from '@/composables/api';
import { useInstitutionStaffStore } from '@/stores/institution-staff';
import ForceMicrosoftSsoCheckbox from '@/components/ForceMicrosoftSsoCheckbox.vue';
import type { SelectedStaff } from '@/stores/staff';

const props = withDefaults(
  defineProps<{
    staff: SelectedStaff;
    canEditDetails?: boolean;
    showEditDetailsActions?: boolean;
  }>(),
  {
    canEditDetails: false,
    showEditDetailsActions: false,
  }
);

const emit = defineEmits<{
  updateStaffData: [payload: SelectedStaff];
  'update:hideProfilePicture': [v: boolean];
}>();

const { userStaff, selectedInstitution } = mapState();
const { refreshUser } = mapActions();
const {
  actions: { clearInstitutionStaff },
} = useInstitutionStaffStore();
const route = useRoute();

const api = useApi();

const name = ref(props.staff.user.name);
const email = ref(props.staff.user.email);
const isDemo = ref(props.staff.user.is_demo);
const forceMicrosoftSso = ref(props.staff.user.force_microsoft_sso);
const hideProfilePicture = ref(props.staff.hide_profile_picture);
watchEffect(() => emit('update:hideProfilePicture', hideProfilePicture.value));

const canSave = computed(() => {
  const user = props.staff?.user;
  return (
    user &&
    ((!user.activated && user.email !== email.value && validateEmail(email.value)) ||
      user.name !== name.value ||
      user.is_demo !== isDemo.value ||
      user.force_microsoft_sso !== forceMicrosoftSso.value ||
      props.staff.hide_profile_picture !== hideProfilePicture.value)
  );
});

const disableDemoAccountCheckbox = computed(
  () =>
    !props.staff?.user.is_demo &&
    props.staff?.user.in_multiple_institutions &&
    props.canEditDetails &&
    props.showEditDetailsActions
);

// Email verification flags
const { isEmailVerificationOnForSelectedInstitution } = mapGetters();

// Update Staff
const updateError = ref('');
const processing = ref(false);
const updateSuccessSnackbar = ref(false);
const updateSuccess = ref('');

async function updateStaff() {
  updateError.value = '';
  processing.value = true;

  try {
    const staffId = route.params.id;
    const r = await api.put<unknown, SelectedStaff>(`/staff/${staffId}`, {
      name: name.value,
      email: email.value,
      is_demo: isDemo.value,
      force_microsoft_sso: forceMicrosoftSso.value,
      hide_profile_picture: hideProfilePicture.value,
    });
    emit('updateStaffData', r.data);
    clearInstitutionStaff();
    updateSuccessSnackbar.value = true;
    updateSuccess.value = 'Instructor updated';
  } catch (e: unknown) {
    if (axios.isAxiosError(e) && e.response?.status === 409) {
      updateError.value = 'This email address is already in use';
    } else {
      console.log(e);
      updateError.value = 'Sorry, cannot update this Instructor at the moment';
    }
  }
  processing.value = false;
}

// Edit email
const editEmailDialog = ref({
  active: false,
  email: '',
  errorMessage: '',
  processing: false,
});

const canSaveEmail = computed(
  () =>
    !!editEmailDialog.value.email &&
    editEmailDialog.value.email !== email.value &&
    validateEmail(editEmailDialog.value.email)
);

function editEmail() {
  editEmailDialog.value = {
    active: true,
    email: email.value ?? '',
    errorMessage: '',
    processing: false,
  };
}

async function submitEditEmail() {
  if (!validateEmail(editEmailDialog.value.email)) {
    return 'Please supply a valid email';
  }
  const r = await api.put<unknown, SelectedStaff>(`staff/${route.params.id}`, { email: editEmailDialog.value.email });
  email.value = editEmailDialog.value.email;
  emit('updateStaffData', r.data);
  clearInstitutionStaff();
  if (route.params.id === userStaff.value.id.toString()) {
    refreshUser();
  }
  editEmailDialog.value.active = false;
}
</script>
