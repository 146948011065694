<template>
  <div class="d-flex">
    <mosaic-btn v-if="showPrevious" @click="emitPrevClick" class="ml-2">
      <mosaic-icon icon="chevron-left" :color="iconColor" />
      <span v-if="prevButtonText" :class="{ [`text-${textColor}`]: textColor }">{{ prevButtonText }}</span>
    </mosaic-btn>

    <mosaic-btn v-if="showNext" :class="{ [`text-${textColor}`]: textColor }" @click="emitNextClick" class="ml-2">
      <span v-if="nextButtonText">{{ nextButtonText }}</span>
      <mosaic-icon icon="chevron-right" :color="iconColor" />
    </mosaic-btn>
  </div>
</template>

<script setup lang="ts">
withDefaults(
  defineProps<{
    prevButtonText?: string;
    nextButtonText?: string;
    showPrevious?: boolean;
    showNext?: boolean;
    iconColor?: string;
    textColor?: string;
  }>(),
  { showPrevious: false, showNext: false }
);

const emit = defineEmits<{
  (e: 'prevClick'): void;
  (e: 'nextClick'): void;
}>();

const emitPrevClick = () => emit('prevClick');
const emitNextClick = () => emit('nextClick');
</script>
