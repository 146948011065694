<template>
  <v-dialog v-model="activeVDialog" :max-width="width" @click:outside="$emit('clickOutside')">
    <v-sheet>
      <div class="dialog-body">
        <div class="d-flex px-4 mb-4 py-3 align-center text-white bg-primary">
          <div class="text-h7 flex-grow-1">
            {{ title }}
          </div>
          <slot name="header" />
        </div>
        <div v-if="!!errorMessage" class="bg-white error-section px-4 mb-4">
          <mosaic-alert class="my-0" density="compact" color="error"> {{ errorMessage }}</mosaic-alert>
        </div>

        <div class="d-flex flex-column bg-white content-section px-4 mb-2">
          <slot />
        </div>
        <div class="button-section px-2 mb-2">
          <div class="d-flex justify-end ga-4">
            <v-btn variant="text" ripple @click.prevent="close()">
              {{ closeButtonText }}
            </v-btn>
            <slot name="buttons" />
          </div>
        </div>
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script>
import $ from 'jquery';

// Deprecated: prefer MosaicDialog
export default {
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    onClose: {
      type: Function,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => {},
    },
    width: {
      type: [String, Number],
      default: 650,
    },
    closeButtonText: {
      type: String,
      default: 'Cancel',
    },
    errorMessage: {
      type: String,
      default: null,
    },
  },
  emits: ['clickOutside', 'update:active'],
  data: function () {
    return {
      activeVDialog: this.active,
    };
  },
  watch: {
    errorMessage: function (newError) {
      if (newError) {
        $('.v-dialog--active').scrollTop(0);
      }
    },
    active: function (val) {
      this.activeVDialog = val ? true : false;
    },
    activeVDialog: function (val) {
      this.$emit('update:active', val);
    },
  },
  methods: {
    close: function () {
      this.activeVDialog = false;
      this.onClose();
    },
  },
};
</script>
<style lang="css" scoped>
.button-section,
.error-section {
  flex-shrink: 0;
}
.content-section {
  overflow-y: auto;
  flex-grow: 1;
  min-height: 30%;
  position: relative;
  background: linear-gradient(white 30%, rgba(255, 255, 255, 0)) center top,
    linear-gradient(rgba(255, 255, 255, 0), white 70%) center bottom,
    radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) center top,
    radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) center bottom;
  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;
}
.dialog-body {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 80vh;
}
.error-box {
  margin: 0 !important;
}
</style>
