<template>
  <div>
    <v-card class="mb-4">
      <v-card-text>
        <div class="d-flex align-center">
          <v-text-field
            v-model="searchTerm"
            density="compact"
            hide-details
            variant="outlined"
            label="Filter Institutions by name"
            prepend-inner-icon="mdi-magnify"
          />
          <div class="pl-4">
            <mosaic-checkbox
              no-icon
              v-model="show_trials"
              class="mt-0"
              density="compact"
              label="Show trials"
              name="show-trials"
            />
          </div>
          <div class="pl-4">
            <mosaic-checkbox
              no-icon
              v-model="show_archived"
              class="mt-0"
              density="compact"
              label="Show archived"
              name="show-archived"
            />
          </div>
          <div class="flex-grow-1"></div>
          <v-btn ripple @click.prevent="createInstitutionDialog.active = true">
            <div class="d-flex align-center">
              <v-icon>mdi-plus</v-icon>
              <span>Institution</span>
            </div>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-text>
        <v-list-subheader class="pl-6">Institutions</v-list-subheader>
        <v-list>
          <template v-for="i in filteredInstitutions" :key="i.id">
            <v-list-item ripple @click.prevent="institutionClick(i.id)">
              <template #prepend>
                <v-avatar>
                  <v-icon>mdi-school</v-icon>
                </v-avatar>
              </template>
              <div class="d-flex align-center">
                <div>
                  <v-list-item-title>{{ i.name }}</v-list-item-title>
                  <v-list-item-subtitle>
                    Storage: {{ i.storage_type }} - Standard Set:
                    {{ i.standard_set.name }}
                  </v-list-item-subtitle>
                </div>
                <div class="flex-grow-1"></div>
                <div class="pr-4 flex-shrink-0">
                  <v-chip class="mr-2" v-if="i.is_trial" color="accent">Trial</v-chip>
                  <v-chip class="mr-2" v-if="i.status === 'archived'" color="secondary">Archived</v-chip>
                  <v-chip class="mr-2" v-else color="blue">Active</v-chip>
                  <v-chip class="mr-2" v-if="i.early_careers" color="purple">AB</v-chip>
                  <v-chip class="mr-2" v-else color="primary">ITT</v-chip>
                  <v-chip v-if="i.region === 'english'" color="red" variant="outlined">Eng</v-chip>
                  <v-chip v-else color="yellow">Aus</v-chip>
                </div>
                <v-list-item-action>
                  <ndt-icon-button icon="pencil" tooltip="Edit Institution" @click.prevent="editInstitution(i)" />
                </v-list-item-action>
                <v-list-item-action>
                  <v-icon>mdi-chevron-right</v-icon>
                </v-list-item-action>
              </div>
            </v-list-item>
          </template>
        </v-list>
      </v-card-text>
    </v-card>

    <ndt-dialog
      v-model:active="createInstitutionDialog.active"
      title="Create Institution"
      :error-message="createInstitutionDialog.error"
    >
      <v-text-field v-model="createInstitutionDialog.name" name="create-institution-name" label="Name" type="text" />
      <v-select v-model="createInstitutionDialog.storage_type" label="Storage Type" :items="storageTypes" />
      <v-select
        v-model="createInstitutionDialog.standard_set"
        label="Standard Set"
        :items="unassignedStandardSets"
        no-data-text="No unassigned standard sets. Create a new one or copy from existing"
      >
        <template #item="{ item, props }">
          <v-list-item v-bind="props" :title="item.raw.name" />
        </template>
        <template #selection="{ item }">
          <div>{{ item.raw.name }}</div>
        </template>
      </v-select>
      <v-text-field
        v-model="createInstitutionDialog.default_password_stem"
        name="default-password"
        label="Default password stem"
        :error-messages="passwordIsCommonlyUsed ? ['This default password stem creates a commonly used password'] : []"
        @keyup="onPasswordStemKeyUp"
      />
      <mosaic-checkbox
        no-icon
        v-model="createInstitutionDialog.trial"
        :label="'Create as trial (Adds dummy mentors, trainees/ECTs and targets)'"
      />
      <mosaic-checkbox
        no-icon
        :disabled="createInstitutionDialog.early_careers"
        v-model="createInstitutionDialog.mentor_training_only"
        label="Create as Mentor Training Only"
      />
      <mosaic-checkbox
        no-icon
        v-model="createInstitutionDialog.early_careers"
        :disabled="createInstitutionDialog.mentor_training_only"
        label="Create as Early Careers Institution"
      />
      <mosaic-checkbox
        v-if="createInstitutionDialog.early_careers"
        no-icon
        v-model="createInstitutionDialog.ect_reviews_happen_at_the_end_of_term"
        label="Review points happen at the end of term (as opposed to at 1/6ths of the induction period)"
      />
      <v-text-field
        v-if="createInstitutionDialog.early_careers"
        v-model="createInstitutionDialog.establishment_id"
        name="establishment-id"
        label="Establishment ID"
      />
      <mosaic-select
        v-model="createInstitutionDialog.region"
        :items="regions"
        prepend-icon="mdi-map"
        label="Region"
        name="region"
        item-title="title"
      >
      </mosaic-select>
      <template #buttons>
        <v-btn variant="text" ripple :disabled="!canAddInstitution" @click.prevent="submitCreateInstitution()">{{
          createInstitutionDialog.processing ? 'Processing...' : 'Create'
        }}</v-btn>
      </template>
    </ndt-dialog>
    <ndt-dialog
      v-model:active="editInstitutionDialog.active"
      title="Update Institution"
      :error-message="editInstitutionDialog.errorMessage"
      :on-close="
        () => {
          editInstitutionDialog.active = false;
        }
      "
    >
      <div class="flex-grow-1 px-4">
        <v-text-field v-model="editInstitutionDialog.institution.name" label="Name" hide-details></v-text-field>
      </div>
      <template #buttons>
        <v-btn variant="text" ripple :disabled="!canEditInstitution" @click.prevent="submitEditInstitution()"
          >Save</v-btn
        >
      </template>
    </ndt-dialog>
  </div>
</template>

<script>
import NdtDialog from '../../components/NdtDialog.vue';
import NdtIconButton from '../../components/NdtIconButton.vue';
import { mapState } from 'vuex';
import { refreshAdminInstitutions } from '../../models/institutions';
import { syncQueryParamsMixin } from '../../mixins/query-mixins';
import { isPasswordStemCommonlyUsed } from '../../utils/passwords';
import { useQueryStore } from '@/stores/query';

export default {
  name: 'AdminPage',
  setup() {
    const queryStore = useQueryStore();
    return { queryStore };
  },
  mixins: [
    syncQueryParamsMixin({
      show_trials: { query: 'trial', type: 'boolean' },
    }),
  ],
  data: () => ({
    storageTypes: ['One Drive', 'Google Drive', 'S3'],

    standardSets: [],
    createInstitutionDialog: {
      active: false,
      establishment_id: '',
      name: '',
      storage_type: 'S3',
      standard_set: null,
      processing: false,
      error: null,
      trial: false,
      default_password_stem: '',
      early_careers: false,
      mentor_training_only: false,
      ect_reviews_happen_at_the_end_of_term: false,
      region: 'english',
    },
    passwordIsCommonlyUsed: false,
    passwordIsCommonlyUsedBusy: false,
    searchTerm: '',
    show_trials: false,
    show_archived: false,
    editInstitutionDialog: {
      active: false,
      processing: false,
      institution: {
        name: '',
        id: null,
      },
      errorMessage: '',
    },
  }),
  created: function () {
    this.$store.commit('updateAdminInstitution', null);
    this.loadStandardSets();
  },
  computed: {
    ...mapState(['adminInstitutions', 'regions']),
    breadcrumbs() {
      return [{ text: 'Institutions' }];
    },
    canAddInstitution: function () {
      return (
        !this.createInstitutionDialog.processing &&
        !!this.createInstitutionDialog.default_password_stem &&
        !!this.createInstitutionDialog.name &&
        !!this.createInstitutionDialog.standard_set &&
        !this.passwordIsCommonlyUsedBusy &&
        !this.passwordIsCommonlyUsed
      );
    },
    filteredInstitutions() {
      return this.adminInstitutions.filter(i => {
        return (
          i.name.toLowerCase().includes(this.searchTerm.toLowerCase()) &&
          (!i.is_trial || this.show_trials) &&
          (i.status != 'archived' || this.show_archived)
        );
      });
    },
    unassignedStandardSets() {
      return this.standardSets.filter(x => x.institutions.length === 0 && !x.cohort);
    },
    canEditInstitution() {
      return !this.editInstitutionDialog.processing;
    },
  },
  methods: {
    loadStandardSets() {
      this.$api.get('/standard-sets').then(r => {
        this.standardSets = r.data;
        if (!this.createInstitutionDialog.standard_set) {
          this.createInstitutionDialog.standard_set = this.defaultStandardSet();
        }
      });
    },
    submitCreateInstitution() {
      this.createInstitutionDialog.processing = true;
      const is_trial = this.createInstitutionDialog.trial;
      this.$api.post('/institutions', this.createInstitutionDialog).then(
        () => {
          this.createInstitutionDialog = {
            active: false,
            establishment_id: '',
            name: '',
            storage_type: 'S3',
            standard_set: null,
            processing: false,
            error: null,
            trial: false,
            default_password_stem: '',
            early_careers: false,
            mentor_training_only: false,
            ect_reviews_happen_at_the_end_of_term: false,
            region: 'english',
          };
          refreshAdminInstitutions(this.$api);
          this.loadStandardSets();
          if (is_trial) this.show_trials = true;
        },
        () => {
          this.createInstitutionDialog.error = 'Sorry, cannot create this institution at the moment';
          this.createInstitutionDialog.processing = false;
        }
      );
    },
    institutionClick: function (id) {
      this.$router.push({ name: 'AdminInstitutionPage', params: { institutionId: id } });
    },
    editInstitution(institution) {
      this.editInstitutionDialog = {
        active: true,
        processing: false,
        institution: {
          name: institution.name,
          id: institution.id,
        },
        errorMessage: '',
      };
    },
    async submitEditInstitution() {
      this.editInstitutionDialog.processing = true;
      try {
        await this.$api.put(`/institutions/${this.editInstitutionDialog.institution.id}`, {
          name: this.editInstitutionDialog.institution.name,
        });
        refreshAdminInstitutions(this.$api);
        this.editInstitutionDialog = {
          active: false,
          processing: false,
          institution: {
            name: '',
            id: null,
          },
          errorMessage: '',
        };
      } catch (e) {
        console.log(e);
        this.editInstitutionDialog.processing = false;
        this.editInstitutionDialog.errorMessage = 'Sorry cannot update institution right now';
      }
    },
    defaultStandardSet: function () {
      return this.unassignedStandardSets.length > 0 ? this.unassignedStandardSets[0] : null;
    },
    async onPasswordStemKeyUp() {
      if (!this.createInstitutionDialog.default_password_stem) {
        this.passwordIsCommonlyUsed = false;
        return;
      }
      this.passwordIsCommonlyUsedBusy = true;
      this.passwordIsCommonlyUsed = false;
      this.passwordIsCommonlyUsed = await isPasswordStemCommonlyUsed(
        this.$api,
        this.createInstitutionDialog.default_password_stem
      );
      this.passwordIsCommonlyUsedBusy = false;
    },
  },
  components: { NdtDialog, NdtIconButton },
};
</script>
