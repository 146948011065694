import type { Cohort, Institution, Permission, StudentCohort, StudentUser } from '@/store/map-store';
import type { SidebarItemConfig } from './common';
import { icons } from '@/utils/icons';

export function studentSidebarConfig(
  reviewNounCapitalisedAndPluralised: string,
  traineeNounCapitalised: string,
  userStaffHasPermissionForSelectedStudent: (permission: Permission) => boolean
): SidebarItemConfig<StudentSidebarConfig>[] {
  const hasAnyPermissions = (permissions: Permission[]) => {
    return permissions.some(p => userStaffHasPermissionForSelectedStudent(p));
  };
  const targetsPage: (earlyCareers: boolean) => StudentSidebarConfig = earlyCareers => ({
    icon: icons.target,
    title: 'Targets',
    pageNameSuffix: 'TargetsListPage',
    show: (_, institution) => institution.config.early_careers === earlyCareers,
    hideForStaff: institution =>
      institution.config.early_careers && !userStaffHasPermissionForSelectedStudent('ect.assessment.view'),
    childPageNameSuffixes: ['TargetCreatePage', 'TargetPage'],
  });
  const reviewsPage: (earlyCareers: boolean) => StudentSidebarConfig = earlyCareers => ({
    icon: icons.reviewPoint,
    title: reviewNounCapitalisedAndPluralised,
    pageNameSuffix: 'ReviewsListPage',
    childPageNameSuffixes: ['ReviewPage', 'ReviewStandardPage', 'ReviewOverallPage', 'ReviewPart2Page'],
    hideForStaff: institution =>
      (institution.config.early_careers && !userStaffHasPermissionForSelectedStudent('ect.assessment.view')) ||
      (!institution.config.early_careers && !userStaffHasPermissionForSelectedStudent('student.reviews.view')),
    legacyChildPageNameSuffixes: [
      'LegacyReviewPage',
      'LegacyReviewStandardPage',
      'LegacyReviewOverallReviewPage',
      'LegacyReviewRequirementsPage',
    ],
    studentBadge: user => (user.student.unsubmitted_review_count > 0 ? user.student.unsubmitted_review_count : null),
    show: (_, institution) => institution.config.show_reviews && institution.config.early_careers === earlyCareers,
  });

  return [
    {
      icon: 'home',
      title: 'Home',
      pageNameSuffix: 'HomePage',
      hideForStaff: () => true,
      childPageNameSuffixes: ['AnnouncementsListPage', 'AnnouncementPage'],
    },
    {
      icon: icons.home,
      title: 'Details',
      pageNameSuffix: 'StudentDetailsPage',
      hideForStaff: () => !hasAnyPermissions(['Admin', 'student.details.view']),
      hideForStudents: () => true,
    },
    {
      icon: icons.manageInduction,
      title: 'Manage Induction',
      pageNameSuffix: 'EctProgressPage',
      show: (_, institution) => institution.config.early_careers,
      hideForStaff: () => !hasAnyPermissions(['Admin', 'ect.manageInduction.view']),
      hideForStudents: () => true,
    },
    {
      icon: icons.files,
      title: 'Files',
      children: [
        {
          icon: icons.filesStudent,
          title: 'My Files',
          tutorTitle: `${traineeNounCapitalised} Files`,
          pageNameSuffix: 'FilesListPage',
          tutorPageNameOverride: 'TutorStudentFilesListPage',
          show: (cohort, institution) => !institution.config.early_careers && cohort.show_student_files,
        },
        {
          icon: icons.courseFiles,
          title: 'Course Files',
          pageNameSuffix: 'CourseFilesListPage',
          show: (cohort, institution) =>
            institution.has_any_course_files && !institution.config.early_careers && cohort.show_student_files,
        },
      ],
    },
    {
      icon: icons.course,
      title: 'Course',
      children: [
        {
          icon: icons.curriculum,
          title: 'Curriculum',
          pageNameSuffix: 'CurriculumPage',
          childPageNameSuffixes: ['CurriculumStatementPage', 'CurriculumTeachingStrategyPage'],
          show: (_, __, curriculumVisible) => curriculumVisible,
        },
        {
          icon: icons.trainingPlan,
          title: 'Training Plan',
          pageNameSuffix: 'CohortCoursePage',
          childPageNameSuffixes: ['CohortCourseWeekPage'],
          show: (cohort, _) => cohort.has_course,
        },
        {
          icon: icons.mentorMeeting,
          title: 'Mentor Meetings',
          pageNameSuffix: 'MentorMeetingsListPage',
          childPageNameSuffixes: [
            'MentorMeetingPage',
            'MentorMeetingCreatePage',
            'MentorMeetingTargetCreatePage',
            'MentorMeetingTargetPage',
          ],
          show: (_, institution) => institution.config.show_mentor_meetings && !institution.config.early_careers,
        },
        {
          icon: icons.courseActivity,
          title: 'Activities',
          pageNameSuffix: 'AssignmentsListPage',
          childPageNameSuffixes: ['AssignmentPage', 'AssignmentLatestVersionPage'],
          show: (cohort, _) => cohort.show_assignments,
        },
        {
          icon: icons.calendar,
          title: 'Calendar',
          pageNameSuffix: 'CalendarPage',
          show: (_, institution) => institution.config.show_calendar_for_students,
          hideForStaff: () => !userStaffHasPermissionForSelectedStudent('student.calendar'),
        },
      ],
    },
    {
      icon: icons.development,
      title: 'Development',
      children: [
        targetsPage(false),
        {
          icon: icons.reflection,
          title: 'Reflections',
          pageNameSuffix: 'ReflectionsListPage',
          childPageNameSuffixes: ['ReflectionCreatePage', 'ReflectionPage'],
          show: (_, institution) => !institution.config.early_careers,
          hideForStaff: () => !userStaffHasPermissionForSelectedStudent('student.reflections.view'),
        },
        {
          icon: icons.lessonObservation,
          title: 'Lesson Observations',
          pageNameSuffix: 'LessonObservationsListPage',
          childPageNameSuffixes: ['LessonObservationPage', 'LessonObservationCreatePage'],
          show: (_, institution) => institution.config.show_lesson_observations && !institution.config.early_careers,
        },
      ],
    },
    targetsPage(true),
    {
      icon: icons.review,
      title: 'Review',
      children: [
        reviewsPage(false),
        {
          icon: icons.evidence,
          title: 'Evidence',
          pageNameSuffix: 'EvidenceListPage',
          show: (_, institution) => institution.config.show_evidence && !institution.config.early_careers,
        },
        {
          icon: icons.portfolio,
          title: 'Portfolio',
          pageNameSuffix: 'PortfolioOverviewPage',
          childPageNameSuffixes: ['PortfolioStandardPage'],
          show: (_, institution) =>
            institution.config.show_evidence && institution.config.show_portfolio && !institution.config.early_careers,
        },
        {
          icon: icons.proficiencies,
          title: 'Proficiencies',
          pageNameSuffix: 'ProficienciesPage',
          show: (_, institution) => institution.config.has_proficiencies && !institution.config.early_careers,
          hideForStudents: institution => !institution.config.students_see_proficiencies,
          hideForStaff: () => !userStaffHasPermissionForSelectedStudent('student.proficiencies.edit'),
        },
      ],
    },
    reviewsPage(true),
  ];
}

export interface StudentSidebarConfig {
  icon: string;
  title: string;
  tutorTitle?: string;
  pageNameSuffix: string;
  tutorPageNameOverride?: string;
  childPageNameSuffixes?: string[];
  legacyChildPageNameSuffixes?: string[];
  studentBadge?: (user: StudentUser) => number | null;
  show?: (cohort: Cohort | StudentCohort, institution: Institution, curriculumVisible: boolean) => boolean;
  // Staff and student specific overrides of `show`
  hideForStaff?: (institution: Institution) => boolean;
  hideForStudents?: (institution: Institution) => boolean;
}
