<template>
  <mosaic-save-card
    :hide-return="true"
    save-text="Next"
    :save="save"
    :can-save="canSave"
    object-type="Reflection Template"
  >
    <mosaic-create-edit-title :is-creating="true" object-type="Reflection Template" />
    <mosaic-text-field label="Name" v-model="name" prepend-icon="pencil" />
    <mosaic-text-field
      :label="`${traineeNoun} facing name`"
      v-model="studentFacingName"
      :prepend-icon="icons.student"
    />
  </mosaic-save-card>
</template>

<script setup lang="ts">
import { useApi } from '@/composables/api';
import { useInstitutionStore } from '@/stores/institution';
import { useStudentStore } from '@/stores/student';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { icons } from '@/utils/icons';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const api = useApi();

const { selectedInstitution } = useInstitutionStore();
const { traineeNoun } = useStudentStore();

setBreadcrumbs([
  { text: 'Reflection Templates', to: { name: 'InstitutionReflectionTemplatesListPage' } },
  { text: 'New Reflection Template' },
]);

const name = ref('');
const studentFacingName = ref('');

const canSave = computed(() => !!name.value && !!studentFacingName.value);

async function save() {
  const r = await api.post<{ name: string; studentFacingName: string }, { id: number }>(
    `/institutions/${selectedInstitution.value.id}/reflection-templates`,
    {
      name: name.value,
      studentFacingName: studentFacingName.value,
    }
  );
  router.push({
    name: 'InstitutionReflectionTemplateEditPage',
    params: { templateId: r.data.id },
  });
}
</script>
