import { useApi } from '@/composables/api';
import { useInstitutionStore } from '@/stores/institution';
import { ref } from 'vue';

export interface ReflectionTemplateSlimResponse {
  id: number;
  name: string;
  studentFacingName: string;
  cohorts: { id: number; name: string }[];
}

export function useLoadReflectionTemplates() {
  const api = useApi();
  const { selectedInstitution } = useInstitutionStore();
  const templates = ref<ReflectionTemplateSlimResponse[]>([]);

  async function load() {
    const r = await api.get<ReflectionTemplateSlimResponse[]>(
      `/institutions/${selectedInstitution.value.id}/reflection-templates`
    );
    templates.value = r.data;
  }

  return { templates, load };
}
