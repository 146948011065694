<template>
  <div>
    <mosaic-tab-card-page
      object-type="Reflections"
      :object-type-is-plural="true"
      title="Reflections"
      :headers="tabHeaders"
      :load="load"
      type="table"
      @tab-selected="tab = $event"
    >
      <template #monitoring-tab-item v-if="loadReflectionsError">
        <mosaic-load-error object-type="Reflections" :object-type-is-plural="true" @retry="loadReflections" />
      </template>

      <template #monitoring-tab-item v-else-if="loadReflectionsProcessing">
        <mosaic-loading-card type="inline-table" />
      </template>

      <template #monitoring-tab-item v-else>
        <div class="d-flex align-center mb-6">
          <div>
            <mosaic-select
              :prepend-icon="icons.artefactType"
              :items="reflectionTemplateOptions"
              v-model="selectedTemplateId"
              item-title="name"
              item-value="id"
              hide-details
            />
          </div>
          <mosaic-cohort-monitoring-filters
            :students="selectedCohortStudents"
            @update:filtered-student-ids="filteredStudentIds = $event"
          />
          <monitoring-placement-select
            v-if="hasPlacements"
            v-model:selected-placement-id="selectedPlacementId"
            class="ml-4"
          />
        </div>

        <mosaic-cohort-weekly-monitoring-filter-table
          v-if="selectedPlacement"
          :placement-id="selectedPlacementId"
          object-type-pluralised="Reflections"
          :rows="monitoringRows"
          :headers="selectedPlacement.headers"
          :name-click-route="nameClickRoute"
        />

        <div v-else class="py-4">
          This Cohort does not have any Monitoring Windows configured. These can be added/updated in Cohort Settings.
        </div>
      </template>

      <template #templates-tab-item>
        <mosaic-tab-item-list
          :items="selectedCohort.reflection_templates"
          mosaic-key="templates"
          :action="
            userStaffHasPermission('Admin') ? { icon: 'plus', text: 'Template', click: addTemplates } : undefined
          "
        >
          <template #item="{ item }">
            <mosaic-list-item
              :icon="icons.reflection"
              :title="item.name"
              :subtitle="item.student_facing_name"
              :to="
                userStaffHasPermission('Admin')
                  ? {
                      name: 'InstitutionReflectionTemplateEditPage',
                      params: { templateId: item.id, institutionId: selectedCohort.institution_id },
                    }
                  : undefined
              "
              :actions="
                userStaffHasPermission('Admin')
                  ? [
                      {
                        icon: 'delete',
                        tooltip: 'Unassign this Reflection Template from this Cohort',
                        disabled: selectedCohort.reflection_templates.length === 1 || cohortLoading,
                        disabledTooltip: cohortLoading
                          ? undefined
                          : 'Cannot remove the last Reflection Template for this Cohort. All Cohorts must have at least one Reflection Template.',
                        click: () => unassignTemplate(item),
                      },
                    ]
                  : []
              "
            />
          </template>
        </mosaic-tab-item-list>
      </template>
    </mosaic-tab-card-page>

    <mosaic-save-dialog
      v-model:active="addTemplatesDialog.active"
      title="Add Reflection Templates"
      object-type="Reflection Templates"
      :object-type-is-plural="true"
      :save="saveAddTemplates"
      :can-save="addTemplatesDialog.templateIds.length > 0"
      action="Add"
    >
      <mosaic-select
        :items="unassignedTemplates"
        label="Reflection Templates"
        item-title="name"
        :prepend-icon="icons.reflection"
        item-value="id"
        multiple
        v-model="addTemplatesDialog.templateIds"
      />
    </mosaic-save-dialog>

    <mosaic-delete-dialog
      v-model:active="unassignTemplateDialog.active"
      action="unassign"
      object-type="Reflection Template"
      :object-name="unassignTemplateDialog.name"
      :url="`/reflection-templates/${unassignTemplateDialog.id}/cohorts/${selectedCohort.id}`"
      :error-code-map="{
        cannot_remove_last_template:
          'Cannot remove the last Reflection Template for this Cohort. All Cohorts must have at least one Reflection Template.',
      }"
      @delete="loadCohortInBackground()"
    />
  </div>
</template>

<script setup lang="ts">
import { useCohortStore } from '@/stores/cohort';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { computed, ref, watch } from 'vue';
import type { Row } from '@/components/monitoring/mosaic-table';
import MosaicCohortWeeklyMonitoringFilterTable from '@/components/monitoring/MosaicCohortWeeklyMonitoringFilterTable.vue';
import type { BaseStudentRow, MonitoringPlacement } from '../utils/monitoring';
import { useWeeklyMonitoring } from '../utils/monitoring';
import MonitoringPlacementSelect from '@/components/monitoring/MonitoringPlacementSelect.vue';
import { useApi } from '@/composables/api';
import { hasPermissionForSelectedInstitution } from '@/composables/permission';
import { icons } from '@/utils/icons';
import type { Cohort } from '@/store/map-store';
import { useLoadReflectionTemplates } from '@/pages/reflections/reflection-templates';
import { withProcessingAndError } from '@/composables/processing-and-errors';
import { syncQueryParam } from '@/composables/query';

const api = useApi();
const {
  hasPlacements,
  selectedCohort,
  selectedCohortStudents,
  actions: { loadCohort },
} = useCohortStore();

// Page Setup

setBreadcrumbs([
  {
    text: 'Development Reflections',
  },
]);

type ReflectionCount = Record<string, number>;
type StudentRow = BaseStudentRow & ReflectionCount;

async function load() {
  await Promise.all([loadReflections(), loadTemplates()]);
}

// Tabs
const tabHeaders = computed(() => {
  const h = [
    {
      text: 'Monitoring',
      key: 'monitoring',
    },
  ];

  if (hasPermissionForSelectedInstitution('Admin')) {
    h.push({
      text: 'Templates',
      key: 'templates',
    });
  }
  return h;
});
const tab = ref('monitoring');

// Load Reflections

type MonitoringApiResponse = MonitoringPlacement<StudentRow>[];

const placements = ref<MonitoringPlacement<StudentRow>[]>([]);

const {
  action: loadReflections,
  error: loadReflectionsError,
  processing: loadReflectionsProcessing,
} = withProcessingAndError(async () => {
  let url = `/cohorts/${selectedCohort.value.id}/reflection-monitoring`;
  if (selectedTemplateId.value != -1) {
    url += `?templateId=${selectedTemplateId.value}`;
  }
  const response = await api.get<MonitoringApiResponse>(url);
  placements.value = response.data;
});

// Monitoring
const selectedPlacementId = ref(-1);
const filteredStudentIds = ref(selectedCohortStudents.value.map(s => s.id));

const selectedTemplateId = ref(-1);
syncQueryParam(selectedTemplateId, 'templateId', 'integer');
const reflectionTemplateOptions = computed(() => {
  return [{ name: 'All Templates', id: -1 }, ...selectedCohort.value.reflection_templates];
});

watch(selectedTemplateId, loadReflections);

const { selectedPlacement, monitoringRows } = useWeeklyMonitoring<StudentRow>(
  placements,
  selectedPlacementId,
  filteredStudentIds
);

type Student = {
  id: number;
  name: string;
};

function nameClickRoute(row: Row) {
  return {
    name: 'ReflectionsListPage',
    params: {
      studentId: (row as Student).id.toString(),
    },
  };
}

// #region templates
const { templates, load: loadInstitutionTemplates } = useLoadReflectionTemplates();

async function loadTemplates() {
  if (hasPermissionForSelectedInstitution('Admin').value) {
    return loadInstitutionTemplates();
  }
}

const unassignedTemplates = computed(() => {
  const assignedId = selectedCohort.value.reflection_templates.map(t => t.id);
  return templates.value.filter(t => !assignedId.includes(t.id));
});

const addTemplatesDialog = ref({ active: false, templateIds: [] as number[] });

function addTemplates() {
  addTemplatesDialog.value.active = true;
}

async function saveAddTemplates() {
  await api.post(`/cohorts/${selectedCohort.value.id}/reflection-templates`, {
    templateIds: addTemplatesDialog.value.templateIds,
  });
  await loadCohortInBackground();
}

const { action: loadCohortInBackground, processing: cohortLoading } = withProcessingAndError(() =>
  loadCohort({ cohortId: selectedCohort.value.id, force: true, throwError: true, background: true })
);

const unassignTemplateDialog = ref({ active: false, name: '', id: -1 });

function unassignTemplate(t: Cohort['reflection_templates'][0]) {
  unassignTemplateDialog.value = {
    active: true,
    name: t.name,
    id: t.id,
  };
}
// #endregion
</script>
