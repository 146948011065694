import { isArrayBuffer } from 'lodash';
import SparkMD5 from 'spark-md5';

export function fileMD5(file: File) {
  return new Promise((resolve, reject) => {
    const chunkSize = 2097152, // Read in chunks of 2MB
      chunks = Math.ceil(file.size / chunkSize),
      spark = new SparkMD5.ArrayBuffer(),
      fileReader = new FileReader();
    let currentChunk = 0;

    fileReader.onload = e => {
      // e.target.result should be an ArrayBuffer. I'm not sure what has happened if it's not.
      if (e.target && e.target.result && isArrayBuffer(e.target.result)) {
        spark.append(e.target.result);
      }
      currentChunk++;

      if (currentChunk < chunks) {
        loadNext();
      } else {
        const hash = hexToBase64(spark.end());
        resolve(hash);
      }
    };

    fileReader.onerror = () => {
      reject(`Cannot calculate MD5 of file`);
    };

    function loadNext() {
      const start = currentChunk * chunkSize,
        end = start + chunkSize >= file.size ? file.size : start + chunkSize;

      fileReader.readAsArrayBuffer(File.prototype.slice.call(file, start, end));
    }

    loadNext();
  });
}

function hexToBase64(hexstring: string) {
  return btoa(
    hexstring
      .match(/\w{2}/g)!
      .map(function (a) {
        return String.fromCharCode(parseInt(a, 16));
      })
      .join('')
  );
}
