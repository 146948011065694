export const fteRateRules = [
  (v: number | string) => isValidNumberOrNumberString(v, (v: number) => v > 0) || 'Fraction should be greater than 0',
  (v: number | string) => isValidNumberOrNumberString(v, (v: number) => v <= 1) || 'Fraction should be less than 1',
];

export const termsCompletedRules = [
  (v: number | string) =>
    isValidNumberOrNumberString(v, (v: number) => v >= 0) || 'Terms completed should be greater than 0',
  (v: number | string) =>
    isValidNumberOrNumberString(v, (v: number) => v < 6) || 'Terms completed should be less than 6',
];

function isValidNumberOrNumberString(v: number | string, test: (v: number) => boolean) {
  if (typeof v === 'string') {
    return test(parseFloat(v));
  } else {
    return test(v);
  }
}

export const absencesRules = [
  (v: number | string) => isValidNumberOrNumberString(v, (v: number) => v >= 0) || 'Absences should be positive number',
];

export const adminTrnRules = [
  (v: string) =>
    trnValidation(v) || !v || 'TRN should be 7 digits (including any leading zeros, but no special characters)',
];
export const schoolTrnRules = [
  (v: string) => trnValidation(v) || 'TRN should be 7 digits (including any leading zeros, but no special characters)',
];
export const schoolUrnRules = [
  (v: string) =>
    (v && new RegExp(/^\d{6}$/).test(v)) ||
    'URN should be 6 digits (including any leading zeros, but no special characters)',
];

//Need to allow zeros for selects hence separate falsey checks
export const requiredText = (v: string | null | undefined) =>
  (v !== null && v !== undefined && v !== '') || 'This field is required';

//Currently we only use function-based rules which take a string or number as argument
export type Rule = (value: string | number) => boolean | string;
export function validationsPass(rules: Rule[], value: string | number) {
  return rules.every(r => typeof r(value) !== 'string' && r(value) !== false);
}

export function isValidEmail(email: string) {
  const re = /^[\w+\-.']+@[a-z\d-]+(\.[a-z\d-]+)*\.[a-z]+$/i;
  return re.test(email) || 'Please enter a valid email address';
}

export function requiredListInput(multiple: boolean) {
  if (multiple) return (v: unknown[]) => v.length > 0 || 'Please select at least one option from the list';
  else return (v: unknown) => (v !== null && v !== undefined && v !== '') || 'Please select an option from the list';
}

export const trnValidation = (x: string) => x && new RegExp(/^\d{7}$/).test(x);
