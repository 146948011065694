<template>
  <div v-if="!disabled">
    <slot :disabled="false" />
  </div>
  <v-tooltip v-else location="top">
    <template #activator="{ props }">
      <div v-bind="props">
        <slot :disabled="true" />
      </div>
    </template>
    <div v-if="isSlotEmpty($slots.tooltip)">{{ tooltip }}</div>
    <slot name="tooltip" v-else />
  </v-tooltip>
</template>

<script>
import { isSlotEmpty } from '@/utils/mosaic-slots';
export default {
  name: 'MosaicDisabledTooltip',
  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
    tooltip: {
      type: String,
    },
  },
  methods: {
    isSlotEmpty,
  },
};
</script>
