<template>
  <div>
    <mosaic-loading-card v-if="busy" type="card" />
    <mosaic-load-error-card v-if="error" :object-type="reviewNounCapitalised" @retry="loadReview" />
    <mosaic-stepper-card
      v-if="!error && !busy"
      :steps="['Details', traineeNounCapitalisedAndPluralised]"
      :can-continue="() => canContinue"
      :return-to="{
        name: 'CohortReviewsPage',
      }"
    >
      <template #title>
        <mosaic-card-title>
          {{ isEditing ? 'Edit ' : 'New ' }}{{ reviewNounCapitalised }}
          <template #chip>
            <v-chip v-if="isEditing" :color="'primary'" class="ml-2">
              {{ 'Live with ' + traineeNounCapitalisedAndPluralised }}
            </v-chip>
          </template>
        </mosaic-card-title>
      </template>

      <template #item.1>
        <v-alert
          v-if="!reviewTemplatesBusy && reviewTemplates.length === 0"
          type="warning"
          density="compact"
          variant="outlined"
          class="mt-2"
          style="cursor: pointer"
          @click.prevent="goToReviewTemplates()"
        >
          To create a new {{ reviewNounCapitalised }} requires a {{ reviewNounCapitalised }} Template, however, no
          templates have been created yet. Click here to create one.
        </v-alert>
        <div>
          <v-text-field v-model="name" prepend-icon="mdi-pencil" name="create-review-name" label="Name" type="text" />
          <mosaic-text-field
            v-if="review && review.note"
            v-model="review.note"
            name="note"
            label="Note"
            :readonly="true"
          />
          <mosaic-date-picker v-model:date="dueDate" label="Due date" :exact-width="false" />
          <v-select
            v-if="!isEditing"
            v-model="selectedReviewTemplateId"
            prepend-icon="mdi-scissors-cutting"
            :label="reviewNounCapitalised + ' Template'"
            :items="reviewTemplates"
            item-title="name"
            item-value="id"
            :disabled="isEditing"
          >
          </v-select>
          <div v-else class="d-flex align-center mb-6">
            <v-icon class="mt-5">mdi-scissors-cutting</v-icon>
            <div class="pl-2">
              <div class="text-caption">{{ reviewNounCapitalised }} Template</div>
              <a class="text-link" @click.prevent="goToReviewTemplate()">{{
                selectedReviewTemplate && selectedReviewTemplate.name
              }}</a>
            </div>
          </div>

          <div v-if="reviewType" class="mb-4 d-flex align-center">
            <div class="pl-8">
              <div class="text-caption">{{ reviewNounCapitalised }} Type</div>
              <div class="text-body-1">{{ reviewTypeItems.find(x => x.value === reviewType).title }}</div>
            </div>
          </div>
        </div>

        <div v-if="selectedReviewTemplate">
          <mosaic-card-heading>Permissions</mosaic-card-heading>
          <div v-if="isEditing">
            These permissions were copied from "{{ selectedReviewTemplate.name }}" template on
            {{ formatDate(review.created_at) }}.
            <span v-if="havePermissionsChangedFromTemplate"
              >These have since been updated for this {{ reviewNounCapitalised }}.</span
            >
          </div>
          <v-alert v-else variant="outlined" type="info" class="mt-2">
            Please check these permissions. If anything needs changing before you assign this
            {{ reviewNounCapitalised }}, then edit them in the {{ reviewNounCapitalised }} Template.
          </v-alert>
          <div class="ml-1 mr-1 mb-1 mt-4">
            <v-expansion-panels multiple :model-value="startingOpenExpansionPanels">
              <v-expansion-panel>
                <v-expansion-panel-title>
                  <mosaic-card-subheading icon="mdi-lock-open-outline"
                    >{{ reviewNounCapitalised }} Permissions</mosaic-card-subheading
                  >
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  <div>
                    <div class="font-weight-bold">Approvers</div>
                    <tutor-admin-review-edit-page-permission-checkboxes
                      label="Who can approve"
                      :is-editing="isEditing"
                      :is-trainee-an-option="false"
                      review-role-key="approver"
                      :review-roles="reviewRoles"
                      @update-review-permission="
                        ({ roleId, value }) => updateReviewPermissionEdit('Approve', roleId, value)
                      "
                    />
                  </div>
                  <div class="pt-2">
                    <div class="font-weight-bold">Viewers</div>
                    <tutor-admin-review-edit-page-permission-checkboxes
                      v-model:can-trainee="canTraineeViewReview"
                      label="Who can view"
                      :is-editing="isEditing"
                      :trainee-disabled="isTraineeContributor"
                      review-role-key="viewer"
                      :role-disabled="r => r.contributor"
                      disabled-tooltip="is a Contributor and therefore must be a Viewer"
                      :review-roles="reviewRoles"
                      @update-review-permission="
                        ({ roleId, value }) => updateReviewPermissionEdit('Viewer', roleId, value)
                      "
                    />
                  </div>
                  <div class="pt-2">
                    <div class="font-weight-bold">Contributors</div>
                    <tutor-admin-review-edit-page-permission-checkboxes
                      label="Who are the main contributors"
                      :is-editing="isEditing"
                      :can-trainee="isTraineeContributor && canTraineeViewReview"
                      :trainee-disabled="true"
                      review-role-key="contributor"
                      :role-disabled="r => true"
                      :disabled-tooltip="`cannot be changed as a Contributor, as the ${reviewNounCapitalised} is live`"
                      :review-roles="reviewRoles"
                    />
                  </div>
                </v-expansion-panel-text>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-title>
                  <mosaic-card-subheading icon="mdi-lock-open-outline"
                    >General Section Permissions</mosaic-card-subheading
                  >
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  <div v-if="canAddTargetsOverall">
                    <div class="font-weight-bold">Targets</div>
                    <tutor-admin-review-edit-page-permission-checkboxes
                      v-model:can-trainee="canTraineeAddTargetsOverallComputed"
                      label="Who can set Targets"
                      :is-editing="isEditing"
                      :trainee-disabled="!canTraineeViewReview"
                      review-role-key="canAddTargetsOverall"
                      :review-roles="reviewRoles"
                      @update-review-permission="
                        ({ roleId, value }) => updateReviewPermissionEdit('AddTargetsOverall', roleId, value)
                      "
                    />
                  </div>
                  <div v-if="reviewOverallJudgementSetId" class="pt-2">
                    <div class="font-weight-bold">Assessment of Progress</div>
                    <tutor-admin-review-edit-page-permission-checkboxes
                      label="Who can make"
                      :is-editing="isEditing"
                      :is-trainee-an-option="false"
                      review-role-key="canMakeOverallJudgement"
                      :review-roles="reviewRoles"
                      @update-review-permission="
                        ({ roleId, value }) => updateReviewPermissionEdit('OverallJudgement', roleId, value)
                      "
                    />
                    <div class="d-flex align-center">
                      <span>Can {{ traineeNounCapitalisedAndPluralised }} view:</span>
                      <v-checkbox
                        v-model="canTraineesViewOverallJudgementComputed"
                        :label="traineeNounCapitalised()"
                        :disabled="!canTraineeViewReview || !isEditing"
                        class="ml-4"
                        density="compact"
                        color="primary"
                        hide-details
                      />
                    </div>
                  </div>
                  <div v-if="recordAbsences" class="pt-2">
                    <div class="font-weight-bold">Record Absences</div>
                    <tutor-admin-review-edit-page-permission-checkboxes
                      v-model:can-trainee="canTraineeRecordAbsencesComputed"
                      label="Who can record"
                      :is-editing="isEditing"
                      :trainee-disabled="!canTraineeViewReview"
                      review-role-key="canRecordAbsences"
                      :review-roles="reviewRoles"
                      @update-review-permission="
                        ({ roleId, value }) => updateReviewPermissionEdit('RecordAbsences', roleId, value)
                      "
                    />
                  </div>
                  <div v-if="overallStatementGroupsWithPermissions.length > 0" class="pt-2">
                    <div class="font-weight-bold pt-2">Statements</div>
                    <div v-for="group of overallStatementGroupsWithPermissions" :key="group.id" class="pl-2">
                      <div class="font-weight-bold pt-2">{{ group.name }}</div>
                      <div v-for="statement of group.overallStatements" :key="statement.id" class="pl-2">
                        <div class="font-weight-bold pt-2">{{ statement.statement }}</div>
                        <review-role-select
                          :roles="statement.editPermissions"
                          label="Who can answer"
                          label-width="130px"
                          :is-editing="isEditing"
                          disabled-tooltip="is not a Viewer"
                          @selected-roles-updated="overallStatementSelectedRolesEditUpdated(group, statement, $event)"
                        />
                        <review-role-select
                          :roles="statement.viewPermissions"
                          label="Who can view"
                          label-width="130px"
                          :is-editing="isEditing"
                          disabled-tooltip="is not a Viewer"
                          @selected-roles-updated="overallStatementSelectedRolesViewUpdated(group, statement, $event)"
                        />
                      </div>
                    </div>
                  </div>
                  <div v-if="overallCommentsComputed.length > 0" class="pt-2">
                    <div class="font-weight-bold pt-2">Comments</div>
                    <div v-for="comment of overallCommentsComputed" :key="comment.id" class="pl-2">
                      <div class="font-weight-bold pt-2">{{ comment.label }}</div>
                      <review-role-select
                        :roles="comment.editPermissions"
                        label="Who can answer"
                        :is-editing="isEditing"
                        disabled-tooltip="is not a Viewer"
                        @selected-roles-updated="overallCommentSelectedRolesUpdated(comment, $event)"
                      />

                      <div class="d-flex align-center">
                        <span class="mr-4">Mark comment as optional?</span>
                        <v-checkbox
                          :model-value="comment.optional"
                          :disabled="!isEditing"
                          density="compact"
                          hide-details
                          color="primary"
                          @update:model-value="overallCommentOptionalityChanged(comment, $event)"
                        />
                      </div>
                    </div>
                  </div>
                </v-expansion-panel-text>
              </v-expansion-panel>
              <v-expansion-panel v-if="hasReviewStandards">
                <v-expansion-panel-title>
                  <mosaic-card-subheading icon="mdi-lock-open-outline"
                    >Per {{ standardNounCapitalised }} Permissions</mosaic-card-subheading
                  >
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  <div v-if="canAddTargetsPerStandard">
                    <div class="font-weight-bold">Targets</div>
                    <tutor-admin-review-edit-page-permission-checkboxes
                      v-model:can-trainee="canTraineeAddTargetsPerStandardComputed"
                      label="Who can set Targets"
                      :is-editing="isEditing"
                      :trainee-disabled="!canTraineeViewReview"
                      review-role-key="canAddTargetsPerStandard"
                      :review-roles="reviewRoles"
                      @update-review-permission="
                        ({ roleId, value }) => updateReviewPermissionEdit('AddTargetsPerStandard', roleId, value)
                      "
                    />
                  </div>
                  <div v-if="reviewStandardJudgementSetId || reviewStandardStatementJudgementSetId" class="pt-2">
                    <div class="font-weight-bold">Assessments of Progress</div>
                    <div class="pl-2">
                      <div v-if="reviewStandardJudgementSetId" class="pt-2">
                        <div class="font-weight-bold">Overall</div>
                        <tutor-admin-review-edit-page-permission-checkboxes
                          label="Who can make"
                          :is-editing="isEditing"
                          :is-trainee-an-option="false"
                          review-role-key="canMakeStandardJudgement"
                          :review-roles="reviewRoles"
                          @update-review-permission="
                            ({ roleId, value }) => updateReviewPermissionEdit('StandardJudgement', roleId, value)
                          "
                        />
                        <div class="d-flex align-center">
                          <span>Can {{ traineeNounCapitalisedAndPluralised }} view:</span>
                          <v-checkbox
                            v-model="canTraineesViewOverallJudgementComputed"
                            :label="traineeNounCapitalised()"
                            :disabled="!canTraineeViewReview || !isEditing"
                            class="ml-4"
                            density="compact"
                            color="primary"
                            hide-details
                          />
                        </div>
                      </div>
                      <div v-if="reviewStandardStatementJudgementSetId" class="pt-2">
                        <div class="font-weight-bold">Against a set of statements</div>
                        <tutor-admin-review-edit-page-permission-checkboxes
                          label="Who can make"
                          :is-editing="isEditing"
                          :trainee-disabled="!canTraineeViewReview"
                          review-role-key="canMakeStandardStatementJudgement"
                          :review-roles="reviewRoles"
                          v-model:can-trainee="canTraineesEditStandardStatementJudgementsComputed"
                          @update-review-permission="
                            ({ roleId, value }) =>
                              updateReviewPermissionEdit('StandardStatementJudgement', roleId, value)
                          "
                        />
                      </div>
                    </div>
                  </div>

                  <div v-if="standardCommentsComputed.length > 0" class="pt-2">
                    <div class="font-weight-bold pt-2">Comments</div>
                    <div v-for="comment of standardCommentsComputed" :key="comment.id" class="pl-2">
                      <div class="font-weight-bold pt-2">{{ comment.label }}</div>
                      <review-role-select
                        :roles="comment.editPermissions"
                        label="Who can answer"
                        :is-editing="isEditing"
                        disabled-tooltip="is not a Viewer"
                        @selected-roles-updated="standardCommentSelectedRolesUpdated(comment, $event)"
                      />

                      <div class="d-flex align-center justify-start">
                        <span class="mr-4">Mark comment as optional?</span>
                        <v-checkbox
                          :model-value="comment.optional"
                          :disabled="!isEditing"
                          density="compact"
                          hide-details
                          color="primary"
                          @update:model-value="standardCommentOptionalityChanged(comment, $event)"
                        />
                      </div>
                    </div>
                  </div>
                </v-expansion-panel-text>
              </v-expansion-panel>
              <v-expansion-panel v-if="usePart2">
                <v-expansion-panel-title>
                  <mosaic-card-subheading icon="mdi-lock-open-outline">Part 2 Permissions</mosaic-card-subheading>
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  <div>
                    <div class="font-weight-bold">Part 2 Assessments</div>
                    <tutor-admin-review-edit-page-permission-checkboxes
                      label="Who can make"
                      :is-editing="isEditing"
                      :is-trainee-an-option="false"
                      review-role-key="part2"
                      :review-roles="reviewRoles"
                      @update-review-permission="
                        ({ roleId, value }) => updateReviewPermissionEdit('Part2', roleId, value)
                      "
                    />
                  </div>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </div>
      </template>

      <template #item.2>
        <review-student-select
          v-if="!selectedInstitution.config.early_careers || (selectedReviewTemplateId && dueDate)"
          v-model:selected-student-ids="selectedStudents"
          :review="review"
          :students-to-add="studentsToAdd"
          :students-to-remove="studentsToRemove"
          :is-editing="isEditing"
          :can-update="canUpdateReview"
          :can-add="canAddReview"
          :students="students"
          :processing="processing"
          :review-type="reviewType"
          :due-date="dueDate"
          @cancel-clicked="cancelClick()"
          @create-or-update-clicked="saveReview()"
        >
        </review-student-select>
        <mosaic-error-alert :override-error-message="editError" />
      </template>

      <template #actions="{ step }">
        <template v-if="step == 2">
          <v-btn
            v-if="isEditing"
            :disabled="!canUpdateReview || processing"
            color="primary"
            @click.prevent="saveReview()"
            >Update {{ reviewNounCapitalised }}</v-btn
          >
          <v-btn v-else :disabled="!canAddReview || processing" color="primary" @click.prevent="saveReview()"
            >Create {{ reviewNounCapitalised }}</v-btn
          >
        </template>
      </template>
    </mosaic-stepper-card>

    <update-course-action-dialog
      v-if="students"
      v-model:active="updateCourseActionDialog.active"
      :action-type="reviewNounCapitalised"
      :action-target-title="review?.name"
      :unactivated-student-count="unactivatedStudentCount"
      :students-to-remove="studentsToRemove"
      :students-to-add="studentsToAdd"
      :students="students"
      :selected-students="selectedStudents"
      :processing="processing"
      :error="updateCourseActionDialog.error"
      :is-editing="isEditing"
      :statuses="['approved', 'completed', 'partiallycompleted', 'started', 'notstarted']"
      @submit-clicked="submitSaveReview()"
    >
      <template #message>
        <v-alert v-if="isEditing && permissionsDirty" type="info" variant="outlined">
          You are updating permissions for this {{ reviewNounCapitalised }}. These are specific to this
          {{ reviewNounCapitalised }} and won't automatically be applied to the {{ reviewNounCapitalised }} Template (or
          other {{ reviewNounCapitalisedAndPluralised }} created from the same template).
        </v-alert>
        <v-alert
          v-if="isEditing && dueDateDirty && review && review.student_reviews.some(x => x.due_date !== review.due_date)"
          type="info"
          variant="outlined"
        >
          You are updating the due date for this {{ reviewNounCapitalised }}.
          {{ traineeNounCapitalisedAndPluralised }} with individually set due dates will not be affected by this change.
        </v-alert>
      </template>

      <template #status-list>
        <student-review-status-list
          :statuses="statuses"
          :students="
            studentsToBeRemoved.map(s => ({
              id: s.id,
              userId: s.id,
              displayName: s.display_name,
              email: s.email,
              profilePictureUpdatedAt: s.profile_picture_updated_at,
              hideProfilePicture: s.hide_profile_picture,
              studentReview: s.studentReview,
            }))
          "
          :action-target-title="review?.name"
        />
      </template>
    </update-course-action-dialog>

    <unsaved-changes-dialog
      v-model:unsaved-changes-dialog="unsavedChangesDialog"
      :object-type="reviewNounCapitalised"
    />
  </div>
</template>

<script>
import ReviewStudentSelect from '@/components/ReviewStudentSelect.vue';
import UpdateCourseActionDialog from '@/components/UpdateCourseActionDialog.vue';
import unsavedChangesMixin from '@/components/unsaved-changes-mixin';
import UnsavedChangesDialog from '@/components/UnsavedChangesDialog.vue';
import TutorAdminReviewEditPagePermissionCheckboxes from './TutorAdminReviewEditPagePermissionCheckboxes.vue';
import { mapState } from 'vuex';
import StudentReviewStatusList from '@/components/StudentReviewStatusList.vue';
import ReviewRoleSelect from '../ReviewRoleSelect.vue';
import { useCohortStore } from '@/stores/cohort';

export default {
  name: 'TutorAdminReviewEditPage',
  components: {
    ReviewStudentSelect,
    UpdateCourseActionDialog,
    TutorAdminReviewEditPagePermissionCheckboxes,
    UnsavedChangesDialog,
    StudentReviewStatusList,
    ReviewRoleSelect,
  },
  setup() {
    const {
      selectedCohortStudents,
      actions: { loadCohortStudents },
    } = useCohortStore();
    return { selectedCohortStudents, loadCohortStudents };
  },
  mixins: [unsavedChangesMixin],
  ...unsavedChangesMixin,
  data() {
    return {
      reviewId: null,
      review: null,
      name: '',
      dueDate: null,
      reviewType: null,
      selectedReviewTemplateId: null,
      reviewTemplates: [],
      reviewPermissions: [],
      overallComments: [],
      overallStatementGroups: [],
      standardComments: [],
      canTraineeViewReview: false,
      isTraineeContributor: false,
      canTraineeAddTargetsOverall: false,
      canTraineeRecordAbsences: false,
      canTraineesViewOverallJudgement: false,
      canTraineeAddTargetsPerStandard: false,
      canTraineesEditStandardStatementJudgements: false,
      isEditing: true,
      updateCourseActionDialog: {
        active: false,
        error: null,
      },
      showStudents: false,
      selectedStudents: [],
      initialSelectedStudents: [],
      studentReviews: [],
      busy: true,
      reviewTemplatesBusy: true,
      error: null,
      editError: null,
      processing: false,
      startingOpenExpansionPanels: [0],
    };
  },
  computed: {
    ...mapState(['selectedCohort', 'selectedInstitution', 'reviewTypeItems', 'roles']),
    breadcrumbs() {
      return [
        {
          text: this.reviewNounCapitalisedAndPluralised,
          to: {
            name: 'CohortReviewsPage',
          },
        },
        {
          text: this.isEditing ? this.review?.name : `New ${this.reviewNounCapitalised}`,
        },
      ];
    },
    canContinue() {
      return !!(this.name && this.dueDate && this.selectedReviewTemplateId);
    },
    canAddReview() {
      return this.canContinue && this.selectedStudents.length > 0;
    },
    nonEmptyReview() {
      return (
        this.name ||
        this.dueDate ||
        (this.selectedReviewTemplateId && this.selectedReviewTemplateId !== this.$route.query.reviewTemplateId) ||
        this.selectedStudents.length
      );
    },
    dueDateDirty() {
      return this.isEditing && this.dueDate !== this.review?.due_date;
    },
    canUpdateReview() {
      return (
        this.canAddReview &&
        !!(
          this.name !== this.review?.name ||
          this.dueDateDirty ||
          this.studentsToAdd.length ||
          this.studentsToRemove.length ||
          this.permissionsDirty
        )
      );
    },
    permissionsDirty() {
      if (!this.isEditing || !this.review) return false;
      const reviewPermissions = this.review.review_permissions;
      return (
        this.canTraineeViewReview !== this.review.can_trainee_view_review ||
        this.isTraineeContributor !== this.review.is_trainee_contributor ||
        this.canTraineeAddTargetsOverall !== this.review.can_trainee_add_targets_overall ||
        this.canTraineeRecordAbsences !== this.review.can_trainee_record_absences ||
        this.canTraineesViewOverallJudgement !== this.review.can_trainees_view_overall_judgement ||
        this.canTraineeAddTargetsPerStandard !== this.review.can_trainee_add_targets_per_standard ||
        this.canTraineesEditStandardStatementJudgements !==
          this.review.can_trainees_edit_standard_statement_judgements ||
        this.overallComments.some(c => {
          const comment = this.review.review_overall_comments.find(x => x.id === c.id);
          return comment.can_trainee_edit !== c.can_trainee_edit || comment.optional !== c.optional;
        }) ||
        this.standardComments.some(c => {
          const comment = this.review.review_standard_comments.find(x => x.id === c.id);
          return comment.can_trainee_edit !== c.can_trainee_edit || comment.optional !== c.optional;
        }) ||
        this.overallStatementGroups.some(g => {
          const group = this.review.review_overall_statement_groups.find(x => x.id === g.id);
          return g.overallStatements.find(s => {
            const statement = group.review_overall_statements.find(x => x.id === s.id);
            return (
              statement.can_trainee_view !== s.can_trainee_view || statement.can_trainee_edit !== s.can_trainee_edit
            );
          });
        }) ||
        this.reviewPermissions.length !== reviewPermissions.length ||
        this.reviewPermissions.some(p => {
          const permission = reviewPermissions.find(
            x =>
              x.permission_type === p.permission_type &&
              x.role_id === p.role_id &&
              (!p.review_overall_statement_id || x.review_overall_statement_id === p.review_overall_statement_id) &&
              (!p.review_overall_comment_id || x.review_overall_comment_id === p.review_overall_comment_id)
          );
          if (!permission) return true;
          return permission.edit !== p.edit || permission.view !== p.view;
        })
      );
    },
    // These have to be calculated front end as well as backend (on review creation) as the backend
    // checks were added after reviews had been created
    havePermissionsChangedFromTemplate() {
      if (!this.isEditing || !this.review) return false;
      const removeUnusedPermissionsFilter = r => p => {
        return (
          (r.can_record_absences || p.permission_type !== 'RecordAbsences') &&
          (r.review_overall_statement_institution_judgement_set_id || p.permission_type !== 'OverallStatement') &&
          (r.review_overall_institution_judgement_set_id || p.permission_type !== 'OverallJudgement') &&
          (r.review_standard_institution_judgement_set_id || p.permission_type !== 'StandardJudgement') &&
          (r.review_standard_statement_institution_judgement_set_id ||
            p.permission_type !== 'StandardStatementJudgement') &&
          (r.use_part2 || p.permission_type !== 'Part2')
        );
      };
      const reviewPermissions = this.review.review_permissions.filter(removeUnusedPermissionsFilter(this.review));
      const reviewTemplatePermissions = this.selectedReviewTemplate.review_template_permissions.filter(
        removeUnusedPermissionsFilter(this.selectedReviewTemplate)
      );
      return (
        this.selectedReviewTemplate.can_trainee_view_review !== this.review.can_trainee_view_review ||
        this.selectedReviewTemplate.is_trainee_contributor !== this.review.is_trainee_contributor ||
        this.selectedReviewTemplate.can_trainee_add_targets_overall !== this.review.can_trainee_add_targets_overall ||
        this.selectedReviewTemplate.can_trainee_record_absences !== this.review.can_trainee_record_absences ||
        this.selectedReviewTemplate.can_trainees_view_overall_judgement !==
          this.review.can_trainees_view_overall_judgement ||
        this.selectedReviewTemplate.can_trainee_add_targets_per_standard !==
          this.review.can_trainee_add_targets_per_standard ||
        this.selectedReviewTemplate.can_trainees_edit_standard_statement_judgements !==
          this.review.can_trainees_edit_standard_statement_judgements ||
        this.selectedReviewTemplate.review_template_overall_comments.some(
          c =>
            this.review.review_overall_comments.find(x => x.review_template_overall_comment_id === c.id)
              .can_trainee_edit !== c.can_trainee_edit
        ) ||
        this.selectedReviewTemplate.review_template_standard_comments.some(
          c =>
            this.review.review_standard_comments.find(x => x.review_template_standard_comment_id === c.id)
              .can_trainee_edit !== c.can_trainee_edit
        ) ||
        // First check is needed because it's possible for a Review to have no overall statement groups, even when the template does
        // due to the overall statements being created for the template but then the section being switched off
        (this.review.review_overall_statement_groups.length &&
          this.selectedReviewTemplate.review_template_overall_statement_groups.some(g => {
            const group = this.review.review_overall_statement_groups.find(
              x => x.review_template_overall_statement_group_id === g.id
            );
            return g.review_template_overall_statements.find(s => {
              const statement = group.review_overall_statements.find(
                x => x.review_template_overall_statement_id === s.id
              );
              return (
                statement.can_trainee_view !== s.can_trainee_view || statement.can_trainee_edit !== s.can_trainee_edit
              );
            });
          })) ||
        reviewTemplatePermissions.length !== reviewPermissions.length ||
        reviewTemplatePermissions.some(p => {
          const permission = reviewPermissions.find(
            x =>
              x.permission_type === p.permission_type &&
              x.role_id === p.role_id &&
              (!p.review_overall_statement_id || x.review_overall_statement_id === p.review_overall_statement_id) &&
              (!p.review_overall_comment_id || x.review_overall_comment_id === p.review_overall_comment_id)
          );
          if (!permission) return true;
          return permission.edit !== p.edit || permission.view !== p.view;
        })
      );
    },
    studentsToAdd() {
      return this.selectedStudents.filter(s => !this.initialSelectedStudents.includes(s));
    },
    studentsToRemove() {
      return this.initialSelectedStudents.filter(s => !this.selectedStudents.includes(s));
    },
    students() {
      return this.selectedCohortStudents.map(s => {
        const studentReview = this.isEditing ? this.studentReviews.find(x => x.student.id === s.id) : null;
        return {
          ...s,
          studentReview,
          group_ids: s.student_groups.map(g => g.group_id).join(''),
        };
      });
    },
    completedInductionStudentReviewStudentIds() {
      return this.studentReviews
        .filter(sr => sr.student.ect?.status === 'completed_induction')
        .map(sr => sr.student_id);
    },
    unactivatedStudentCount() {
      return this.students.filter(s => {
        return !s.details_filled_in && this.selectedStudents.includes(s.id);
      }).length;
    },
    selectedReviewTemplate() {
      return this.reviewTemplates.find(x => x.id === this.selectedReviewTemplateId);
    },
    reviewRoles() {
      const viewerPermissions = this.createPermissionsObject('Viewer');
      const approverPermissions = this.createPermissionsObject('Approve');
      const contributorPermissions = this.createPermissionsObject('Contributor');
      const canAddTargetsOverall = this.createPermissionsObject('AddTargetsOverall');
      const canAddTargetsPerStandard = this.createPermissionsObject('AddTargetsPerStandard');
      const canMakeOverallJudgement = this.createPermissionsObject('OverallJudgement');
      const canRecordAbsences = this.createPermissionsObject('RecordAbsences');
      const canMakeStandardJudgement = this.createPermissionsObject('StandardJudgement');
      const canMakeStandardStatementJudgement = this.createPermissionsObject('StandardStatementJudgement');
      const part2 = this.createPermissionsObject('Part2');

      return this.roles.map(r => {
        const roleKey = `${r.id}_edit`;
        const canView = viewerPermissions[roleKey];
        return {
          ...r,
          viewer: canView,
          approver: canView && approverPermissions[roleKey],
          contributor: canView && contributorPermissions[roleKey],
          canAddTargetsOverall: canView && canAddTargetsOverall[roleKey],
          canAddTargetsPerStandard: canView && canAddTargetsPerStandard[roleKey],
          canMakeOverallJudgement: canView && canMakeOverallJudgement[roleKey],
          canRecordAbsences: canView && canRecordAbsences[roleKey],
          canMakeStandardJudgement: canView && canMakeStandardJudgement[roleKey],
          canMakeStandardStatementJudgement: canView && canMakeStandardStatementJudgement[roleKey],
          part2: canView && part2[roleKey],
        };
      });
    },
    overallCommentsComputed() {
      const commentPermissions = this.reviewPermissions.filter(p => p.permission_type === 'OverallComment');
      return this.overallComments
        .map(x => {
          return {
            ...x,
            editPermissions: [
              { roleId: 'student', selected: x.can_trainee_edit, canView: this.canTraineeViewReview },
              ...this.reviewRoles.map(r => ({
                roleId: r.id,
                selected:
                  commentPermissions.find(p => p.role_id === r.id && p.review_overall_comment_id === x.id)?.edit ||
                  false,
                canView: r.viewer,
              })),
            ],
          };
        })
        .sortBy('order');
    },
    overallStatementGroupsWithPermissions() {
      const statementPermissions = this.reviewPermissions.filter(p => p.permission_type === 'OverallStatement');
      return this.overallStatementGroups
        .map(g => ({
          ...g,
          overallStatements: g.overallStatements
            .map(s => {
              return {
                ...s,
                editPermissions: [
                  { roleId: 'student', selected: s.can_trainee_edit, canView: this.canTraineeViewReview },
                  ...this.reviewRoles.map(r => ({
                    roleId: r.id,
                    selected:
                      statementPermissions.find(x => x.role_id === r.id && x.review_overall_statement_id === s.id)
                        ?.edit || false,
                    canView: r.viewer,
                  })),
                ],
                viewPermissions: [
                  { roleId: 'student', selected: s.can_trainee_view, canView: this.canTraineeViewReview },
                  ...this.reviewRoles.map(r => ({
                    roleId: r.id,
                    selected:
                      statementPermissions.find(x => x.role_id === r.id && x.review_overall_statement_id === s.id)
                        ?.view || false,
                    canView: r.viewer,
                  })),
                ],
              };
            })
            .sortBy('order'),
        }))
        .sortBy('order');
    },
    standardCommentsComputed() {
      const commentPermissions = this.reviewPermissions.filter(p => p.permission_type === 'StandardComment');
      return this.standardComments
        .map(x => {
          const c = {
            ...x,
            editPermissions: [
              { roleId: 'student', selected: x.can_trainee_edit, canView: this.canTraineeViewReview },
              ...this.reviewRoles.map(r => ({
                roleId: r.id,
                selected:
                  commentPermissions.find(p => p.role_id === r.id && p.review_standard_comment_id === x.id)?.edit ||
                  false,
                canView: r.viewer,
              })),
            ],
          };

          for (const r of this.reviewRoles) {
            c[`${r.id}_edit`] =
              (r.viewer &&
                commentPermissions.find(x => x.role_id === r.id && x.review_standard_comment_id === c.id)?.edit) ||
              false;
          }
          return c;
        })
        .sortBy('order');
    },
    canTraineeAddTargetsOverallComputed: {
      get() {
        return this.canTraineeViewReview && this.canTraineeAddTargetsOverall;
      },
      set(x) {
        this.canTraineeAddTargetsOverall = x;
      },
    },
    canTraineeAddTargetsPerStandardComputed: {
      get() {
        return this.canTraineeViewReview && this.canTraineeAddTargetsPerStandard;
      },
      set(x) {
        this.canTraineeAddTargetsPerStandard = x;
      },
    },
    canTraineesEditStandardStatementJudgementsComputed: {
      get() {
        return this.canTraineeViewReview && this.canTraineesEditStandardStatementJudgements;
      },
      set(x) {
        this.canTraineesEditStandardStatementJudgements = x;
      },
    },
    canTraineesViewOverallJudgementComputed: {
      get() {
        return this.canTraineeViewReview && this.canTraineesViewOverallJudgement;
      },
      set(x) {
        this.canTraineesViewOverallJudgement = x;
      },
    },
    canTraineeRecordAbsencesComputed: {
      get() {
        return this.canTraineeViewReview && this.canTraineeRecordAbsences;
      },
      set(x) {
        this.canTraineeRecordAbsences = x;
      },
    },
    canAddTargetsOverall() {
      if (this.review) return this.review.can_add_targets_overall;
      return this.selectedReviewTemplate.can_add_targets_overall;
    },
    reviewOverallJudgementSetId() {
      if (this.review) return this.review.review_overall_institution_judgement_set_id;
      return this.selectedReviewTemplate.review_overall_institution_judgement_set_id;
    },
    recordAbsences() {
      if (this.review) return this.review.record_absences;
      return this.selectedReviewTemplate.record_absences;
    },
    canAddTargetsPerStandard() {
      if (this.review) return this.review.can_add_targets_per_standard;
      return this.selectedReviewTemplate.can_add_targets_per_standard;
    },
    reviewStandardJudgementSetId() {
      if (this.review) return this.review.review_standard_institution_judgement_set_id;
      return this.selectedReviewTemplate.review_standard_institution_judgement_set_id;
    },
    reviewStandardStatementJudgementSetId() {
      if (this.review) return this.review.review_standard_statement_institution_judgement_set_id;
      return this.selectedReviewTemplate.review_standard_statement_institution_judgement_set_id;
    },
    usePart2() {
      if (this.review) return this.review.use_part2;
      return this.selectedReviewTemplate.use_part2;
    },
    hasReviewStandards() {
      if (this.review) return this.review.review_standards.length > 0;
      return this.selectedReviewTemplate.review_template_standards.length > 0;
    },
    selectedAll() {
      return this.selectedInstitution.config.early_careers
        ? false
        : this.selectedStudents.length === this.students.length;
    },
  },
  watch: {
    selectedReviewTemplate(x) {
      if (!this.review) {
        this.reviewType = x.review_type;
        this.canTraineeViewReview = x.can_trainee_view_review;
        this.isTraineeContributor = x.is_trainee_contributor;
        this.canTraineeAddTargetsOverall = x.can_trainee_add_targets_overall;
        this.canTraineeAddTargetsPerStandard = x.can_trainee_add_targets_per_standard;
        this.canTraineesEditStandardStatementJudgements = x.can_trainees_edit_standard_statement_judgements;
        this.canTraineesViewOverallJudgement = x.can_trainees_view_overall_judgement;
        this.canTraineeRecordAbsences = x.can_trainee_record_absences;
        this.reviewPermissions = x.review_template_permissions.map(p => ({
          ...p,
          review_overall_comment_id: p.review_template_overall_comment_id,
          review_overall_statement_id: p.review_template_overall_statement_id,
          review_standard_comment_id: p.review_template_standard_comment_id,
        }));
        this.overallStatementGroups = x.review_template_overall_statement_groups.map(g => ({
          ...g,
          overallStatements: g.review_template_overall_statements.map(s => ({ ...s })),
        }));
        this.overallComments = x.review_template_overall_comments.map(c => ({ ...c }));
        this.standardComments = x.review_template_standard_comments.map(c => ({ ...c }));
        this.$store.commit('updateSelectedStandardSet', x.standard_set);
      }
    },
    canUpdateReview(x) {
      if (this.isEditing) {
        this.dirty = x;
      }
    },
    nonEmptyReview(x) {
      if (!this.isEditing) {
        this.dirty = x;
      }
    },
    review() {
      this.name = this.review.name;
      this.dueDate = this.review.due_date;
      this.selectedReviewTemplateId = this.review.review_template_id;
      this.studentReviews = this.review.student_reviews;
      this.selectedStudents = this.studentReviews.map(x => x.student.id);
      this.initialSelectedStudents = [...this.selectedStudents];
      this.reviewType = this.review.review_type;
      this.reviewPermissions = this.review.review_permissions.map(x => ({ ...x }));
      this.overallStatementGroups = this.review.review_overall_statement_groups.map(g => ({
        ...g,
        overallStatements: g.review_overall_statements.map(s => ({ ...s })),
      }));
      this.overallComments = this.review.review_overall_comments.map(c => ({ ...c }));
      this.standardComments = this.review.review_standard_comments.map(c => ({ ...c }));
      this.canTraineeViewReview = this.review.can_trainee_view_review;
      this.isTraineeContributor = this.review.is_trainee_contributor;
      this.canTraineeAddTargetsOverall = this.review.can_trainee_add_targets_overall;
      this.canTraineeAddTargetsPerStandard = this.review.can_trainee_add_targets_per_standard;
      this.canTraineesEditStandardStatementJudgements = this.review.can_trainees_edit_standard_statement_judgements;
      this.canTraineesViewOverallJudgement = this.review.can_trainees_view_overall_judgement;
      this.canTraineeRecordAbsences = this.review.can_trainee_record_absences;
      this.$store.commit('updateSelectedStandardSet', this.review.standard_set);
    },
  },
  created() {
    this.loadReviewTemplates();
    this.$store.dispatch('loadRoles');
    if (this.$route.name === 'TutorAdminReviewCreatePage') {
      this.isEditing = false;
      this.busy = false;
    } else {
      this.reviewId = this.$route.params.id.toString();
      this.isEditing = true;
      if (this.$route.params.review) {
        this.review = this.$route.params.review;
        this.busy = false;
      } else {
        this.loadReview();
      }
    }
    this.selectedReviewTemplateId = this.$route.query.reviewTemplateId
      ? parseInt(this.$route.query.reviewTemplateId)
      : null;
  },
  methods: {
    async loadReviewTemplates() {
      this.reviewTemplatesBusy = true;
      try {
        const r = await this.$api.get(
          `/institutions/${this.selectedInstitution.id}/review-templates-for-review-create`
        );
        this.reviewTemplates = r.data;
      } catch (e) {
        console.log(e);
      }
      this.reviewTemplatesBusy = false;
    },
    async loadReview() {
      this.busy = true;
      this.error = null;
      try {
        // Assumed to be same API call as TutorAdminReviewViewPage
        const r = await this.$api.get(`/reviews/${this.reviewId}`);
        this.review = r.data;
      } catch (e) {
        console.log(e);
        this.error = true;
      }
      this.busy = false;
    },
    cancelClick() {
      this.$router.go(-1);
    },
    saveReview() {
      this.updateCourseActionDialog = {
        active: true,
        error: null,
      };
    },
    async submitSaveReview() {
      this.processing = true;
      this.updateCourseActionDialog.error = null;
      this.editError = null;
      try {
        let apiCall;
        if (!this.isEditing) {
          apiCall = x => this.$api.post('/reviews', x);
        } else {
          apiCall = x =>
            this.$api.put(`/reviews/${this.reviewId}`, {
              ...x,
              review_permissions: this.reviewPermissions,
              review_overall_comments: this.overallComments,
              review_overall_statement_groups: this.overallStatementGroups.map(g => ({
                ...g,
                review_overall_statements: g.overallStatements,
              })),
              review_standard_comments: this.standardComments,
              can_trainee_view_review: this.canTraineeViewReview,
              is_trainee_contributor: this.isTraineeContributor,
              can_trainee_add_targets_overall: this.canTraineeAddTargetsOverall,
              can_trainees_view_overall_judgement: this.canTraineesViewOverallJudgement,
              can_trainee_record_absences: this.canTraineeRecordAbsences,
              can_trainee_add_targets_per_standard: this.canTraineeAddTargetsPerStandard,
              can_trainees_edit_standard_statement_judgements: this.canTraineesEditStandardStatementJudgements,
            });
        }
        await apiCall({
          name: this.name,
          due_date: this.dueDate,
          review_template_id: this.selectedReviewTemplateId,
          cohort_id: this.selectedCohort.id,
          // This ensures completed_induction ECT student reviews are always maintained
          student_ids: this.selectedStudents.concat(this.completedInductionStudentReviewStudentIds).unique(),
          selected_all: this.selectedAll,
        });
        this.updateCourseActionDialog = {
          active: false,
          error: null,
        };

        const promises = [this.$store.commit('clearStudentReview')];
        if (this.selectedInstitution.config.early_careers) {
          promises.push(this.loadCohortStudents(true, true));
        }
        await Promise.all(promises);

        this.dirty = false;
        this.$router.go(-1);
      } catch (e) {
        console.log(e);
        const message = `Sorry, cannot ${this.isEditing ? 'update' : 'set'} your ${
          this.reviewNounCapitalised
        } at the moment.`;
        if (!this.setInTheFuture) {
          this.updateCourseActionDialog.error = message;
        } else {
          this.editError = message;
        }
      }
      this.processing = false;
    },
    goToReviewTemplates() {
      this.$router.push({
        name: 'TutorAdminReviewTemplateCreatePage',
        params: { institutionId: this.selectedInstitution.id },
      });
    },
    goToReviewTemplate() {
      this.$router.push({
        name: 'TutorAdminReviewTemplatePage',
        params: { institutionId: this.selectedInstitution.id, templateId: this.selectedReviewTemplateId },
      });
    },
    createPermissionsObject(permission) {
      const permissions = this.reviewPermissions.filter(x => x.permission_type === permission);
      const permissionsObject = {};
      for (const r of this.roles) {
        permissionsObject[`${r.id}_edit`] = permissions.find(x => x.role_id === r.id)?.edit || false;
      }
      return permissionsObject;
    },
    // reviews (as opposed to review_templates) don't have a permission when it's false
    // I'm going to try and keep that here
    updateReviewPermissionEdit(
      permissionType,
      roleId,
      edit,
      { overallStatementId, overallCommentId, standardCommentId } = {}
    ) {
      const permissionIndex = this.reviewPermissions.findIndex(
        p =>
          p.role_id === roleId &&
          p.permission_type === permissionType &&
          (!overallStatementId || p.review_overall_statement_id === overallStatementId) &&
          (!overallCommentId || p.review_overall_comment_id === overallCommentId) &&
          (!standardCommentId || p.review_standard_comment_id === standardCommentId)
      );
      if (permissionIndex !== -1) {
        // Only overall statements have view permissions
        if (edit || (this.reviewPermissions[permissionIndex].view && overallStatementId)) {
          this.reviewPermissions.splice(permissionIndex, 1, { ...this.reviewPermissions[permissionIndex], edit });
        } else {
          this.reviewPermissions.splice(permissionIndex, 1);
        }
      } else {
        if (edit) {
          this.reviewPermissions.push({
            permission_type: permissionType,
            role_id: roleId,
            edit: true,
            view: true,
            review_overall_statement_id: overallStatementId,
            review_overall_comment_id: overallCommentId,
            review_standard_comment_id: standardCommentId,
          });
        }
      }
    },
    // reviews (as opposed to review_templates) don't have a permission when it's false
    // I'm going to try and keep that here
    updateReviewPermissionView(permissionType, roleId, view, { overallStatementId } = {}) {
      const permissionIndex = this.reviewPermissions.findIndex(
        p =>
          p.role_id === roleId &&
          p.permission_type === permissionType &&
          (!overallStatementId || p.review_overall_statement_id === overallStatementId)
      );
      if (permissionIndex !== -1) {
        if (view) {
          this.reviewPermissions.splice(permissionIndex, 1, { ...this.reviewPermissions[permissionIndex], view: true });
        } else {
          this.reviewPermissions.splice(permissionIndex, 1);
        }
      } else {
        if (view) {
          this.reviewPermissions.push({
            permission_type: permissionType,
            review_overall_statement_id: overallStatementId,
            role_id: roleId,
            edit: false,
            view: true,
          });
        }
      }
    },
    overallStatementSelectedRolesEditUpdated(group, statement, roles) {
      for (const r of roles.filter(r => r.roleId !== 'student')) {
        this.updateReviewPermissionEdit('OverallStatement', r.roleId, r.selected, {
          overallStatementId: statement.id,
        });
      }
      this.overallStatementTraineeEditChanged(group, statement, roles.find(r => r.roleId === 'student').selected);
    },
    overallStatementSelectedRolesViewUpdated(group, statement, roles) {
      for (const r of roles.filter(r => r.roleId !== 'student')) {
        this.updateReviewPermissionView('OverallStatement', r.roleId, r.selected, {
          overallStatementId: statement.id,
        });
      }
      this.overallStatementTraineeViewChanged(group, statement, roles.find(r => r.roleId === 'student').selected);
    },
    overallStatementTraineeEditChanged(group, statement, edit) {
      const updates = { can_trainee_edit: edit };
      if (updates.edit) {
        updates.view = true;
      }
      this.updateOverallStatementTraineePermissions(group, statement, updates);
    },
    overallStatementTraineeViewChanged(group, statement, view) {
      const updates = { can_trainee_view: view };
      if (!updates.view) {
        updates.edit = false;
      }
      this.updateOverallStatementTraineePermissions(group, statement, updates);
    },
    updateOverallStatementTraineePermissions(group, statement, updates) {
      this.overallStatementGroups = this.overallStatementGroups.map(g => {
        if (g.id === group.id) {
          return {
            ...g,
            overallStatements: g.overallStatements.map(s => {
              if (s.id === statement.id) {
                return { ...s, ...updates };
              }
              return s;
            }),
          };
        }
        return g;
      });
    },
    overallCommentSelectedRolesUpdated(comment, roles) {
      for (const r of roles.filter(r => r.roleId !== 'student')) {
        this.updateReviewPermissionEdit('OverallComment', r.roleId, r.selected, {
          overallCommentId: comment.id,
        });
      }
      this.overallCommentTraineeEditChanged(comment, roles.find(r => r.roleId === 'student').selected);
    },
    overallCommentTraineeEditChanged(comment, edit) {
      this.overallComments = this.overallComments.map(c => {
        if (c.id === comment.id) {
          return { ...c, can_trainee_edit: edit };
        }
        return c;
      });
    },
    overallCommentOptionalityChanged(comment, optional) {
      this.overallComments = this.overallComments.map(c => {
        if (c.id === comment.id) {
          return { ...c, optional: optional };
        }
        return c;
      });
    },
    standardCommentSelectedRolesUpdated(comment, roles) {
      for (const r of roles.filter(r => r.roleId !== 'student')) {
        this.updateReviewPermissionEdit('StandardComment', r.roleId, r.selected, {
          standardCommentId: comment.id,
        });
      }
      this.standardCommentTraineeEditChanged(comment, roles.find(r => r.roleId === 'student').selected);
    },
    standardCommentTraineeEditChanged(comment, edit) {
      this.standardComments = this.standardComments.map(c => {
        if (c.id === comment.id) {
          return { ...c, can_trainee_edit: edit };
        }
        return c;
      });
    },
    standardCommentOptionalityChanged(comment, optional) {
      this.standardComments = this.standardComments.map(c => {
        if (c.id === comment.id) {
          return { ...c, optional: optional };
        }
        return c;
      });
    },
  },
};
</script>
