<template>
  <div>
    <div v-if="loadError" class="mt-2">
      <span v-if="isString(loadError)">{{ loadError }}</span>
      <span v-else>
        Sorry, cannot load {{ isMe ? 'your' : "this Instructor's" }} Training Modules right now. If this problem
        persists, please contact support.
      </span>
    </div>
    <mosaic-tab-item-list v-else :items="sortedAndFilteredItems" :empty-text="emptyText" mosaic-key="modules">
      <template #filters>
        <mosaic-text-field
          dense
          no-icon
          hide-details
          name="name-filter"
          label="Filter by name"
          v-model="nameFilter"
          style="width: 300px"
        />
        <mosaic-select
          name="type-filter"
          label="Filter by status"
          :items="statusFilterItems"
          v-model="statusFilter"
          hide-details
          dense
          no-icon
          style="width: 210px"
          multiple
        />
      </template>

      <template #item="{ item }">
        <mosaic-list-item
          :key="item.key"
          :icon="item.icon"
          :title="item.title"
          :subtitle="item.subtitle"
          :to="item.to"
          :chip="item.chip"
        >
        </mosaic-list-item>
      </template>
    </mosaic-tab-item-list>
  </div>
</template>

<script setup lang="ts">
import { formatDate } from '@/mixins/global-mixins';
import type { StaffTraining } from '@/stores/staff-training';
import { icons } from '@/utils/icons';
import { computed, ref } from 'vue';
import type { RouteLocationNamedRaw } from 'vue-router';
import { isString } from 'lodash';
import { mapModuleStatus, staffTrainingModuleExpectedDuration } from './staff-training';
import { syncQueryParam } from '@/composables/query';
import { allModuleStatuses, publishedModuleStatuses, type AllModuleStatuses } from './staff-training';
import { DateTime } from 'luxon';

type ModuleItem = { id: number; date: string | undefined; status: AllModuleStatuses; title: string };

const props = withDefaults(
  defineProps<{
    moduleCompletions: StaffTraining['staffTrainingModuleCompletions'];
    assignedFrameworks: StaffTraining['staffTrainingFrameworks'];
    loadError?: boolean | string;
    clickModuleTo: (
      module: StaffTraining['staffTrainingModuleCompletions'][number]['staffTrainingModule']
    ) => RouteLocationNamedRaw;
    isMe?: boolean;
    canEditTraining?: boolean;
    showUnpublished?: boolean;
    canViewUnpublished?: boolean;
    reload: () => Promise<unknown>;
  }>(),
  {
    loadError: false,
    isMe: false,
  }
);

// #region items and filters
const items = computed(() =>
  props.assignedFrameworks
    .flatMap(f =>
      f.staffTrainingCompetencyThemes.flatMap(t => t.staffTrainingCompetencies.flatMap(c => c.requiredModules))
    )
    .map(m => {
      const completion = props.moduleCompletions.find(c => c.staffTrainingModuleId === m.id);
      const duration = ` - ${staffTrainingModuleExpectedDuration(m, '')}`;
      const date = completion?.completedAt ?? completion?.createdAt;
      const chip = mapModuleStatus(m.status);

      return {
        ...m,
        id: m.id,
        key: `module-${m.id}`,
        type: 'module',
        icon: icons.instructorTrainingModule,
        title: completion ? completion.staffTrainingModule.name : m.name,
        subtitle: `Training Module - ${
          completion && completion.completed
            ? 'Completed'
            : completion
            ? 'Started'
            : m.status === 'not_started'
            ? 'Not started'
            : 'Unpublished'
        } ${date ? `on ${formatDate(date)}` : ''}${duration}`,

        to:
          props.canViewUnpublished && m.status === 'unpublished'
            ? { name: 'InstitutionStaffTrainingModulePage', params: { id: m.id.toString() } }
            : props.clickModuleTo(m),
        chip,
        date,
      };
    })
    .filter((m: ModuleItem, index: number, self: ModuleItem[]) => self.findIndex(i => i.id === m.id) === index)
);

const nameFilter = ref('');
syncQueryParam(nameFilter, 'modules-name', 'string');

const statusFilterItems = computed(() => (props.canViewUnpublished ? allModuleStatuses : publishedModuleStatuses));
const statusFilter = ref(statusFilterItems.value?.map(m => m.value) ?? []);

syncQueryParam(statusFilter, 'modules-status', 'string[]');

const compareItems = (a: ModuleItem, b: ModuleItem) => {
  const statusOrder = ['started', 'not_started', 'completed', 'unpublished'];
  const statusDiff = statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
  const getDate = (d?: string) =>
    DateTime.fromISO(d || '1970-01-01')
      .startOf('day')
      .toMillis();

  return statusDiff || getDate(a.date) - getDate(b.date);
};

const sortedAndFilteredItems = computed(() =>
  items.value
    .filter((i: ModuleItem) => {
      return i.title.toLowerCase().includes(nameFilter.value.toLowerCase()) && statusFilter.value.includes(i.status);
    })
    .sort(compareItems)
);

// #endregion

const emptyText = computed(() => {
  if (items.value.length == 0) {
    return props.isMe ? 'You have no Training Modules' : `This Instructor has no Training Modules`;
  } else {
    return props.isMe
      ? 'You have no Training Modules for these filters'
      : 'This Instructor has no Training Modules for these filters';
  }
});
</script>
