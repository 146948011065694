import { hasPermissionForStudent } from '@/mixins/global-mixins';
import type { SelectedStudent, UserStaff } from '@/store/map-store';

export function findStudentLevelHomePage(staff: UserStaff, student: SelectedStudent) {
  const hasAdminPermission = hasPermissionForStudent(staff, 'Admin', student);
  const isEarlyCareers = staff.institution.config.early_careers;
  const hasEctAssessmentPermission = hasPermissionForStudent(staff, 'ect.assessment.view', student);
  const hasReviewPermission = isEarlyCareers
    ? hasEctAssessmentPermission
    : hasPermissionForStudent(staff, 'student.reviews.view', student);
  const hasManageInductionPermission = hasPermissionForStudent(staff, 'ect.manageInduction.view', student);
  const hasTargetsPermission = !isEarlyCareers || hasEctAssessmentPermission;

  const name = isEarlyCareers
    ? hasAdminPermission || hasManageInductionPermission
      ? 'TutorEctProgressPage'
      : hasReviewPermission
      ? 'TutorReviewsListPage'
      : 'TutorTargetsListPage'
    : hasAdminPermission
    ? 'TutorStudentDetailsPage'
    : hasTargetsPermission
    ? 'TutorTargetsListPage'
    : undefined;

  // If the user does not have any permissions that corredspond to student landing pages, return undefined so that
  // there is no click route and the user won't be able to navigate
  return name
    ? {
        name,
        params: { studentId: student.id },
      }
    : undefined;
}
