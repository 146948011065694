<template>
  <mosaic-checkbox
    v-if="selectedRoleId !== 'student'"
    :prepend-icon="icons.notification"
    :label="
      isEditing && selectedRoleId == savedRoleId
        ? `Instructors should receive a Notification for ${traineeNounPluralised} you add?`
        : `Instructors should receive a Notification?`
    "
    :help-text="`If selected, all Instructors who are ${
      rolesWithStudent.find(r => r.id == selectedRoleId)?.pluralisedName
    } for ${
      isEditing && selectedRoleId == savedRoleId ? `any newly assigned` : `the assigned`
    } ${traineeNounPluralised} will be notified.`"
    :model-value="modelValue"
    @update:model-value="emit('update:model-value', $event)"
  />
</template>

<script setup lang="ts">
import { mapGetters } from '@/store/map-store';
import { useStudentStore } from '@/stores/student';
import { icons } from '@/utils/icons';

defineProps<{
  modelValue: boolean;
  isEditing: boolean;
  savedRoleId: number | null;
  selectedRoleId: number | 'student';
}>();

const emit = defineEmits<{
  'update:model-value': [v: boolean];
}>();

const { rolesWithStudent } = mapGetters();
const { traineeNounPluralised } = useStudentStore();
</script>
