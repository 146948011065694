<template>
  <div>
    <mosaic-loading-card v-if="busy" type="inline-list" />
    <div v-else-if="error" class="pa-4">{{ error }}</div>
    <template v-else>
      <template v-if="reviews.length > 0">
        <mosaic-list :items="paginatedReviews">
          <template #item="{ item: review }">
            <mosaic-list-item :to="viewReviewTo(review)" icon="mdi-check-circle-outline" :title="review.name">
              <template #subtitle>
                <span v-if="review.review_type"
                  >{{ reviewTypeItems.find(x => x.value === review.review_type).title }} - </span
                >Due {{ review.due_date }}<span v-if="review.note"> - {{ review.note }}</span>
              </template>

              <template #informations>
                <mosaic-trainee-count-chip :count="review.student_review_count" :object-type="reviewNounCapitalised" />
              </template>

              <template #actions>
                <mosaic-icon-btn
                  v-if="userStaffHasPermission('reviews.edit')"
                  icon="pencil"
                  :tooltip="`Edit ${reviewNounCapitalised}`"
                  @click.prevent="editReview(review)"
                />
                <mosaic-icon-btn
                  v-if="
                    userStaffHasPermission('reviews.edit') &&
                    (!deleteReviewLoadingDialog.id ||
                      deleteReviewLoadingDialog?.id !== review.id ||
                      (!deleteReviewLoadingDialog.processing && deleteReviewLoadingDialog?.id === review.id))
                  "
                  icon="delete"
                  :tooltip="`Delete ${reviewNounCapitalised}`"
                  @click.prevent="deleteReview(review)"
                />
                <mosaic-loading-icon
                  class="ml-4 mr-3"
                  :is-loading="deleteReviewLoadingDialog.processing && deleteReviewLoadingDialog?.id === review.id"
                />
              </template>
            </mosaic-list-item>
          </template>
        </mosaic-list>
      </template>

      <template v-else-if="!busy">
        <div v-if="userStaffHasPermission('reviews.edit')" class="pa-4">
          Create a {{ selectedInstitution.config.review_noun }} by clicking the create button in the top right.
        </div>
        <div v-else>
          You have no {{ reviewNounPluralised }}. If you expect to be able to create {{ reviewNounPluralised }} then get
          in touch with Mosaic support.
        </div>
      </template>
      <mosaic-pagination v-model="currentPage" :total="reviews.length" v-model:page-size="pageSize" />
    </template>

    <mosaic-dialog
      v-model:active="deleteReviewDialog.active"
      :title="`Delete ${reviewNounCapitalised}`"
      :error-message="deleteReviewDialog.error"
    >
      <template v-if="deleteReviewDialog.review">
        <div>
          <span class="font-weight-medium">Are you sure want to delete "{{ deleteReviewDialog.review.name }}"?</span>
        </div>
        <div class="mt-2">This {{ reviewNounCapitalised }} has:</div>
        <student-review-status-list
          :students="
            deleteReviewDialog.students.map(s => ({
              id: s.id,
              userId: s.id,
              displayName: s.display_name,
              email: s.email,
              profilePictureUpdatedAt: s.profile_picture_updated_at,
              hideProfilePicture: s.hide_profile_picture,
              studentReview: s.studentReview,
            }))
          "
          :statuses="['notstarted', 'started', 'partiallycompleted', 'completed', 'approved']"
          :action-target-title="deleteReviewDialog.review.name"
        ></student-review-status-list>
      </template>
      <template #buttons>
        <mosaic-btn
          variant="text"
          ripple
          color="error"
          :disabled="deleteReviewDialog.processing"
          @click.prevent="submitDeleteReview()"
          >Delete</mosaic-btn
        >
      </template>
    </mosaic-dialog>
    <mosaic-snackbar
      v-model="deleteReviewLoadingDialog.active"
      color="error"
      :message="deleteReviewLoadingDialog.error"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import config from '@/utils/config';
import StudentReviewStatusList from '@/components/StudentReviewStatusList.vue';
import { useCohortStore } from '@/stores/cohort';

export default {
  name: 'CohortReviewsPageLiveTab',
  components: { StudentReviewStatusList },
  setup() {
    const {
      actions: { loadCohortStudents },
    } = useCohortStore();
    return { loadCohortStudents };
  },
  data: () => ({
    config: config,
    error: null,
    busy: false,
    reviews: [],
    reviewTemplates: [],
    deleteReviewLoadingDialog: {
      id: null,
      error: '',
      active: false,
      processing: false,
    },
    deleteReviewDialog: {
      viewStarted: false,
      active: false,
      processing: false,
      error: '',
      review: null,
      students: [],
      archivedStudents: [],
    },
    currentPage: 1,
    pageSize: 10,
  }),
  computed: {
    ...mapState(['user', 'selectedCohort', 'selectedInstitution', 'reviewTypeItems']),
    breadcrumbs() {
      return [
        {
          text: this.reviewNounCapitalisedAndPluralised,
        },
      ];
    },
    notStartedStudentReviews() {
      return this.deleteReviewDialog.students.filter(x => x.studentReview.status === 'notstarted');
    },
    startedStudentReviews() {
      return this.deleteReviewDialog.students.filter(x => x.studentReview.status === 'started');
    },
    partiallyCompletedStudentReviews() {
      return this.deleteReviewDialog.students.filter(x => x.studentReview.status === 'partiallycompleted');
    },
    completedStudentReviews() {
      return this.deleteReviewDialog.students.filter(x => x.studentReview.status === 'completed');
    },
    approvedStudentReviews() {
      return this.deleteReviewDialog.students.filter(x => x.studentReview.status === 'approved');
    },
    paginatedReviews() {
      const index = (this.currentPage - 1) * this.pageSize;
      return this.reviews.slice(index, index + this.pageSize);
    },
  },
  watch: {
    reviews() {
      this.currentPage = 1;
    },
  },
  created() {
    this.loadReviews();
  },
  methods: {
    async loadReviews() {
      this.busy = true;
      try {
        const response = await this.$api.get(`/cohorts/${this.selectedCohort.id}/reviews`);
        this.reviews = response.data;
      } catch (e) {
        console.log(e);
        this.error = `Sorry, cannot load your ${this.reviewNounPluralised} at the moment.`;
      }
      this.busy = false;
    },
    viewReviewTo(review) {
      return {
        name: 'TutorAdminReviewViewPage',
        params: { cohortId: this.selectedCohort.id, reviewId: review.id },
      };
    },
    createReview() {
      this.$router.push({
        name: 'TutorAdminReviewCreatePage',
        params: { cohortId: this.selectedCohort.id },
      });
    },
    editReview(review) {
      this.$router.push({
        name: 'TutorAdminReviewEditPage',
        params: { cohortId: this.selectedCohort.id, id: review.id },
      });
    },
    async deleteReview(review) {
      try {
        this.deleteReviewLoadingDialog.id = review.id;
        this.deleteReviewLoadingDialog.processing = true;
        const r = await this.$api.get(`/reviews-with-archived/${review.id}`);
        this.deleteReviewDialog.review = r.data;
        this.deleteReviewDialog.students = r.data.student_reviews.map(x => ({
          ...x.student,
          studentReview: x,
        }));
        this.deleteReviewDialog.archivedStudents = r.data.archived_student_reviews.map(x => ({
          ...x.student,
          studentReview: x,
        }));
        this.deleteReviewDialog.active = true;
      } catch (e) {
        this.deleteReviewLoadingDialog.error = `Sorry, cannot delete your ${this.reviewNoun} at the moment`;
        this.deleteReviewDialog.active = true;
      } finally {
        this.deleteReviewLoadingDialog.processing = false;
      }
    },
    renderDeleteReviewNames(student) {
      let bracket;
      switch (student.studentReview.status) {
        case 'approved':
          bracket = ` (${this.reviewNoun} approved)`;
          break;
        case 'completed':
          bracket = ` (${this.reviewNoun} marked as complete)`;
          break;
        case 'partiallycompleted':
          bracket = ` (${this.reviewNoun} partially marked as complete)`;
          break;
        case 'started':
          bracket = ` (${this.reviewNoun} started)`;
          break;
        default:
          bracket = '';
      }

      return (student.name || student.email) + bracket;
    },
    async submitDeleteReview() {
      this.deleteReviewDialog.processing = true;
      this.deleteReviewDialog.error = '';
      try {
        await this.$api.delete(`/reviews/${this.deleteReviewDialog.review.id}`);
        this.deleteReviewDialog.active = false;
        this.loadReviews();

        // I think this is for reloading of ECT progress
        if (this.selectedInstitution.config.early_careers) {
          this.loadCohortStudents(true, true);
        }
      } catch (e) {
        this.deleteReviewDialog.error = `Sorry, cannot delete your ${this.reviewNoun} at the moment`;
        console.log(e);
      }
      this.deleteReviewDialog.processing = false;
    },
  },
};
</script>
