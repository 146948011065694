<template>
  <v-tooltip :text="`Export full list (${props.titleForExportedList})`">
    <template #activator="{ props: tooltipProps }">
      <div class="d-flex justify-end">
        <div
          v-if="props.lists.some(list => list.items.length && list.showExpand)"
          class="text-xs export-btn cursor-pointer text-primary"
          v-bind="tooltipProps"
          @click.prevent="exportLists(props.lists, props.titleForExportedList)"
        >
          <mosaic-icon color="primary" :size="17" icon="mdi-table-arrow-down" />
          <span class="text-decoration-underline mr-1">Download</span>
        </div>
      </div>
    </template>
  </v-tooltip>
  <div class="d-flex flex-column ga-2">
    <div class="" v-for="list in lists" :key="list.id">
      <div
        class="d-flex"
        @click="expandOrCollapse(list)"
        :class="{ 'cursor-pointer': list.showExpand && list.items.length }"
      >
        <mosaic-icon :color="list.title.color" :icon="icons.user" size="small" class="mr-2 list-icon" />
        <div :class="{ ['text-' + list.title.color]: list.title.color }">
          <span>{{ list.title.text }} </span>
          <mosaic-icon
            v-if="list.showExpand && list.items.length"
            :icon="isCollapsed.includes(list.id) ? 'mdi-chevron-up' : 'mdi-chevron-down'"
          />
        </div>
      </div>
      <v-expand-transition>
        <div v-if="isCollapsed.includes(list.id)">
          <li v-for="(item, index) in getDisplayedItems(list.items)" :key="item.avatar.user.id" class="mt-2 ml-8 list">
            <span v-if="index < props.listItemsLimit">
              <mosaic-avatar
                v-if="item.avatar"
                class="mr-2"
                :user="item.avatar.user"
                :hide-profile-picture="item.avatar.hideProfilePicture"
                size="small"
              />
              {{ item.avatar.user.displayName }}
            </span>
            <span v-if="index === props.listItemsLimit">
              <v-avatar v-if="item.avatar" class="mr-2" color="secondary" size="small"
                ><span class="mb-2">...</span></v-avatar
              >

              <v-tooltip :text="`Export list (${list.exportConfig.title})`">
                <template #activator="{ props: tooltipProps }">
                  <span> {{ getExtraItemsCount(list.items) }} more </span>
                  <span
                    class="text-xs export-btn cursor-pointer text-primary"
                    v-bind="tooltipProps"
                    @click.prevent="exportLists([list], list.exportConfig.title)"
                    >(<mosaic-icon color="primary" :size="17" icon="mdi-table-arrow-down" />
                    <span class="text-decoration-underline mr-1">Download</span>)</span
                  >
                </template>
              </v-tooltip>
            </span>
          </li>
        </div>
      </v-expand-transition>
    </div>
  </div>
  <mosaic-error-snackbar v-model="downloadError" action="proceed with the download" />
</template>
<script setup lang="ts">
import { ref } from 'vue';
import XLSX from 'xlsx';
import moment from 'moment';
import { icons } from '@/utils/icons';

interface Avatar {
  id: number;
  displayName: string;
  email: string;
  profilePictureUpdatedAt: string;
}

export type Item = {
  avatar: { user: Avatar; hideProfilePicture: boolean };
};

export type ExpandCollapseList = {
  id: string;
  showExpand: boolean;
  title: { text: string; color?: string };
  exportConfig: { title: string; subtitle?: string };
  items: Item[];
};

const props = withDefaults(
  defineProps<{ lists: ExpandCollapseList[]; listItemsLimit?: number; titleForExportedList?: string }>(),
  {
    listItemsLimit: 10,
    titleForExportedList: 'List',
  }
);

const isCollapsed = ref<string[]>([]);
const downloadError = ref(false);

const expandOrCollapse = (list: ExpandCollapseList) => {
  if (!list.showExpand) return;
  if (isCollapsed.value.includes(list.id)) {
    isCollapsed.value = isCollapsed.value.filter(t => t !== list.id);
  } else {
    isCollapsed.value = [...isCollapsed.value, list.id];
  }
};

function getDisplayedItems(items: Item[]) {
  return items.slice(0, props.listItemsLimit + 1);
}

function getExtraItemsCount(items: Item[]) {
  return items.length - props.listItemsLimit;
}

function exportLists(lists: ExpandCollapseList[], docTitle: string) {
  try {
    const xlxs = XLSX.utils.book_new();

    lists.forEach(list => {
      if (!list.showExpand) return;

      const subtitle = list.exportConfig?.subtitle ? [list.exportConfig.subtitle] : null;

      const dataToSheet = [['Email', 'Name']];
      if (subtitle) dataToSheet.unshift(subtitle);

      const tableList = list.items.length
        ? list.items.map(item => [item.avatar.user.email, item.avatar.user.displayName])
        : [['No results']];

      XLSX.utils.book_append_sheet(
        xlxs,
        XLSX.utils.aoa_to_sheet([...dataToSheet, ...tableList]),
        list.exportConfig.title.substring(0, 30)
      );
    });
    XLSX.writeFile(xlxs, `${docTitle} (${moment().format('DD-MM-YYYY')}).xlsx`);
  } catch (e) {
    console.log(e);
    downloadError.value = true;
  }
}
</script>
<style scoped>
.export-btn {
  font-size: 15px;
}
.list {
  list-style: none;
}
</style>
