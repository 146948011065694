<template>
  <div>
    <div class="d-flex align-center" v-if="!readonly">
      <v-checkbox
        :readonly="readonly"
        :disabled="disabled"
        v-bind="$attrs"
        color="primary"
        :model-value="modelValue"
        :label="label"
        :name="name"
        :rules="allRules"
        :class="prependIcon ? '' : noIcon ? '' : 'pl-10'"
        hide-details
        :density="dense ? 'compact' : 'default'"
        @update:model-value="$emit('update:modelValue', !!$event)"
      >
        <template v-if="!label" #label><slot name="label" /></template>

        <template #prepend v-if="prependIcon">
          <mosaic-icon :icon="prependIcon" />
        </template>
      </v-checkbox>
      <div v-if="helpText" class="pl-2">
        <mosaic-help>{{ helpText }}</mosaic-help>
      </div>
    </div>
    <div v-else class="d-flex py-2 align-center">
      <v-chip :color="readOnlyColour">
        <mosaic-icon :color="readOnlyColour" :icon="readOnlyIcon" class="pr-2" :size="23" />
        <span v-if="label" class="label">{{ label }}</span>
        <span v-else class="align-self-center"><slot name="label" /></span>
      </v-chip>
      <div v-if="readOnlyHelpText" class="pl-2">
        <mosaic-help>{{ readOnlyHelpText }}</mosaic-help>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineEmits, withDefaults, watchEffect } from 'vue';
import type { Rule } from '@/utils/validations';

const props = withDefaults(
  defineProps<{
    readonly?: boolean;
    disabled?: boolean;
    prependIcon?: string;
    noIcon?: boolean;
    name?: string;
    label?: string;
    modelValue: boolean;
    helpText?: string;
    positiveHelpText?: string;
    negativeHelpText?: string;
    required?: boolean;
    dense?: boolean;
    rules?: [];
  }>(),
  {
    modelValue: true,
    rules: () => [],
  }
);

defineEmits(['update:modelValue']);

const readOnlyColour = computed(() => (props.modelValue ? 'primary' : 'secondary'));
const readOnlyIcon = computed(() => (props.modelValue ? 'mdi-check' : 'mdi-close'));
const readOnlyHelpText = computed(() => (props.modelValue ? props.positiveHelpText : props.negativeHelpText));

watchEffect(() => {
  if (props.readonly && (!props.positiveHelpText || !props.negativeHelpText)) {
    throw new Error('When readonly is true, a positiveHelpText and a negativeHelpText must be supplied.');
  }
});

const allRules = computed<Rule[]>(() =>
  props.required ? [v => !!v || 'Box must be checked', ...props.rules] : props.rules
);
</script>
<style scoped>
.label {
  font-size: 1rem;
  letter-spacing: 0.009375em;
  align-self: center;
}
</style>
