<template>
  <mosaic-card-and-list-page
    object-type="Course Activity"
    title="Course Activity"
    :load="load"
    :list-items="studentAssignments"
  >
    <template #actions>
      <mosaic-btn icon="mdi-pencil" :to="{ name: 'CohortAssignmentEditPage' }">Edit</mosaic-btn>
    </template>

    <template v-if="assignment" #card-content>
      <mosaic-text-field
        :readonly="true"
        name="name"
        label="Name"
        :model-value="assignment.name"
        prepend-icon="mdi-pencil"
      />
      <mosaic-date-picker
        v-if="assignment.dueDate"
        :readonly="true"
        name="due-date"
        label="Due date"
        :date="assignment.dueDate"
      />
      <mosaic-text-field
        v-else
        :readonly="true"
        name="due-date"
        label="Due date"
        model-value="No due date"
        prepend-icon="mdi-calendar"
      />
      <mosaic-text-field
        :readonly="true"
        name="role"
        label="Who should complete this Course Activity?"
        :model-value="completingRole"
        prepend-icon="mdi-account-multiple-check"
      />
      <mosaic-text-field
        :readonly="true"
        name="template"
        label="Course Activity Template"
        :model-value="assignment.assignmentTemplate.name"
        prepend-icon="mdi-scissors-cutting"
        :link="{
          name: 'TutorAdminAssignmentTemplateEditPage',
          params: { institutionId: selectedInstitution.id, id: assignment.assignmentTemplate.id },
        }"
      />
      <curriculum-links
        class="mt-4"
        :selected-curriculum-statements="assignment.curriculumStatements"
        :can-edit="false"
        artefact-type="Course Activity"
      >
      </curriculum-links>
    </template>

    <template #list-filters>
      <div class="d-flex">
        <mosaic-select
          v-model="statusFilter"
          class="multiple-v-select"
          style="min-width: 250px"
          multiple
          item-value="value"
          item-title="title"
          label="Filter by status"
          :items="studentAssignmentStatusItems"
          hide-details
        />
        <mosaic-select
          v-model="selectedGradedFilter"
          style="min-width: 150px"
          label="Filter by graded"
          :items="notGradedItems"
          hide-details
        />
      </div>
      <mosaic-cohort-monitoring-filters
        :students="selectedCohortStudents"
        @update:filtered-student-ids="filteredStudentIds = $event"
      />
    </template>

    <template v-if="assignment" #list-item="{ item: sa }">
      <mosaic-list-item
        :key="sa.id"
        :title="sa.student.name"
        icon="mdi-clipboard-text"
        :to="{
          name: 'TutorAssignmentLatestVersionPage',
          params: { studentId: sa.student.id, assignmentId: assignment.id.toString() },
        }"
      >
        <template #information>
          <assignment-version-chip
            v-if="sa.versionNumber"
            :version-number="sa.versionNumber"
            :is-student="false"
            class="mr-2"
          />
          <assignment-grade-chip
            :status="sa.status"
            :grade="sa.reviewPageGradeJudgementDescriptor"
            class="mr-2"
            force-show-grade
          />
          <assignment-status-chip :status="sa.status" />
        </template>
      </mosaic-list-item>
    </template>
  </mosaic-card-and-list-page>
</template>

<script setup lang="ts">
import MosaicCardAndListPage from '@/components/library/pages/MosaicCardAndListPage.vue';
import type { CurriculumStatementSlim, CurriculumStatementSlimLegacy } from '@/store/map-store';
import AssignmentStatusChip from './AssignmentStatusChip.vue';
import CurriculumLinks from '@/components/CurriculumLinks.vue';
import { mapState, mapActions, mapGetters, type JudgementDescriptor } from '@/store/map-store';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { computed } from 'vue';
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import AssignmentGradeChip from './AssignmentGradeChip.vue';
import AssignmentVersionChip from './AssignmentVersionChip.vue';
import type { StudentAssignmentStatus } from './student-assignments';
import { studentAssignmentStatuses, studentAssignmentStatusItems } from './student-assignments';
import { filterByNameOrEmail } from '@/components/library/filters/filters';
import { useApi } from '@/composables/api';
import { useCohortStore } from '@/stores/cohort';
import { syncQueryParam } from '@/composables/query';

const api = useApi();

const { selectedInstitution } = mapState();

const route = useRoute();
const assignmentId = route.params.id;
interface StudentAssignment {
  id: number;
  status: StudentAssignmentStatus;
  student: {
    id: number;
    name: string;
    email: string;
  };
  versionNumber?: number;
  reviewPageGradeJudgementDescriptor: JudgementDescriptor | null;
}

interface Assignment {
  id: number;
  name: string;
  dueDate: string;
  roleId: number | null;
  isTraineeContributor: boolean;
  curriculumStatements: CurriculumStatementSlim[] | CurriculumStatementSlimLegacy[];
  assignmentTemplate: {
    id: number;
    name: string;
  };
  studentAssignments: StudentAssignment[];
}
const { loadRoles } = mapActions();
loadRoles();
const { rolesWithStudent } = mapGetters();
const completingRole = computed(() => {
  const nullSafeRoleId = assignment.value?.roleId ?? 'student';
  return rolesWithStudent.value.find(r => r.id === nullSafeRoleId)?.pluralisedName;
});

const traineeFilter = ref('');
const statusFilter = ref([...studentAssignmentStatuses]);
syncQueryParam(statusFilter, 'status', 'string[]');

const selectedGradedFilter = ref('all');
syncQueryParam(selectedGradedFilter, 'graded', 'string');

const notGradedItems = [
  { text: 'All', value: 'all' },
  { text: 'Graded', value: 'graded' },
  { text: 'Not graded', value: 'not-graded' },
];
const gradedfilter = (sa: StudentAssignment) => {
  if (selectedGradedFilter.value === 'all') return true;
  if (selectedGradedFilter.value === 'graded') return !!sa.reviewPageGradeJudgementDescriptor;
  return !sa.reviewPageGradeJudgementDescriptor;
};
const { selectedCohortStudents } = useCohortStore();
const filteredStudentIds = ref<number[]>(selectedCohortStudents.value.map(s => s.id));

const assignment = ref<Assignment>();
const studentAssignments = computed(
  () =>
    assignment.value?.studentAssignments
      .filter(
        sa =>
          filterByNameOrEmail(sa.student, traineeFilter.value) &&
          gradedfilter(sa) &&
          statusFilter.value.includes(sa.status) &&
          filteredStudentIds.value.includes(sa.student.id)
      )
      .sortBy(['student.name', 'student.email']) || []
);

setBreadcrumbs(
  computed(() => [
    {
      text: `Course Activities`,
      to: {
        name: 'CohortAssignmentsListPage',
      },
    },
    {
      text: assignment.value?.name || '',
    },
  ])
);

async function load() {
  const r = await api.get<Assignment>(`/assignments/${assignmentId}`);
  assignment.value = r.data;
}
</script>
