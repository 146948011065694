<template>
  <div>
    <mosaic-expandable-user-list
      :lists="studentsLists"
      :title-for-exported-list="`Review Point Status per ${traineeNounCapitalised()}`"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { mapGetters } from '@/store/map-store';

const { reviewNounCapitalised, traineeNounCapitalisedAndPluralised, traineeNounCapitalised } = mapGetters();

type ReviewStatus = 'notstarted' | 'started' | 'partiallycompleted' | 'completed' | 'approved';
const props = defineProps<{
  students: {
    id: number;
    userId: number;
    email: string;
    displayName: string;
    profilePictureUpdatedAt: string;
    hideProfilePicture: boolean;
    studentReview: { status: ReviewStatus };
  }[];
  statuses: ReviewStatus[];
  actionTargetTitle?: string;
}>();

const displayStatus = {
  notstarted: 'Not started',
  started: 'Started',
  partiallycompleted: 'Partially completed',
  completed: 'Completed',
  approved: 'Approved',
};

const studentsLists = computed(() =>
  props.statuses.map(status => {
    const studentReviews = props.students
      .filter(x => x.studentReview.status === status)
      .map(student => ({
        avatar: {
          user: {
            id: student.userId,
            displayName: student.displayName,
            email: student.email,
            profilePictureUpdatedAt: student.profilePictureUpdatedAt,
          },
          hideProfilePicture: student.hideProfilePicture,
        },
      }));

    return {
      id: status,
      showExpand: status !== 'notstarted',
      title: {
        text: `${displayStatus[status]}: ${studentReviews.length} ${
          studentReviews.length !== 1 ? traineeNounCapitalisedAndPluralised.value : traineeNounCapitalised.value()
        }`,
        color: status !== 'notstarted' && studentReviews.length ? 'error' : undefined,
      },
      items: studentReviews,
      exportConfig: {
        title: `${reviewNounCapitalised.value} - ${displayStatus[status]}`,
        subtitle: `${traineeNounCapitalisedAndPluralised.value} with the ${
          props.actionTargetTitle ? `"${props.actionTargetTitle}"` : ''
        } ${reviewNounCapitalised.value} marked as ${displayStatus[status].toLowerCase()}`,
      },
    };
  })
);
</script>
