<template>
  <mosaic-loading-and-error-cards
    v-model:trigger-background-load="internalTriggerBackgroundLoad"
    :object-type="objectTypePluralised"
    :load="load"
    loading-type="list"
  >
    <mosaic-card>
      <mosaic-card-title :edit="editTitle">
        <span>{{ titleInternal }}</span>

        <template #subtitle v-if="subtitle">
          {{ subtitle }}
        </template>

        <template #chip>
          <slot name="title-chip"></slot>
        </template>

        <template #actions>
          <slot name="title-actions"></slot>
        </template>
      </mosaic-card-title>

      <div v-if="!isSlotEmpty($slots.subtitle)" class="mt-3">
        <slot name="subtitle"></slot>
      </div>
      <mosaic-card-subheading v-if="listTitle" class="mt-6">{{ listTitle }}</mosaic-card-subheading>
      <mosaic-inline-creation-list
        v-model:trigger-background-load="internalTriggerBackgroundLoad"
        :items="items"
        :icon="icon"
        :object-type="objectType"
        :hide-add="hideAdd"
        :can-add-item="canAddItem"
        :add-item="addItem"
        :edit-item-clicked="editItemClicked"
        :edit-action="editAction"
        :delete-url-stem="deleteUrlStem"
        :move-item="moveItem"
        :move-disabled-message="moveDisabledMessage"
        @after-update="afterUpdate()"
        @update:trigger-background-load="internalTriggerBackgroundLoad = $event"
      >
        <template #information="{ item }" v-if="$slots.information">
          <slot name="information" v-bind="{ item }"></slot>
        </template>

        <template #actions="{ item }" v-if="$slots.actions">
          <slot name="actions" v-bind="{ item }"></slot>
        </template>

        <template #add-item-fields>
          <slot name="add-item-fields"></slot>
        </template>
      </mosaic-inline-creation-list>
    </mosaic-card>
  </mosaic-loading-and-error-cards>
</template>

<script setup lang="ts" generic="T extends Item">
import { computed, ref, defineEmits, watch } from 'vue';
import { pluralise } from '@/utils/text';
import type { Item } from '../mosaic-list/list-item-helpers';
import { isSlotEmpty } from '@/utils/mosaic-slots';

/*
The LegacyMosaicInlineCreationListPage is for lists with a limited number of items (no filtering or pagination required) and items that are simple to create
e.g. just a name or a name and date
Longer lists or items with more complicated creation should use the MosaicFilterListPage.
*/

const props = defineProps<{
  objectType: string;
  title?: string;
  listTitle?: string;
  subtitle?: string;
  load: (force?: boolean) => Promise<void>;
  items: T[];
  icon: string;
  hideAdd: boolean;
  canAddItem: boolean;
  addItem: () => Promise<void>;
  editAction?: string;
  editItemClicked: (item: T) => void;
  moveItem?: (direction: 'up' | 'down', item: T) => Promise<void>;
  moveDisabledMessage?: string;
  deleteUrlStem: string;
  editTitle?: () => void;
  triggerBackgroundLoad: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:triggerBackgroundLoad', triggerBackgroundLoad: boolean): void;
  (e: 'afterUpdate'): void;
}>();

function afterUpdate() {
  emit('afterUpdate');
}

//Add item
const internalTriggerBackgroundLoad = ref(false);
watch(
  () => props.triggerBackgroundLoad,
  x => {
    internalTriggerBackgroundLoad.value = !!x;
  }
);
watch(internalTriggerBackgroundLoad, x => {
  emit('update:triggerBackgroundLoad', x);
});

const objectTypePluralised = computed(() => pluralise(props.objectType));
const titleInternal = computed(() => props.title || objectTypePluralised.value);
</script>
