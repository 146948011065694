<template>
  <div>
    <legacy-mosaic-inline-creation-list-page
      object-type="Competency"
      :title="name"
      :load="load"
      :items="competencyItems"
      :icon="icons.instructorTrainingCompetency"
      :hide-add="false"
      :can-add-item="canAddCompetency"
      :add-item="addCompetencyItem"
      :can-retitle-item="canRetitleCompetency"
      :retitle-item-dialog-opened="retitleCompetency"
      :retitle-item="saveRetitleCompetency"
      :delete-url-stem="deleteUrlStem"
      :move-item="moveCompetencyListItem"
      @after-update="afterUpdate()"
    >
      <template #add-item-fields>
        <mosaic-text-field
          v-model="newCompetencyCode"
          name="add-competency-code"
          label="Code"
          prepend-icon="mdi-pencil"
          style="width: 100px"
        />
        <mosaic-text-field
          v-model="newCompetencyName"
          name="add-competency-name"
          label="Name"
          prepend-icon="mdi-pencil"
          class="flex-grow-1 pl-4"
        />
      </template>

      <template #retitle-item-fields="{ onKeyupEnter }">
        <div class="d-flex align-center">
          <mosaic-text-field
            v-model="renameDialog.code"
            name="rename-competency-code"
            label="Code"
            prepend-icon="mdi-pencil"
            style="width: 100px"
            class="mr-4"
          />
          <mosaic-text-field
            v-model="renameDialog.name"
            name="rename-competency-name"
            label="Name"
            prepend-icon="mdi-pencil"
            @keyup.enter="onKeyupEnter"
            class="flex-grow-1"
          />
        </div>
      </template>
    </legacy-mosaic-inline-creation-list-page>
  </div>
</template>

<script setup lang="ts">
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { computed, ref } from 'vue';
import type { Item } from '@/components/library/mosaic-list/list-item-helpers';
import { useMoveListItem, useRetitleListItem } from '@/components/library/mosaic-list/list-item-helpers';
import { enumerateItems } from '@/utils/text';
import { parseRouteId } from '@/composables/vue-router';
import { useApi } from '@/composables/api';
import { icons } from '@/utils/icons';
import { useStaffStore } from '@/stores/staff';
import { useStaffTrainingStore } from '@/stores/staff-training';
import { useInstitutionStaffTrainingStore } from '@/stores/institution-staff-training';

const api = useApi();
const { userStaff } = useStaffStore();
const {
  actions: { loadStaffTraining },
} = useStaffTrainingStore();
const {
  actions: { clearInstitutionStaffTrainingFrameworks },
} = useInstitutionStaffTrainingStore();

const themeId = parseRouteId('id');

type StaffTrainingCompetencyTheme = {
  id: number;
  name: string;
  staffTrainingFramework: {
    id: number;
    name: string;
    status: 'published' | 'draft';
  };
  staffTrainingCompetencies: Competency[];
};

type Competency = {
  id: number;
  order: number;
  name: string;
  code: string;
  hasStaffTrainingRecordCompetencies: boolean;
  staffTrainingCompetencyRequirements: {
    id: number;
  }[];
  staffTrainingAcceptedCertificateCompetencies: {
    id: number;
  }[];
  publishedStaffTrainingEventCompetencies: {
    id: number;
  }[];
};

const theme = ref<StaffTrainingCompetencyTheme | null>(null);
const name = computed(() => theme.value?.name || '');

async function load() {
  const r = await api.get<StaffTrainingCompetencyTheme>(
    `/staff-training/frameworks/competency-themes/${themeId.value}`
  );
  theme.value = r.data;
}

setBreadcrumbs(
  computed(() => [
    { text: 'Instructors', to: { name: 'InstitutionStaffListPage' } },
    { text: 'Training', to: { name: 'InstitutionStaffTrainingPage' } },
    {
      text: `Competency Frameworks`,
      to: {
        name: 'InstitutionStaffTrainingPage',
        query: { tab: 'frameworks' },
      },
    },
    ...(theme.value
      ? [
          {
            text: theme.value?.staffTrainingFramework.name || '',
            to: {
              name: 'InstitutionStaffTrainingFrameworkPage',
              params: { id: theme.value.staffTrainingFramework.id.toString() },
            },
          },
          {
            text: name.value,
          },
        ]
      : []),
  ])
);

// Transform Competencies to Simple List Items

const competencyItems = computed(() => {
  let competencies: Item[] = [];
  if (theme.value) {
    competencies = theme.value.staffTrainingCompetencies
      .map(c => ({
        id: c.id,
        order: c.order,
        title: `${c.code} - ${c.name}`,
        hideMove: false,
        subtitle: `${enumerateItems(
          c.staffTrainingCompetencyRequirements.length,
          'Training Module Requirement'
        )} - ${enumerateItems(
          c.staffTrainingAcceptedCertificateCompetencies.length,
          'Certificate Requirement'
        )} - ${enumerateItems(c.publishedStaffTrainingEventCompetencies.length, 'Event Requirement')}`,
        warningMessage:
          c.staffTrainingCompetencyRequirements.length === 0 &&
          c.staffTrainingAcceptedCertificateCompetencies.length == 0 &&
          c.publishedStaffTrainingEventCompetencies.length == 0 &&
          theme.value!.staffTrainingFramework.status === 'published'
            ? 'This Competency has no Training Module, Certificate or published Event requirements. The Competency will still show to Instructors assigned to this Framework, but they will only be able to meet it through Manual Training Records.'
            : '',
        disableDelete: c.staffTrainingCompetencyRequirements.length > 0 || c.hasStaffTrainingRecordCompetencies,
        deleteDisabledTooltip: c.staffTrainingCompetencyRequirements.length
          ? 'Cannot delete Competency with requirements'
          : c.hasStaffTrainingRecordCompetencies
          ? 'Cannot delete Competency that Instructors have already marked as met'
          : '',
        clickTo: {
          name: 'InstitutionStaffTrainingCompetencyPage',
          params: {
            frameworkId: theme.value!.staffTrainingFramework.id.toString(),
            themeId: theme.value!.id.toString(),
            id: c.id.toString(),
          },
        },
      }))
      .sortBy('order');
  }
  return competencies;
});

// Add Competency

const newCompetencyName = ref('');
const newCompetencyCode = ref('');
const canAddCompetency = computed(() => !!newCompetencyName.value && !!newCompetencyCode.value);

async function addCompetencyItem() {
  await api.post(`/staff-training/frameworks/competency-themes/${themeId.value}/competencies`, {
    name: newCompetencyName.value,
    code: newCompetencyCode.value,
  });
}

// Move Competency

const { moveListItem } = useMoveListItem('/staff-training/frameworks/competency-themes/competencies', api);
async function moveCompetencyListItem(direction: 'up' | 'down', listItem: Item) {
  await moveListItem(direction, listItem);
  clearInstitutionStaffTrainingFrameworks();
}

// Delete Competency
const deleteUrlStem = '/staff-training/frameworks/competency-themes/competencies';

// Retitle Competency
const renameDialog = ref({ name: '', originalName: '', code: '', originalCode: '' });

function retitleCompetency(competencyItem: Item) {
  const competency = theme.value?.staffTrainingCompetencies.find(c => c.id === competencyItem.id);
  if (!competency) return;
  renameDialog.value = {
    code: competency.code,
    name: competency.name,
    originalName: competency.name,
    originalCode: competency.code,
  };
}

const canRetitleCompetency = computed(
  () =>
    (!!renameDialog.value.name &&
      !!renameDialog.value.code &&
      renameDialog.value.name !== renameDialog.value.originalName) ||
    renameDialog.value.code !== renameDialog.value.originalCode
);

const body = computed(() => ({
  name: renameDialog.value.name,
  code: renameDialog.value.code,
}));
const { retitleListItem } = useRetitleListItem('/staff-training/frameworks/competency-themes/competencies', body, api);
async function saveRetitleCompetency(itemId: number) {
  await retitleListItem(itemId);
  clearInstitutionStaffTrainingFrameworks();
}

async function afterUpdate() {
  await loadStaffTraining(userStaff.value.id, true);
  clearInstitutionStaffTrainingFrameworks();
}
</script>
