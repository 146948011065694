<template>
  <div>
    <mosaic-save-card
      :save="save"
      :can-save="canSave"
      object-type="Reflection"
      :is-creating="isCreating"
      :return-to="returnTo"
      :readonly="!editable"
      :preview="preview"
    >
      <mosaic-create-edit-title
        object-type="Reflection"
        :is-creating="isCreating"
        :readonly="!editable"
        :subtitle="reflection ? { action: 'Created', name: createdBy, actionAt: reflection.createdAt } : undefined"
      >
        <template #title-chip v-if="reflection && adminReflectionStudentId">
          <v-chip v-if="!reflection.submitted" color="accent">Active</v-chip>
          <v-chip v-else color="primary">Completed</v-chip>
        </template>
      </mosaic-create-edit-title>
      <mosaic-text-area
        name="reflection-title"
        v-if="!adminReflectionStudentId"
        v-model="entryTitle"
        class="mb-4"
        label="Title"
        hide-details
        autofocus
        auto-grow
        rows="1"
        prepend-icon="pencil"
        :readonly="!editable"
      />
      <mosaic-text-field
        v-else
        name="title"
        label="Title"
        :readonly="true"
        :model-value="entryTitle"
        prepend-icon="pencil"
      />
      <mosaic-text-field
        v-if="prompt"
        name="prompt"
        label="Prompt"
        :readonly="true"
        :model-value="prompt"
        prepend-icon="pencil"
      />

      <mosaic-quill-field
        v-if="reflection && !reflection.contentLayout"
        name="reflection"
        label="Reflection"
        v-model="content"
        class="mt-5"
        :readonly="!editable"
        :has-min-height="true"
      />
      <div v-else>
        <mosaic-select
          label="Reflection Type"
          v-model="mosaicSelectTemplateId"
          :items="reflectionTemplates"
          item-title="studentFacingName"
          item-value="id"
          :readonly="!!reflection"
          :prepend-icon="icons.artefactType"
        />
        <mosaic-content-layout
          v-if="contentLayout"
          :content-layout="contentLayout"
          get-resource-url-prefix="/reflection-templates/resources"
          get-file-url-prefix="/reflections/files"
          :upload-file-presign-url="`/presign/students/${studentId}/reflection-files`"
          v-model:comments="comments"
          v-model:tasks="tasks"
          v-model:files="files"
          :preview="preview"
          :readonly="!editable"
        />
      </div>

      <curriculum-links
        class="mt-4"
        :selected-curriculum-statements="curriculumStatements"
        :curriculum-statement-id="curriculumStatementId"
        :can-edit="!adminReflectionStudentId && editable"
        artefact-type="Reflection"
        @update:link-added="addCurriculumLink"
        @update:link-removed="removeCurriculumLink"
      />

      <template #beside-buttons>
        <div class="d-flex align-center" v-if="adminReflectionStudentId && editable">
          <div>
            <mosaic-checkbox v-model="submitted" no-icon label="Mark as Complete" class="mt-0 mr-1" />
          </div>
          <mosaic-help>
            <div>This will help your course leads know</div>
            <div>that this reflection is ready to review</div>
          </mosaic-help>
        </div>
      </template>
    </mosaic-save-card>

    <mosaic-dialog v-model:active="markCompleteDialog.active" title="Mark as complete?">
      <div>
        It looks like you've added to your Reflection, but have not marked it as complete. Marking your Reflection as
        complete will help your course leads know that this Reflection is ready to review.
      </div>

      <div class="pt-4">Would you like to mark this Reflection as complete?</div>
      <mosaic-checkbox v-model="markCompleteDialog.markAsComplete" no-icon label="Mark as Complete" />

      <template #buttons>
        <v-btn variant="text" ripple color="primary" @click="markCompleteDialog.active = false">Save</v-btn>
      </template>
    </mosaic-dialog>

    <mosaic-dialog
      v-model:active="changeTemplateDialog.active"
      title="Change Reflection Type"
      :hide-close="true"
      @click-outside="cancelChangeTemplate"
    >
      <div>
        You are changing the type of this Reflection. If you do this, you will lose all of your in progress work for
        this Reflection.
      </div>

      <template #buttons>
        <v-btn variant="text" ripple @click="cancelChangeTemplate">Cancel</v-btn>
        <v-btn variant="text" ripple color="error" @click="changeTemplate">Change</v-btn>
      </template>
    </mosaic-dialog>
  </div>
</template>

<script setup lang="ts">
import CurriculumLinks from '@/components/CurriculumLinks.vue';
import MosaicContentLayout from '@/components/mosaic-content-layout/MosaicContentLayout.vue';
import {
  createContentLayoutCompletionComments,
  createContentLayoutCompletionCommentsFromTemplate,
  createContentLayoutCompletionFiles,
  createContentLayoutCompletionFilesFromTemplate,
  createContentLayoutCompletionTasks,
  createContentLayoutCompletionTasksFromTemplate,
  type ContentLayout,
  type ContentLayoutCompletionComment,
  type ContentLayoutCompletionFile,
  type ContentLayoutCompletionTask,
} from '@/utils/content-layout';
import { syncRef, syncRefs, until } from '@vueuse/core';
import { useUserStore } from '@/stores/user';
import { ref, computed, watchEffect, watch, type WatchStopHandle } from 'vue';
import type { RouteLocationNamedRaw } from 'vue-router';
import { useApi } from '@/composables/api';
import { useCurriculumStore } from '@/stores/curriculum';
import { useStudentStore } from '@/stores/student';
import { todaysDate } from '@/mixins/global-mixins';
import type { CurriculumStatement } from '@/store/map-store';
import { fromSnakeCaseToCamelCase } from '@/utils/transforms';
import { icons } from '@/utils/icons';

const props = withDefaults(
  defineProps<{
    // reflection prop cannot by updated after the component has been created
    reflection?: ReflectionResponse;
    studentId?: number;
    curriculumStatementId?: string;
    returnTo: RouteLocationNamedRaw;
    editable: boolean;
    preview?: boolean;
    templates?: { id: number; studentFacingName: string; contentLayout: ContentLayout }[];
  }>(),
  {
    preview: false,
    templates: () => [],
  }
);

if (!props.reflection && props.templates.length == 0)
  throw `ReflectionCard when creating requires at least one template`;
if (props.editable && !props.preview && !props.studentId)
  throw `Editable (and not preview) ReflectionCard requires a studentId`;

const emit = defineEmits<{
  'update:dirty': [dirty: boolean];
  'update:reflection': [r: ReflectionResponse];
}>();

defineExpose({ save });

interface ReflectionResponse {
  id: number;
  title: string;
  createdAt: string;
  prompt: string;
  submitted: boolean;
  contentLayout: ContentLayout;
  adminReflectionStudentId: number;
  reflectionContentLayoutComments: {
    comment: string;
    reflectionCommentId: string;
  }[];
  reflectionContentLayoutTasks: {
    completed: boolean;
    reflectionTaskId: string;
  }[];
  reflectionContentLayoutFiles: {
    title: string;
    fileId: string;
    reflectionFileUploadId: string;
  }[];
  curriculumStatements: {
    id: number;
    statement: string;
    code: string;
    subjectId: number;
    hidden: boolean;
  }[];
  reflectionTemplate?: {
    id: number;
    studentFacingName: string;
  };
  // Just used for the editing of old style reflections
  content?: string;
  template?: string;
}

const api = useApi();

const { user } = useUserStore();
const {
  actions: { loadCurriculum },
} = useCurriculumStore();
loadCurriculum({ force: false, throwError: false });
const { traineeNoun } = useStudentStore();

const submitted = ref(true);
const content = ref<string | undefined>('');
const defaultTitle = `Reflection - ${todaysDate()}`;
const entryTitle = ref('');
const prompt = ref('');
const curriculumStatements = ref<{ id: number }[]>([]);

const templateId = props.reflection
  ? props.reflection.reflectionTemplate?.id
  : props.templates.length == 1
  ? props.templates[0].id
  : undefined;
const selectedTemplateId = ref(templateId);
const mosaicSelectTemplateId = ref(templateId);

const changeTemplateDialog = ref({ active: false });
watch(mosaicSelectTemplateId, templateId => {
  if (!templateId || templateId == selectedTemplateId.value) return;
  if (commentsDirty.value || tasksDirty.value || filesDirty.value) {
    changeTemplateDialog.value = {
      active: true,
    };
  } else {
    selectedTemplateId.value = templateId;
  }
});

function changeTemplate() {
  selectedTemplateId.value = mosaicSelectTemplateId.value;
  changeTemplateDialog.value.active = false;
}

function cancelChangeTemplate() {
  mosaicSelectTemplateId.value = selectedTemplateId.value;
  changeTemplateDialog.value.active = false;
}

const reflectionTemplates = computed(() => {
  if (
    !props.reflection ||
    !props.reflection.reflectionTemplate ||
    props.templates.some(t => t.id == props.reflection?.reflectionTemplate?.id)
  ) {
    return props.templates;
  }

  // This is when viewing or editing an existing reflection and the template is not assigned to the cohort
  // Therefore will not need the content layout
  return props.templates.concat([{ ...props.reflection.reflectionTemplate, contentLayout: { sections: [] } }]);
});

const contentLayout = computed(() =>
  props.reflection
    ? props.reflection.contentLayout
    : props.templates.find(t => t.id == selectedTemplateId.value)?.contentLayout
);

if (props.reflection) {
  entryTitle.value = props.reflection.title;
  prompt.value = props.reflection.prompt;
  content.value = props.reflection.content || props.reflection.template;
  curriculumStatements.value = props.reflection.curriculumStatements.map(cs => ({ ...cs }));
  submitted.value = props.reflection.submitted;
} else {
  entryTitle.value = defaultTitle;
}

const adminReflectionStudentId = computed(() => props.reflection?.adminReflectionStudentId || null);

// #region content layout completables
const comments = ref<ContentLayoutCompletionComment[]>([]);
const commentsDirty = ref(false);
const tasks = ref<ContentLayoutCompletionTask[]>([]);
const tasksDirty = ref(false);
const files = ref<ContentLayoutCompletionFile[]>([]);
const filesDirty = ref(false);

if (props.reflection) {
  const c = createContentLayoutCompletionComments(
    computed(() => props.reflection!.reflectionContentLayoutComments),
    c => c.reflectionCommentId
  );
  const t = createContentLayoutCompletionTasks(
    computed(() => props.reflection!.reflectionContentLayoutTasks),
    t => t.reflectionTaskId
  );
  const f = createContentLayoutCompletionFiles(
    computed(() => props.reflection!.reflectionContentLayoutFiles),
    f => f.reflectionFileUploadId
  );
  syncRef(c.comments, comments);
  syncRefs(c.commentsDirty, commentsDirty);
  syncRef(t.tasks, tasks);
  syncRefs(t.tasksDirty, tasksDirty);
  syncRef(f.files, files);
  syncRefs(f.filesDirty, filesDirty);
} else {
  let stopComments: WatchStopHandle | undefined = undefined;
  let stopCommentsDirty: WatchStopHandle | undefined = undefined;
  let stopTasks: WatchStopHandle | undefined = undefined;
  let stopTasksDirty: WatchStopHandle | undefined = undefined;
  let stopFiles: WatchStopHandle | undefined = undefined;
  let stopFilesDirty: WatchStopHandle | undefined = undefined;
  watch(contentLayout, syncContentLayoutCompletables);
  syncContentLayoutCompletables(contentLayout.value);

  function syncContentLayoutCompletables(cl?: ContentLayout) {
    if (stopComments) stopComments();
    if (stopCommentsDirty) stopCommentsDirty();
    if (stopTasks) stopTasks();
    if (stopTasksDirty) stopTasksDirty();
    if (stopFiles) stopFiles();
    if (stopFilesDirty) stopFilesDirty();

    if (cl) {
      const c = createContentLayoutCompletionCommentsFromTemplate(cl, id => ({
        reflectionCommentId: id,
      }));
      const t = createContentLayoutCompletionTasksFromTemplate(cl, id => ({ reflectionTaskId: id }));
      const f = createContentLayoutCompletionFilesFromTemplate();
      stopComments = syncRef(c.comments, comments);
      stopCommentsDirty = syncRefs(c.commentsDirty, commentsDirty);
      stopTasks = syncRef(t.tasks, tasks);
      stopTasksDirty = syncRefs(t.tasksDirty, tasksDirty);
      stopFiles = syncRef(f.files, files);
      stopFilesDirty = syncRefs(f.filesDirty, filesDirty);
    }
  }
}

// #endregion

const markCompleteDialog = ref({
  active: false,
  markAsComplete: false,
});

const isCreating = computed(() => !props.reflection);

const curriculumStatementsDirty = computed(() => {
  const originalIds = props.reflection ? props.reflection.curriculumStatements.map(s => s.id) : [];
  const currentIds = curriculumStatements.value.map(s => s.id);
  return originalIds.length !== currentIds.length || originalIds.some(id => !currentIds.includes(id));
});

const dirty = computed(() => {
  if (commentsDirty.value || filesDirty.value || tasksDirty.value || curriculumStatementsDirty.value) return true;

  if (!props.reflection) {
    return entryTitle.value !== defaultTitle || curriculumStatementsDirty.value;
  } else {
    const contentDirty = props.reflection.contentLayout
      ? false
      : content.value !== props.reflection.content && content.value !== props.reflection.template;

    return !!(
      contentDirty ||
      entryTitle.value !== props.reflection.title ||
      (props.reflection.adminReflectionStudentId && submitted.value !== props.reflection.submitted)
    );
  }
});
watchEffect(() => emit('update:dirty', dirty.value));

const canSave = computed(() => {
  const hasContentLayoutOrHasContent = contentLayout.value || !!content.value;
  return Boolean(hasContentLayoutOrHasContent && entryTitle.value && dirty.value);
});
const createdBy = computed(() => {
  return user.value.student ? 'Me' : traineeNoun.value;
});

function addCurriculumLink(x: CurriculumStatement) {
  curriculumStatements.value.push(x);
}

function removeCurriculumLink(x: CurriculumStatement) {
  const index = curriculumStatements.value.findIndex(s => s.id === x.id);
  curriculumStatements.value.splice(index, 1);
}

async function save() {
  if (!submitted.value && props.reflection && props.reflection.adminReflectionStudentId) {
    markCompleteDialog.value = {
      active: true,
      markAsComplete: false,
    };
    await until(computed(() => markCompleteDialog.value.active)).toBe(false);
    submitted.value = markCompleteDialog.value.markAsComplete;
  }

  await submitSave();
}

async function submitSave() {
  const reflection = {
    title: entryTitle.value,
    content: content.value,
    curriculumStatementIds: curriculumStatements.value.map(s => s.id),
    submitted: Boolean(props.reflection?.adminReflectionStudentId && submitted.value),
    comments: comments.value.map(c => ({ comment: c.comment, reflectionCommentId: c.templateId })),
    tasks: tasks.value.map(t => ({ completed: t.completed, reflectionTaskId: t.templateId })),
    files: files.value.map(f => ({ file_id: f.fileId, title: f.title, reflectionFileUploadId: f.templateId })),
    reflectionTemplateId: selectedTemplateId.value,
  };

  if (!props.reflection) {
    await api.post(`/students/${props.studentId}/reflections`, { ...reflection, contentLayout: contentLayout.value });
  } else {
    const r = await api.put<typeof reflection, ReflectionResponse>(`/reflections/${props.reflection.id}`, reflection);
    emit('update:reflection', fromSnakeCaseToCamelCase(r.data));
  }
  emit('update:dirty', false);
}
</script>
