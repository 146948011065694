import { Comment, Fragment, Text, isVNode } from 'vue';
import type { Slot, VNodeArrayChildren } from 'vue';

/**
 * Determines whether a slot is empty for Vue 3: https://github.com/vuejs/vue-next/issues/3056
 */
// Adapted from https://www.telerik.com/blogs/checking-vue-3-slots-emptiness
// Adapted from https://github.com/vuejs/vue-next/blob/ca17162e377e0a0bf3fae9d92d0fdcb32084a9fe/packages/runtime-core/src/helpers/renderSlot.ts#L77

function vNodeIsEmpty(vnodes: VNodeArrayChildren): boolean {
  if (vnodes === null) return true;
  return vnodes.every(node => {
    if (!isVNode(node)) return true;
    if (node.type === Comment) return true;
    // It appears children of Text nodes are always string, but typescript isn't type narrowing
    if (node.type === Text && !(node.children as string).trim()) return true;
    // it appears Vue also type casts for this check: https://github.com/vuejs/core/blob/ca17162e377e0a0bf3fae9d92d0fdcb32084a9fe/packages/runtime-core/src/helpers/renderSlot.ts#L77
    if (node.type === Fragment && vNodeIsEmpty(node.children as VNodeArrayChildren)) {
      return true;
    }

    return false;
  });
}

// This function does not work with scoped slots - they should just check using v-if="$slots.name"
// This is because it's not possible to see whether the slot is empty without evaluating it using some props
// And the props are only accessible within the slot itself
// Therefore, scoped slots shouldn't render themselves as empty (when outer components rely on detecting the presence of the slot)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isSlotEmpty = (slot: Slot<any> | undefined) => {
  if (!slot) return true;
  return vNodeIsEmpty(slot());
};
