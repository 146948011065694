import type { CurriculumStatementSlimLegacy } from '@/store/map-store';

export interface CourseReflection {
  admin_reflection_students: {
    id: number;
    student_id: number;
    started: boolean;
    submitted: boolean;
    student: {
      id: number;
      email: string;
      name: string;
      user_id: number;
      display_name: string;
      hide_profile_picture: boolean;
      profile_picture_updated_at: string;
    };
  }[];
  created_at: string;
  curriculum_statements: CurriculumStatementSlimLegacy[];
  id: number;
  title: string;
  prompt: string;
  template: string;
  schedule_created_date?: string;
  scheduled_at_date: string;
  selected_all: boolean;
  set_date?: string;
}

export const reflectionStatusItems = [
  { title: 'Completed', value: 'submitted' },
  { title: 'Started', value: 'started' },
  { title: 'Not Started', value: 'not_started' },
];

export function reflectionStatus(
  adminReflectionStudents: CourseReflection['admin_reflection_students'],
  studentId: number
) {
  const adminReflectionStudent = adminReflectionStudents.find(s => s.student_id === studentId);
  if (!adminReflectionStudent) return;
  return adminReflectionStudent.submitted ? 'submitted' : adminReflectionStudent.started ? 'started' : 'not_started';
}
