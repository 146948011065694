import type { Institution, Permission } from '@/store/map-store';
import type { SidebarItemConfig } from './common';
import { icons } from '@/utils/icons';

export function institutionSidebarConfig(
  traineeNounCapitalised: string,
  traineeNounCapitalisedAndPluralised: string,
  reviewNounCapitalisedAndPluralised: string
): SidebarItemConfig<InstitutionSidebarConfig>[] {
  const instructorAccountsChildren = [
    'InstitutionTutorStaffPage',
    'InstitutionTutorStaffTrainingModulePage',
    'InstitutionTutorStaffTrainingCompetencyThemePage',
    'InstitutionStaffTrainingRecordCreatePage',
    'InstitutionStaffTrainingRecordEditPage',
    'InstitutionStaffTrainingCertificateCreatePage',
    'InstitutionStaffTrainingCertificateEditPage',
    'InstitutionStaffTrainingEventPage',
  ];
  const instructorTrainingChildren = [
    'InstitutionStaffTrainingModulePage',
    'InstitutionStaffTrainingModuleSectionPage',
    'InstitutionStaffTrainingFrameworkPage',
    'InstitutionStaffTrainingCompetencyThemePage',
    'InstitutionStaffTrainingCompetencyPage',
    'InstitutionStaffTrainingAcceptedCertificateCreatePage',
    'InstitutionStaffTrainingAcceptedCertificateEditPage',
    'InstitutionStaffTrainingEventCreatePage',
    'InstitutionStaffTrainingEventEditPage',
  ];
  const instructorForumsChildren = ['InstitutionForumCreatePage', 'InstitutionForumEditPage'];
  return [
    {
      icon: icons.students,
      title: 'ECTs',
      children: [
        {
          icon: icons.ectsOverview,
          title: 'Overview',
          pageName: 'TutorEctsOverviewPage',
          allPermissions: ['Admin'],
          show: institution => institution.config.early_careers,
        },
        {
          icon: icons.ectsPending,
          title: 'Pending',
          pageName: 'TutorPendingEctsPage',
          allPermissions: ['Admin'],
          show: institution => institution.config.early_careers,
          children: ['TutorEctApproveRegistrationPage'],
        },
        {
          icon: icons.ectsCompleted,
          title: 'Completed',
          pageName: 'TutorCompletedEctsPage',
          allPermissions: ['Admin'],
          show: institution => institution.config.early_careers,
        },
        {
          icon: icons.search,
          title: `Search`,
          pageName: 'TutorAdminGlobalSearchPage',
          allPermissions: ['Admin'],
          show: institution => institution.config.early_careers,
        },
      ],
    },
    {
      icon: icons.cohort,
      title: 'Cohorts',
      pageName: 'TutorCohortListPage',
      allPermissions: ['Admin'],
      children: ['TutorAdminCohortCreatePage'],
    },
    {
      icon: icons.studentSearch,
      title: `Search ${traineeNounCapitalisedAndPluralised}`,
      pageName: 'TutorAdminGlobalSearchPage',
      allPermissions: ['Admin'],
      show: institution => !institution.config.mentor_training_only && !institution.config.early_careers,
    },
    {
      icon: icons.school,
      title: 'Schools',
      pageName: 'TutorSchoolsListPage',
      allPermissions: ['schools.edit'],
      show: institution => institution.config.early_careers,
    },
    {
      icon: icons.instructor,
      title: 'Instructors',
      children: [
        {
          icon: icons.instructorAccounts,
          title: 'Accounts',
          pageName: 'InstitutionStaffListPage',
          allPermissions: ['staff.edit'],
          show: institution => !institution.config.early_careers && !institution.config.mentor_training_only,
          children: instructorAccountsChildren,
        },
        {
          icon: icons.instructorTraining,
          title: 'Training',
          pageName: 'InstitutionStaffTrainingPage',
          allPermissions: ['staff.training.edit'],
          show: institution => !institution.config.early_careers && !institution.config.mentor_training_only,
          children: instructorTrainingChildren,
        },
        {
          icon: icons.forum,
          title: 'Forums',
          singleItemTitle: 'Instructor Forums',
          pageName: 'InstitutionForumsListPage',
          haveAnyPermissionInEachSet: [['staff.forums.edit'], ['staff.edit', 'staff.view']],
          show: institution => !institution.config.early_careers && !institution.config.mentor_training_only,
          children: instructorForumsChildren,
        },
      ],
    },
    {
      icon: icons.instructor,
      title: 'Instructors',
      pageName: 'InstitutionStaffListPage',
      allPermissions: ['staff.edit'],
      show: institution => institution.config.early_careers || institution.config.mentor_training_only,
      children: instructorAccountsChildren,
    },
    {
      icon: icons.instructorTraining,
      title: 'Training',
      pageName: 'InstitutionStaffTrainingPage',
      allPermissions: ['staff.training.edit'],
      show: institution => !institution.config.early_careers && institution.config.mentor_training_only,
      children: instructorTrainingChildren,
    },
    {
      icon: icons.forum,
      title: 'Forums',
      pageName: 'InstitutionForumsListPage',
      allPermissions: ['staff.forums.edit'],
      show: institution => !institution.config.early_careers && institution.config.mentor_training_only,
      children: instructorForumsChildren,
    },
    {
      icon: icons.files,
      title: 'Files Config',
      children: [
        {
          icon: icons.folderTemplates,
          title: 'Folder Structure',
          pageName: 'TutorAdminFolderTemplatesListPage',
          allPermissions: ['folderTemplates.edit'],
          show: institution => !institution.config.mentor_training_only && !institution.config.early_careers,
          children: ['TutorAdminFolderTemplatePage'],
        },
        {
          icon: icons.courseFilesTrainee,
          title: `${traineeNounCapitalised} Files`,
          pageName: 'TutorAdminStudentCourseFilesPage',
          allPermissions: ['courseFiles.edit'],
          show: institution => !institution.config.mentor_training_only && !institution.config.early_careers,
        },
        {
          icon: icons.courseFilesInstructor,
          title: `Instructor Files`,
          pageName: 'TutorAdminStaffFilesPage',
          allPermissions: ['courseFiles.edit', 'staff.edit'],
          show: institution => !institution.config.mentor_training_only && !institution.config.early_careers,
        },
      ],
    },
    //This is repeated so that the logic in the sidebar files remains the same,
    //and this menu item is named 'Files' rather than 'Instructor Files'.
    {
      icon: icons.files,
      title: `Files`,
      pageName: 'TutorAdminStaffFilesPage',
      allPermissions: ['courseFiles.edit', 'staff.edit'],
      show: institution => institution.config.mentor_training_only && !institution.config.early_careers,
    },
    {
      icon: icons.course,
      title: 'Course Config',
      children: [
        {
          icon: icons.curriculum,
          title: 'Curriculum',
          pageName: 'TutorAdminCurriculumPage',
          allPermissions: ['curriculum.edit'],
          show: institution =>
            !institution.config.mentor_training_only &&
            !institution.config.early_careers &&
            institution.config.has_curriculum,
          children: [
            'TutorAdminCurriculumThemePage',
            'InstAdminCurriculumStatementPage',
            'InstAdminCurriculumTeachingStrategyPage',
          ],
        },
        {
          icon: icons.courseActivity,
          title: 'Course Activities',
          pageName: 'TutorAdminAssignmentTemplatesListPage',
          allPermissions: ['assignments.edit'],
          show: institution => !institution.config.mentor_training_only && !institution.config.early_careers,
          children: [
            'TutorAdminAssignmentTemplateEditPage',
            'TutorAdminAssignmentTemplateSectionPage',
            'TutorAdminAssignmentTemplateCreatePage',
          ],
        },
        {
          icon: icons.goReact,
          title: 'GoReact Templates',
          pageName: 'InstitutionGoReactTemplatesListPage',
          allPermissions: ['goReactTemplates.edit'],
          show: institution => institution.config.use_go_react,
          children: ['InstitutionGoReactTemplateEditPage', 'InstitutionGoReactTemplateCreatePage'],
        },
        {
          icon: icons.calendar,
          title: 'Calendar',
          pageName: 'TutorAdminCalendarPage',
          allPermissions: ['calendar.edit'],
          show: institution => !institution.config.mentor_training_only && !institution.config.early_careers,
        },
        {
          icon: icons.target,
          title: 'Targets',
          pageName: 'TutorAdminTargetTemplatePage',
          show: institution => !institution.config.mentor_training_only && !institution.config.early_careers,
          allPermissions: ['Admin'],
        },
        {
          icon: icons.reflection,
          title: 'Reflections',
          pageName: 'InstitutionReflectionTemplatesListPage',
          allPermissions: ['Admin'],
          show: institution => !institution.config.mentor_training_only && !institution.config.early_careers,
          children: ['InstitutionReflectionTemplateCreatePage', 'InstitutionReflectionTemplateEditPage'],
        },
        {
          icon: icons.reflection,
          title: 'Course Reflections',
          pageName: 'TutorAdminCourseReflectionTemplatePage',
          allPermissions: ['Admin'],
          show: institution => !institution.config.mentor_training_only && !institution.config.early_careers,
        },
      ],
    },
    {
      icon: icons.reviewPoint,
      title: reviewNounCapitalisedAndPluralised,
      pageName: 'TutorAdminReviewTemplatesListPage',
      allPermissions: ['reviews.edit'],
      children: [
        'TutorAdminReviewTemplatePage',
        'TutorAdminReviewTemplateCreatePage',
        'TutorAdminReviewTemplateCopyPage',
        'TutorAdminReviewTemplateOverallPage',
        'TutorAdminReviewTemplatePerStandardPage',
        'TutorAdminReviewTemplateStandardPage',
        'TutorAdminReviewTemplatePart2Page',
      ],
      show: institution => !institution.config.mentor_training_only,
    },
    {
      icon: icons.settings,
      title: 'Settings',
      children: [
        {
          icon: icons.home,
          title: 'Home Pages',
          pageName: 'InstitutionHomePagesSettingsPage',
          allPermissions: ['Admin'],
          show: institution => !institution.config.mentor_training_only,
        },
        {
          icon: icons.home,
          title: 'Home Page',
          pageName: 'InstitutionHomePagesSettingsPage',
          allPermissions: ['Admin'],
          show: institution => institution.config.mentor_training_only,
        },
        {
          icon: icons.judgementSets,
          title: 'Judgement Sets',
          pageName: 'TutorAdminJudgementSetsPage',
          allPermissions: ['judgementSets.edit'],
          show: institution => !institution.config.mentor_training_only,
        },
        {
          icon: icons.featureConfig,
          title: 'Features',
          pageName: 'TutorAdminFeatureConfigPage',
          allPermissions: ['Admin'],
          show: institution => !institution.config.mentor_training_only && !institution.config.early_careers,
        },
        {
          icon: icons.permission,
          title: 'Role Permissions',
          pageName: 'TutorAdminPermissionsPage',
          allPermissions: ['Admin'],
          show: institution => !institution.config.mentor_training_only && !institution.config.early_careers,
        },
        {
          icon: icons.professionalResources,
          title: 'Professional Resources',
          pageName: 'InstitutionProfessionalResourcesConfigurationPage',
          allPermissions: ['Admin'],
        },
      ],
    },
  ];
}

export interface InstitutionSidebarConfig {
  icon: string;
  title: string;
  singleItemTitle?: string;
  pageName: string;
  allPermissions?: Permission[];
  haveAnyPermissionInEachSet?: Permission[][];
  show?: (institution: Institution) => boolean;
  children?: string[];
}
