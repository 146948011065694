<template>
  <div>
    <mosaic-error-snackbar v-model="deleteError" action="delete this Course Reflection" />
    <mosaic-list
      :items="paginatedList"
      :empty-text="`There are no ${type} Course Reflections. Add one by clicking the button above.`"
      class="py-0"
    >
      <template #item="{ item }">
        <mosaic-list-item
          :icon="icons.reflection"
          :title="item.title"
          :subtitle="subtitle(item)"
          :to="clickReflectionTo(item)"
        >
          <template #information>
            <cohort-action-curriculum-links-badge :curriculum-statements="item.curriculum_statements" />
            <mosaic-trainee-count-chip :count="item.admin_reflection_students.length" object-type="Course Reflection" />
          </template>

          <template #actions>
            <mosaic-delete-icon-btn
              object-type="Course Reflection"
              :disabled="deleteProcessing"
              @click.prevent="deleteCourseReflection(item)"
            />
          </template>
        </mosaic-list-item>
      </template>
    </mosaic-list>
    <mosaic-pagination v-model="currentPage" v-model:page-size="pageSize" :total="paginationTotal" />

    <mosaic-delete-dialog
      v-model:active="deleteDialog.active"
      :url="deleteDialog.url"
      object-type="Course Reflection"
      :object-name="deleteDialog.name"
      :deleting-data="deleteDialog.deletingStudentData"
      @delete="emit('update:triggerBackgroundLoad', true)"
    >
      <cohort-action-delete-dialog-information
        v-if="deleteDialog.deletingStudentData"
        action-noun="Course Reflection"
        :action-target-title="deleteDialog.name"
        :status-items="
          computed(() => reflectionStatusItems.map(s => ({ ...s, showDetails: s.value !== 'not_started' }))).value
        "
        :get-action-status="getReflectionStatus"
        :get-student-or-staff-details="getStudentDetails"
        :student-or-staff-ids-to-be-removed="deleteDialog.adminReflectionStudents.map(s => s.student_id)"
        :student-or-staff-noun="traineeNoun"
        :student-or-staff-noun-pluralised="traineeNounPluralised"
      />
    </mosaic-delete-dialog>
  </div>
</template>

<script setup lang="ts">
import { paginateList } from '@/components/library/pagination/pagination';
import { withProcessingAndError } from '@/composables/processing-and-errors';
import { icons } from '@/utils/icons';
import { computed, ref } from 'vue';
import type { RouteLocationNamedRaw } from 'vue-router';
import type { CourseReflection as CourseReflectionFull } from './course-reflections';
import { reflectionStatusItems, reflectionStatus } from './course-reflections';
import CohortActionDeleteDialogInformation from '@/components/cohort-actions/CohortActionDeleteDialogInformation.vue';
import { useApi } from '@/composables/api';
import CohortActionCurriculumLinksBadge from '@/components/cohort-actions/CohortActionCurriculumLinksBadge.vue';
import type { CurriculumStatementSlimLegacy } from '@/store/map-store';
import { useStudentStore } from '@/stores/student';

const { traineeNoun, traineeNounPluralised } = useStudentStore();

const api = useApi();

export interface CourseReflection {
  id: number;
  title: string;
  schedule_created_date: string | null;
  scheduled_date: string | null;
  set_date: string | null;
  created_at: string;
  admin_reflection_students: { id: number; student_id: number; submitted: boolean; reflection_id: number }[];
  curriculum_statements: CurriculumStatementSlimLegacy[];
}

const props = defineProps<{
  reflections: CourseReflection[];
  type: 'live' | 'scheduled';
  subtitle: (reflection: CourseReflection) => string;
  clickReflectionTo: (reflection: CourseReflection) => RouteLocationNamedRaw;
}>();

const emit = defineEmits<{
  (e: 'update:triggerBackgroundLoad', triggerBackgroundLoad: boolean): void;
}>();

const { paginatedList, currentPage, pageSize, paginationTotal } = paginateList(computed(() => props.reflections));

const deleteDialog = ref({
  active: false,
  url: '',
  name: '',
  deletingStudentData: false,
  adminReflectionStudents: [] as CourseReflectionFull['admin_reflection_students'],
});

const {
  action: deleteCourseReflection,
  processing: deleteProcessing,
  error: deleteError,
} = withProcessingAndError(async (reflection: CourseReflection) => {
  const isScheduled = !!reflection.scheduled_date && !reflection.schedule_created_date;
  let deletingStudentData = false;
  let adminReflectionStudents: CourseReflectionFull['admin_reflection_students'] = [];
  if (!isScheduled) {
    const r = await api.get<CourseReflectionFull>(`admin-reflections/${reflection.id}`);
    adminReflectionStudents = r.data.admin_reflection_students;
    if (adminReflectionStudents.some(s => s.started || s.submitted)) {
      deletingStudentData = true;
    }
  }
  deleteDialog.value = {
    active: true,
    url: `admin-reflections/${reflection.id}`,
    name: reflection.title,
    deletingStudentData,
    adminReflectionStudents,
  };
});

function getReflectionStatus(id: number) {
  return reflectionStatus(deleteDialog.value.adminReflectionStudents, id);
}

function getStudentDetails(id: number) {
  const adminReflectionStudent = deleteDialog.value.adminReflectionStudents.find(ars => ars.student.id === id);

  return adminReflectionStudent
    ? {
        id: adminReflectionStudent.id,
        userId: adminReflectionStudent.student.user_id,
        displayName: adminReflectionStudent.student.display_name,
        email: adminReflectionStudent.student.email,
        hideProfilePicture: adminReflectionStudent.student.hide_profile_picture,
        profilePictureUpdatedAt: adminReflectionStudent.student.profile_picture_updated_at,
      }
    : undefined;
}
</script>
