<template>
  <v-card>
    <v-card-text>
      <mosaic-load-error v-bind="props" @retry="emit('retry')" />
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
const props = defineProps<{
  objectType: string;
  objectTypeIsPlural?: boolean;
  viewingStudent?: boolean;
}>();

const emit = defineEmits<{
  (e: 'retry'): void;
}>();
</script>
