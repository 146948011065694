<template>
  <div v-if="staff">
    <mosaic-info-alert v-if="props.infoAlertMessage && sortedStaffRoles.length > 0">
      {{ props.infoAlertMessage }}
    </mosaic-info-alert>
    <mosaic-tab-item-list
      mosaic-key="roles"
      empty-text="This Instructor doesn't have any Roles assigned."
      :items="sortedStaffRoles"
      :action="
        props.canEditRoles && props.showEditRolesActions
          ? {
              icon: 'plus',
              text: 'Role',
              click: addRole,
            }
          : undefined
      "
    >
      <template #item="{ item: sr }">
        <mosaic-list-item icon="mdi-account-multiple-check " :title="sr.role.name">
          <template #subtitle>
            <mosaic-router-link
              v-if="sr.student && hasPermissionForSelectedCohort('Admin')"
              :to="{
                name: 'TutorStudentDetailsPage',
                params: { studentId: sr.student.id },
              }"
            >
              {{ sr.student.name || sr.student.email }}
            </mosaic-router-link>
            <mosaic-router-link
              v-else-if="
                sr.school &&
                (hasPermissionForSchool(userStaff, 'schools.edit', sr.school) ||
                  hasPermissionForSchool(userStaff, 'schools.view', sr.school))
              "
              :to="{
                name: 'TutorSchoolEditPage',
                params: { schoolId: sr.school.id },
              }"
            >
              {{ sr.school.name }}
            </mosaic-router-link>
            <mosaic-router-link
              v-else-if="sr.cohort && hasPermissionForCohort(userStaff, 'Admin', sr.cohort)"
              :to="{
                name: 'TutorStudentListPage',
                params: { cohortId: sr.cohort.id },
              }"
            >
              {{ sr.cohort.name }}
            </mosaic-router-link>
            <span v-else>{{ staffRoleSubtitle(sr) }}</span>
          </template>

          <template #information v-if="sr.cohort && sr.cohort.status === 'closed'">
            <v-chip color="secondary">Cohort Closed</v-chip>
          </template>

          <template #actions>
            <mosaic-icon-button
              v-if="
                    props.canEditRoles && props.showEditRolesActions && (staff.user.id !== user.id || !(sr.role.permissions.map((x: RolePermission) => x.name).includes('Admin') && !sr.cohort)
                  )"
              icon="delete"
              :tooltip="!props.canEditRoles ? `You don't have permission to remove Roles` : 'Remove Role'"
              @click.prevent="
                deleteRoleDialog.active = true;
                deleteRoleDialog.staffRole = sr;
              "
            />
          </template>
        </mosaic-list-item>
      </template>
    </mosaic-tab-item-list>

    <mosaic-save-dialog
      :save="submitDeleteRole"
      action="delete"
      object-type="Role"
      v-model:active="deleteRoleDialog.active"
      title="Remove Role"
    >
      <div>
        Are you sure you want to remove the "{{ deleteRoleDialog.staffRole.role.name }}" role from this instructor?
      </div>
    </mosaic-save-dialog>

    <mosaic-save-dialog
      :save="submitAddRole"
      :save-errors="{
        role_conflict: 'This person already has this role',
        student_is_archived: `This ${traineeNounCapitalised()} is archived. Please contact support if you'd like them to be unarchived.`,
      }"
      :can-save="!!addRoleDialog.roleAndScopeValid"
      action="add"
      object-type="Role"
      v-model:active="addRoleDialog.active"
      title="Add Role"
    >
      <role-select
        v-model:role="addRoleDialog.selectedRole"
        @update:scope="addRoleDialog.selectedScope = $event"
        @update:no-student-yet-cohort-id="addRoleDialog.selectedNoStudentYetCohortId = $event"
        @update:valid="addRoleDialog.roleAndScopeValid = $event"
      ></role-select>
    </mosaic-save-dialog>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { mapState, mapActions, mapGetters } from '@/store/map-store';
import RoleSelect from '@/components/RoleSelect.vue';
import MosaicIconButton from '@/components/library/buttons/MosaicIconButton.vue';
import { useRoute } from 'vue-router';
import { hasPermissionForCohort, hasPermissionForSchool } from '@/mixins/global-mixins';
import { useApi } from '@/composables/api';
import { useInstitutionStaffStore } from '@/stores/institution-staff';
import type { SelectedStaff, StaffRole, Role, RolePermission } from '@/stores/staff';
import { hasPermissionForSelectedCohort } from '@/composables/permission';

type AddRoleDialog = {
  active: boolean;
  selectedRole?: Role;
  selectedScope: {
    type: string;
    id: number;
  } | null;
  selectedNoStudentYetCohortId: number | null;
  roleAndScopeValid: boolean;
};
type DeleteRoleDialog = {
  active: boolean;
  staffRole: StaffRole;
};

const addRoleDialogDefaults: AddRoleDialog = {
  active: false,
  selectedRole: undefined,
  selectedScope: null,
  selectedNoStudentYetCohortId: null,
  roleAndScopeValid: false,
};

const { selectedInstitution, userStaff, user } = mapState();
const { loadRoles, refreshUser } = mapActions();
const {
  actions: { clearInstitutionStaff },
} = useInstitutionStaffStore();
const route = useRoute();
const api = useApi();

const props = withDefaults(
  defineProps<{
    staff: SelectedStaff | null;
    staffRoles: StaffRole[] | [];
    canEditRoles?: boolean;
    showEditRolesActions?: boolean;
    infoAlertMessage?: string;
  }>(),
  {
    staff: null,
    staffRoles: () => [],
    canEditRoles: false,
    showEditRolesActions: false,
  }
);

const emit = defineEmits(['updateStaff']);

loadRoles();

const sortedStaffRoles = computed(() => {
  return props.staffRoles.sortBy('role.name');
});

const { traineeNounCapitalised } = mapGetters();

function staffRoleSubtitle(staffRole: StaffRole) {
  if (staffRole.cohort) return staffRole.cohort.name;
  if (staffRole.no_student_yet_cohort)
    return `${staffRole.no_student_yet_cohort.name} (no ${traineeNounCapitalised.value()} yet)`;
  if (staffRole.school) return staffRole.school.name;
  return selectedInstitution.value.name;
}
const addRoleDialog = ref<AddRoleDialog>({ ...addRoleDialogDefaults });
const deleteRoleDialog = ref<DeleteRoleDialog>({
  active: false,
  staffRole: {
    created_at: '',
    id: -1,
    no_student_yet_cohort: null,
    role: {
      name: '',
      id: -1,
      permissions: [],
    },
    student: null,
    school: null,
    cohort: null,
  },
});

function addRole() {
  addRoleDialog.value.active = true;
}

async function submitAddRole() {
  const scope = addRoleDialog.value.selectedScope;
  await api.post(`/staff/${route.params.id}/roles`, {
    roleId: addRoleDialog.value.selectedRole?.id,
    institutionId: scope?.type === 'institution' ? scope.id : null,
    studentId: scope?.type === 'student' ? scope.id : null,
    noStudentYetCohortId:
      scope?.type === 'student' && scope?.id === -1 ? addRoleDialog.value.selectedNoStudentYetCohortId : null,
    cohortId: scope?.type === 'cohort' ? scope.id : null,
    schoolId: scope?.type === 'school' ? scope.id : null,
  });
  emit('updateStaff');
  clearInstitutionStaff();
  if (route.params.id === userStaff.value.id.toString()) {
    refreshUser();
  }
  addRoleDialog.value = {
    ...addRoleDialogDefaults,
  };
}

async function submitDeleteRole() {
  await api.delete(`/staff/${route.params.id}/roles/${deleteRoleDialog.value.staffRole.id}`);
  emit('updateStaff');
  clearInstitutionStaff();
  if (route.params.id == userStaff.value.id.toString()) {
    refreshUser();
  }
  deleteRoleDialog.value.active = false;
}
</script>
