import type { User, InstitutionConfigFlag } from '@/store/map-store';

declare global {
  interface Window {
    ndt_config: {
      environment: 'production' | 'staging' | 'development' | 'local';
      web_url: string;
      one_drive_client_id: string;
      one_drive_redirect_uri: string;
      enforce_password_strength: string;
      app_version: string;
      nasbtt_modules_frontend_path: string;
      supports_web_sockets: boolean;
    };
  }
}

export function vueDevTools() {
  return !isProduction();
}

function isProduction() {
  return window.ndt_config && window.ndt_config.environment == 'production';
}

export const webUrl = window.ndt_config.web_url;
export const nasbttModulesFrontendPath = window.ndt_config.nasbtt_modules_frontend_path;

// For turning off on local/review apps
const enforcePasswordStrength = window.ndt_config.enforce_password_strength !== 'false';

function isPasswordStrengthTurnedOnForCurrentUser(user: User | null): boolean {
  return isInstitutionConfigTurnedOnForCurrentUser(user, 'password_strength');
}

function isInstitutionConfigTurnedOnForCurrentUser(user: User | null, config: InstitutionConfigFlag): boolean {
  if (!user) return false;
  if (user.isAdmin) return true;
  if (user.student) {
    return user.student.institution.config[config];
  }

  return user.staff.some(s => s.institution.config[config]);
}

const supportsWebSockets = window.ndt_config.supports_web_sockets;
const environment = window.ndt_config.environment;

export default {
  vueDevTools,
  enforcePasswordStrength,
  isPasswordStrengthTurnedOnForCurrentUser,
  isInstitutionConfigTurnedOnForCurrentUser,
  supportsWebSockets,
  environment,
};
