import { defineStore } from 'pinia';
import { mapState, type CurriculumStatements } from '@/store/map-store';
import { computed, ref } from 'vue';
import { useStore } from './common';
import { useApi } from '@/composables/api';
import { capitaliseFirstLetters } from '@/utils/text';
import { CacheMap } from './cache-map';
import { dateIsInclusiveBetween } from '@/utils/date';

import {
  getContentLayoutItemsOfType,
  safelyGetCurriculumStatement,
  itemIsCurriculumLink,
  type ContentLayout,
  type ContentLayoutCurriculumLink,
} from '@/utils/content-layout';

export interface CohortCourseTerm {
  id: number;
  name: string;
  cohortCourseWeeks: TermWeek[];
}

interface TermWeek {
  id: number;
  name: string;
  startDate: string;
  endDate: string;
  contentLayout: ContentLayout;
}
export interface CohortCourse {
  id: number;
  termNoun: string;
  termNounPlural: string;
  weekNoun: string;
  weekNounPlural: string;
  weekTemplateContentLayout: ContentLayout;
  cohortCourseWeekTemplateSections: {
    id: number;
    title: string;
    order: number;
    contentLayout: ContentLayout;
    subjectId: number | null;
    subjectName: string | null;
  }[];
  cohortCourseTerms: CohortCourseTerm[];
}
export interface Week {
  id: number;
  name: string;
  taskCountAcrossSections: number;
  contentLayout: ContentLayout;
  commentCount: number;
  status: string;
  startDate: string;
  endDate: string;
  cohortCourseTerm: {
    name: string;
    id: number;
  };
  cohortCourseWeekSections: WeekSection[];
}
export interface StudentWeek {
  id: number;
  status: 'completed' | 'started' | 'not_started';
  studentId: number;
  cohortCourseWeek: {
    id: number;
    cohortCourseWeekSections: WeekSection[];
    name: string;
    startDate: string;
    endDate: string;
  };
  completedAt: string | null;
  studentCohortCourseWeekSections: StudentWeekSection[];
}

export interface WeekSection {
  id: number;
  title: string;
  order: number;
  contentLayout: ContentLayout;
  subjectId: number | null;
  subjectName: string | null;
}

export interface StudentWeekSection {
  id: number;
  cohortCourseWeekSectionId: number;
  completed: boolean;
  studentCohortCourseWeekComments: { comment: string; cohortCourseWeekSectionCommentId: string }[];
  studentCohortCourseWeekTasks: { completed: boolean; cohortCourseWeekSectionTaskId: string }[];
  studentCohortCourseWeekFiles: { fileId: string; title: string; cohortCourseWeekSectionFileUploadId: string }[];
}

interface EditSectionDialog {
  active: boolean;
  section: WeekSection;
}

interface EditWeekDialog {
  active: boolean;
  week: Week;
}

export const cohortCourseWeekStatuses = ['not_started', 'started', 'completed'] as const;
export type CohortCourseWeekStatus = (typeof cohortCourseWeekStatuses)[number];

export function sectionCurriculumSubjectConflict(
  contentLayout: ContentLayout,
  curriculumStatements: CurriculumStatements,
  subjectId: number | null
) {
  if (!contentLayout) return false;
  const curriculumLinks = getContentLayoutItemsOfType(
    contentLayout,
    itemIsCurriculumLink
  ) as ContentLayoutCurriculumLink[];

  return curriculumLinks.some(cl => {
    const curriculumStatement = safelyGetCurriculumStatement(cl, curriculumStatements);
    return curriculumStatement.subject_id && curriculumStatement.subject_id !== subjectId;
  });
}

export function useEditWeekDetails() {
  const editWeekDialog = ref<EditWeekDialog>({
    active: false,
    week: {
      id: 0,
      name: '',
      taskCountAcrossSections: 0,
      contentLayout: { sections: [] },
      commentCount: 0,
      status: '',
      startDate: '',
      endDate: '',
      cohortCourseTerm: {
        name: '',
        id: 0,
      },
      cohortCourseWeekSections: [],
    },
  });
  const editWeek = (week: Week | null) => {
    editWeekDialog.value = {
      active: true,
      week: week || {
        id: 0,
        name: '',
        taskCountAcrossSections: 0,
        contentLayout: { sections: [] },
        commentCount: 0,
        status: '',
        startDate: '',
        endDate: '',
        cohortCourseTerm: {
          name: '',
          id: 0,
        },
        cohortCourseWeekSections: [],
      },
    };
  };
  return { editWeek, editWeekDialog };
}

export function useEditWeekSectionDetails() {
  const editSectionDialog = ref<EditSectionDialog>({
    active: false,
    section: {
      id: 0,
      title: '',
      order: -1,
      contentLayout: { sections: [] },
      subjectId: null,
      subjectName: null,
    },
  });
  const editSection = (section: WeekSection) => {
    editSectionDialog.value = {
      active: true,
      section: section,
    };
  };

  return { editSection, editSectionDialog };
}

export function conflictingWeeks(term: CohortCourseTerm, startDate: string, endDate: string) {
  if (!(startDate || endDate)) return false;
  const conflictingWeeks = term.cohortCourseWeeks.filter(
    w =>
      dateIsInclusiveBetween(startDate, w.startDate, w.endDate) ||
      dateIsInclusiveBetween(endDate, w.startDate, w.endDate) ||
      dateIsInclusiveBetween(w.startDate, startDate, endDate) ||
      dateIsInclusiveBetween(w.endDate, startDate, endDate)
  );
  return conflictingWeeks;
}

export function isConflictingWeek(week: Week, term: CohortCourseTerm, startDate: string, endDate: string) {
  const conflictingWeeksArray: TermWeek[] = conflictingWeeks(term, startDate, endDate) || [];
  return conflictingWeeksArray.filter(w => w.id != week.id).length > 0;
}

export const useCohortCourseWeekStore = useStore(
  defineStore('cohortCourseWeek', () => {
    const api = useApi();
    const { selectedCohort } = mapState();

    const week = ref<Week | null>(null);

    function reset() {
      week.value = null;
    }

    async function loadCohortCourseWeek(weekId: number, force: boolean = false) {
      if (week.value && !force && week.value.id === weekId) return;
      const weekResponse = await api.get<Week>(`cohorts/${selectedCohort.value.id}/course/weeks/${weekId}`);
      week.value = weekResponse.data;
    }

    return { reset, week, loadCohortCourseWeek };
  })
);

export const useCohortCourseStore = useStore(
  defineStore('cohortCourse', () => {
    const api = useApi();
    const { selectedCohort } = mapState();

    const cohortCourseCache = ref<CacheMap<CohortCourse>>(new CacheMap());

    function reset() {
      cohortCourseCache.value.clear();
    }

    async function loadCohortCourse(force?: boolean) {
      if (!cohortCourseCache.value.get(selectedCohort.value.id) || force) {
        const r = await api.get<CohortCourse>(`/cohorts/${selectedCohort.value.id}/course`);
        cohortCourseCache.value.set(selectedCohort.value.id, r.data);
      }
    }

    function clearCohortCourse() {
      cohortCourseCache.value.remove(selectedCohort.value.id);
    }
    const selectedCohortCourse = computed(() => {
      return selectedCohort.value ? cohortCourseCache.value.getEvenIfExpired(selectedCohort.value.id) : undefined;
    });

    const cohortCourseTermNoun = computed(() => selectedCohortCourse.value?.termNoun || '');
    const cohortCourseTermNounCapitalised = computed(() => capitaliseFirstLetters(cohortCourseTermNoun.value));
    const cohortCourseTermNounPluralised = computed(() => selectedCohortCourse.value?.termNounPlural || '');
    const cohortCourseTermNounCapitalisedAndPluralised = computed(() =>
      capitaliseFirstLetters(cohortCourseTermNounPluralised.value)
    );
    const cohortCourseWeekNoun = computed(() => selectedCohortCourse.value?.weekNoun || '');
    const cohortCourseWeekNounCapitalised = computed(() => capitaliseFirstLetters(cohortCourseWeekNoun.value));
    const cohortCourseWeekNounPluralised = computed(() => selectedCohortCourse.value?.weekNounPlural || '');
    const cohortCourseWeekNounCapitalisedAndPluralised = computed(() =>
      capitaliseFirstLetters(cohortCourseWeekNounPluralised.value)
    );

    return {
      reset,
      selectedCohortCourse,
      cohortCourseTermNoun,
      cohortCourseTermNounCapitalised,
      cohortCourseTermNounPluralised,
      cohortCourseTermNounCapitalisedAndPluralised,
      cohortCourseWeekNoun,
      cohortCourseWeekNounCapitalised,
      cohortCourseWeekNounPluralised,
      cohortCourseWeekNounCapitalisedAndPluralised,
      loadCohortCourse,
      clearCohortCourse,
    };
  })
);
